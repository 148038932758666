import { serverTimestamp } from 'firebase/firestore';
import { filterByTimeRange, filterRows, paginateRows, sortByTime } from 'src/utils/table';

import { useFetchDeliveryLocations } from '../delivery-locations';
import { useFetchItems } from '../items';
import { useFetchLlcs } from '../llcs';
import { useFetchTerritories } from '../territories';
import { useFetchUsers } from '../users';
import { useFetchOrderQuery, useFetchOrdersQuery } from './orders.api';

export const getOrderDetails = ({
  order = {},
  territories = [],
  deliveryLocations = [],
  llcs = [],
  users = [],
  orderItems: allOrderItems = [],
  isDraft = false
}) => {
  const {
    overnightShipping = false,
    items = [],
    territoryId = null,
    deliveryLocationId = null,
    llcId = null,
    status = 'pending',
    userId = null,
    revisions = []
  } = order;

  const territory = territoryId ? territories.find(item => item.id === territoryId) : undefined;

  if (!territory) {
    return undefined;
  }

  const deliveryLocation = deliveryLocationId
    ? deliveryLocations.find(item => item.id === deliveryLocationId) || {}
    : {};
  const llc = llcId ? llcs.find(item => item.id === llcId) || {} : {};
  const user = userId ? users.find(item => item.id === userId) || {} : {};
  const oldOrderItems = items
    .map(item => {
      const orderItem = allOrderItems.find(orderItem => orderItem.id === item.id);

      // check for isDraft order to get latest item price or
      // check if item is using old format with id and quantity
      // to set full item props and avoid filtering this item out
      if (isDraft || Object.keys(item)?.length === 2) {
        return { ...item, ...(orderItem || {}) };
      } else {
        return { ...item };
      }
    })
    .filter(({ status }) => status === 'active');

  const newOrderItems = allOrderItems
    .filter(({ id, status }) => {
      const isActive = status === 'active';
      const isOrderItems = items.map(({ id }) => id).includes(id);

      return isActive && !isOrderItems;
    })
    .map(item => ({ ...item, quantity: 0 }));

  const orderItems = [...oldOrderItems, ...newOrderItems].map(item => {
    const { itemDesc = '', casesPerPallet = 1, casesPerOvernight = 1 } = item;
    const casesPer = overnightShipping
      ? `${casesPerOvernight} cases per overnight box`
      : `${casesPerPallet} cases per pallet`;
    const itemName = `${itemDesc} - ${casesPer}`.trim();

    return {
      ...item,
      itemName
    };
  });

  const statusName =
    status === 'fulfilled'
      ? 'Fulfilled'
      : status === 'approved'
      ? 'Approved'
      : status === 'revision'
      ? 'Revision Requested'
      : status === 'deleted'
      ? 'Deleted'
      : 'Pending';

  const orderRevisions = revisions.map(revisionItem => {
    const orderItem = orderItems.find(item => item.id === revisionItem.id) || {};
    const { itemDesc, itemName = '' } = orderItem;

    return {
      ...revisionItem,
      itemName,
      itemDesc
    };
  });

  return {
    ...order,
    items: orderItems,
    territory,
    deliveryLocation,
    llc,
    user,
    statusName,
    revisions: orderRevisions
  };
};

export const useFetchOrders = ({ where = null } = {}) => {
  const { data: orders = [], isLoading: loading_orders } = useFetchOrdersQuery({ where });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: deliveryLocations, isLoading: loading_deliveryLocations } = useFetchDeliveryLocations();
  const { data: llcs, isLoading: loading_llcs } = useFetchLlcs();
  const { data: users, isLoading: loading_users } = useFetchUsers();
  const { data: orderItems, isLoading: loading_orderItems } = useFetchItems();

  const data = orders
    .map(order => getOrderDetails({ order, territories, deliveryLocations, llcs, users, orderItems }))
    .filter(order => !!order);

  return {
    data,
    isLoading:
      loading_orders ||
      loading_territories ||
      loading_deliveryLocations ||
      loading_llcs ||
      loading_users ||
      loading_orderItems
  };
};

/**
 *
 * @param {string} search
 * @param {Object} filters
 * @param {string} territoryId
 * @param {string} sortedBy - Default 'desc'
 * @param {Object} dateRange - Object of {start, end} whose value is timestamp
 * @param {number} page
 * @param {number} limit
 * @param {Array} where - Array of [fieldPath: string | FieldPath, opStr: WhereFilterOp, value: any]
 * @returns {Object}
 */
export const useFetchOrdersTableRows = ({
  search = '',
  filters = {},
  territoryId = '',
  sortedBy = 'desc',
  dateRange = {
    start: serverTimestamp(),
    end: serverTimestamp()
  },
  page,
  limit,
  where = null
}) => {
  const { data, isLoading } = useFetchOrders({ where });

  const all = data || [];
  const filteredByTimeRange = filterByTimeRange(all, dateRange.start, dateRange.end);
  const filteredByTerritoryId =
    !territoryId || territoryId === 'all'
      ? filteredByTimeRange
      : filteredByTimeRange.filter(item => item.territoryId === territoryId);

  const filteredBySearch = filterRows(filteredByTerritoryId, search, filters);
  const sortedByCreatedAt = sortByTime(filteredBySearch, sortedBy);

  const pending = sortedByCreatedAt.filter(item => item.status === 'pending');
  const revision = sortedByCreatedAt.filter(item => item.status === 'revision');
  const approved = sortedByCreatedAt.filter(item => item.status === 'approved');
  const fulfilled = sortedByCreatedAt.filter(item => item.status === 'fulfilled');
  const deleted = sortedByCreatedAt.filter(item => item.status === 'deleted');

  const paginated = {
    all: paginateRows(sortedByCreatedAt, page, limit),
    pending: paginateRows(pending, page, limit),
    revision: paginateRows(revision, page, limit),
    approved: paginateRows(approved, page, limit),
    fulfilled: paginateRows(fulfilled, page, limit),
    deleted: paginateRows(deleted, page, limit)
  };

  return {
    data: {
      all,
      pending,
      revision,
      approved,
      fulfilled,
      deleted,
      paginated
    },
    isLoading
  };
};

export const useFetchOrder = ({ id }) => {
  const { data: order = {}, isLoading: loading_order } = useFetchOrderQuery({ id });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: deliveryLocations, isLoading: loading_deliveryLocations } = useFetchDeliveryLocations();
  const { data: llcs, isLoading: loading_llcs } = useFetchLlcs();
  const { data: users, isLoading: loading_users } = useFetchUsers();
  const { data: orderItems, isLoading: loading_orderItems } = useFetchItems();

  const data = getOrderDetails({ order, territories, deliveryLocations, llcs, users, orderItems });

  return {
    data,
    isLoading:
      loading_order ||
      loading_territories ||
      loading_deliveryLocations ||
      loading_llcs ||
      loading_users ||
      loading_orderItems
  };
};
