import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { CreatedAtSorter, DateRangeSelector, TextFilter } from 'src/components';

export const FulfillmentsListFilterAndSorter = () => {
  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextFilter />
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={3} container spacing={2}>
          <Grid item xs={12}>
            <CreatedAtSorter />
          </Grid>

          <Grid item xs={12}>
            <DateRangeSelector />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
