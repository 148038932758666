import { baseApi, bulkUpdateDocs, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';
import {
  addDoc,
  updateDoc as fUpdateDoc,
  deleteDoc as fDeleteDoc,
  collection,
  doc,
  getDoc as fGetDoc,
  getDocs,
  query,
  serverTimestamp,
  where,
  writeBatch,
  orderBy,
  limit
} from 'firebase/firestore';
import { auth, db } from 'src/lib/firebase';

/**
 * recommended to use
 */
const collectionName = 'orders';

export const ordersApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchOrders: builder.query({
      queryFn: async (props = { where: null }) => {
        const { where } = props;

        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['orders']
    }),

    fetchOrder: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      },
      providesTags: (result, error, { id }) => [{ type: 'order', id }]
    }),

    createOrder: builder.mutation({
      queryFn: async ({
        overnightShipping,
        items,
        maxPallets,
        franchiseeNotes,
        corporateNotes,
        territoryId,
        deliveryLocationId,
        llcId,
        status,
        enabled,
        readRecipients,
        userId
      }) => {
        let orderNumber = 1;

        try {
          const cRef = collection(db, collectionName);
          const cQuery = query(
            cRef,
            orderBy("createdAt", "desc"),
            limit(1)
          );
          const lastOrderRes = await getDocs(cQuery);

          const lastOrderDetails = lastOrderRes?.docs?.[0]?.data();
          orderNumber = (lastOrderDetails?.orderNumber || 0) + 1;
        } catch (e) {
          console.log('error getting lastOrderDetails e', e);
        }

        return await createDoc(collectionName, {
          overnightShipping,
          items,
          maxPallets,
          franchiseeNotes,
          corporateNotes,
          territoryId,
          deliveryLocationId,
          llcId,
          status,
          enabled,
          userId,
          orderNumber,
          readRecipients
        });
      },
      invalidatesTags: ['orders']
    }),

    updateOrder: builder.mutation({
      queryFn: async ({
        id = '',
        overnightShipping = false,
        items: _items = [],
        maxPallets = 1,
        franchiseeNotes = '',
        corporateNotes = '',
        territoryId = '',
        deliveryLocationId = '',
        llcId = '',
        status = 'active',
        enabled = true,
        userId = '',
        orderNumber = 1,
        revisions: _revisions = [],
        readRecipients = []
      }) => {
        const items = _items.map((item) => ({ ...item }));
        const revisions = _revisions.map((item) => ({ ...item }));

        return await updateDoc(collectionName, {
          id,
          overnightShipping,
          items,
          maxPallets,
          franchiseeNotes,
          corporateNotes,
          territoryId,
          deliveryLocationId,
          llcId,
          status,
          enabled,
          userId,
          orderNumber,
          revisions,
          readRecipients
        });
      },
      invalidatesTags: (result, error, { id }) => ['orders', { type: 'order', id }, { type: 'order', id: 'LIST' }]
    }),

    bulkUpdateOrders: builder.mutation({
      queryFn: async ({ orders: _orders }) => {
        const orders = _orders.map(order => {
          const {
            id = '',
            overnightShipping = false,
            items: _items = [],
            maxPallets = 1,
            franchiseeNotes = '',
            corporateNotes = '',
            territoryId = '',
            deliveryLocationId = '',
            llcId = '',
            status = 'active',
            enabled = true,
            userId = '',
            orderNumber = 1,
            revisions: _revisions = [],
            readRecipients = []
          } = order;

          const items = _items.map((item) => ({ ...item }));
          const revisions = _revisions.map((item) => ({ ...item }));

          return {
            id,
            overnightShipping,
            items,
            maxPallets,
            franchiseeNotes,
            corporateNotes,
            territoryId,
            deliveryLocationId,
            llcId,
            status,
            enabled,
            userId,
            orderNumber,
            revisions,
            readRecipients
          };
        });

        return await bulkUpdateDocs(collectionName, orders);
      },
      invalidatesTags: ['orders']
    }),

    deleteOrder: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['orders']
    })
  })
});

export const {
  useFetchOrdersQuery,
  useFetchOrderQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useBulkUpdateOrdersMutation,
  useDeleteOrderMutation
} = ordersApi;

/**
 * not recommended to use
 */
export const useOrdersApi = () => {
  const getOrderByUserId = id => {
    const cRef = collection(db, 'orders');
    const cQuery = query(
      cRef,
      where('user_id', '==', id)
    );

    return getDocs(cQuery);
  }

  const getThisWeeksOrders = (beginningOfWeek, endOfWeek) => {
    const cRef = collection(db, 'orders');
    const cQuery = query(
      cRef,
      where('createdAt', '>=', beginningOfWeek),
      where('createdAt', '<=', endOfWeek)
    );

    return getDocs(cQuery);
  };

  const createOrder = data => {
    const {
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      llcId,
      llcName,
      enabled,
      recipientName,
      email,
      mobile
    } = data;
    const cRef = collection(db, 'orders');

    return addDoc(cRef, {
      // TODO - refactor auth, ensure this works
      user_id: auth.currentUser.uid,
      createdAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      llcId,
      llcName,
      enabled,
      recipientName,
      email,
      mobile,
      isPending: true,
      isApproved: false,
      isFulfilled: false
    });
  };

  const updateOrder = (
    id,
    overnightShipping,
    items,
    franchiseNotes,
    corporateNotes,
    territoryId,
    name,
    deliveryAddressId,
    address1,
    address2,
    city,
    state,
    zip,
    llcId,
    llcName,
    enabled,
    recipientName,
    email,
    mobile
  ) => {
    const docRef = doc(db, 'orders', id);

    return fUpdateDoc(docRef, {
      updatedAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      llcId,
      llcName,
      enabled,
      recipientName,
      email,
      mobile
    });
  };

  const fulfillOrder = id => {
    const reset = {
      isPending: false,
      isApproved: false,
      isFulfilled: false
    };
    const docRef = doc(db, 'orders', id);

    return fUpdateDoc(docRef, {
      ...reset,
      ['isFulfilled']: true
    });
  };

  // Change for single order update

  const approveOrderAndSetCorporateNotes = (id, corporateNotes) => {
    const docRef = doc(db, 'orders', id);

    return fUpdateDoc(docRef, {
      corporateNotes,
      isApproved: true,
      isPending: false,
      isFulfilled: false
    });
  };

  const setOrderStatus = (id, status) => {
    const reset = {
      isPending: false,
      isApproved: false,
      isFulfilled: false
    };
    const docRef = doc(db, 'orders', id);

    return fUpdateDoc(docRef, { ...reset, [status]: true });
  };

  const approveMultipleOrders = selectedOrders => {
    const reset = {
      isPending: false,
      isApproved: false,
      isFulfilled: false
    };
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orders', id);

      batch.update(docRef, { ...reset, ['isApproved']: true });
    });

    return batch.commit();
  };

  const fulfillOrderStatus = selectedOrders => {
    const reset = {
      isPending: null,
      isApproved: null,
      isFulfilled: null
    };
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orders', id);

      batch.update(docRef, { ...reset, ['isFulfilled']: true });
    });

    return batch.commit();
  };

  const getAllOrders = async () => {
    const cRef = collection(db, 'orders');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    return refs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const getFulfillmentOrders = async () => {
    const cRef = collection(db, 'orders');
    const cQuery = query(
      cRef,
      where('isApproved', '==', true),
      where('isFulfilled', '==', true)
    );
    const refs = await getDocs(cQuery);

    return refs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const getUniqueOrder = id => {
    const docRef = doc(db, 'orders', id);

    return fGetDoc(docRef);
  };

  const deleteSelectedOrders = selectedOrders => {
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orders', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  /* ==== Draft Orders ==== */

  const getUsersDraftOrders = async (id) => {
    const cRef = collection(db, 'draft_orders');
    const cQuery = query(
      cRef,
      where('user_id', '==', id)
    );
    const refs = await getDocs(cQuery);

    return refs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const getAllDraftOrders = () => {
    const cRef = collection(db, 'draft_orders');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  }

  const deleteDraftOrder = id => {
    const docRef = doc(db, 'draft_orders', id);

    return fDeleteDoc(docRef);
  };

  const deleteSelectedDraftOrders = selectedDraftOrders => {
    const batch = writeBatch(db);

    selectedDraftOrders.forEach(id => {
      const docRef = doc(db, 'draft_orders', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  const getUniqueDraftOrder = id => {
    const docRef = doc(db, 'draft_orders', id);

    return getDoc(docRef);
  };

  const updateUniqueDraftOrder = (id, draftOrder) => {
    const {
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    } = draftOrder;
    const docRef = doc(db, 'draft_orders', id);

    return fUpdateDoc(docRef, {
      updatedAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    });
  };

  const createDraftOrder = data => {
    const {
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    } = data;
    const cRef = collection(db, 'draft_orders');

    return addDoc(cRef, {
      // TODO - refactor auth, ensure this works
      user_id: auth.currentUser.uid,
      updatedAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    });
  };

  return {
    getOrderByUserId,
    getThisWeeksOrders,
    createOrder,
    updateOrder,
    fulfillOrder,
    approveOrderAndSetCorporateNotes,
    setOrderStatus,
    approveMultipleOrders,
    fulfillOrderStatus,
    getAllOrders,
    getFulfillmentOrders,
    getUniqueOrder,
    deleteSelectedOrders,
    getUsersDraftOrders,
    getAllDraftOrders,
    deleteDraftOrder,
    deleteSelectedDraftOrders,
    getUniqueDraftOrder,
    updateUniqueDraftOrder,
    createDraftOrder
  };
};
