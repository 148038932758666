import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { useCurrentUser } from 'src/contexts/firebase-context';
import { useUsersApi } from 'src/apis';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/lib/firebase';

export const usePasswordSettingsFormManager = () => {
  const { id: uid } = useCurrentUser();
  const { reinitializeIdToken } = useUsersApi();
  const updateUserPassword = httpsCallable(functions, 'updateUserPassword');
  const { enqueueSnackbar } = useSnackbar();

  const formManager = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      passwordConfirm: ''
    },

    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .max(255)
        .required('Required'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required')
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const { password } = values;

        await updateUserPassword({
          uid,
          password
        });

        await reinitializeIdToken();

        enqueueSnackbar('Password updated', {
          variant: 'success'
        });
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      } finally {
        setSubmitting(false);
      }
    }
  });

  return { formManager };
};
