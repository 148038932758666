export const checkPermissions = (permissions, permissionToCheck) => {
  for (const parentProperty of Object.keys(permissions)) {
    for (const childProperty of Object.keys(permissions[parentProperty])) {
      if (childProperty === permissionToCheck && permissions[parentProperty][childProperty]) {
        return true;
      }
    }
  }

  return false;
};
