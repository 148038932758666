import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export const Loading = ({ text = 'Loading...', height = 120, textStyle = {} }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={height}>
      <Typography color="textSecondary" style={{...{ marginBottom: 16 }, ...textStyle}}>
        {text}
      </Typography>
      <CircularProgress />
    </Box>
  );
};
