import React from 'react';
import { useHistory } from 'react-router';
import { Box, Divider } from '@material-ui/core';

import { TabFilter } from 'src/components';
import { usePageContext } from './context';

export const Tab = () => {
  const history = useHistory();
  const { currentTab } = usePageContext();

  const tabs = [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'restaurant',
      label: 'Restaurant'
    },
    {
      value: 'truck',
      label: 'Truck'
    }
  ];

  return tabs.map(item => item.value).includes(currentTab) ? (
    <Box>
      <TabFilter value={currentTab} tabs={tabs} onChange={value => history.push(`?type=${value}`)} />

      <Divider />
    </Box>
  ) : null;
};
