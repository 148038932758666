import React from 'react';
import { Card, Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { Table } from './table';

export const ComplianceReportQuestionSectionsPage = () => {
  return (
    <DashboardPageContainer title="Compliance Report Sections" maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Compliance Questions',
                link: '/app/compliance-v2/questions?type=all'
              },
              {
                label: 'Compliance Question Sections'
              }
            ]}
            title={'Compliance Question Sections'}
            customButton1={{
              label: 'Questions',
              link: '/app/compliance-v2/questions',
              startIcon: null
            }}
            createButton={{ label: 'New Section', link: '/app/compliance-v2/question-sections/new' }}
          />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Table />
          </Card>
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
