import React, { useRef } from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';

import { HeaderWithButtons, Page } from 'src/components';
import { Form } from './form';
import { useFetchFranchiseeLocations } from 'src/apis';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { FRANCHISEE_OWNER } from 'src/constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

export const ComplianceReportsSelectFranchiseeLocationPage = () => {
  const classes = useStyles();
  const formRef = useRef();
  const { data: franchiseeLocations } = useFetchFranchiseeLocations();
  const { role } = useCurrentUser();
  const isFranchiseeOwner = role === FRANCHISEE_OWNER;

  return (
    <Page className={classes.root} title="Start Creating Compliance Report">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderWithButtons
              breadcrumbs={[
                {
                  label: 'Dashboard',
                  link: '/app'
                },
                {
                  label: 'Compliance Reports',
                  link: '/app/compliance-v2/reports?status=all'
                },
                {
                  label: 'Select Franchise'
                }
              ]}
              title={'Select Franchise'}
              cancelButton={{ link: '/app/compliance-v2/reports?status=all' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Form ref={formRef} />
          </Grid>

          {franchiseeLocations.length > 0 && (
            <Grid item xs={12}>
              <Button onClick={() => formRef.current.onSubmit()} color="secondary" variant="contained" type="submit">
                {isFranchiseeOwner ? 'View Sample Compliance Report' : 'Start Compliance Report'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};
