import { baseApi, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';
import { arrayRemove, arrayUnion, collection, doc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { db } from 'src/lib/firebase';

/**
 * recommended to use
 */
const collectionName = 'territories';

export const territoriesApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchTerritories: builder.query({
      queryFn: async ({ userId }) => {
        let options = {};

        const where = userId ? ['userIds', 'array-contains', userId] : null;

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['territories']
    }),

    fetchTerritory: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createTerritory: builder.mutation({
      queryFn: async ({ name }) => {
        return await createDoc(collectionName, { name });
      },
      invalidatesTags: ['territories']
    }),

    updateTerritory: builder.mutation({
      queryFn: async ({ id, name }) => {
        return await updateDoc(collectionName, { id, name });
      },
      invalidatesTags: ['territories']
    }),

    deleteTerritory: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['territories']
    })
  })
});

export const {
  useFetchTerritoriesQuery,
  useFetchTerritoryQuery,
  useCreateTerritoryMutation,
  useUpdateTerritoryMutation,
  useDeleteTerritoryMutation
} = territoriesApi;

/**
 * not recommended to use
 */
export const useTerritoriesApi = () => {
  const deleteTerritoryFromFranchiseAndDeliveryLocations = async territoryId => {
    const batch = writeBatch(db);
    const franchiseCollectionRef = collection(db, 'franchise_locations');
    const franchiseCollectionQuery = query(
      franchiseCollectionRef,
      where('territoryId', '==', territoryId)
    );
    const franchiseRefs = await getDocs(franchiseCollectionQuery);
    const deliveryCollectionRef = collection(db, 'delivery_locations');
    const deliveryCollectionQuery = query(
      deliveryCollectionRef,
      where('territoryId', '==', territoryId)
    );
    const deliveryRefs = await getDocs(deliveryCollectionQuery);

    franchiseRefs.forEach(ref => {
      const docRef = doc(db, 'franchise_locations', ref.id);

      batch.update(docRef, { name: '', territoryId: '' });
    });

    deliveryRefs.forEach(ref => {
      const docRef = doc(db, 'delivery_locations', ref.id);

      batch.update(docRef, { name: '', territoryId: '' });
    });

    return batch.commit();
  };

  const updateTerritoryNameAcrossLocations = async (territoryId, newTerritoryName) => {
    const batch = writeBatch(db);
    const franchiseCollectionRef = collection(db, 'franchise_locations');
    const franchiseCollectionQuery = query(
      franchiseCollectionRef,
      where('territoryId', '==', territoryId)
    );
    const franchiseRefs = await getDocs(franchiseCollectionQuery);
    const deliveryCollectionRef = collection(db, 'delivery_locations');
    const deliveryCollectionQuery = query(
      deliveryCollectionRef,
      where('territoryId', '==', territoryId)
    );
    const deliveryRefs = await getDocs(deliveryCollectionQuery);

    franchiseRefs.forEach(ref => {
      const docRef = doc(db, 'franchise_locations', ref.id);

      batch.update(docRef, { name: newTerritoryName });
    });

    deliveryRefs.forEach(ref => {
      const docRef = doc(db, 'delivery_locations', ref.id);

      batch.update(docRef, { name: newTerritoryName });
    });

    return batch.commit();

    // franchiseRef.update({ name: newTerritoryName });
    // deliveryRef.update({ name: newTerritoryName });

    // get all delivery and franchise that has old terr name
    // loop through each and then update with new territory name
  };

  const exchangeTerritoryIdFromOldToNewUser = (oldUserId, newUserId, territoryId) => {
    const batch = writeBatch(db);

    // Remove
    const oldUserRef = doc(db, 'users', oldUserId);

    // Add
    const newUserRef = doc(db, 'users', newUserId);

    batch.update(oldUserRef, 'territories', arrayRemove(territoryId));
    batch.update(newUserRef, 'territories', arrayUnion(territoryId));

    return batch.commit();
  };

  return {
    deleteTerritoryFromFranchiseAndDeliveryLocations,
    updateTerritoryNameAcrossLocations,
    exchangeTerritoryIdFromOldToNewUser
  };
};
