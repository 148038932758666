import React from 'react';
import { useHistory } from 'react-router';
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';

export const NoQuestionsCard = () => {
  const history = useHistory();

  return (
    <Card>
      <CardHeader title="No questions configured" />

      <Divider />

      <CardContent>
        <Box textAlign={'center'} padding={3}>
          <Typography color="textSecondary">
            No questions that already exist. Create some questions to continue.
          </Typography>

          <Box marginTop={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push('/app/compliance-v2/questions/new');
              }}
            >
              Create a question
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
