import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, CardHeader, Divider, Grid, useTheme } from '@material-ui/core';

import { reorder } from 'src/utils/dnd';
import { Questions } from './questions';
import { useComplianceReportFormContext } from './compliance-report-form.context';

export const Sections = () => {
  const theme = useTheme();
  const [{ dragging_section, dragging_question }, setDragging] = useState({
    dragging_section: false,
    dragging_question: false
  });
  const [hoveredSection, setHoveredSection] = useState(null);
  const { sections, updateSections, isOrganizing } = useComplianceReportFormContext();

  const setDraggingSection = dragging_section => setDragging(prev => ({ ...prev, dragging_section }));
  const setDraggingQuestion = dragging_question => setDragging(prev => ({ ...prev, dragging_question }));

  const handleDragEnd = result => {
    setDraggingSection(false);

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const section = sections.find(({ section: { id = '' } = {} } = {}) => id === result.draggableId);

    if (section) {
      const items = reorder(sections, result.source.index, result.destination.index);

      updateSections({ sections: items });
    }
  };

  return (
    <>
      {sections.map(
        ({ section: { id: sectionId = '', name: sectionName = '' } = {}, questions = [] } = {}, sectionIndex) => (
          <Grid key={sectionId} item xs={12}>
            <Card>
              <CardHeader title={sectionName} />

              <Divider />

              <Questions
                sectionId={sectionId}
                questions={questions}
                isOrganizing={isOrganizing}
              />
            </Card>
          </Grid>
        )
      )}
    </>
  )

  // return (
  //   <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={() => setDraggingSection(true)}>
  //     <Droppable droppableId="droppable-sections">
  //       {({ innerRef: droppable_innerRef, placeholder: droppable_placeholder }) => (
  //         <Grid container spacing={2} ref={droppable_innerRef}>
  //           {sections.map(
  //             ({ section: { id: sectionId = '', name: sectionName = '' } = {}, questions = [] } = {}, sectionIndex) => (
  //               <Draggable
  //                 key={`draggable-section-${sectionId}`}
  //                 draggableId={sectionId}
  //                 index={sectionIndex}
  //                 isDragDisabled={!isOrganizing}
  //               >
  //                 {({ innerRef: draggable_innerRef, draggableProps, dragHandleProps }, snapshot) => (
  //                   <Grid key={sectionId} item xs={12} ref={draggable_innerRef} {...draggableProps}>
  //                     <Card
  //                       style={
  //                         !dragging_question && (snapshot.isDragging || hoveredSection === sectionId)
  //                           ? { boxShadow: `0 0 0 1px ${theme.palette.primary.main}` }
  //                           : {}
  //                       }
  //                     >
  //                       <CardHeader
  //                         title={sectionName}
  //                         {...dragHandleProps}
  //                         onMouseOver={isOrganizing ? () => setHoveredSection(sectionId) : null}
  //                         onMouseLeave={isOrganizing ? () => setHoveredSection(null) : null}
  //                       />

  //                       <Divider />

  //                       <Questions
  //                         sectionId={sectionId}
  //                         questions={questions}
  //                         dragging={dragging_section || dragging_question}
  //                         setDragging={setDraggingQuestion}
  //                         isOrganizing={isOrganizing}
  //                       />
  //                     </Card>
  //                   </Grid>
  //                 )}
  //               </Draggable>
  //             )
  //           )}

  //           {droppable_placeholder}
  //         </Grid>
  //       )}
  //     </Droppable>
  //   </DragDropContext>
  // );
};
