import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Grid, IconButton } from '@material-ui/core';
import { Settings } from 'react-feather';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { FULFILLMENT, SUPER_ADMIN } from 'src/constants';
import { Tab } from './tab';
import { PageContextProvider } from './context';
import { VideosList } from './videos-list';

export const VideosPage = () => {
  const { role: userRole } = useCurrentUser();

  return (
    <DashboardPageContainer title="Training Videos" maxWidth={'lg'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Training Videos'
              }
            ]}
            title={'Training Videos'}
            createButton={
              (userRole === SUPER_ADMIN || userRole === FULFILLMENT) ? { label: 'Add New Video', link: '/app/training-v2/videos/new' } : undefined
            }
          />
        </Grid>

        <PageContextProvider>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item style={{ width: (userRole === SUPER_ADMIN || userRole === FULFILLMENT) ? 'calc(100% - 64px)' : '100%' }}>
                    <Card>
                      <Tab />
                    </Card>
                  </Grid>

                  {(userRole === SUPER_ADMIN || userRole === FULFILLMENT) && (
                    <Grid item>
                      <IconButton color="secondary" component={RouterLink} to={`/app/training-v2/video-categories`}>
                        <Settings />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <VideosList />
              </Grid>
            </Grid>
          </Grid>
        </PageContextProvider>
      </Grid>
    </DashboardPageContainer>
  );
};
