import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

import { usePasswordSettingsFormManager } from './password-settings-form.hook';

export const PasswordSettingsForm = () => {
  const { formManager } = usePasswordSettingsFormManager();

  return (
    <form onSubmit={formManager.handleSubmit}>
      <Card>
        <CardHeader title="Change Password" />

        <Divider />

        <CardContent>
          <Box maxWidth={480} paddingY={2} margin={'auto'}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  label="Password"
                  name="password"
                  value={formManager.values.password}
                  onChange={formManager.handleChange}
                  error={Boolean(formManager.touched.password && formManager.errors.password)}
                  helperText={formManager.touched.password && formManager.errors.password}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  label="Password Confirmation"
                  name="passwordConfirm"
                  value={formManager.values.passwordConfirm}
                  onChange={formManager.handleChange}
                  error={Boolean(formManager.touched.passwordConfirm && formManager.errors.passwordConfirm)}
                  helperText={formManager.touched.passwordConfirm && formManager.errors.passwordConfirm}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>

        <Divider />

        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={formManager.isSubmitting}
            endIcon={formManager.isSubmitting && <CircularProgress size={16} color="inherit" />}
          >
            Change Password
          </Button>
        </Box>
      </Card>
    </form>
  );
};
