import React from 'react';
import { Card, Container, Grid, makeStyles } from '@material-ui/core';

import { HeaderBreadcrumbs, Page } from 'src/components';

import { FulfillmentsListPageContextProvider } from './fulfillments-list-page.context';
import { FulfillmentNote } from './fulfillment-note';
import { FulfillmentsListTab } from './fulfillments-list-tab';
import { FulfillmentsListFilterAndSorter } from './fulfillments-list-filter-and-sorter';
import { FulfillmentsListTable } from './fulfillments-list-table';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

export const FulfillmentsListPage = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Fulfillments">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderBreadcrumbs
              breadcrumbs={[
                {
                  label: 'Dashboard',
                  link: '/app'
                },
                {
                  label: 'Fulfillment'
                }
              ]}
              title={'Fulfillment'}
            />
          </Grid>

          <FulfillmentsListPageContextProvider>
            <Grid item xs={12}>
              <FulfillmentNote />
            </Grid>

            <Grid item xs={12}>
              <Card>
                <FulfillmentsListTab />

                <FulfillmentsListFilterAndSorter />

                <FulfillmentsListTable />
              </Card>
            </Grid>
          </FulfillmentsListPageContextProvider>
        </Grid>
      </Container>
    </Page>
  );
};
