import React from 'react';
import { Box, Grid, TablePagination, Typography, useTheme } from '@material-ui/core';

import { Empty, Loading } from 'src/components';
import { usePageContext } from './context';
import { VideoCard } from './video-card';

export const VideosList = () => {
  const theme = useTheme();
  const {
    currentTab,
    videos,
    paginatedVideos,
    loading_videos,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePageContext();

  if (loading_videos) {
    return <Loading text="Loading videos" height={300} />;
  }

  if (!loading_videos && videos.length === 0) {
    return <Empty description="There is no video in the category." />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" color="textPrimary">
          Showing {paginatedVideos.length} videos
        </Typography>

        <Box height={3} width={48} bgcolor={theme.palette.primary.main} marginTop={0.5} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          {paginatedVideos.map((video, index) => {
            const { id, title, description, url, categories } = video;
            const videoCardPrps = { id, title, description, url, categories, activeCategory: currentTab };

            return (
              <Grid key={`video-card-${index}`} item xs={4}>
                <VideoCard {...videoCardPrps} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <TablePagination
            rowsPerPageOptions={[25, 50, 75]}
            component="div"
            count={videos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Videos per Page"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
