import React from 'react';

const logo_white = '/static/logoalt.png';
const logo = '/static/logo.png';

export const Logo = ({ theme = 'LIGHT', width = 120 }) => {
  return <img style={{ width }} src={theme === 'LIGHT' ? logo : logo_white} alt="Cousins Maine Lobster Logo" />;
};

export default Logo;
