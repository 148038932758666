import React, { createContext, useEffect, useReducer } from 'react';
import { useRollbarPerson } from '@rollbar/react';

import SplashScreen from 'src/components/SplashScreen';
import { FULFILLMENT, SUPER_ADMIN } from 'src/constants';
import firebase, { auth, db, functions, storage } from 'src/lib/firebase';
import {
  arrayUnion,
  collection,
  doc,
  endBefore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  where,
  writeBatch,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  arrayRemove,
  Timestamp,
} from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
  getIdToken,
  onAuthStateChanged,
  signInWithEmailAndPassword as fSignInWithEmailAndPassword,
  createUserWithEmailAndPassword as fCreateUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut
} from 'firebase/auth';

const defaultFirebaseContextValue = {
  /**
   * handle firebase
   */
  firebase,

  /**
   * use firestore
   */
  db,
  // db,

  /**
   * use firebase clound functions
   */
  functions,
  // functions,

  /**
   * authentication status
   */
  isAuthenticated: false,

  /**
   * app initialized status
   */
  isInitialised: false,

  /**
   * current user
   */
  user: null,

  /**
   * show announcement in the beginning
   */
  isAnnouncementShowing: true,

  /**
   * announcement content
   */
  announcement: ''
};

export const FirebaseContext = createContext(defaultFirebaseContextValue);

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'TOGGLE_GLOBAL_ANNOUNCEMENT': {
      const { value } = action.payload;

      return {
        ...state,
        isAnnouncementShowing: value
      };
    }
    case 'SET_LATEST_ANNOUNCEMENT': {
      const { announcement } = action.payload;

      return {
        ...state,
        announcement
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const FirebaseContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultFirebaseContextValue);

  /* ==== Reducer Methods ==== */

  const dispatchToggleAnnouncementBar = value => {
    return dispatch({
      type: 'TOGGLE_GLOBAL_ANNOUNCEMENT',
      payload: {
        value
      }
    });
  };

  const dispatchLatestAnnouncement = (id, announcement) => {
    return dispatch({
      type: 'SET_LATEST_ANNOUNCEMENT',
      payload: {
        announcement: {
          id: id,
          message: announcement
        }
      }
    });
  };

  /* ==== Auth ==== */

  const signInWithEmailAndPassword = (email, password) => {
    return fSignInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    return signInWithPopup(auth, provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return fCreateUserWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  /* ==== Notifications ==== */

  // Create
  const createOrderNotification = (orderId, data) => {
    const { territoryId, name, isOvernightShipping } = data;
    const docRef = doc(db, 'orderNotifications', orderId);

    return setDoc(docRef, {
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp(),
      status: 'isPending',
      territoryId,
      name,
      isOvernightShipping,
      readRecipients: []
    });
  };

  // Update
  const updateOrderNotification = (orderId, status) => {
    // type of ordering dependant on status
    const docRef = doc(db, 'orderNotifications', orderId);

    return updateDoc(docRef, {
      lastUpdated: serverTimestamp(),
      status,
      readRecipients: []
    });
  };

  // updateFulfilledForMultipleOrderNotifications
  const updateFulfilledForMultipleOrderNotifications = selectedOrders => {
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orderNotifications', id);

      batch.update(docRef, {
        status: 'isFulfilled',
        readRecipients: [],
        lastUpdated: serverTimestamp()
      });
    });

    return batch.commit();
  };

  // updateApprovedForMultipleOrderNotifications
  const updateApprovedForMultipleOrderNotifications = selectedOrders => {
    console.log(selectedOrders);
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orderNotifications', id);

      batch.update(docRef, {
        status: 'isApproved',
        readRecipients: [],
        lastUpdated: serverTimestamp()
      });
    });

    return batch.commit();
  };

  // For All notifications used by admin accounts
  const getRecentOrderNotifications = () => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      orderBy('lastUpdated', 'desc'),
      limit(5)
    );

    return getDocs(cQuery);
  }

  const getNextPaginationOrderNotifications = document => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      orderBy('lastUpdated', 'desc'),
      startAfter(document),
      limit(5)
    );

    return getDocs(cQuery);
  };

  const getPreviousPaginationOrderNotifications = document => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      orderBy('lastUpdated', 'desc'),
      endBefore(document),
      limitToLast(5)
    );

    return getDocs(cQuery);
  };

  const userReadOrderNotifications = async (orderNotifications, userId) => {
    const batch = writeBatch(db);

    orderNotifications.forEach(r => {
      const docRef = doc(db, 'orderNotifications', r.id);

      batch.update(docRef, {
        readRecipients: arrayUnion(userId)
      });
    });

    return batch.commit();
  };

  const checkForUnreadOrderNotification = async userId => {
    let hasUnreadNotifications = false;

    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    refs.forEach(doc => {
      const { readRecipients } = doc.data();

      if (!readRecipients.includes(userId)) {
        hasUnreadNotifications = true;
      }
    });

    return hasUnreadNotifications;
  };

  const markAllOrderNotificationsRead = async userId => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    refs.forEach(r => {
      const docRef = doc(db, 'orderNotifications', r.id);

      batch.update(docRef, {
        readRecipients: arrayUnion(userId)
      });
    });

    return batch.commit();
  };

  // Approved order notificaitons for Fulfillment Team

  const markAllApprovedOrderNotificationsRead = async userId => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('status', '==', 'isApproved')
    );
    const refs = await getDocs(cQuery);

    refs.forEach(doc => {
      const docRef = doc(db, 'orderNotifications', doc.id);

      batch.update(docRef, {
        readRecipients: arrayUnion(userId)
      });
    });

    return batch.commit();
  };

  const getApprovedOrderNotifications = async () => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('status', '==', 'isApproved'),
      orderBy('lastUpdated', 'desc'),
      limit(5)
    );
    const refs = await getDocs(cQuery);

    return refs;
  };

  const getNextPaginationApprovedOrderNotifications = document => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('status', '==', 'isApproved'),
      orderBy('lastUpdated', 'desc'),
      startAfter(document),
      limit(5)
    );

    return getDocs(cQuery);
  };

  const getPreviousPaginationApprovedOrderNotifications = document => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('status', '==', 'isApproved'),
      orderBy('lastUpdated', 'desc'),
      endBefore(document),
      limitToLast(5)
    );

    return getDocs(cQuery);
  };

  const checkForUnreadApprovedOrderNotification = async userId => {
    let hasUnreadNotifications = false;
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('status', '==', 'isApproved')
    );
    const refs = await getDocs(cQuery);

    refs.forEach(doc => {
      const { readRecipients } = doc.data();

      if (!readRecipients.includes(userId)) {
        hasUnreadNotifications = true;
      }
    });

    return hasUnreadNotifications;
  };

  // Fulfilled order notificaitons for Franchisee Owner
  const getFulfilledOrderNotifications = async territoryIds => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('territoryId', 'in', territoryIds),
      where('status', '==', 'isFulfilled'),
      orderBy('lastUpdated', 'desc'),
      limit(5)
    );
    const refs = await getDocs(cQuery)

    return refs;
  };

  const getNextPaginationFulfilledOrderNotifications = (document, territoryIds) => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('territoryId', 'in', territoryIds),
      where('status', '==', 'isFulfilled'),
      orderBy('lastUpdated', 'desc'),
      startAfter(document),
      limit(5)
    );

    return getDocs(cQuery);
  };

  const getPreviousPaginationFulfilledOrderNotifications = (document, territoryIds) => {
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('territoryId', 'in', territoryIds),
      where('status', '==', 'isFulfilled'),
      orderBy('lastUpdated', 'desc'),
      endBefore(document),
      limitToLast(5)
    );

    return getDocs(cQuery);
  };

  const checkForUnreadFulfilledOrderNotification = async (userId, territoryIds) => {
    let hasUnreadNotifications = false;
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('territoryId', 'in', territoryIds),
      where('status', '==', 'isFulfilled')
    );
    const refs = await getDocs(cQuery)

    refs.forEach(doc => {
      const { readRecipients } = doc.data();
      if (!readRecipients.includes(userId)) {
        hasUnreadNotifications = true;
      }
    });

    return hasUnreadNotifications;
  };

  const markAllFulfilledOrderNotificationsRead = async (userId, territoryIds) => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'orderNotifications');
    const cQuery = query(
      cRef,
      where('territoryId', 'in', territoryIds),
      where('status', '==', 'isFulfilled')
    );
    const refs = await getDocs(cQuery);

    refs.forEach(r => {
      const docRef = doc(db, 'orderNotifications', r.id);

      batch.update(docRef, {
        readRecipients: arrayUnion(userId)
      });
    });

    return batch.commit();
  };

  /* ==== Announcements ==== */

  const userReadAnnouncementNotifications = async (announcements, userId) => {
    const batch = writeBatch(db);

    announcements.forEach(r => {
      const docRef = doc(db, 'announcements', r.id);

      batch.update(docRef, {
        readRecipients: arrayUnion(userId)
      });
    });

    return batch.commit();
  };

  const getNextPaginationAnnouncementNotifications = document => {
    const cRef = collection(db, 'announcements');
    const cQuery = query(
      cRef,
      orderBy('createdAt', 'desc'),
      startAfter(document),
      limit(5)
    );

    return getDocs(cQuery);
  };

  const getPreviousPaginationAnnouncementNotifications = document => {
    const cRef = collection(db, 'announcements');
    const cQuery = query(
      cRef,
      orderBy('createdAt', 'desc'),
      endBefore(document),
      limitToLast(5)
    );

    return getDocs(cQuery);
  };

  const markAllAnnouncementNotificationsRead = async userId => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'announcements');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    refs.forEach(r => {
      const docRef = doc(db, 'announcements', r.id);

      batch.update(docRef, {
        readRecipients: arrayUnion(userId)
      });
    });

    return batch.commit();
  };

  const checkForUnreadAnnouncementNotification = async userId => {
    let hasUnreadNotifications = false;
    const cRef = collection(db, 'announcements');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    refs.forEach(doc => {
      const { readRecipients } = doc.data();

      if (!readRecipients.includes(userId)) {
        hasUnreadNotifications = true;
      }
    });

    return hasUnreadNotifications;
  };

  const getRecentAnnouncementNotifications = () => {
    const cRef = collection(db, 'announcements');
    const cQuery = query(
      cRef,
      orderBy('createdAt', 'desc'),
      limit(5)
    );

    return getDocs(cQuery);
  };

  const updateUniqueAnnouncement = (id, announcement) => {
    const docRef = doc(db, 'announcements', id);

    return updateDoc(docRef, { announcement });
  }

  const setAllAnnouncementsAsPrevious = async () => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'announcements');
    const cQuery = query(
      cRef,
      where('isCurrent', '==', true)
    );
    const refs = await getDocs(cQuery);

    refs.forEach(r => {
      const docRef = doc(db, 'announcements', r.id);

      batch.update(docRef, {
        isCurrent: false,
        isPrevious: true
      });
    });

    return batch.commit();
  };

  const markAnnouncementReadByUser = (userId, announcementId) => {
    const docRef = doc(db, 'announcements', announcementId);

    return updateDoc(docRef, {
      readRecipients: arrayUnion(userId)
    });
  };

  const getAllAnnouncements = () => {
    const cRef = collection(db, 'announcements');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const getUniqueAnnouncement = id => {
    const docRef = doc(db, 'announcements', id);

    return getDoc(docRef);
  }

  const createAnnouncement = ({ announcement }) => {
    const cRef = collection(db, 'announcements');

    return addDoc(cRef, {
      announcement,
      isCurrent: true,
      isPrevious: false,
      readRecipients: [],
      createdAt: serverTimestamp()
    });
  };

  const deleteAnnouncementById = id => {
    const docRef = doc(db, 'announcements', id);

    return deleteDoc(docRef);
  };

  /* ==== Users ==== */
  const updateUserTerritoryAssignment = async (userId, territoryIds) => {
    // giving user territory ID?
    const docRef = doc(db, 'users', userId);

    return updateDoc(docRef, { assignedTerritories: territoryIds });
  };

  const updateUserPermissions = (userId, permissions) => {
    const docRef = doc(db, 'users', userId);

    return updateDoc(docRef, { permissions });
  }

  const deleteUserFromTerritories = async userId => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'territories');
    const cQuery = query(
      cRef,
      where('user_id', '==', userId)
    );
    const refs = await getDocs(cQuery);

    refs.forEach(r => {
      const docRef = doc(db, 'territories', r.id);

      batch.update(docRef, {
        user_email: '',
        user_first: '',
        user_last: '',
        user_id: '',
        user_mobile: ''
      });
    });

    return batch.commit();
  };


  // TODO: v9 refactor auth - make sure this works
  const reinitializeIdToken = () => {
    return getIdToken(auth.currentUser, true);
  };

  const getUniqueUser = id => {
    const docRef = doc(db, 'users', id);

    return getDoc(docRef);
  };

  const getAllUsers = async () => {
    const cRef = collection(db, 'users');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    return refs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const getAllUsersByRole = role => {
    const cRef = collection(db, 'users');
    const cQuery = query(
      cRef,
      where('role', '==', role)
    );

    return getDocs(cQuery);
  };

  /* ==== Territories ==== */

  // Delete franchise and delivery
  const deleteTerritoryFromFranchiseAndDeliveryLocations = async territoryId => {
    const batch = writeBatch(db);
    const cFranchiseLocationRef = collection(db, 'franchise_locations');
    const cFranchiseLocationQuery = query(
      cFranchiseLocationRef,
      where('territoryId', '==', territoryId)
    );
    const franchiseLocationRefs = await getDocs(cFranchiseLocationQuery);
    const cDeliveryLocationRef = collection(db, 'delivery_locations');
    const cDeliveryLocationQuery = query(
      cDeliveryLocationRef,
      where('territoryId', '==', territoryId)
    );
    const deliveryLocationRefs = await getDocs(cDeliveryLocationQuery);

    franchiseLocationRefs.forEach(r => {
      const docRef = doc(db, 'franchise_locations', r.id);

      batch.update(docRef, { name: '', territoryId: '' });
    });

    deliveryLocationRefs.forEach(r => {
      const docRef = doc(db, 'delivery_locations', r.id);

      batch.update(docRef, { name: '', territoryId: '' });
    });

    return batch.commit();
  };

  const deleteUniqueTerritory = id => {
    const docRef = doc(db, 'territories', id);

    return deleteDoc(docRef);
  }

  const updateTerritoryNameAcrossLocations = async (territoryId, newTerritoryName) => {
    const batch = writeBatch(db);
    const cFranchiseLocationRef = collection(db, 'franchise_locations');
    const cFranchiseLocationQuery = query(
      cFranchiseLocationRef,
      where('territoryId', '==', territoryId)
    );
    const franchiseLocationRefs = await getDocs(cFranchiseLocationQuery);
    const cDeliveryLocationRef = collection(db, 'delivery_locations');
    const cDeliveryLocationQuery = query(
      cDeliveryLocationRef,
      where('territoryId', '==', territoryId)
    );
    const deliveryLocationRefs = await getDocs(cDeliveryLocationQuery);

    franchiseLocationRefs.forEach(r => {
      const docRef = doc(db, 'franchise_locations', r.id);

      batch.update(docRef, { name: newTerritoryName });
    });

    deliveryLocationRefs.forEach(r => {
      const docRef = doc(db, 'delivery_locations', r.id);

      batch.update(docRef, { name: newTerritoryName });
    });

    return batch.commit();

    // franchiseRef.update({ name: newTerritoryName });
    // deliveryRef.update({ name: newTerritoryName });

    // get all delivery and franchise that has old terr name
    // loop through each and then update with new territory name
  };

  const createTerritory = territory => {
    const { name, user_id, user_mobile, user_email, user_first, user_last } = territory;
    const cRef = collection(db, 'territories');

    return addDoc(cRef, {
      name,
      user_id,
      user_mobile,
      user_email,
      user_last,
      user_first,
      deliveryLocations: [],
      franchiseLocations: []
    });
  };

  const getAllTerritories = () => {
    const cRef = collection(db, 'territories');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const getUniqueTerritory = id => {
    const docRef = doc(db, 'territories', id);

    return getDoc(docRef);
  };

  const exchangeTerritoryIdFromOldToNewUser = (oldUserId, newUserId, territoryId) => {
    const batch = writeBatch(db);
    // Remove
    const oldUserRef = doc(db, 'users', oldUserId);
    // Add
    const newUserRef = doc(db, 'users', newUserId);

    batch.update(oldUserRef, 'territories', arrayRemove(territoryId));
    batch.update(newUserRef, 'territories', arrayUnion(territoryId));

    return batch.commit();
  };

  const updateUniqueTerritory = (id, territory) => {
    const { name, user_id, user_mobile, user_email, user_first, user_last } = territory;
    const docRef = doc(db, 'territories', id);

    return updateDoc(docRef, {
      name,
      user_id,
      user_mobile,
      user_email,
      user_first,
      user_last
    });
  };

  const getUserTerritories = userId => {
    const cRef = collection(db, 'territories');
    const cQuery = query(
      cRef,
      where('userIds', 'array-contains', userId)
    );

    return getDocs(cQuery);
  };

  /* ==== Franchise Locations ==== */

  const deleteFranchiseLocationFromTerritory = (territoryId, locationToBeRemoved) => {
    const docRef = doc(db, 'territories', territoryId);

    return updateDoc(docRef, {
      franchiseLocations: arrayRemove(locationToBeRemoved)
    });
  };

  const deleteUniqueFranchiseLocation = id => {
    const docRef = doc(db, 'franchise_locations', id);

    return deleteDoc(docRef);
  };

  const createFranchiseLocation = franchiseLocation => {
    const { territoryId, name, franchiseLocationName, franchiseLocationType, enabled } = franchiseLocation;
    const cRef = collection(db, 'franchise_locations');

    return addDoc(cRef, {
      territoryId,
      name,
      franchiseLocationName,
      franchiseLocationType,
      enabled
    });
  };

  const addFranchiseLocationToTerritory = (territoryId, franchiseLocation) => {
    const { franchiseLocationName, franchiseLocationType, enabled, id } = franchiseLocation;
    const docRef = doc(db, 'territories', territoryId);

    return updateDoc(docRef, {
      franchiseLocations: arrayUnion({
        id,
        franchiseLocationName,
        franchiseLocationType,
        enabled
      })
    });
  };

  const removeFranchiseLocationAndAddToNewTerritory = (
    oldTerritoryId,
    newTerritoryId,
    oldFranchiseLocation,
    newFranchiseLocation
  ) => {
    const batch = writeBatch(db);
    // Remove
    const oldTerritoryRef = doc(db, 'territories', oldTerritoryId);
    // Add
    const newTerritoryRef = doc(db, 'territories', newTerritoryId);

    batch.update(oldTerritoryRef, 'franchiseLocations', arrayRemove(oldFranchiseLocation));
    batch.update(newTerritoryRef, 'franchiseLocations', arrayUnion(newFranchiseLocation));

    return batch.commit();
  };

  const getAllFranchiseLocations = () => {
    const cRef = collection(db, 'franchise_locations');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const getUniqueFranchiseLocation = id => {
    const docRef = doc(db, 'franchise_locations', id);

    return getDoc(docRef);
  };

  const updateUniqueFranchiseLocation = (id, franchiseLocation) => {
    const { territoryId, name, franchiseLocationName, franchiseLocationType, enabled } = franchiseLocation;
    const docRef = doc(db, 'franchise_locations', id);

    return updateDoc(docRef, {
      territoryId,
      name,
      franchiseLocationName,
      franchiseLocationType,
      enabled
    });
  };

  /* ==== Delivery Locations ==== */

  const deleteDeliveryLocationFromTerritory = (territoryId, deliveryLocationToBeRemoved) => {
    const docRef = doc(db, 'territories', territoryId);

    return updateDoc(docRef, {
      deliveryLocations: arrayRemove(deliveryLocationToBeRemoved)
    });
  };

  const deleteUniqueDeliveryLocation = id => {
    const docRef = doc(db, 'delivery_locations', id);

    return deleteDoc(docRef);
  };

  // May remove below
  const addLocationIdToTerritory = (locationId, territoryId) => {
    const docRef = doc(db, 'territories', territoryId);

    return updateDoc(docRef, {
      locationIds: arrayUnion(locationId)
    });
  };

  const removeDeliveryLocationAndAddToNewTerritory = (
    oldTerritoryId,
    newTerritoryId,
    oldDeliveryAddress,
    newDeliveryAddress
  ) => {
    const batch = writeBatch(db);
    // Remove
    const oldTerritoryRef = doc(db, 'territories', oldTerritoryId)
    // Add
    const newTerritoryRef = doc(db, 'territories', newTerritoryId)

    batch.update(oldTerritoryRef, 'deliveryLocations', arrayRemove(oldDeliveryAddress));
    batch.update(newTerritoryRef, 'deliveryLocations', arrayUnion(newDeliveryAddress));

    return batch.commit();
  };

  const addDeliveryLocationToTerritory = (territoryId, deliveryLocation) => {
    const { id, recipientName, address1, address2, city, state, zip, mobile, email, enabled } = deliveryLocation;
    const docRef = doc(db, 'territories', territoryId);

    return updateDoc(docRef, {
      deliveryLocations: arrayUnion({
        id,
        recipientName,
        address1,
        address2,
        city,
        state,
        zip,
        mobile,
        email,
        enabled
      })
    });
  };

  const createDeliveryLocation = location => {
    const {
      territoryId,
      // territoryLocationIds,
      name,
      recipientName,
      address1,
      address2,
      city,
      state,
      zip,
      mobile,
      email,
      enabled
    } = location;
    const cRef = collection(db, 'delivery_locations');

    return addDoc(cRef, {
      territoryId,
      // territoryLocationIds,
      name,
      recipientName,
      address1,
      address2,
      city,
      state,
      zip,
      mobile,
      email,
      enabled
    });
  };

  const getAllLocations = () => {
    const cRef = collection(db, 'delivery_locations');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const updateUniqueLocation = (id, location) => {
    const { territoryId, name, recipientName, address1, address2, city, state, zip, mobile, email } = location;
    const docRef = doc(db, 'delivery_locations', id);

    return updateDoc(docRef, {
      territoryId,
      name,
      recipientName,
      address1,
      address2,
      city,
      state,
      zip,
      mobile,
      email
    });
  };

  const getUniqueLocation = id => {
    const docRef = doc(db, 'delivery_locations', id);

    return getDoc(docRef);
  };

  /* ==== LLCs ==== */

  const createLLC = llc => {
    const { name, territories } = llc;
    const cRef = collection(db, 'llcs');

    return addDoc(cRef, {
      name,
      territories
    });
  };

  const getAllLLCs = () => {
    const cRef = collection(db, 'llcs');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const getLLCById = id => {
    const docRef = doc(db, 'llcs', id);

    return getDoc(docRef);
  };

  const updateLLCById = (id, llc) => {
    const { name, territories } = llc;
    const docRef = doc(db, 'llcs', id);

    return updateDoc(docRef, {
      name,
      territories
    });
  };

  const deleteLLCById = id => {
    const docRef = doc(db, 'llcs', id);

    return deleteDoc(docRef);
  };

  /* ==== Items ==== */

  const deleteSelectedItems = selectedItems => {
    const batch = writeBatch(db);

    selectedItems.forEach(id => {
      const docRef = doc(db, 'items', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  const getUniqueItem = id => {
    const docRef = doc(db, 'items', id);

    return getDoc(docRef);
  };

  const getAllItems = () => {
    const cRef = collection(db, 'items');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  }

  const updateUniqueItem = (id, item) => {
    const { itemDesc, sku, itemOrder, status, price, salePrice, isOnSale } = item;
    const docRef = doc(db, 'items', id);

    return updateDoc(docRef, {
      itemDesc,
      sku,
      price,
      itemOrder,
      status,
      salePrice,
      isOnSale
    });
  };

  const createNewItem = item => {
    const { itemDesc, sku, itemOrder, status, salePrice, isOnSale } = item;
    const cRef = collection(db, 'items');

    return addDoc(cRef, {
      itemDesc,
      sku,
      itemOrder,
      status,
      salePrice,
      isOnSale
    });
  };

  /* ==== Orders ==== */

  const getOrderByUserId = id => {
    const cRef = collection(db, 'orders');
    const cQuery = query(
      cRef,
      where('user_id', '==', id)
    );

    return getDocs(cQuery);
  }

  const getThisWeeksOrders = (beginningOfWeek, endOfWeek) => {
    const cRef = collection(db, 'orders');
    const cQuery = query(
      cRef,
      where('createdAt', '>=', beginningOfWeek),
      where('createdAt', '<=', endOfWeek)
    );

    return getDocs(cQuery);
  };

  const createOrder = data => {
    const {
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      llcId,
      llcName,
      enabled,
      recipientName,
      email,
      mobile
    } = data;
    const cRef = collection(db, 'orders');

    return addDoc(cRef, {
      // TODO - refactor auth, ensure this works
      user_id: auth.currentUser.uid,
      createdAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      llcId,
      llcName,
      enabled,
      recipientName,
      email,
      mobile,
      isPending: true,
      isApproved: false,
      isFulfilled: false
    });
  };

  const updateOrder = (
    id,
    overnightShipping,
    items,
    franchiseNotes,
    corporateNotes,
    territoryId,
    name,
    deliveryAddressId,
    address1,
    address2,
    city,
    state,
    zip,
    llcId,
    llcName,
    enabled,
    recipientName,
    email,
    mobile
  ) => {
    const docRef = doc(db, 'orders', id);

    return updateDoc(docRef, {
      updatedAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      llcId,
      llcName,
      enabled,
      recipientName,
      email,
      mobile
    });
  };

  const fulfillOrder = id => {
    const reset = {
      isPending: false,
      isApproved: false,
      isFulfilled: false
    };
    const docRef = doc(db, 'orders', id);

    return updateDoc(docRef, {
      ...reset,
      ['isFulfilled']: true
    });
  };

  // Change for single order update

  const approveOrderAndSetCorporateNotes = (id, corporateNotes) => {
    const docRef = doc(db, 'orders', id);

    return updateDoc(docRef, {
      corporateNotes,
      isApproved: true,
      isPending: false,
      isFulfilled: false
    });
  };

  const setOrderStatus = (id, status) => {
    const reset = {
      isPending: false,
      isApproved: false,
      isFulfilled: false
    };
    const docRef = doc(db, 'orders', id);

    return updateDoc(docRef, { ...reset, [status]: true });
  };

  const approveMultipleOrders = selectedOrders => {
    const reset = {
      isPending: false,
      isApproved: false,
      isFulfilled: false
    };
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orders', id);

      batch.update(docRef, { ...reset, ['isApproved']: true });
    });

    return batch.commit();
  };

  const fulfillOrderStatus = selectedOrders => {
    const reset = {
      isPending: null,
      isApproved: null,
      isFulfilled: null
    };
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orders', id);

      batch.update(docRef, { ...reset, ['isFulfilled']: true });
    });

    return batch.commit();
  };

  const getAllOrders = async () => {
    const cRef = collection(db, 'orders');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    return refs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const getFulfillmentOrders = async () => {
    const cRef = collection(db, 'orders');
    const cQuery = query(
      cRef,
      where('isApproved', '==', true),
      where('isFulfilled', '==', true)
    );
    const refs = await getDocs(cQuery);

    return refs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const getUniqueOrder = id => {
    const docRef = doc(db, 'orders', id);

    return getDoc(docRef);
  };

  const deleteSelectedOrders = selectedOrders => {
    const batch = writeBatch(db);

    selectedOrders.forEach(id => {
      const docRef = doc(db, 'orders', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  /* ==== Draft Orders ==== */

  const getUsersDraftOrders = async (id) => {
    const cRef = collection(db, 'draft_orders');
    const cQuery = query(
      cRef,
      where('user_id', '==', id)
    );
    const refs = await getDocs(cQuery);

    return refs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const getAllDraftOrders = () => {
    const cRef = collection(db, 'draft_orders');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  }

  const deleteDraftOrder = id => {
    const docRef = doc(db, 'draft_orders', id);

    return deleteDoc(docRef);
  };

  const deleteSelectedDraftOrders = selectedDraftOrders => {
    const batch = writeBatch(db);

    selectedDraftOrders.forEach(id => {
      const docRef = doc(db, 'draft_orders', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  const getUniqueDraftOrder = id => {
    const docRef = doc(db, 'draft_orders', id);

    return getDoc(docRef);
  };

  const updateUniqueDraftOrder = (id, draftOrder) => {
    const {
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    } = draftOrder;
    const docRef = doc(db, 'draft_orders', id);

    return updateDoc(docRef, {
      updatedAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    });
  };

  const createDraftOrder = data => {
    const {
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    } = data;
    const cRef = collection(db, 'draft_orders');

    return addDoc(cRef, {
      // TODO - refactor auth, ensure this works
      user_id: auth.currentUser.uid,
      updatedAt: serverTimestamp(),
      overnightShipping,
      items,
      franchiseNotes,
      corporateNotes,
      territoryId,
      name,
      deliveryAddressId,
      address1,
      address2,
      city,
      state,
      zip,
      enabled,
      recipientName,
      email,
      mobile
    });
  };

  /* ==== Fulfillment Notes ==== */
  // 1/Cloud function that clears the current note every week...
  // 3/Setting an previous one, posts same note but a new one
  // 4/Copy and set copies a previous one and then takes users to
  // 2/Creating New Order Note makes it current
  const createOrderNoteAndSetAsCurrent = notes => {
    const timestamp = serverTimestamp();
    const cRef = collection(db, 'fulfillment_notes');

    return addDoc(cRef, {
      notes,
      isCurrent: true,
      isPast: false,
      createdAt: timestamp,
      updatedAt: timestamp
    });
  };

  const setAllNotesAsPast = async () => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'fulfillment_notes');
    const cQuery = query(
      cRef,
      where('isCurrent', '==', true)
    );
    const refs = await getDocs(cQuery);

    refs.forEach(r => {
      const docRef = doc(db, 'fulfillment_notes', r.id);

      batch.update(docRef, {
        isCurrent: false,
        isPast: true
      });
    });

    return batch.commit();
  };

  const updateUniqueOrderNote = (id, notes) => {
    const docRef = doc(db, 'fulfillment_notes', id);

    return updateDoc(docRef, {
      notes,
      updatedAt: serverTimestamp()
    });
  };

  const getUniqueOrderNote = id => {
    const docRef = doc(db, 'fulfillment_notes', id);

    return getDoc(docRef);
  };

  const getAllOrderNotes = () => {
    const cRef = collection(db, 'fulfillment_notes');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const getCurrentWeekOrderNote = () => {
    const cRef = collection(db, 'fulfillment_notes');
    const cQuery = query(
      cRef,
      where('isCurrent', '==', true)
    );

    return getDocs(cQuery);
  };

  const deleteSelectedOrderNotes = selectedIds => {
    const batch = writeBatch(db);

    selectedIds.forEach(id => {
      const docRef = doc(db, 'fulfillment_notes', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  /* ==== Compliance Report Images ==== */
  const uploadComplianceReportImage = async file => {
    // TODO - refactor storage, ensure this works
    const storageRef = ref(storage, file.name);
    const uploadTask = await uploadBytes(storageRef, file);

    return await getDownloadURL(uploadTask.ref);
  };

  const updateComplianceReportQuestionsAfterImageDelete = (reportId, questions) => {
    const docRef = doc(db, 'compliance_reports', reportId);

    return updateDoc(docRef, { questions });
  };

  const deleteUniqueComplianceReportImage = fileName => {
    // TODO - refactor storage, ensure this works
    const storageRef = ref(storage, fileName);

    return deleteObject(storageRef);
  };

  /* === Inventory Reports ==== */

  const updateUniqueInventoryReportWithClosingInventory = (id, closingInventory) => {
    const docRef = doc(db, 'inventory_reports', id);

    return updateDoc(docRef, { closingInventory });
  };

  const getUniqueInventoryReport = id => {
    const docRef = doc(db, 'inventory_reports', id);

    return getDoc(docRef);
  }

  const getAllInventoryReports = () => {
    const cRef = collection(db, 'inventory_reports');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const createOpeningInventoryReport = ({
    openingInventory,
    franchiseLocationName,
    franchiseLocationId,
    territoryId,
    name
  }) => {
    const cRef = collection(db, 'inventory_reports');

    return addDoc(cRef, {
      createdAt: serverTimestamp(),
      // TODO - refactor auth, ensure this works
      user_id: auth.currentUser.uid,
      openingInventory,
      franchiseLocationName,
      franchiseLocationId,
      territoryId,
      name
    });
  };

  /* ==== Compliance Reports ==== */

  const resolveComplianceReportDnq = (id, didPass) => {
    const filter = {
      dnqResolved: true,
      Passed: didPass,
      failed: !didPass
    };
    const docRef = doc(db, 'compliance_reports', id);

    return updateDoc(docRef, filter);
  };

  const editUniqueComplianceReport = (reportId, { questions, highlightNotes, lowlightNotes, passed, failed, dnq }) => {
    const docRef = doc(db, 'compliance_reports', reportId);

    return updateDoc(docRef, {
      questions,
      highlightNotes,
      lowlightNotes,
      passed,
      failed,
      dnq
    });
  };

  const createComplianceReport = async data => {
    const {
      franchiseLocationType,
      franchiseLocationId,
      highlightNotes,
      lowlightNotes,
      questions,
      passed,
      failed,
      dnq,
      name,
      territoryId,
      franchiseLocationName
    } = data;
    // const sections = Object.keys(questions);

    // for (let section of sections) {
    //   for (let q of questions[section]) {
    //     const file = q.images;
    //     if (q.images) {
    //       const storageRef = firebase.storage().ref();
    //       const fileRef = storageRef.child(file.name);
    //       await fileRef.put(file);
    //       const URL = await fileRef.getDownloadURL();

    //       q.images = URL;
    //     }
    //   }
    // }

    const cRef = collection(db, 'compliance_reports');

    return addDoc(cRef, {
      // TODO - refactor auth, ensure this works
      user_id: auth.currentUser.uid,
      createdAt: serverTimestamp(),
      franchiseLocationType,
      franchiseLocationId,
      highlightNotes,
      lowlightNotes,
      questions,
      passed,
      failed,
      dnq,
      name,
      territoryId,
      franchiseLocationName,
      dnqResolved: false
    });
  };

  const getUsersComplianceReports = userId => {
    const cRef = collection(db, 'compliance_reports');
    const cQuery = query(
      cRef,
      where('user_id', '==', userId)
    );

    return getDocs(cQuery);
  }

  const getUniqueComplianceReport = id => {
    const docRef = doc(db, 'compliance_reports', id);

    return getDoc(docRef);
  };

  const getComplianceReportByFranchiseLocation = franchiseLocationId => {
    // First group all of that franchises compliance reports
    // Filter the most recent in client

    const cRef = collection(db, 'compliance_reports');
    const cQuery = query(
      cRef,
      where('franchiseLocationId', '==', franchiseLocationId)
    );

    return getDocs(cQuery);
  };

  const getAllComplianceReports = () => {
    const cRef = collection(db, 'compliance_reports');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const deleteSelectedComplianceReports = selectedComplianceReports => {
    const batch = writeBatch(db);

    selectedComplianceReports.forEach(id => {
      const docRef = doc(db, 'compliance_reports', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  /* ==== Draft Compliance Report Images ==== */

  const deleteUniqueDraftComplianceReportImage = async (fileName, complianceReportId) => {
    // TODO - refactor storage, ensure this works
    const storageRef = ref(storage, fileName);
    const docRef = doc(db, 'compliance_reports', complianceReportId);

    await deleteObject(storageRef);
    return updateDoc(docRef, { images: undefined });
  };
  /* ==== Draft Compliance Reports ==== */

  const deleteUniqueDraftComplianceReport = id => {
    const docRef = doc(db, 'draft_compliance_reports', id);

    return deleteDoc(docRef);
  };

  const deleteSelectedDraftComplianceReports = selectedComplianceReports => {
    const batch = writeBatch(db);

    selectedComplianceReports.forEach(id => {
      const docRef = doc(db, 'draft_compliance_reports', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  const updateUniqueDraftComplianceReport = (id, data) => {
    const {
      franchiseLocationType,
      franchiseLocationId,
      franchiseLocationName,
      name,
      territoryId,
      highlightNotes,
      lowlightNotes,
      questions
    } = data;
    const docRef = doc(db, 'draft_compliance_reports', id);

    return updateDoc(docRef, {
      franchiseLocationName,
      name,
      territoryId,
      franchiseLocationType,
      franchiseLocationId,
      highlightNotes,
      lowlightNotes,
      questions
    });
  };

  const getUniqueDraftComplianceReport = id => {
    const docRef = doc(db, 'draft_compliance_reports', id);

    return getDoc(docRef);
  };

  const getDraftComplianceReportByFranchiseLocation = franchiseLocationId => {
    const cRef = collection(db, 'draft_compliance_reports');
    const cQuery = query(
      cRef,
      where('franchiseLocationId', '==', franchiseLocationId)
    );

    return getDocs(cQuery);
  };

  const getAllDraftComplianceReports = () => {
    const cRef = collection(db, 'draft_compliance_reports');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const createDraftComplianceReport = data => {
    const {
      franchiseLocationType,
      franchiseLocationId,
      highlightNotes,
      lowlightNotes,
      name,
      territoryId,
      franchiseLocationName,
      questions
    } = data;
    const cRef = collection(db, 'draft_compliance_reports');

    // TODO - refactor auth, ensure this works
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return { error: 'User not authorized' };
    }

    return addDoc(cRef, {
      // TODO - refactor auth, ensure this works
      createdByUserId: auth.currentUser.uid,
      updatedAt: serverTimestamp(),
      franchiseLocationType,
      franchiseLocationId,
      highlightNotes,
      lowlightNotes,
      questions,
      name,
      territoryId,
      franchiseLocationName
    });
  };

  /* ==== Compliance Report Question Sections ==== */

  const getAllComplianceReportSections = () => {
    const cRef = collection(db, 'compliance_report_sections');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const createComplianceReportSection = data => {
    const { sectionTitle } = data;
    const cRef = collection(db, 'compliance_report_sections');

    return addDoc(cRef, { sectionTitle: sectionTitle.toLowerCase() });
  };

  const updateUniqueComplianceReportSection = data => {
    const { sectionTitle } = data;
    const cRef = collection(db, 'compliance_report_sections');

    return addDoc(cRef, { sectionTitle });
  };

  const deleteSelectedComplianceReportSections = ids => {
    const cRef = collection(db, 'compliance_report_sections');

    return addDoc(cRef, {});
  };
  /* ==== Compliance Questions ==== */

  const deleteSelectedComplianceQuestions = selectedQuestions => {
    const batch = writeBatch(db);

    selectedQuestions.forEach(id => {
      const docRef = doc(db, 'compliance_report_questions', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  const createComplianceQuestion = complianceQuestion => {
    const { questionType, questionWeight, question, sectionTitle } = complianceQuestion;
    const cRef = collection(db, 'compliance_report_questions');

    return addDoc(cRef, {
      questionType,
      questionWeight,
      question,
      sectionTitle
    });
  };

  const getAllComplianceQuestions = () => {
    const cRef = collection(db, 'compliance_report_questions');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const updateUniqueComplianceQuestion = (id, complianceQuestion) => {
    const { question, questionType, questionWeight, sectionTitle } = complianceQuestion;
    const docRef = doc(db, 'compliance_report_questions', id);

    return updateDoc(docRef, {
      question,
      questionType,
      questionWeight,
      sectionTitle
    });
  };

  const getUniqueComplianceQuestion = id => {
    const docRef = doc(db, 'compliance_report_questions', id);

    return getDoc(docRef);
  };

  /* ==== Compliance Questions ==== */
  const getAllComplianceQuestionSections = () => {
    const cRef = collection(db, 'compliance_question_sections');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  /* ==== Training Videos ==== */

  const deleteSelectedTrainingVideos = selectedVideos => {
    const batch = writeBatch(db);

    selectedVideos.forEach(id => {
      const docRef = doc(db, 'training_videos', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  const createVideo = data => {
    const { title, description, category, url } = data;
    const cRef = collection(db, 'training_videos');

    return addDoc(cRef, {
      title,
      description,
      category,
      url
    });
  };

  const getAllTrainingVideos = () => {
    const cRef = collection(db, 'training_videos');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const getUniqueTrainingVideo = id => {
    const docRef = doc(db, 'training_videos', id);

    return getDoc(docRef);
  };

  const updateUniqueTrainingVideo = (id, data) => {
    const { title, description, url, category } = data;
    const docRef = doc(db, 'training_videos', id);

    return updateDoc(docRef, { title, description, url, category });
  };

  /* ==== Videos Categories ==== */

  const getAllTrainingVideoCategories = () => {
    const cRef = collection(db, 'training_video_categories');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const createTrainingVideoCategory = data => {
    const { categoryTitle } = data;
    const cRef = collection(db, 'training_video_categories');

    return addDoc(cRef, { categoryTitle: categoryTitle.toLowerCase() });
  };

  /* ==== Approvals ==== */

  // Get request for detail/revise views that have files...

  const getApprovalFiles = (formId, approvalRequestId) => {
    const fileName = `${formId}/${approvalRequestId}`;
    const storageRef = ref(storage, fileName);

    return storageRef;
  }

  const reviseApprovalFiles = (formId, approvalRequestId, filesToDelete, filesToAdd) => {
    // TODO - refactor storage, ensure this works
    try {
      filesToDelete.forEach(fileName => {
        const name = `${formId}/${approvalRequestId}/${fileName}`;
        const storageRef = ref(storage, name);

        deleteObject(storageRef)
          .then(res => console.log(res))
          .catch(err => console.log(err));
      });
    } catch (e) {
      console.log(e)
    }

    try {
      filesToAdd.forEach(file => {
        const name = `${formId}/${approvalRequestId}/${file.name}`;
        const storageRef = ref(storage, name);

        uploadBytes(storageRef, file)
          .then(res => console.log(res))
          .catch(err => console.log(err));
      });
    } catch (e) {
      console.log(e)
    }
  };

  const createRefundPolicyRequest = async data => {
    // TODO - refactor storage, ensure this works
    const { files, transactionDate, refundDate } = data;
    // Upload files into Storage and return an array

    const cRef = collection(db, 'approvals');
    const approvalRef = doc(cRef);

    Promise.all(
      files.map(async file => {
        const fileName = `${data.formId}/${approvalRef.id}/${file.name}`;
        const storageRef = ref(storage, fileName);
        const uploadTask = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(uploadTask.ref);

        return {
          name: file.name,
          url
        };
      })
    );
    const copy = Object.assign({}, data);
    delete copy.files;

    const updated = {
      ...copy,
      revisionNotes: '',
      transactionDate: new Date(transactionDate),
      refundDate: new Date(refundDate),
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp()
    };

    return setDoc(approvalRef, updated);
  };

  const createTruckSupplierRequest = async data => {
    // TODO - refactor storage, ensure this works
    const { files } = data;
    // Upload files into Storage and return an array
    const cRef = collection(db, 'approvals');
    const approvalRef = doc(cRef);

    Promise.all(
      files.map(async file => {
        const fileName = `${data.formId}/${approvalRef.id}/${file.name}`;
        const storageRef = ref(storage, fileName);
        const uploadTask = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(uploadTask.ref);

        return {
          name: file.name,
          url
        };
      })
    );

    const copy = Object.assign({}, data);
    delete copy.files;

    const updated = {
      ...copy,
      revisionNotes: '',
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp()
    };

    return setDoc(approvalRef, updated);
  };

  const createNewRestaurantOrTruckOrTerritoryApprovalRequest = async data => {
    // TODO - refactor storage, ensure this works
    const { files, launchDate } = data;
    // Upload files into Storage and return an array
    const cRef = collection(db, 'approvals');
    const approvalRef = doc(cRef);

    Promise.all(
      files.map(async file => {
        const fileName = `${data.formId}/${approvalRef.id}/${file.name}`;
        const storageRef = ref(storage, fileName);
        const uploadTask = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(uploadTask.ref);

        return {
          name: file.name,
          url
        };
      })
    );

    const copy = Object.assign({}, data);
    delete copy.files;

    const updated = {
      ...copy,
      launchDate: new Date(launchDate),
      revisionNotes: '',
      createdAt: serverTimestamp(),
      lastUpdated: serverTimestamp()
    };

    return setDoc(approvalRef, updated);
  };

  const createTentPopupApprovalRequest = async data => {
    // TODO - refactor storage, ensure this works
    const { files, eventTimeAndDate } = data;
    // Upload files into Storage and return an array
    const cRef = collection(db, 'approvals');
    const approvalRef = doc(cRef);

    Promise.all(
      files.map(async file => {
        const fileName = `${data.formId}/${approvalRef.id}/${file.name}`;
        const storageRef = ref(storage, fileName);
        const uploadTask = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(uploadTask.ref);

        return {
          name: file.name,
          url
        };
      })
    );

    const copy = Object.assign({}, data);
    delete copy.files;

    const updated = {
      ...copy,
      eventTimeAndDate: new Date(eventTimeAndDate),
      revisionNotes: '',
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp()
    };

    return setDoc(approvalRef, updated);
  };

  // End of file related create approval requests

  const reviseTentPopupApprovalRequest = async (id, data) => {
    const { eventTimeAndDate } = data;
    // Upload files into Storage and return an array

    const copy = Object.assign({}, data);
    delete copy.files;
    delete copy.filesToAdd;
    delete copy.filesToDelete;

    const updated = {
      ...copy,
      eventTimeAndDate: new Date(eventTimeAndDate),
      lastUpdated: serverTimestamp()
    };
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, updated);
  };

  const reviseTruckSupplierRequest = async (id, data) => {
    const copy = Object.assign({}, data);
    delete copy.files;
    delete copy.filesToAdd;
    delete copy.filesToDelete;

    const updated = {
      ...copy,
      lastUpdated: serverTimestamp()
    };
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, updated);
  };

  const reviseRefundPolicy = async (id, data) => {
    const { transactionDate, refundDate } = data;
    // Upload files into Storage and return an array

    const copy = Object.assign({}, data);
    delete copy.files;
    delete copy.filesToAdd;
    delete copy.filesToDelete;

    const updated = {
      ...copy,
      lastUpdated: serverTimestamp(),
      transactionDate: new Date(transactionDate),
      refundDate: new Date(refundDate)
    };
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, updated);
  };

  const reviseNewRestaurantOrTruckOrTerritoryRequest = async (id, data) => {
    const { launchDate } = data;
    // Upload files into Storage and return an array
    const copy = Object.assign({}, data);
    delete copy.files;
    delete copy.filesToAdd;
    delete copy.filesToDelete;

    const updated = {
      ...copy,
      launchDate: new Date(launchDate),
      lastUpdated: serverTimestamp()
    };

    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, updated);
  };

  // End of file related revise approval requests

  const createOutsideTerritoryRequest = async data => {
    const batch = writeBatch(db);

    data.events.forEach(event => {
      const { isEventRecurring, dateAndTime, startDate, endDate } = event;
      let updated;
      const copy = Object.assign({}, data);
      if (isEventRecurring) {
        delete event.dateAndTime;
        delete copy.events;
        updated = {
          ...copy,
          ...event,
          startDate: new Date(startDate),
          endDate: new Date(endDate)
        };
      } else {
        delete event.startDate;
        delete event.endDate;
        delete copy.events;
        updated = {
          ...copy,
          ...event,
          dateAndTime: new Date(dateAndTime)
        };
      }

      const cRef = collection(db, 'approvals');
      const requestRef = doc(cRef);

      batch.set(requestRef, {
        ...updated,
        revisionNotes: '',
        lastUpdated: serverTimestamp(),
        createdAt: serverTimestamp()
      });
    });

    return batch.commit();
  };

  const reviseCustomApparelRequest = (id, data) => {
    const { needByDate, startDate, endDate } = data;
    const updated = {
      ...data,
      needByDate: new Date(needByDate),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      lastUpdated: serverTimestamp()
    };
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, updated);
  };

  const createCustomApparelRequest = data => {
    const { needByDate, startDate, endDate } = data;
    const updated = {
      ...data,
      needByDate: new Date(needByDate),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      revisionNotes: '',
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp()
    };
    const cRef = collection(db, 'approvals');

    return addDoc(cRef, updated);
  };

  const createSingleOutsideTerritoryRequest = async data => {
    const { startDate, endDate, isEventRecurring, dateAndTime } = data;
    const cRef = collection(db, 'approvals');
    // Upload files into Storage and return an array
    if (isEventRecurring) {
      const updated = {
        ...data,
        revisionNotes: '',
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp()
      };

      delete updated.dateAndTime;

      return addDoc(cRef, updated);
    } else {
      const updated = {
        ...data,
        revisionNotes: '',
        dateAndTime: new Date(dateAndTime),
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp()
      };
      delete updated.startDate;
      delete updated.endDate;

      return addDoc(cRef, updated);
    }
  };

  const reviseOutsideTerritoryRequest = async (id, data) => {
    const { startDate, endDate, isEventRecurring, dateAndTime } = data;
    // Upload files into Storage and return an array
    if (isEventRecurring) {
      const updated = {
        ...data,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        lastUpdated: serverTimestamp()
      };

      delete updated.dateAndTime;

      const docRef = doc(db, 'approvals', id);

      return updateDoc(docRef, updated);
    } else {
      const updated = {
        ...data,
        dateAndTime: new Date(dateAndTime),
        lastUpdated: serverTimestamp()
      };
      delete updated.startDate;
      delete updated.endDate;

      const docRef = doc(db, 'approvals', id);

      return updateDoc(docRef, updated);
    }
  };

  const reviseAddOrRemoveItemRequest = async (id, data) => {
    const { startDate, endDate } = data;
    // Upload files into Storage and return an array
    const updated = {
      ...data,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      lastUpdated: serverTimestamp()
    };
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, updated);
  };

  const updateStatusOfApprovalRequest = (requestId, newStatus, revisionNotes = '') => {
    const docRef = doc(db, 'approvals', requestId);

    return updateDoc(docRef, {
      lastUpdated: serverTimestamp(),
      revisionNotes,
      status: newStatus
    });
  };

  const createSingleAddOrRemoveItemRequest = async data => {
    const updated = {
      ...data,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      revisionNotes: '',
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp()
    };
    const cRef = collection(db, 'approvals');

    return addDoc(cRef, updated);
  };

  const createAddOrRemoveItemRequest = async data => {
    const batch = writeBatch(db);
    const {
      territoryId,
      name,
      user_email,
      user_mobile,
      user_first,
      user_last,
      isTruckForm,
      user_id,
      franchiseLocationId,
      franchiseLocationName,
      franchiseLocationType,
      status,
      formId,
      isAddingItem
    } = data;

    if (data.isAddingItem) {
      data.addItems.forEach(item => {
        const cRef = collection(db, 'approvals');
        const requestRef = doc(cRef);

        batch.set(requestRef, {
          ...item,
          startDate: new Date(item.startDate),
          endDate: new Date(item.endDate),
          territoryId,
          name,
          user_email,
          user_mobile,
          user_first,
          user_id,
          user_last,
          franchiseLocationId,
          franchiseLocationName,
          franchiseLocationType,
          status,
          formId,
          isTruckForm,
          revisionNotes: '',
          lastUpdated: serverTimestamp(),
          createdAt: serverTimestamp(),
          isAddingItem
        });
      });
    } else {
      data.removeItems.forEach(item => {
        const cRef = collection(db, 'approvals');
        const requestRef = doc(cRef);

        batch.set(requestRef, {
          ...item,
          territoryId,
          name,
          user_email,
          user_mobile,
          user_first,
          user_last,
          user_id,
          franchiseLocationId,
          franchiseLocationName,
          franchiseLocationType,
          status,
          formId,
          isTruckForm,
          revisionNotes: '',
          lastUpdated: serverTimestamp(),
          createdAt: serverTimestamp(),
          isAddingItem
        });
      });
    }

    return batch.commit();
  };

  const reviseLogoGraphicsRequest = (id, data) => {
    const { startDate, endDate } = data;
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, {
      ...data,
      lastUpdated: serverTimestamp(),
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    });
  };

  const createLogoGraphicsRequest = data => {
    const { startDate, endDate } = data;
    const cRef = collection(db, 'approvals');

    return addDoc(cRef, {
      ...data,
      revisionNotes: '',
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp(),
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    });
  };

  const reviseThirdPartySupplierRequest = (id, data) => {
    const { startDate, endDate } = data;
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, {
      ...data,
      lastUpdated: serverTimestamp(),
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    });
  };

  const createThirdPartySupplierRequest = data => {
    const { startDate, endDate } = data;
    const cRef = collection(db, 'approvals');

    return addDoc(cRef, {
      ...data,
      revisionNotes: '',
      createdAt: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    });
  };

  const reviseSpecialItemRequest = (id, data) => {
    const { startDate, endDate } = data;
    const docRef = doc(db, 'approvals', id);

    return updateDoc(docRef, {
      ...data,
      lastUpdated: serverTimestamp(),
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    });
  };

  const createSingleSpecialItemRequest = async data => {
    const { startDate, endDate } = data;
    const cRef = collection(db, 'approvals');

    return addDoc(cRef, {
      ...data,
      revisionNotes: '',
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp()
    });
  };

  const createSpecialItemRequest = async data => {
    const batch = writeBatch(db);
    const {
      territoryId,
      name,
      user_email,
      user_mobile,
      user_first,
      user_last,
      isTruckForm,
      franchiseLocationId,
      franchiseLocationName,
      franchiseLocationType,
      howAdvertised,
      status,
      user_id,
      formId
    } = data;

    data.addItems.forEach(item => {
      const cRef = collection(db, 'approvals');
      const requestRef = doc(cRef);

      batch.set(requestRef, {
        ...item,
        revisionNotes: '',
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
        howAdvertised,
        territoryId,
        name,
        user_email,
        user_mobile,
        user_first,
        user_last,
        franchiseLocationId,
        franchiseLocationName,
        franchiseLocationType,
        status,
        formId,
        user_id,
        isTruckForm,
        lastUpdated: serverTimestamp(),
        createdAt: serverTimestamp()
      });
    });

    return batch.commit();
  };

  const getUniqueApprovalRequest = id => {
    const docRef = doc(db, 'approvals', id);

    return getDoc(docRef);
  }

  const getAllApprovals = () => {
    const cRef = collection(db, 'approvals');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  }

  const getApprovalsByUser = userId => {
    const cRef = collection(db, 'approvals');
    const cQuery = query(
      cRef,
      where('user_id', '==', userId)
    );

    return getDocs(cQuery);
  }

  const editMysteryShopperReport = (id, data) => {
    const docRef = doc(db, 'mystery_shopper_reports', id);
    const copy = Object.assign({}, data);
    delete copy.filesToAdd;
    delete copy.filesToDelete;

    return updateDoc(docRef, { ...copy, date: new Date(copy.date) });
  };

  const editMysteryShopperReportFiles = (reportId, filesToDelete, filesToAdd) => {
    filesToDelete.forEach(fileName => {
      const name = `mystery-shopper-reports/${reportId}/${fileName}`;
      const storageRef = ref(storage, name);

      deleteObject(storageRef)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    });

    filesToAdd.forEach(file => {
      const name = `mystery-shopper-reports/${reportId}/${file.name}`;
      const storageRef = ref(storage, name);

      uploadBytes(storageRef, file)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    });
  };

  const getAllMysteryShopperReports = () => {
    const cRef = collection(db, 'mystery_shopper_reports');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  }

  const getMysteryShopperReportsByUserId = userId => {
    const cRef = collection(db, 'mystery_shopper_reports');
    const cQuery = query(
      cRef,
      where('user_id', '==', userId)
    );

    return getDocs(cQuery);
  }

  const getUniqueMysteryShopperReport = reportId => {
    const docRef = doc(db, 'mystery_shopper_reports', reportId);

    return getDoc(docRef);
  }

  const getMysteryShopperReportFiles = reportId => {
    // TODO - refactor storage, ensure this works
    const fileName = `mystery-shopper-reports/${reportId}`
    const storageRef = ref(storage, fileName);

    return storageRef;
  }

  const createMysteryShopperReport = data => {
    // TODO - refactor storage, ensure this works
    const {
      date,
      files,
      report,
      name,
      user_mobile,
      user_email,
      user_first,
      user_last,
      territoryId,
      franchiseLocationId,
      franchiseLocationName
    } = data;
    const cRef = collection(db, 'mystery_shopper_reports');
    const requestRef = doc(cRef);

    Promise.all(
      files.map(async file => {
        const fileName = `mystery-shopper-reports/${requestRef.id}/${file.name}`;
        const storageRef = ref(storage, fileName);

        await uploadBytes(storageRef, file);
      })
    );

    return setDoc(requestRef, {
      date: new Date(date),
      report,
      name,
      user_mobile,
      user_email,
      user_first,
      user_last,
      territoryId,
      franchiseLocationId,
      franchiseLocationName,
      createdAt: serverTimestamp()
    });
  };

  // Email Notifications

  const setTeamNotificationEmails = (
    complianceTeamEmail,
    complianceTeamPhone,
    fulfillmentTeamEmail,
    fulfillmentTeamPhone,
    ordersTeamEmail,
    ordersTeamPhone
  ) => {
    const docRef = doc(db, 'teamNotificationEmails', 'teamEmails');

    return setDoc(docRef, {
      complianceTeamEmail,
      complianceTeamPhone,
      fulfillmentTeamEmail,
      fulfillmentTeamPhone,
      ordersTeamEmail,
      ordersTeamPhone
    });
  };

  const getTeamNotificationEmails = () => {
    const docRef = doc(db, 'teamNotificationEmails', 'teamEmails');

    return getDoc(docRef);
  }

  const getAppConfig = async () => {
    const docRef = doc(db, 'config', 'appConfig');
    const ref = await getDoc(docRef);

    return ref.data();
  }

  // =============================================================================

  useEffect(() => {
    const cRef = collection(db, 'announcements');
    const cQuery = query(cRef);

    getDocs(cQuery)
      .then(({ docs }) => {
        if (docs.length > 0) {
          const latestAnnouncements = docs.reduce((a, b) => {
            return a.data().createdAt.seconds > b.data().createdAt.seconds ? a : b;
          });

          const { announcement, readRecipients } = latestAnnouncements.data();
          const { id } = latestAnnouncements;

          if (!readRecipients?.includes(auth?.currentUser?.uid)) {
            dispatch({
              type: 'SET_LATEST_ANNOUNCEMENT',
              payload: {
                announcement: {
                  id: id,
                  message: announcement
                }
              }
            });
          } else {
            dispatchToggleAnnouncementBar(false);
          }
        } else {
          dispatchToggleAnnouncementBar(false);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    // TODO - refactor auth, ensure this works
    const unsubscribe = onAuthStateChanged(auth, async user => {
      try {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const userRef = await getDoc(docRef);
          let userData = userRef.data();

          if (!userData) {
            const cRef = collection(db, 'users');
            const cQuery = query(cRef);
            const usersRef = await getDocs(cQuery);
            const isUsers = usersRef.docs.length > 0;
            const email = user.email;
            const role = isUsers ? FULFILLMENT : SUPER_ADMIN;
            const createdAt = Timestamp.now();
            const newUserData = { email, role, createdAt };
            const docRef = doc(db, 'users', user.uid);

            await setDoc(docRef, newUserData);
            userRef = await getDoc(docRef);
            userData = userRef.data();
          }

          const { uid: id, photoURL: avatar, email } = user;

          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                // from auth
                id,
                avatar,
                email,
                // from firestore
                ...userData
              }
            }
          });
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    });

    return unsubscribe;
  }, [dispatch]);

  useRollbarPerson(state?.user);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        /**
         * Firebase context reducer functions
         */
        dispatch,
        dispatchToggleAnnouncementBar,
        dispatchLatestAnnouncement,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
        // === Custom functions below ===
        // Order notifications
        createOrderNotification,
        updateOrderNotification,
        updateFulfilledForMultipleOrderNotifications,
        updateApprovedForMultipleOrderNotifications,

        // All/Admin Order Notifications
        checkForUnreadOrderNotification,
        markAllOrderNotificationsRead,
        getRecentOrderNotifications,
        getNextPaginationOrderNotifications,
        getPreviousPaginationOrderNotifications,
        userReadOrderNotifications,

        // Approved Order Notification
        getNextPaginationApprovedOrderNotifications,
        getPreviousPaginationApprovedOrderNotifications,
        checkForUnreadApprovedOrderNotification,
        getApprovedOrderNotifications,
        markAllApprovedOrderNotificationsRead,

        // Fulfilled Order Notifications
        getFulfilledOrderNotifications,
        getNextPaginationFulfilledOrderNotifications,
        getPreviousPaginationFulfilledOrderNotifications,
        checkForUnreadFulfilledOrderNotification,
        markAllFulfilledOrderNotificationsRead,
        // Team Notifications
        setTeamNotificationEmails,
        getTeamNotificationEmails,
        // Approvals
        getApprovalFiles,
        reviseApprovalFiles,
        reviseNewRestaurantOrTruckOrTerritoryRequest,
        createSingleOutsideTerritoryRequest,
        reviseOutsideTerritoryRequest,
        reviseAddOrRemoveItemRequest,
        reviseRefundPolicy,
        createOutsideTerritoryRequest,
        updateStatusOfApprovalRequest,
        reviseTruckSupplierRequest,
        createTruckSupplierRequest,
        reviseCustomApparelRequest,
        createCustomApparelRequest,
        createRefundPolicyRequest,
        reviseLogoGraphicsRequest,
        createLogoGraphicsRequest,
        reviseThirdPartySupplierRequest,
        createThirdPartySupplierRequest,
        createSingleAddOrRemoveItemRequest,
        createAddOrRemoveItemRequest,
        reviseSpecialItemRequest,
        createSingleSpecialItemRequest,
        createSpecialItemRequest,
        getUniqueApprovalRequest,
        reviseTentPopupApprovalRequest,
        createTentPopupApprovalRequest,
        createNewRestaurantOrTruckOrTerritoryApprovalRequest,
        getApprovalsByUser,
        getAllApprovals,
        // Announcements
        getNextPaginationAnnouncementNotifications,
        getPreviousPaginationAnnouncementNotifications,
        userReadAnnouncementNotifications,
        markAllAnnouncementNotificationsRead,
        checkForUnreadAnnouncementNotification,
        getRecentAnnouncementNotifications,
        updateUniqueAnnouncement,
        getUniqueAnnouncement,
        setAllAnnouncementsAsPrevious,
        markAnnouncementReadByUser,
        getAllAnnouncements,
        createAnnouncement,
        deleteAnnouncementById,
        // Users
        updateUserTerritoryAssignment,
        updateUserPermissions,
        deleteUserFromTerritories,
        reinitializeIdToken,
        getAllUsers,
        getUniqueUser,
        getAllUsersByRole,
        // Orders
        getThisWeeksOrders,
        getOrderByUserId,
        getUniqueOrder,
        createOrder,
        getAllOrders,
        getUniqueOrder,
        approveMultipleOrders,
        updateOrder,
        deleteSelectedOrders,
        setOrderStatus,
        approveOrderAndSetCorporateNotes,
        // Fulfillment Notes
        createOrderNoteAndSetAsCurrent,
        deleteSelectedOrderNotes,
        setAllNotesAsPast,
        getAllOrderNotes,
        getUniqueOrderNote,
        updateUniqueOrderNote,
        getCurrentWeekOrderNote,
        // Draft Orders
        deleteSelectedDraftOrders,
        updateUniqueDraftOrder,
        createDraftOrder,
        deleteDraftOrder,
        getUsersDraftOrders,
        getAllDraftOrders,
        getUniqueDraftOrder,
        // Fulfillment
        fulfillOrder,
        fulfillOrderStatus,
        getFulfillmentOrders,
        // Items
        createNewItem,
        deleteSelectedItems,
        getAllItems,
        getUniqueItem,
        updateUniqueItem,
        // Territories
        deleteTerritoryFromFranchiseAndDeliveryLocations,
        deleteUniqueTerritory,
        getUserTerritories,
        updateTerritoryNameAcrossLocations,
        exchangeTerritoryIdFromOldToNewUser,
        getUniqueTerritory,
        getAllTerritories,
        createTerritory,
        updateUniqueTerritory,
        // Franchise Locations
        deleteFranchiseLocationFromTerritory,
        createFranchiseLocation,
        deleteUniqueFranchiseLocation,
        addFranchiseLocationToTerritory,
        removeFranchiseLocationAndAddToNewTerritory,
        updateUniqueFranchiseLocation,
        getAllFranchiseLocations,
        getUniqueFranchiseLocation,
        // Delivery Locations
        deleteDeliveryLocationFromTerritory,
        getUniqueLocation,
        removeDeliveryLocationAndAddToNewTerritory,
        addDeliveryLocationToTerritory,
        addLocationIdToTerritory,
        deleteUniqueDeliveryLocation,
        createDeliveryLocation,
        getAllLocations,
        updateUniqueLocation,
        // LLCs
        createLLC,
        getAllLLCs,
        getLLCById,
        updateLLCById,
        deleteLLCById,
        // Compliance Report Images
        uploadComplianceReportImage,
        deleteUniqueComplianceReportImage,
        updateComplianceReportQuestionsAfterImageDelete,
        // Myster Shopper Reports
        createMysteryShopperReport,
        getAllMysteryShopperReports,
        getMysteryShopperReportFiles,
        editMysteryShopperReport,
        editMysteryShopperReportFiles,
        getMysteryShopperReportsByUserId,
        getUniqueMysteryShopperReport,
        // Compliance Reports
        resolveComplianceReportDnq,
        createComplianceReport,
        editUniqueComplianceReport,
        getAllComplianceReports,
        getUsersComplianceReports,
        getUniqueComplianceReport,
        deleteSelectedComplianceReports,
        getComplianceReportByFranchiseLocation,
        // Draft Compliance Report Images
        deleteUniqueDraftComplianceReportImage,
        // Draft Compliance Reports
        createDraftComplianceReport,
        deleteUniqueDraftComplianceReport,
        deleteSelectedDraftComplianceReports,
        updateUniqueDraftComplianceReport,
        getUniqueDraftComplianceReport,
        getAllDraftComplianceReports,
        getDraftComplianceReportByFranchiseLocation,
        // Compliance Sections
        getAllComplianceReportSections,
        createComplianceReportSection,
        updateUniqueComplianceReportSection,
        deleteSelectedComplianceReportSections,
        // Compliance Questions
        deleteSelectedComplianceQuestions,
        getAllComplianceQuestions,
        getUniqueComplianceQuestion,
        updateUniqueComplianceQuestion,
        createComplianceQuestion,
        // Compliance Questions Sections
        getAllComplianceQuestionSections,
        // /Video
        getAllTrainingVideos,
        getUniqueTrainingVideo,
        createVideo,
        updateUniqueTrainingVideo,
        deleteSelectedTrainingVideos,
        // Videos Categories
        getAllTrainingVideoCategories,
        createTrainingVideoCategory,
        // Inventory Reports
        getUniqueInventoryReport,
        createOpeningInventoryReport,
        updateUniqueInventoryReportWithClosingInventory,
        getAllInventoryReports,
        getAppConfig
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
