import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  Package as PackageIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from 'react-feather';
import { useCurrentUser, useFirebaseContext } from 'src/contexts/firebase-context';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const ManagerAndEmployeeNotifications = () => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const ref = useRef(null);
  const [orderNotifications, setOrderNotifications] = useState([]);
  const [userTerritoryIds, setUserTerritoryIds] = useState(['']);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [hasUnreadOrderNotifications, setHasUnreadOrderNotifications] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const {
    markAllFulfilledOrderNotificationsRead,
    userReadOrderNotifications,
    //
    getFulfilledOrderNotifications,
    getPreviousPaginationFulfilledOrderNotifications,
    getNextPaginationFulfilledOrderNotifications,
    checkForUnreadFulfilledOrderNotification,
    getUserTerritories
  } = useFirebaseContext();
  const user = useCurrentUser();

  const handleOpen = () => {
    setOpen(true);
    checkForUnreadFulfilledOrderNotification(user.id, userTerritoryIds)
      .then(check => setHasUnreadOrderNotifications(check))
      .catch(err => console.error(err));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = useCallback(async () => {
    try {
      // Gets all orders

      const response = await getFulfilledOrderNotifications(user.assignedTerritories);
      const check = await checkForUnreadFulfilledOrderNotification(user.id, user.assignedTerritories);

      if (isMountedRef.current) {
        setOrderNotifications(response.docs);
        setHasUnreadOrderNotifications(check);
        setUserTerritoryIds(user.assignedTerritories);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Tooltip title="Orders">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={() => {
            userReadOrderNotifications(orderNotifications, user.id).then(() => {
              checkForUnreadFulfilledOrderNotification(user.id, userTerritoryIds)
                .then(check => {
                  setHasUnreadOrderNotifications(check);
                })
                .catch(err => console.error(err));
            });

            handleOpen();
          }}
        >
          {hasUnreadOrderNotifications ? (
            <Badge variant="dot" color="error">
              <SvgIcon>
                <PackageIcon />
              </SvgIcon>
            </Badge>
          ) : (
            <SvgIcon>
              <PackageIcon />
            </SvgIcon>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color="textPrimary">
            Orders
          </Typography>
          <Box>
            {currentPagination > 1 && (
              <Tooltip title="Previous">
                <IconButton
                  onClick={async () => {
                    let paginatedNotifications;

                    paginatedNotifications = await getPreviousPaginationFulfilledOrderNotifications(
                      orderNotifications[0],
                      userTerritoryIds
                    );
                    userReadOrderNotifications(paginatedNotifications.docs, user.id)
                      .then(() =>
                        checkForUnreadFulfilledOrderNotification(user.id, userTerritoryIds)
                          .then(check => {
                            setHasUnreadOrderNotifications(check);
                          })
                          .catch(err => console.error(err))
                      )
                      .catch(err => console.error(err));

                    if (paginatedNotifications.docs.length) {
                      setCurrentPagination(prev => prev - 1);
                      setOrderNotifications(paginatedNotifications.docs);
                    }
                  }}
                >
                  <SvgIcon fontSize="small">
                    <ChevronLeftIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Next">
              <IconButton
                onClick={async () => {
                  const paginatedNotifications = await getNextPaginationFulfilledOrderNotifications(
                    orderNotifications[orderNotifications.length - 1],
                    userTerritoryIds
                  );

                  userReadOrderNotifications(paginatedNotifications.docs, user.id)
                    .then(() =>
                      checkForUnreadFulfilledOrderNotification(user.id, userTerritoryIds)
                        .then(check => {
                          setHasUnreadOrderNotifications(check);
                        })
                        .catch(err => console.error(err))
                    )
                    .catch(err => console.error(err));

                  if (paginatedNotifications.docs.length) {
                    setCurrentPagination(prev => prev + 1);
                    setOrderNotifications(paginatedNotifications.docs);
                  }
                }}
              >
                <SvgIcon fontSize="small">
                  <ChevronRightIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider />

        {orderNotifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {orderNotifications.map(doc => {
                const { lastUpdated, name, isOvernightShipping } = doc.data();
                return (
                  <ListItem component={RouterLink} divider key={doc.id} to={`/app/orders/${doc.id}`} target="_blank">
                    <ListItemText
                      primary={`${isOvernightShipping ? 'Overnight ' : ''}Order for ${name} has been Fulfilled`}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary'
                      }}
                      secondary={moment.unix(lastUpdated.seconds).fromNow()}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box p={3} display="flex" justifyContent="center">
              <Button
                onClick={() => {
                  markAllFulfilledOrderNotificationsRead(user.id, userTerritoryIds)
                    .then(() => {
                      checkForUnreadFulfilledOrderNotification(user.id, userTerritoryIds)
                        .then(check => setHasUnreadOrderNotifications(check))
                        .catch(err => console.error(err));
                    })
                    .catch(err => console.error(err));
                }}
                size="small"
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default ManagerAndEmployeeNotifications;
