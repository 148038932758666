import React from 'react';
import { useHistory } from 'react-router';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Edit } from 'react-feather';

import { useBulkDeleteTrainingVideoCategoriesMutation } from 'src/apis';
import { ActionsCell, EnhancedTable } from 'src/components';
import { reorder } from 'src/utils/dnd';
import { usePageContext } from './context';

export const Table = () => {
  const history = useHistory();
  const { categories, updateCategories, isOrganizing } = usePageContext();
  const [bulkDelete, { isLoading: loading_bulkDelete }] = useBulkDeleteTrainingVideoCategoriesMutation();

  const columns = [
    {
      id: 'nameCol',
      label: 'Name',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      }
    },
    {
      id: 'actionsCol',
      label: '',
      align: 'center',
      render: ({ actionsCol }) => <ActionsCell actions={actionsCol} />,
      sorter: false,
      filter: false
    }
  ];

  const rows = categories.map(item => {
    const { id: categoryId, name: nameCol } = item;

    const actionsCol = [
      {
        icon: <Edit />,
        onClick: event => {
          event.stopPropagation();
          history.push(`/app/training-v2/video-categories/${categoryId}/edit`);
        }
      }
    ];

    const row = {
      ...item,
      nameCol,
      actionsCol
    };

    return row;
  });

  const tableActionsComponentProps = [
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={loading_bulkDelete ? <CircularProgress size={16} color="inherit" /> : <Delete />}
          disabled={loading_bulkDelete}
          onClick={() => {
            const ids = rows.map(row => row.id);

            bulkDelete({ ids });
          }}
        >
          Delete
        </Button>
      )
    }
  ];

  const handleDragEnd = result => {
    const category = categories.find(({ id }) => id === result.draggableId);

    if (category) {
      const newCategories = reorder(categories, result.source.index, result.destination.index);
      updateCategories(newCategories);
    }
  };

  return (
    <EnhancedTable
      title={isOrganizing ? 'Rearrange tabs by drag rows' : 'Select rows to delete'}
      columns={columns}
      rows={rows}
      tableActionsComponentProps={tableActionsComponentProps}
      empty={{
        description: 'No training video category yet.',
        buttonLabel: 'Create new category',
        onClick: () => history.push(`/app/training-v2/video-categories/new`)
      }}
      isPaginated={false}
      isDraggable={isOrganizing}
      handleDragEnd={handleDragEnd}
      isCheckable
    />
  );
};
