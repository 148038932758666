import React from 'react';
import { Avatar, Box, Card, CardContent, Typography } from '@material-ui/core';

import { useCurrentUser } from 'src/contexts/firebase-context';

export const UserBasicInfo = () => {
  const { firstName, lastName, name, roleName } = useCurrentUser();

  return (
    <Card>
      <CardContent>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Avatar src={null} style={{ width: 100, height: 100 }}>
            <Typography color="textPrimary" style={{ fontSize: 36 }}>
              {firstName?.[0]} {lastName?.[0]}
            </Typography>
          </Avatar>

          <Box textAlign={'center'} marginTop={2}>
            <Typography color="textPrimary" gutterBottom variant="h3">
              {name}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {roleName}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
