import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';

import { useEnhancedTableContext } from './enhanced-table.context';
import { HeadFilterButton } from './head-filter-button';

export const EnhancedTableHead = () => {
  const {
    columns,
    rows,
    selected,
    order,
    orderBy,
    isCheckable,
    isDraggable,
    updateTableStatus
  } = useEnhancedTableContext();

  const rowCount = rows.length;
  const numSelected = selected.length;

  const onSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      updateTableStatus({ selected: newSelecteds });
      return;
    }

    updateTableStatus({ selected: [] });
  };

  const onRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';

    updateTableStatus({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  return (
    <TableHead>
      <TableRow style={isDraggable ? { opacity: 0.5 } : {}}>
        {isCheckable && !isDraggable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="secondary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}

        {columns.map(({ id, label, render, sorter = true, filter = true, ...rest }, index) => {
          let cellProps = rest || {};
          let style = cellProps.style || {};

          cellProps = {
            ...cellProps,
            sortDirection: cellProps.sortDirection ? cellProps.sortDirection : orderBy === id ? order : false,
            align: cellProps.align ? cellProps.align : 'left',
            style: {
              ...style,
              whiteSpace: 'nowrap',
              paddingLeft: style.paddingLeft ? style.paddingLeft : 8,
              paddingRight: style.paddingRight ? style.paddingRight : 8,
              paddingTop: 16,
              paddingBottom: 16
            }
          };

          return (
            <TableCell key={`head-cell-${index}`} {...cellProps}>
              <TableSortLabel
                active={orderBy === id && !isDraggable}
                direction={orderBy === id && !isDraggable ? order : 'asc'}
                onClick={isDraggable ? () => {} : () => onRequestSort(id)}
                disabled={!sorter || isDraggable}
              >
                {label}
              </TableSortLabel>

              {filter !== false && !isDraggable && <HeadFilterButton filter={filter} columnId={id} />}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
