import React from 'react';
import { Clipboard } from 'react-feather';

import { Notifications } from '../notifications';
import { useNotifications } from './useNotifications';

export const ComplianceReportsNotifications = () => {
  const { data: { list = [], onMarkAllAsRead = () => {} } = {} } = useNotifications();

  return (
    <Notifications icon={<Clipboard />} title="Compliance reports" list={list} onMarkAllAsRead={onMarkAllAsRead} />
  );
};
