import { baseApi, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';

/**
 * recommended to use
 */
const collectionName = 'order-drafts';

export const orderDraftsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchOrderDrafts: builder.query({
      queryFn: async (props = { where: null }) => {
        const { where } = props;

        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['order-drafts']
    }),

    fetchOrderDraft: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      },
      providesTags: (result, error, { id }) => [{ type: 'order-draft', id }]
    }),

    createOrderDraft: builder.mutation({
      queryFn: async ({
        overnightShipping,
        items,
        maxPallets,
        franchiseeNotes,
        corporateNotes,
        territoryId,
        deliveryLocationId,
        llcId,
        status,
        enabled,
        userId
      }) => {
        return await createDoc(collectionName, {
          overnightShipping,
          items,
          maxPallets,
          franchiseeNotes,
          corporateNotes,
          territoryId,
          deliveryLocationId,
          llcId,
          status,
          enabled,
          userId
        });
      },
      invalidatesTags: ['order-drafts']
    }),

    updateOrderDraft: builder.mutation({
      queryFn: async ({
        id = '',
        overnightShipping = false,
        items: _items = [],
        maxPallets = 1,
        franchiseeNotes = '',
        corporateNotes = '',
        territoryId = '',
        deliveryLocationId = '',
        llcId = '',
        status = 'active',
        enabled = true,
        userId = '',
        orderNumber = 1,
        revisions: _revisions = [],
        readRecipients = []
      }) => {
        const items = _items.map((item) => ({ ...item }));
        const revisions = _revisions.map((item) => ({ ...item }));

        return await updateDoc(collectionName, {
          id,
          overnightShipping,
          items,
          maxPallets,
          franchiseeNotes,
          corporateNotes,
          territoryId,
          deliveryLocationId,
          llcId,
          status,
          enabled,
          userId,
          orderNumber,
          revisions,
          readRecipients
        });
      },
      invalidatesTags: (result, error, { id }) => ['order-drafts', { type: 'order-drafts', id: 'LIST' }, { type: 'order-draft', id }, { type: 'order-draft', id: 'LIST' }]
    }),

    deleteOrderDraft: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['order-drafts']
    })
  })
});

export const {
  useFetchOrderDraftsQuery,
  useFetchOrderDraftQuery,
  useCreateOrderDraftMutation,
  useUpdateOrderDraftMutation,
  useDeleteOrderDraftMutation
} = orderDraftsApi;
