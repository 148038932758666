import React, { useEffect, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Clear, FormatClear } from '@material-ui/icons';

import { dateRangeOptions, getDateRange } from './utils';

export const EnhancedTableDateFilter = ({
  value = { range: null, start: null, end: null },
  onChange = () => {},
  onClose = () => {},
  placeholder = 'Select Date Range...'
}) => {
  const { range: _range, start: _start, end: _end } = value === null ? { range: null, start: null, end: null } : value;

  const [{ range, start, end }, setState] = useState({
    range: _range,
    start: _start,
    end: _end
  });

  useEffect(() => {
    if (range && start && end) {
      onChange({ range, start, end });
    }

    if (!range && !start && !end) {
      onChange(null);
    }
  }, [range, start, end]);

  const handleChangeRange = (event, range) => {
    if (range === 'custom') {
      setState(prev => ({ ...prev, range }));
    } else {
      const { start, end } = getDateRange(range);

      setState({ range, start, end });
    }
  };

  const handleChangeStart = newValue => {
    const start = newValue.clone().startOf('day');

    setState(prev => {
      if (!prev.start || start.format('YYYY-MM-DD') !== prev.start.format('YYYY-MM-DD')) {
        return { range: 'custom', start, end: !prev.end || (prev.end && prev.end < start) ? start : prev.end };
      }

      return prev;
    });
  };

  const handleChangeEnd = newValue => {
    const end = newValue.clone().endOf('day');

    setState(prev => {
      if (!prev.end || end.format('YYYY-MM-DD') !== prev.end.format('YYYY-MM-DD')) {
        return { range: 'custom', end, start: !prev.start || (prev.start && prev.start > end) ? end : prev.start };
      }

      return prev;
    });
  };

  const handleClear = () => {
    setState({ range: null, start: null, end: null });
  };

  const handleClose = () => {
    onClose();
  };

  const handleButtonClick = range ? handleClear : handleClose;

  const Icon = range ? FormatClear : Clear;
  const iconProps = {
    fontSize: 'small'
  };

  const label = range ? 'Clear' : 'Close';

  return (
    <Box paddingX={1.5} paddingTop={2} paddingBottom={1.5} style={{ width: 300 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            fullWidth
            value={range === null ? '' : range}
            onChange={handleChangeRange}
            options={dateRangeOptions.map(option => option.value)}
            getOptionLabel={optionValue => dateRangeOptions.find(option => option.value === optionValue)?.label || ''}
            renderInput={params => (
              <TextField {...params} label="Date Range" placeholder={placeholder} variant="outlined" />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            fullWidth
            size="small"
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="From"
            name="start"
            format="YYYY-MM-DD"
            value={start}
            onChange={handleChangeStart}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            fullWidth
            size="small"
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="To"
            name="end"
            format="YYYY-MM-DD"
            value={end}
            onChange={handleChangeEnd}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Icon {...iconProps} />}
            onClick={handleButtonClick}
          >
            {label}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
