import React from 'react';
import { Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { MysteryShopperReportForm } from '../mystery-shopper-report-form';

export const ComplianceMysteryShopperReportEditPage = () => {
  return (
    <DashboardPageContainer title="Edit Mystery Shopper Report">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Mystery Shopper Reports',
                link: '/app/compliance-v2/mystery-shopper-reports'
              },
              {
                label: 'Edit'
              }
            ]}
            title={'Edit'}
            cancelButton={{ link: '/app/compliance-v2/mystery-shopper-reports' }}
          />
        </Grid>

        <Grid item xs={12}>
          <MysteryShopperReportForm />
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
