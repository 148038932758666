import React from 'react';
import { Divider, Popover } from '@material-ui/core';

import { NotificationsButton } from './notifications-button';
import { NotificationsActions } from './notifications-actions';
import { NotificationsPagination } from './notifications-pagination';
import { NotificationsList } from './notifications-list';
import { NotificationsContext, NotificationsContextProvider } from './notifications.context';

export const Notifications = ({ icon, title, list, onMarkAllAsRead }) => {
  return (
    <NotificationsContextProvider icon={icon} title={title} list={list} onMarkAllAsRead={onMarkAllAsRead}>
      <NotificationsContext.Consumer>
        {({ buttonRef, open, updateContext, list }) => (
          <>
            <NotificationsButton />

            {buttonRef && buttonRef.current && (
              <Popover
                anchorEl={buttonRef.current}
                open={open}
                onClose={() => updateContext({ open: false, page: 0 })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ style: { width: 320 } }}
              >
                <NotificationsPagination />

                <Divider />

                <NotificationsList />

                {list.length > 0 && <NotificationsActions />}
              </Popover>
            )}
          </>
        )}
      </NotificationsContext.Consumer>
    </NotificationsContextProvider>
  );
};
