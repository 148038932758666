import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useFetchFranchiseeLocations } from 'src/apis';
import { Loading } from 'src/components';

export const FranchiseeLocationSelector = ({
  value = '',
  onChange = () => {},
  label = 'Select Franchisee Location',
  error = ''
}) => {
  const { data: franchiseeLocations, loading_franchiseeLocations } = useFetchFranchiseeLocations();

  return (
    <Autocomplete
      options={franchiseeLocations.map(item => item.id)}
      getOptionLabel={option => {
        const { territoryName = '', name: franchiseeLocationName = '' } =
          franchiseeLocations.find(item => item.id === option) || {};

        return territoryName && franchiseeLocationName
          ? `${territoryName} - ${franchiseeLocationName}`
          : territoryName
          ? territoryName
          : franchiseeLocationName
          ? franchiseeLocationName
          : '';
      }}
      getOptionSelected={(option, value) => option === value}
      value={value}
      onChange={(_, value) => onChange(value)}
      renderInput={params => (
        <TextField {...params} variant="outlined" label={label} error={!!error} helperText={error} />
      )}
      loading={loading_franchiseeLocations}
      loadingText={<Loading text="Loading franchisee locations..." />}
    />
  );
};
