import React from 'react';
import { createContext, useContext, useRef, useState } from 'react';
import { Bell } from 'react-feather';

import { paginateRows } from 'src/utils/table';

const INITIAL_ROWS_PER_PAGE = 5;

export const NotificationsContext = createContext();

export const NotificationsContextProvider = ({
  children = null,
  icon = <Bell />,
  title = 'Notifications',
  list = [],
  onMarkAllAsRead = () => {}
}) => {
  const buttonRef = useRef(null);
  const [{ open, page, rowsPerPage }, setState] = useState({
    open: false,
    page: 0,
    rowsPerPage: INITIAL_ROWS_PER_PAGE
  });

  const updateContext = value => setState(prev => ({ ...prev, ...value }));

  const paginatedList = paginateRows(list, page, rowsPerPage);

  return (
    <NotificationsContext.Provider
      value={{ icon, title, list, paginatedList, open, buttonRef, page, rowsPerPage, updateContext, onMarkAllAsRead }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => {
  return useContext(NotificationsContext);
};
