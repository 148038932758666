import React from 'react';
import { Button, SvgIcon } from '@material-ui/core';
import { Download, Edit } from 'react-feather';
import { Document, PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';

import { useFetchOrders } from 'src/apis';
import { ActionsCell, EnhancedTable, LabelCell } from 'src/components';

export const FulfillmentsListTable = () => {
  const { data = [], isLoading: loading_orders } = useFetchOrders();

  const columns = [
    {
      id: 'dateCol',
      label: 'Date',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      }
    },
    {
      id: 'shipNextDayCol',
      label: 'Ship Next Day?'
    },
    {
      id: 'territoryCol',
      label: 'Territory'
    },
    {
      id: 'deliveryAddressCol',
      label: 'Delivery Address'
    },
    {
      id: 'statusCol',
      label: 'Status',
      render: ({ status = 'pending' }) => (
        <LabelCell
          color={
            status === 'fulfilled'
              ? 'success'
              : status === 'approved'
              ? 'primary'
              : status === 'revision'
              ? 'secondary'
              : 'warning'
          }
          label={
            status === 'fulfilled'
              ? 'Fulfilled'
              : status === 'approved'
              ? 'Approved'
              : status === 'revision'
              ? 'Revision'
              : 'Pending'
          }
        />
      )
    },
    {
      id: 'actionsCol',
      label: 'Actions',
      align: 'center',
      render: ({ actionsCol }) => <ActionsCell actions={actionsCol} />
    }
  ];

  const rows = data.map(item => {
    const {
      createdAt = {},
      overnightShipping = false,
      territory = {},
      deliveryLocation = {},
      status = 'pending'
    } = item;

    const timestamp = (createdAt?.seconds || 0) + (createdAt?.nanosecond || 0) / 1000;
    const dateCol = timestamp ? moment.unix(timestamp).format('DD MMM YYYY | hh:mm') : '-';

    const shipNextDayCol = overnightShipping ? 'Yes' : 'No';

    const territoryCol = territory?.name ? territory?.name : '';

    const deliveryAddressCol = deliveryLocation.name || '';

    const statusCol = status;

    const actionsCol = [
      {
        icon: <Edit />,
        onClick: event => {
          event.stopPropagation();
          console.log('click_order_table_row => ', item.id);
        }
      }
    ];

    const row = {
      ...item,
      dateCol,
      shipNextDayCol,
      territoryCol,
      deliveryAddressCol,
      statusCol,
      actionsCol
    };

    return row;
  });

  const tableActionsComponentProps = [
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            console.log('prnit rows = ', rows);
          }}
        >
          Print
        </Button>
      )
    },
    {
      render: ({ rows }) => (
        <PDFDownloadLink
          document={
            <Document>
              {/* <CoverPDF fulfillmentNotes={fulfillmentNotes} />

              {selectedInvoices.map(invoice => (
                <InvoicePDF key={invoice.id} invoice={invoice} />
              ))} */}
            </Document>
          }
          fileName="invoice"
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="outlined"
            color="secondary"
            startIcon={
              <SvgIcon fontSize="small">
                <Download />
              </SvgIcon>
            }
          >
            PDF
          </Button>
        </PDFDownloadLink>
      )
    },
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            console.log('fulfill rows = ', rows);
          }}
        >
          Fulfill
        </Button>
      )
    },
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            console.log('unfulfill rows = ', rows);
          }}
        >
          Unfulfill
        </Button>
      )
    }
  ];

  return (
    <EnhancedTable columns={columns} rows={rows} tableActionsComponentProps={tableActionsComponentProps} isCheckable />
  );
};
