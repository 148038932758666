import React, { useEffect } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';

import Logo from 'src/components/Logo';
import { useCurrentUser, useFirebaseContext } from 'src/contexts/firebase-context';
import { checkPermissions } from 'src/permissions/checkPermissions';

import { NavItem } from './NavItem';
import { useSections } from './useSections';

const useStyles = makeStyles({
  mobileDrawer: {
    width: 256
  },
  desktopDrawerWithBar: {
    width: 256,
    top: 128,
    height: 'calc(100% - 128px)'
  },
  desktopDrawerWithoutBar: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
});

function renderNavItems({ items, pathname, user, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth, user }), [])}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, user }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        user={user}
        permission={item.permission}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          user
        })}
      </NavItem>
    );
  } else {
    if (checkPermissions(user.permissions, item.permission)) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          user={user}
          permission={item.permission}
        />
      );
    }
  }
  return acc;
}

export const NavBar = ({ onMobileClose, openMobile }) => {
  const { isAnnouncementShowing } = useFirebaseContext();
  const user = useCurrentUser();
  const classes = useStyles(isAnnouncementShowing);
  const location = useLocation();
  const sections = useSections();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account/general" style={{ textDecoration: 'none' }}>
              <Avatar alt="User" className={classes.avatar} src={null}>
                <Typography color="textPrimary" style={{ fontSize: 21 }}>
                  {user?.firstName?.[0]} {user?.lastName?.[0]}
                </Typography>
              </Avatar>
            </RouterLink>
          </Box>

          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account/general" variant="h5" color="textPrimary" underline="none">
              {user.name}
            </Link>

            <Typography variant="body2" color="textSecondary">
              <Link component={RouterLink} to="/pricing">
                {user.location}
              </Link>
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {user.roleName}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box p={2}>
          {sections.map(section => {
            let count = 0;
            let authCount = 0;

            if (section.items) {
              // Don't need to worry about count because theyre just read titles
              section.items.forEach(item => {
                count = count + 1;
                if (item.items) {
                  item.items.forEach(item => {
                    count = count + 1;

                    if (checkPermissions(user.permissions, item.permission)) {
                      authCount = authCount + 1;
                    }
                  });
                }
                // Doesn't have items and its solo, we need to check for permissions
                else {
                  if (checkPermissions(user.permissions, item.permission)) {
                    authCount = authCount + 1;
                  }
                }
              });
            }

            if (authCount) {
              return (
                <List
                  key={section.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                    user: user
                  })}
                </List>
              );
            } else {
              return null;
            }
          })}
        </Box>

        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={
            isAnnouncementShowing ? { paper: classes.desktopDrawerWithBar } : { paper: classes.desktopDrawerWithoutBar }
          }
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};
