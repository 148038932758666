import React from 'react';
import { Card, Grid, Typography, Box, useTheme } from '@material-ui/core';

export const OverviewCard = ({
  overview = [
    {
      label: 'Location Type',
      value: ''
    },
    {
      label: 'Location',
      value: ''
    },
    {
      label: 'Score',
      value: ''
    },
    {
      label: 'Date Created',
      value: ''
    }
  ]
}) => {
  const theme = useTheme();

  return (
    <Card>
      <Grid container>
        {overview.map(({ label, value }, index) => (
          <Grid key={`overview-item-${index}`} item md={3} sm={6} xs={12}>
            <Box
              textAlign="center"
              height="100%"
              padding={3}
              borderRight={index < overview.length - 1 ? `1px solid ${theme.palette.divider}` : 'none'}
            >
              <Typography component="h2" gutterBottom variant="overline" color="textSecondary">
                {label}
              </Typography>

              <Typography variant="h3" color="textPrimary">
                {value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
