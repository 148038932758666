import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { useFetchTrainingVideoCategories } from 'src/apis';
import { Loading } from 'src/components';
import { useVideoForm } from './useVideoForm';

export const VideoForm = () => {
  const [{ formManager, submitButtonLabel, isInitializing, isSubmitting }] = useVideoForm();
  const { data: categories = [], isLoading: loading_categories } = useFetchTrainingVideoCategories();

  if (isInitializing || loading_categories) {
    return <Loading text="Processing initialization..." height={500} />;
  }

  return (
    <form onSubmit={formManager.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Video Details" />

            <Divider />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Title"
                    name="title"
                    onChange={formManager.handleChange('title')}
                    value={formManager.values.title}
                    error={Boolean(formManager.touched.title && formManager.errors.title)}
                    helperText={formManager.touched.title && formManager.errors.title}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Description"
                    name="description"
                    onChange={formManager.handleChange('description')}
                    value={formManager.values.description}
                    error={Boolean(formManager.touched.description && formManager.errors.description)}
                    helperText={formManager.touched.description && formManager.errors.description}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={categories.map(item => item.id)}
                    disableCloseOnSelect
                    getOptionLabel={option => categories.find(item => item.id === option)?.name || ''}
                    getOptionSelected={(option, value) => option === value}
                    value={formManager.values.categoryIds}
                    onChange={(_, value) => formManager.setFieldValue('categoryIds', value)}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {categories.find(item => item.id === option)?.name || ''}
                      </React.Fragment>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Video categories"
                        error={!!(formManager.touched.categoryIds && formManager.errors.categoryIds)}
                        helperText={formManager.touched.categoryIds && formManager.errors.categoryIds}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Vimeo Link"
                    name="url"
                    onChange={formManager.handleChange('url')}
                    value={formManager.values.url}
                    error={Boolean(formManager.touched.url && formManager.errors.url)}
                    helperText={
                      formManager.touched.url && formManager.errors.url
                        ? formManager.errors.url
                        : 'We are currently using Vimeo to upload our videos, please make sure to use links from Vimeo'
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
          >
            {submitButtonLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
