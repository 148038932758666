import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { useCurrentUser, useFirebaseContext } from 'src/contexts/firebase-context';
import { useUsersApi } from 'src/apis';
import { TIMEZONES_MAP } from 'src/constants';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/lib/firebase';

export const useGeneralSettingsFormManager = () => {
  const { id: uid, email, firstName, lastName, mobile, timezone = TIMEZONES_MAP.EST.zone, ...rest } = useCurrentUser();
  const { reinitializeIdToken } = useUsersApi();
  const updateUser = httpsCallable(functions, 'updateUser');
  const { enqueueSnackbar } = useSnackbar();

  const formManager = useFormik({
    enableReinitialize: true,

    initialValues: {
      email,
      firstName,
      lastName,
      mobile,
      timezone
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      firstName: Yup.string()
        .max(255)
        .required('First name is required'),
      lastName: Yup.string()
        .max(255)
        .required('Last name is required'),
      mobile: Yup.string(),
      timezone: Yup.string().required('Timezone is required'),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const { email: emailVal, firstName: firstNameVal, lastName: lastNameVal, mobile, timezone } = values;

        const email = emailVal.trim();
        const firstName = firstNameVal.trim();
        const lastName = lastNameVal.trim();

        const newUser = {
          uid,
          email,
          firstName,
          lastName,
          mobile,
          timezone,
          ...rest
        };

        await updateUser(newUser);

        await reinitializeIdToken();

        enqueueSnackbar('Account updated', {
          variant: 'success'
        });
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      } finally {
        setSubmitting(false);
      }
    }
  });

  return { formManager };
};
