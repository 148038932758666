import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TabFilter } from 'src/components';
import { usePageContext } from './context';

export const Tab = () => {
  const classes = useStyles();

  const { categories } = usePageContext();

  const tabs = categories.map(({ name }) => ({ value: name, label: name }));

  return <TabFilter value={false} tabs={tabs} classes={{ scrollButtons: classes.scrollButtons }} />;
};

const useStyles = makeStyles({
  scrollButtons: {
    '&.MuiTabScrollButton-root.Mui-disabled': {
      opacity: 0.2
    }
  }
});
