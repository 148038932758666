import React from 'react';
import { Badge, IconButton, SvgIcon, Tooltip } from '@material-ui/core';

import { useNotificationsContext } from './notifications.context';

export const NotificationsButton = () => {
  const { icon, title, list, buttonRef, updateContext } = useNotificationsContext();

  const onClick = () => {
    updateContext({ open: true, page: 0 });
  };

  return (
    <Tooltip title={title}>
      <IconButton color="inherit" ref={buttonRef} onClick={onClick}>
        <Badge variant="dot" color="secondary" invisible={list.length === 0}>
          <SvgIcon>{icon}</SvgIcon>
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
