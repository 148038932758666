export const getOverAllScore = (questions = []) => {
  let possibleScoreWeight = 0;
  let actualScoreWeight = 0;

  for (const question of questions) {
    const { weight, pass, isNotApplicable } = question;

    if (weight !== 'dnq' && !isNotApplicable) {
      if (pass) {
        actualScoreWeight += parseInt(weight, 10);
      }

      possibleScoreWeight += parseInt(weight, 10);
    }
  }

  return possibleScoreWeight ? (actualScoreWeight / possibleScoreWeight) * 100 : 0;
};

export const checkIsDnq = (questions = []) => {
  for (const question of questions) {
    const { weight, pass } = question;

    if (weight === 'dnq' && !pass) return true;
  }

  return false;
};
