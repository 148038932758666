import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ReactPlayer from 'react-player';
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Link,
  Typography,
  Tooltip,
  IconButton,
  SvgIcon,
  ClickAwayListener,
  CircularProgress,
  Modal
} from '@material-ui/core';
import { Edit, Trash2 } from 'react-feather';

import { useDeleteTrainingVideoMutation } from 'src/apis';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { FULFILLMENT, SUPER_ADMIN } from 'src/constants';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const VideoCard = ({ id, url, title, description, categories, activeCategory: _activeCategory = '' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { role: userRole } = useCurrentUser();
  const [deleteVideo, { isLoading: delete_video }] = useDeleteTrainingVideoMutation({ id });
  const videoRef = useRef();

  const handleModalOpen = () => {
    setIsModalOpen(true);
  }
  const handleModalClose = () => {
    setIsModalOpen(false);
  }

  let activeCategory = _activeCategory;

  if (_activeCategory === 'all') {
    activeCategory = categories.length > 0 ? categories[0].name : 'No Category';
  }

  const moreCategories = categories.filter(({ name }) => name !== activeCategory);

  return (
    <Card>
      <Box p={3}>
        <ReactPlayer
          ref={videoRef}
          light
          controls
          url={url}
          width="100%"
          height="200px"
          onClickPreview={(e) => {
            videoRef.current.showPreview();
            handleModalOpen();
          }}
        />

        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={modalStyle}>
            <ReactPlayer
              controls
              url={url}
              width="100%"
            />
          </Box>
        </Modal>

        <Box display="flex" alignItems="center" mt={2}>
          <Avatar src="/static/icon_lobster.png" alt="CML" />

          <Box ml={2}>
            <Typography variant="h5">{title}</Typography>

            <Typography variant="body2" color="textSecondary">
              by Cousins Maine Lobster
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box pb={2} px={3}>
        <Typography color="textSecondary" variant="body2">
          {description}
        </Typography>
      </Box>

      <Divider />

      <Box p={2} display="flex" alignItems="center">
        <Grid alignItems="center" container justify="space-between" spacing={3}>
          <Grid item>
            <Box display="flex" alignItems="center">
              {activeCategory && (
                <Typography variant="body2" color="textPrimary">
                  {activeCategory.toUpperCase()}
                </Typography>
              )}

              {moreCategories.length > 0 && (
                <ClickAwayListener onClickAway={() => {}}>
                  <Tooltip
                    interactive
                    arrow
                    title={
                      <Box minWidth={120}>
                        <Typography variant="subtitle2">More Categories: </Typography>

                        {moreCategories.map(({ name }, index) => (
                          <Box key={`category-${index}`} display="inline-flex" alignItems="flex-end">
                            <Link
                              component={RouterLink}
                              to={`?category=${name}`}
                              variant="caption"
                              style={{ color: 'yellow' }}
                            >
                              {name}
                            </Link>

                            {index < moreCategories.length && (
                              <Typography variant="caption" style={{ color: 'yellow', marginRight: 2 }}>
                                ,
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </Box>
                    }
                  >
                    <Box marginLeft={1} style={{ cursor: 'pointer' }}>
                      <Typography variant="subtitle2" color="secondary">
                        {`(+${moreCategories.length})`}
                      </Typography>
                    </Box>
                  </Tooltip>
                </ClickAwayListener>
              )}
            </Box>

            {categories.length > 0 && (
              <Typography variant="body2" color="textSecondary">
                {moreCategories.length > 0 ? 'Categories' : 'Category'}
              </Typography>
            )}
          </Grid>

          <Grid item>
            {(userRole === SUPER_ADMIN || userRole === FULFILLMENT) && (
              <Tooltip title="Edit">
                <IconButton component={RouterLink} to={`/app/training-v2/videos/${id}/edit`} color="secondary">
                  <SvgIcon fontSize="small">
                    <Edit />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}

            {(userRole === SUPER_ADMIN || userRole === FULFILLMENT) && (
              <Tooltip title="Delete">
                <IconButton onClick={() => deleteVideo({ id })} color="primary" disabled={delete_video}>
                  {delete_video ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <SvgIcon fontSize="small">
                      <Trash2 />
                    </SvgIcon>
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
