import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFirebaseContext } from 'src/contexts/firebase-context';

import { NavBar } from './NavBar';
import TopBar from './TopBar';
import { useStyles } from './useStyles';

export const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { isAnnouncementShowing } = useFirebaseContext();

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />

      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

      <div className={isAnnouncementShowing ? classes.wrapperWithBar : classes.wrapperWithoutBar}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};
