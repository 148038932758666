import React from 'react';
import { Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { VideoCategoryForm } from '../video-category-form';

export const VideoCategoryEditPage = () => {
  return (
    <DashboardPageContainer title="Edit Video Section" maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Training Videos',
                link: '/app/training-v2/videos'
              },
              {
                label: 'Training Video Categories',
                link: '/app/training-v2/video-categories'
              },
              {
                label: 'Edit Training Video Category'
              }
            ]}
            title={'Edit Training Video Category'}
            cancelButton={{ link: '/app/training-v2/video-categories' }}
          />
        </Grid>

        <Grid item xs={12}>
          <VideoCategoryForm />
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
