import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';

import { useComplianceReportFormContext } from './compliance-report-form.context';

export const FormActions = () => {
  const {
    id,
    isSubmitting,
    isDraft,
    submitButtonLabel,
    onSaveDraft,
    onPublishDraft,
    loading_createDraft,
    loading_publishDraft,
    isOrganizing,
    updateIsOrganizing,
    autosaveReport,
  } = useComplianceReportFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item style={isOrganizing ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
        <Button
          color="secondary"
          variant="contained"
          onClick={autosaveReport}
          endIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
          disabled={isSubmitting}
        >
          {submitButtonLabel}
        </Button>
      </Grid>

      {isDraft && (
        <Grid item style={isOrganizing ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
          <Button
            onClick={onPublishDraft}
            color="secondary"
            variant="contained"
            endIcon={loading_publishDraft && <CircularProgress size={16} color="inherit" />}
            disabled={loading_publishDraft}
          >
            Publish
          </Button>
        </Grid>
      )}

      {!id && (
        <Grid item style={isOrganizing ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
          <Button
            onClick={autosaveReport}
            variant="contained"
            endIcon={loading_createDraft && <CircularProgress size={16} color="inherit" />}
            disabled={loading_createDraft}
          >
            Save Draft
          </Button>
        </Grid>
      )}

      {isOrganizing && (
        <Grid item style={{ marginLeft: 'auto' }}>
          <Button variant="contained" color="secondary" onClick={() => updateIsOrganizing(false)}>
            Close Configuring
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
