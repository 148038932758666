import {
  complianceReportQuestionsApi,
  complianceReportsApi,
  complianceReportSectionsApi,
  mysteryShopperReportsApi,
  deliveryLocationsApi,
  franchiseeLocationsApi,
  itemsApi,
  llcsApi,
  orderDraftsApi,
  ordersApi,
  teamContactsApi,
  territoriesApi,
  trainingVideoCategoriesApi,
  trainingVideosApi,
  usersApi
} from 'src/apis';

export const appMiddleware = [
  complianceReportQuestionsApi.middleware,
  complianceReportsApi.middleware,
  complianceReportSectionsApi.middleware,
  mysteryShopperReportsApi.middleware,
  deliveryLocationsApi.middleware,
  franchiseeLocationsApi.middleware,
  itemsApi.middleware,
  llcsApi.middleware,
  orderDraftsApi.middleware,
  ordersApi.middleware,
  teamContactsApi.middleware,
  territoriesApi.middleware,
  trainingVideoCategoriesApi.middleware,
  trainingVideosApi.middleware,
  usersApi.middleware
];
