import {
  baseApi,
  bulkDeleteDocs,
  bulkUpdateDocs,
  createDoc,
  deleteDoc,
  getCollection,
  getDoc,
  updateDoc
} from '../base';

const collectionName = 'compliance_report_questions';

export const complianceReportQuestionsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchComplianceReportQuestions: builder.query({
      queryFn: async ({ where } = {}) => {
        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['compliance-report-questions']
    }),

    fetchComplianceReportQuestion: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createComplianceReportQuestion: builder.mutation({
      queryFn: async ({ type, sectionId, weight, question }) => {
        const { data: questions = [] } = await getCollection(collectionName);

        let maxOrder = Math.max(...questions.map(({ order = 0 }) => order));

        if (maxOrder < 0) {
          maxOrder = 0;
        }

        maxOrder += 1;

        return await createDoc(collectionName, { type, sectionId, weight, question, order: maxOrder });
      },
      invalidatesTags: ['compliance-report-questions']
    }),

    updateComplianceReportQuestion: builder.mutation({
      queryFn: async ({ id = '', type = '', sectionId = '', weight = 1, question = '', order = 1 }) => {
        return await updateDoc(collectionName, { id, type, sectionId, weight, question, order });
      },
      invalidatesTags: ['compliance-report-questions']
    }),

    bulkUpdateComplianceReportQuestions: builder.mutation({
      queryFn: async ({ questions: _questions }) => {
        const questions = _questions.map(
          ({ id = '', type = '', sectionId = '', weight = 1, question = '', order = 1 }) => ({
            id,
            type,
            sectionId,
            weight,
            question,
            order
          })
        );

        return await bulkUpdateDocs(collectionName, questions);
      },
      invalidatesTags: ['compliance-report-questions']
    }),

    deleteComplianceReportQuestion: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['compliance-report-questions']
    }),

    bulkDeleteComplianceReportQuestions: builder.mutation({
      queryFn: async ({ ids }) => {
        return await bulkDeleteDocs(collectionName, { ids });
      },
      invalidatesTags: ['compliance-report-questions']
    })
  })
});

export const {
  useFetchComplianceReportQuestionsQuery,
  useFetchComplianceReportQuestionQuery,
  useCreateComplianceReportQuestionMutation,
  useUpdateComplianceReportQuestionMutation,
  useBulkUpdateComplianceReportQuestionsMutation,
  useDeleteComplianceReportQuestionMutation,
  useBulkDeleteComplianceReportQuestionsMutation
} = complianceReportQuestionsApi;
