import React from 'react';
import { Page, View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer';

Font.registerHyphenationCallback(word => {
  // Return entire word as unique part, prevents hyphenation
  return [word];
});

const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    borderWidth: 1,
    borderColor: '#eeeeee',
    borderStyle: 'solid',
    borderRadius: 2,
    flexDirection: 'row'
  },
  overviewSection: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 8,
    paddingRight: 8,
    textAlign: 'center',
    flexDirection: 'column',
    width: '25%'
  },
  main: {
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center'
  },
  brand: {
    height: 'auto',
    width: 100,
    marginBottom: 10
  },
  h1: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 12,
    marginBottom: 24
  },
  borderRight: {
    borderRightWidth: 1,
    borderColor: '#eeeeee',
    borderStyle: 'solid'
  },
  overviewTitle: {
    fontSize: 8,
    marginBottom: 5,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  overviewContent: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    color: '#263238',
    fontWeight: 800,
    marginTop: 4
  },
  noteTitleSection: {
    borderBottomWidth: 1,
    borderColor: '#eeeeee',
    borderStyle: 'solid',
    padding: 8
  },
  noteTitle: {
    fontSize: 9,
    fontWeight: 800,
    fontFamily: 'Helvetica-Bold',
    color: '#263238'
  },
  noteContentSection: {
    padding: 8
  },
  noteContent: {
    fontSize: 8,
    color: '#263238'
  },
  tableRow: {
    borderTopWidth: 1,
    borderColor: '#eeeeee',
    borderStyle: 'solid',
    flexDirection: 'row',
    width: '100%'
  },
  tableHeadCell: {
    fontSize: 9,
    fontWeight: 1200,
    fontFamily: 'Helvetica-Bold',
    color: '#263238'
  },
  tableCell: {
    fontSize: 8,
    fontWeight: 400,
    color: '#263238'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#263238',
  },
});

export const ComplianceReportPdf = ({
  overview = [
    {
      label: 'Location Type',
      value: ''
    },
    {
      label: 'Location',
      value: ''
    },
    {
      label: 'Score',
      value: ''
    },
    {
      label: 'Date Created',
      value: ''
    }
  ],
  highlightNotes = '',
  lowlightNotes = '',
  questionGroups,
  pageNumber,
  isSample
}) => {
  return (
    <Page wrap={true} size="A4" style={styles.page} pageNumber={pageNumber}>
      <View style={styles.main}>
        <Image style={styles.brand} src="/static/logostackedlarge.png" />
        <Text style={styles.h1}>{isSample ? 'Sample ' : ''}Compliance Report</Text>
      </View>
      <View style={styles.section}>
        {overview.map(({ label, value }, index) => (
          <View
            key={`overview-${index}`}
            style={{ ...styles.overviewSection, ...(index < overview.length - 1 ? styles.borderRight : {}) }}
          >
            <Text style={styles.overviewTitle}>{label}</Text>
            <Text style={styles.overviewContent}>{value}</Text>
          </View>
        ))}
      </View>

      <View style={{ flexDirection: 'column', marginTop: 20 }}>
        <View style={{ width: '100%' }}>
          <View style={{ ...styles.section, flexDirection: 'column' }}>
            <View style={styles.noteTitleSection}>
              <Text style={styles.noteTitle}>Positives & Highlights</Text>
            </View>

            <View style={styles.noteContentSection}>
              <Text style={styles.noteContent}>{highlightNotes || 'No notes added'}</Text>
            </View>
          </View>
        </View>

        <View style={{ width: '100%', paddingTop: 12 }}>
          <View style={{ ...styles.section, flexDirection: 'column' }}>
            <View style={styles.noteTitleSection}>
              <Text style={styles.noteTitle}>Opportunities for Improvement</Text>
            </View>

            <View style={styles.noteContentSection}>
              <Text style={styles.noteContent}>{lowlightNotes || 'No notes added'}</Text>
            </View>
          </View>
        </View>
      </View>

      {Object.keys(questionGroups).map((sectionId, section_index) => {
        const questions = questionGroups[sectionId] || [];

        return (
          <View key={`section-${section_index}`} style={{ ...styles.section, marginTop: 20, flexDirection: 'column' }}>
            <View style={styles.tableRow}>
              <View style={{ width: '35%', padding: 8 }}>
                <Text style={styles.tableHeadCell}>{questions[0].sectionName || ''}</Text>
              </View>

              <View style={{ width: '15%', padding: 8 }}>
                <Text style={styles.tableHeadCell}>Weight</Text>
              </View>

              <View style={{ width: '15%', padding: 8 }}>
                <Text style={styles.tableHeadCell}>Grade</Text>
              </View>

              {/* <View style={{ width: '30%', padding: 8 }}>
                <Text style={styles.tableHeadCell}>Images</Text>
              </View> */}

              <View style={{ width: '35%', padding: 8 }}>
                <Text style={styles.tableHeadCell}>Comment</Text>
              </View>
            </View>

            {questions.map(({ question = '', weight = '', pass = false, isNotApplicable = false, images, comment = '' }, question_index) => {
              const formattedWeight = isNotApplicable ? 'N/A' : weight.toLowerCase() === 'dnq' ? weight.toUpperCase() : weight;
              const failedTextStyle = {color: pass === false ? 'red' : null};
              return (
                <View
                  key={`section-${section_index}-question-${question_index}`}
                  style={
                    question_index < questions.length - 1 ? styles.tableRow : styles.tableRow
                  }
                  wrap={false}
                >
                  <View style={{ width: '35%', padding: 8 }}>
                    <Text style={[styles.tableCell, failedTextStyle]}>{question}</Text>
                  </View>

                  <View style={{ width: '15%', padding: 8 }}>
                    <Text style={styles.tableCell}>{formattedWeight}</Text>
                  </View>

                  <View style={{ width: '15%', padding: 8}}>
                    <Text style={[styles.tableCell, failedTextStyle]}>{(isNotApplicable || pass === null) ? 'N/A' : pass ? 'Pass' : 'Fail'}</Text>
                  </View>

                  {/* <View style={{ width: '30%', padding: 8 }}>
                    {images ? <Image src={images.url} style={{ width: '100px', height: '100px' }} /> : null}
                  </View> */}

                  <View style={{ width: '35%', padding: 8 }}>
                    <Text style={{ ...styles.tableCell }}>{comment}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        );
      })}
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  );
};
