import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Paper } from '@material-ui/core';

import { useFetchTrainingVideoCategories, useFetchTrainingVideos } from 'src/apis';
import { Empty, Loading } from 'src/components';
import { paginateRows } from 'src/components/enhanced-table/utils';
import { SUPER_ADMIN } from 'src/constants';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { useQueryValues } from 'src/hooks/route.hook';

export const PageContext = createContext();

export const PageContextProvider = ({ children }) => {
  const history = useHistory();
  const { data: categories = [], isLoading: loading_categories } = useFetchTrainingVideoCategories();
  const { category: currentTab } = useQueryValues(['category']);
  const { data: videos = [], isLoading: loading_videos } = useFetchTrainingVideos({ category: currentTab });
  const [{ page, rowsPerPage }, setPagination] = useState({ page: 0, rowsPerPage: 25 });
  const { role: userRole } = useCurrentUser();

  const tabs = categories.map(({ name }) => name);

  const isValidTab = tabs.includes(currentTab);

  useEffect(() => {
    if (!isValidTab && tabs.length > 0) {
      history.push(`?category=${encodeURIComponent(tabs[0])}`);
    }
  }, [isValidTab, tabs]);

  if (tabs.length === 0) {
    return (
      <Box width="100%" padding={1.5}>
        <Paper>
          {loading_categories ? (
            <Loading text="Loading video categories" height={300} />
          ) : (
            <Empty
              description="There is no video category."
              buttonLabel="Create a category"
              onClick={() => history.push('/app/training-v2/video-categories/new')}
            />
          )}
        </Paper>
      </Box>
    );
  }

  const paginatedVideos = paginateRows({ rows: videos, page, rowsPerPage });

  const handleChangePage = (event, page) => {
    setPagination(prev => ({ ...prev, page }));
  };

  const handleChangeRowsPerPage = event => {
    setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  return (
    <PageContext.Provider
      value={{
        tabs,
        currentTab,
        videos,
        loading_videos,
        paginatedVideos,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  return useContext(PageContext);
};
