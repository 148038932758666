import { filterRows, paginateRows, selectRow } from 'src/utils/table';

import { useFetchTerritories } from '../territories';
import { useFetchOrderDraftQuery, useFetchOrderDraftsQuery } from './order-drafts.api';
import { useFetchDeliveryLocations } from '../delivery-locations';
import { useFetchLlcs } from '../llcs';
import { useFetchUsers } from '../users';
import { useFetchItems } from '../items';
import { getOrderDetails } from '../orders';

export const useFetchOrderDrafts = (props = { where: null }) => {
  const { where } = props;

  const { data: orders = [], isLoading: loading_orders } = useFetchOrderDraftsQuery({ where });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: deliveryLocations, isLoading: loading_deliveryLocations } = useFetchDeliveryLocations();
  const { data: llcs, isLoading: loading_llcs } = useFetchLlcs();
  const { data: users, isLoading: loading_users } = useFetchUsers();
  const { data: orderItems, isLoading: loading_orderItems } = useFetchItems();

  const data = orders
    .map(order => getOrderDetails({ order, territories, deliveryLocations, llcs, users, orderItems, isDraft: true }))
    .filter(order => !!order);

  return {
    data,
    isLoading:
      loading_orders ||
      loading_territories ||
      loading_deliveryLocations ||
      loading_llcs ||
      loading_users ||
      loading_orderItems
  };
};

export const useFetchOrderDraftsTableRows = ({ id, query, filters, page, limit, where }) => {
  const { data, isLoading } = useFetchOrderDrafts({ where });

  const selected = selectRow(data, id);
  const filtered = filterRows(selected, query, filters);
  const paginated = paginateRows(filtered, page, limit);

  return { data: { selected, filtered, paginated, all: data }, isLoading };
};

export const useFetchOrderDraft = ({ id }) => {
  const { data: order = {}, isLoading: loading_order } = useFetchOrderDraftQuery({ id });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: deliveryLocations, isLoading: loading_deliveryLocations } = useFetchDeliveryLocations();
  const { data: llcs, isLoading: loading_llcs } = useFetchLlcs();
  const { data: users, isLoading: loading_users } = useFetchUsers();
  const { data: orderItems, isLoading: loading_orderItems } = useFetchItems();

  const data = getOrderDetails({ order, territories, deliveryLocations, llcs, users, orderItems, isDraft: true });

  return {
    data,
    isLoading:
      loading_order ||
      loading_territories ||
      loading_deliveryLocations ||
      loading_llcs ||
      loading_users ||
      loading_orderItems
  };
};
