import React from 'react';
import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';

import { usePageContext } from './context';

export const OrganizationNote = () => {
  const { isOrganizing, setIsOrganizing } = usePageContext();

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Items Organization" />

        <Divider />

        <CardContent>
          <Grid container spacing={3} justify="space-between">
            {!isOrganizing && (
              <Grid item>
                <Typography color="textSecondary">- You can change tab orders.</Typography>
                <Typography color="textSecondary">- The changes will be affected to videos page.</Typography>
                <Typography color="textSecondary">- Click Edit Organization button to start editing.</Typography>
              </Grid>
            )}

            {isOrganizing && (
              <Grid item>
                <Typography color="textSecondary">- Drag a table row and drop to change the tab order.</Typography>
                <Typography color="textSecondary">- Click Close Configuring button to stop editing.</Typography>
              </Grid>
            )}

            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => setIsOrganizing(!isOrganizing)}>
                {isOrganizing ? 'Close Configuring' : 'Edit Organization'}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
