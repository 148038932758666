import React from 'react';
import { Box, IconButton, SvgIcon } from '@material-ui/core';

export const ActionsCell = ({ actions = [] }) => {
  return (
    <Box>
      {actions.map(({ icon, onClick }, index) => (
        <IconButton
          key={`table-row-action-${index}`}
          color="secondary"
          onClick={onClick}
          style={{
            marginRight: 4,
            width: 36,
            height: 36
          }}
        >
          <SvgIcon
            style={{
              fontSize: 18
            }}
          >
            {icon}
          </SvgIcon>
        </IconButton>
      ))}
    </Box>
  );
};
