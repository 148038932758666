import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useQueryValues = params => {
  const query = useQuery();

  return params
    .map(key => [key, query.get(key)])

    .reduce((prev, curr) => (curr[1] ? { ...prev, [curr[0]]: curr[1] } : prev), {});
};

export const useParamValues = params => {
  const values = useParams();

  return params
    .map(key => [key, values[key]])
    .reduce((prev, curr) => (curr[1] ? { ...prev, [curr[0]]: curr[1] } : prev), {});
};
