import React from 'react';
import { useHistory } from 'react-router';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Edit } from 'react-feather';
import moment from 'moment';

import {
  useBulkDeleteComplianceReportsMutation,
  useFetchComplianceReports,
  useFetchFranchiseeLocations,
  useFetchTerritories
} from 'src/apis';
import { ActionsCell, EnhancedTable } from 'src/components';
import { usePageContext } from './context';
import { COMPLIANCE_REPORT_STATUS_MAP } from 'src/constants';

export const Table = () => {
  const history = useHistory();
  const {
    data: drafts = { all: [], truck: [], restaurant: [] },
    isLoading: loading_drafts
  } = useFetchComplianceReports({ isDraft: true, where: ['status', '==', COMPLIANCE_REPORT_STATUS_MAP.DRAFT] });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: franchiseeLocations = [], isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();
  const { currentTab } = usePageContext();
  const [bulkDeleteReports, { isLoading: loading_bulkDeleteReports }] = useBulkDeleteComplianceReportsMutation();

  const territoryOptions = territories.map(({ id: value, name: label }) => ({ value, label }));
  const franchiseeLocationOptions = franchiseeLocations.map(({ id: value, name: label }) => ({ value, label }));

  const columns = [
    {
      id: 'lastUpdatedCol',
      label: 'Last Updated',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      },
      sorter: (row1, row2) => {
        if (row2['updatedAtTimestamp'] < row1['updatedAtTimestamp']) {
          return -1;
        }

        if (row2['updatedAtTimestamp'] > row1['updatedAtTimestamp']) {
          return 1;
        }

        return 0;
      },
      filter: {
        type: 'date',
        func: value => row => {
          const { start, end } = value ? value : { start: null, end: null };

          if (!start || !end) return true;

          const columnValue = moment.unix(row.updatedAtTimestamp);

          return columnValue > start && columnValue < end;
        }
      }
    },
    {
      id: 'territoryCol',
      label: 'Territory',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: territoryOptions
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.territoryId);
        }
      }
    },
    {
      id: 'franchiseeLocationCol',
      label: 'Franchisee Location',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: franchiseeLocationOptions
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.franchiseeLocationId);
        }
      }
    },
    {
      id: 'typeCol',
      label: 'Type',
      filter:
        currentTab === 'all'
          ? {
              type: 'checkbox',
              componentProps: {
                options: [
                  {
                    value: 'truck',
                    label: 'Truck'
                  },
                  {
                    value: 'restaurant',
                    label: 'Restaurant'
                  }
                ]
              },
              func: value => row => {
                return value.length === 0 || value.includes(row.franchiseeLocationType);
              }
            }
          : false
    },
    {
      id: 'actionsCol',
      label: 'Actions',
      align: 'center',
      render: ({ actionsCol }) => <ActionsCell actions={actionsCol} />,
      sorter: false,
      filter: false
    }
  ];

  const rows = (currentTab ? drafts[currentTab] : []).map(item => {
    const {
      id: draftId = '',
      updatedAtTimestampString: lastUpdatedCol,
      territoryName: territoryCol,
      franchiseeLocationName: franchiseeLocationCol,
      franchiseeLocationTypeName: typeCol,
      franchiseeLocationId
    } = item;

    const actionsCol = [
      {
        icon: <Edit />,
        onClick: event => {
          event.stopPropagation();
          history.push(`/app/compliance-v2/drafts/${draftId}/edit/${franchiseeLocationId}`);
        }
      }
    ];

    const row = {
      ...item,
      lastUpdatedCol,
      territoryCol,
      franchiseeLocationCol,
      typeCol,
      actionsCol
    };

    return row;
  });

  const tableActionsComponentProps = [
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={loading_bulkDeleteReports ? <CircularProgress size={16} color="inherit" /> : <Delete />}
          disabled={loading_bulkDeleteReports}
          onClick={() => {
            const ids = rows.map(row => row.id);
            bulkDeleteReports({ ids, isDraft: true });
          }}
        >
          Delete
        </Button>
      )
    }
  ];

  return (
    <EnhancedTable
      title="Select rows to delete"
      columns={columns}
      rows={rows}
      tableActionsComponentProps={tableActionsComponentProps}
      order="desc"
      orderBy="lastUpdatedCol"
      loading={{
        isLoading: loading_drafts || loading_territories || loading_franchiseeLocations,
        text:
          currentTab === 'all'
            ? 'Loading report drafts...'
            : currentTab === 'truck'
            ? 'Loading truck report drafts...'
            : currentTab === 'restaurant'
            ? 'Loading restaurant report drafts...'
            : ''
      }}
      empty={{
        description:
          currentTab === 'all'
            ? 'No report draft yet.'
            : currentTab === 'truck'
            ? 'No truck report draft yet.'
            : currentTab === 'restaurant'
            ? 'No restaurant report draft yet.'
            : '',
        buttonLabel: 'Create new report',
        onClick: () => history.push(`/app/compliance-v2/reports/new/select-franchisee-location`)
      }}
      isCheckable
    />
  );
};
