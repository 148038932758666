import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Toolbar, Tooltip, IconButton, SvgIcon, Typography, fade, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { X as XIcon } from 'react-feather';
import { useFirebaseContext } from 'src/contexts/firebase-context';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles(theme => ({
  lightTheme: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    maxHeight: 30,
    borderRadius: '0, 0 , 4px, 4px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.background.default
    // backgroundColor: fade(theme.palette.background.default, 0.1)
    // backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  darkTheme: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    maxHeight: 30,
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: '0, 0 , 4px, 4px',
    // backgroundColor: theme.palette.info.main
    // backgroundColor: theme.palette.background.default
    backgroundColor: fade(theme.palette.info.main, 0.1)
    // backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  unicornTheme: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    maxHeight: 30,
    borderRadius: '0, 0 , 4px, 4px',
    backgroundColor: theme.palette.background.default
    // backgroundColor: fade(theme.palette.background.default, 0.1)
    // backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.info.main,
    color: '#FFF',
    height: 34,
    width: 34,
    borderRadius: '50%'
  },
  box: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const AnnouncementBar = ({ isAnnouncementShowing, dispatchToggleAnnouncementBar, userId, className }) => {
  const isMountedRef = useIsMountedRef();
  const classes = useStyles();
  const { announcement, markAnnouncementReadByUser } = useFirebaseContext();
  const { settings } = useSettings();
  // ONE_DARK
  // LIGHT
  // UNICORN

  let selectedThemeClass;
  if (settings.theme === 'LIGHT' && clsx(classes.lightTheme, className)) {
    selectedThemeClass = classes.lightTheme;
  }
  if (settings.theme === 'ONE_DARK' && clsx(classes.darkTheme, className)) {
    selectedThemeClass = classes.darkTheme;
  }
  if (settings.theme === 'UNICORN' && clsx(classes.unicornTheme, className)) {
    selectedThemeClass = classes.unicornTheme;
  }

  return announcement ? (
    <div style={{ display: isAnnouncementShowing ? 'block' : 'none' }}>
      <Toolbar className={clsx(selectedThemeClass, className)}>
        <Box className={classes.box}>
          <InfoIcon className={classes.avatar} />
          <Typography gutterBottom variant="h6" color="textPrimary">
            {announcement.message}
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                dispatchToggleAnnouncementBar(false);
                markAnnouncementReadByUser(userId, announcement.id);
              }}
            >
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </div>
  ) : null;
};

AnnouncementBar.propTypes = {
  className: PropTypes.string,
  notes: PropTypes.string
};

export default AnnouncementBar;
