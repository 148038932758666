import React from 'react';
import { Card, Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { Table } from './table';
import { Tab } from './tab';
import { PageContextProvider } from './context';

export const ComplianceReportDraftsPage = () => {
  return (
    <DashboardPageContainer title="Compliance Report Drafts" maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Compliance',
                link: '/app/compliance-v2/reports?status=passed'
              },
              {
                label: 'Drafts'
              }
            ]}
            title={'Draft Compliance Reports'}
          />
        </Grid>

        <PageContextProvider>
          <Grid item xs={12}>
            <Card>
              <Tab />

              <Table />
            </Card>
          </Grid>
        </PageContextProvider>
      </Grid>
    </DashboardPageContainer>
  );
};
