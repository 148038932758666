import { sortByOrder } from 'src/utils/sort';

const groupQuestions = ({ questions = [], property = 'sectionId' } = {}) => {
  if (!property) return [];

  if (questions.length === 0) return [];

  const groups = questions.reduce((prev = [], curr = {}) => {
    const { section, ...question } = curr;

    if (prev.length === 0) {
      return [{ section, questions: [question] }];
    }

    const { sectionId } = curr;

    const sectionIndex = prev.findIndex(({ section: { id = '' } = {} } = {}) => id === sectionId);

    if (sectionIndex < 0) {
      return [...prev, { section, questions: [question] }];
    }

    prev[sectionIndex].questions.push(question);

    return prev;
  }, []);

  return groups;
};

const sortSections = (a, b) => {
  const { section: section1, questions } = a;
  const { section: section2 } = b;

  const { type } = questions[0];

  const {
    orderInRestaurant: orderInRestaurant1 = 1,
    orderInTruck: orderInTruck1 = 1,
    updatedAtTimestamp: updatedAtTimestamp1
  } = section1;
  const {
    orderInRestaurant: orderInRestaurant2 = 1,
    orderInTruck: orderInTruck2 = 1,
    updatedAtTimestamp: updatedAtTimestamp2
  } = section2;

  const order1 = type === 'restaurant' ? orderInRestaurant1 : orderInTruck1;
  const order2 = type === 'restaurant' ? orderInRestaurant2 : orderInTruck2;

  return order1 !== order2 ? order1 - order2 : updatedAtTimestamp2 - updatedAtTimestamp1;
};

export const questionsToSections = ({ questions = [] } = {}) => {
  const sections = groupQuestions({ questions })
    .sort(sortSections)
    .map(({ section, questions }) => ({ section, questions: questions.sort(sortByOrder) }));

  return sections;
};

export const sectionsToQuestions = ({ sections = [] } = {}) => {
  return sections.reduce((prev = [], curr = {}) => {
    const { questions: _questions = [], section } = curr;

    const questions = _questions.map(
      ({
        id,
        images = null,
        pass,
        isNotApplicable,
        comment = '',
        question,
        weight,
        type,
        order,
        sectionId,
        sectionName,
        typeName
      }) => ({
        id,
        images,
        pass,
        isNotApplicable,
        comment,
        question,
        weight,
        type,
        order,
        sectionId,
        section,
        sectionName,
        typeName
      })
    );

    return [...prev, ...questions];
  }, []);
};
