import React from 'react';
import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography, useTheme } from '@material-ui/core';

import { useComplianceReportFormContext } from './compliance-report-form.context';

export const OrganizationNote = () => {
  const theme = useTheme();
  const { locationType, isOrganizing, updateIsOrganizing } = useComplianceReportFormContext();

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Report Organization" />

        <Divider />

        <CardContent>
          <Grid container spacing={3} justify="space-between">
            {!isOrganizing && (
              <Grid item>
                <Typography color="textSecondary">- You can change the organization of the report.</Typography>
                <Typography color="textSecondary">
                  - The changes will be affected to all{' '}
                  <span style={{ color: theme.palette.primary.main }}>{locationType}</span> type reports.
                </Typography>
                <Typography color="textSecondary">- Click Edit Organization button to start editing.</Typography>
              </Grid>
            )}

            {isOrganizing && (
              <Grid item>
                <Typography color="textSecondary">
                  - Drag a section header and drop to change the section order.
                </Typography>
                <Typography color="textSecondary">
                  - Drag a question and drop to change the question order in the section.
                </Typography>
                <Typography color="textSecondary">- Click Close Configuring button to stop editing.</Typography>
              </Grid>
            )}

            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => updateIsOrganizing(!isOrganizing)}>
                {isOrganizing ? 'Close Configuring' : 'Edit Organization'}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
