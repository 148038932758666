import React from 'react';
import { Card, Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { PageContextProvider } from './context';
import { Tab } from './tab';
import { Table } from './table';
import { OrganizationNote } from './organization-note';

export const VideoCategoriesPage = () => {
  return (
    <DashboardPageContainer title="Training Video categories" maxWidth={'lg'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Training Videos',
                link: '/app/training-v2/videos'
              },
              {
                label: 'Training Video Categories'
              }
            ]}
            title={'Training Video Categories'}
            createButton={{ label: 'Add New Video Category', link: '/app/training-v2/video-categories/new' }}
          />
        </Grid>

        <PageContextProvider>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <Tab />
                </Card>
              </Grid>

              <Grid item xs={12}>
                <OrganizationNote />
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <Table />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </PageContextProvider>
      </Grid>
    </DashboardPageContainer>
  );
};
