import { EMPLOYEE, FRANCHISEE_OWNER, FULFILLMENT, MANAGER, SUPER_ADMIN, TIMEZONES_MAP } from 'src/constants';
import { useCurrentUser } from 'src/contexts/firebase-context';
import {
  adminPermissions,
  franchiseePermissions,
  managerPermissions,
  employeePermissions,
  fulfillmentPermissions
} from 'src/permissions';
import { filterRows, paginateRows, selectRow } from 'src/utils/table';
import { useFetchFranchiseeLocations } from '../franchisee-locations';
import { useFetchTerritories } from '../territories';
import { useFetchUserQuery, useFetchUsersQuery } from './users.api';

export const useFetchUsers = (props = { role: 'all' }) => {
  const { role } = props;

  const { data, isLoading } = useFetchUsersQuery({ role });
  const { data: allTerritories, isLoading: loading_territories } = useFetchTerritories();
  const { data: franchiseeLocations, isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();
  const { role: userRole, franchiseeLocationId: userFranchiseeLocationId } = useCurrentUser();

  let users = data || [];

  users = users.map(user => {
    const {
      id = '',
      avatar = '',
      email = '',
      firstName: firstNameVal = '',
      lastName: lastNameVal = '',
      mobile = '',
      timezone = TIMEZONES_MAP.EST.zone,
      smsOptIn = true,
      tier = 'Premium',
      role = FULFILLMENT,
      permissions: _permissions,
      disabled = false,
      territoryIds = [],
      franchiseeLocationId = '',
      createdAt
    } = user;

    const firstName = firstNameVal.trim();
    const lastName = lastNameVal.trim();
    const name = `${firstName} ${lastName}`.trim() || 'No name';

    let roleName = '';
    let permissions = _permissions;

    if (!permissions) {
      switch (role) {
        case SUPER_ADMIN: {
          permissions = adminPermissions;
          roleName = 'Super Admin';
          break;
        }

        case FRANCHISEE_OWNER: {
          permissions = franchiseePermissions;
          roleName = 'Franchisee Owner';
          break;
        }

        case MANAGER: {
          permissions = managerPermissions;
          roleName = 'Manager';
          break;
        }

        case EMPLOYEE: {
          permissions = employeePermissions;
          roleName = 'Employee';
          break;
        }

        case FULFILLMENT: {
          permissions = fulfillmentPermissions;
          roleName = 'Fulfillment';
          break;
        }
      }
    }

    const territories = allTerritories.filter(item => territoryIds.includes(item.id));
    const franchiseeLocation = franchiseeLocations.find(item => franchiseeLocationId === item.id) || {};

    return {
      id,
      avatar,
      email,
      firstName,
      lastName,
      name,
      mobile,
      timezone,
      smsOptIn,
      tier,
      role,
      roleName,
      permissions,
      disabled,
      territoryIds,
      territories,
      franchiseeLocationId,
      franchiseeLocation,
      createdAt
    };
  });

  if (userRole === FRANCHISEE_OWNER) {
    users = users.filter(
      item =>
        item.role !== SUPER_ADMIN &&
        item.role !== FRANCHISEE_OWNER &&
        item.franchiseeLocationId === userFranchiseeLocationId
    );
  }

  return { data: users, isLoading: isLoading || loading_territories };
};

export const useFetchUsersTableRows = ({ id, query, filters, page, limit, role, territoryIds = [] }) => {
  const { data, isLoading } = useFetchUsers({ role });

  let filteredData = data;

  if (!!territoryIds?.length) {
    filteredData = data?.filter(user => user?.territoryIds?.some(utId => territoryIds.includes(utId)));
  }

  const selected = selectRow(filteredData, id);
  const filtered = filterRows(selected, query, filters);
  const paginated = paginateRows(filtered, page, limit);

  return { data: [selected, filtered, paginated, data], isLoading };
};

export const useFetchUser = ({ id }) => {
  const { data: user = {}, isLoading } = useFetchUserQuery({ id });
  const { data: allTerritories, isLoading: loading_territories } = useFetchTerritories();
  const { data: franchiseeLocations, isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();

  const {
    avatar = '',
    email = '',
    firstName: firstNameVal = '',
    lastName: lastNameVal = '',
    mobile = '',
    timezone = TIMEZONES_MAP.EST.zone,
    smsOptIn = true,
    tier = 'Premium',
    role = FULFILLMENT,
    permissions: _permissions,
    disabled = false,
    territoryIds = [],
    franchiseeLocationId = '',
    createdAt
  } = user;

  const firstName = firstNameVal.trim();
  const lastName = lastNameVal.trim();
  const name = `${firstName} ${lastName}`.trim() || 'No name';

  let roleName = '';
  let permissions = _permissions;

  if (!permissions) {
    switch (role) {
      case SUPER_ADMIN: {
        permissions = adminPermissions;
        roleName = 'Super Admin';
        break;
      }

      case FRANCHISEE_OWNER: {
        permissions = franchiseePermissions;
        roleName = 'Franchisee Owner';
        break;
      }

      case MANAGER: {
        permissions = managerPermissions;
        roleName = 'Manager';
        break;
      }

      case EMPLOYEE: {
        permissions = employeePermissions;
        roleName = 'Employee';
        break;
      }

      case FULFILLMENT: {
        permissions = fulfillmentPermissions;
        roleName = 'Fulfillment';
        break;
      }
    }
  }

  const territories = allTerritories.filter(item => territoryIds.includes(item.id));
  const franchiseeLocation = franchiseeLocations.find(item => franchiseeLocationId === item.id) || {};

  return {
    data: {
      id,
      avatar,
      email,
      firstName,
      lastName,
      name,
      mobile,
      timezone,
      smsOptIn,
      tier,
      role,
      roleName,
      permissions,
      disabled,
      territoryIds,
      territories,
      franchiseeLocationId,
      franchiseeLocation,
      createdAt
    },
    isLoading
  };
};
