import {
  // Dashboard
  DASHBOARD,
  VIEW_DASHBOARD,
  // Orders
  ORDERS,
  VIEW_ORDERS,
  CREATE_ORDERS,
  EDIT_ORDERS,
  DELETE_ORDERS,
  SET_ORDER_STATUS,
  // Draft Orders
  DRAFT_ORDERS,
  VIEW_DRAFT_ORDERS,
  // Item Sales Reports
  ITEM_SALES_REPORTS,
  VIEW_ITEM_SALES_REPORTS,
  // Inventory Reports
  INVENTORY_REPORTS,
  VIEW_INVENTORY_REPORTS,
  CREATE_INVENTORY_REPORTS,
  EDIT_INVENTORY_REPORTS,
  DELETE_INVENTORY_REPORTS,
  // Franchise Sales Reports
  FRANCHISE_SALES_REPORTS,
  VIEW_FRANCHISE_SALES_REPORTS,
  CREATE_FRANCHISE_SALES_REPORTS,
  EDIT_FRANCHISE_SALES_REPORTS,
  DELETE_FRANCHISE_SALES_REPORTS,
  // COGS Reports
  COGS_REPORTS,
  VIEW_COGS_REPORTS,
  // Fulfillments
  FULFILLMENTS,
  VIEW_FULFILLMENTS,
  SET_FULFILLMENT_STATUS,
  // Fulfillment Notes
  FULFILLMENT_NOTES,
  VIEW_FULFILLMENT_NOTES,
  EDIT_FULFILLMENT_NOTES,
  DELETE_FULFILLMENT_NOTES,
  // Approvals
  APPROVALS,
  VIEW_APPROVALS,
  CREATE_REQUESTS,
  // Compliance Reports
  COMPLIANCE_REPORTS,
  VIEW_COMPLIANCE_REPORTS,
  CREATE_COMPLIANCE_REPORTS,
  EDIT_COMPLIANCE_REPORTS,
  DELETE_COMPLIANCE_REPORTS,
  SET_COMPLIANCE_REPORT_DNQ_STATUS,
  // Draft Compliance Reports
  DRAFT_COMPLIANCE_REPORTS,
  VIEW_DRAFT_COMPLIANCE_REPORTS,
  // Compliance Questions
  COMPLIANCE_QUESTIONS,
  VIEW_COMPLIANCE_QUESTIONS,
  CREATE_COMPLIANCE_QUESTIONS,
  EDIT_COMPLIANCE_QUESTIONS,
  DELETE_COMPLIANCE_QUESTIONS,
  CREATE_COMPLIANCE_QUESTION_SECTIONS,
  // Mystery Shopper Reports
  MYSTERY_SHOPPER_REPORTS,
  VIEW_MYSTERY_SHOPPER_REPORTS,
  // Training Videos
  TRAINING_VIDEOS,
  VIEW_TRAINING_VIDEOS,
  CREATE_TRAINING_VIDEOS,
  DELETE_TRAINING_VIDEOS,
  EDIT_TRAINING_VIDEOS,
  CREATE_TRAINING_VIDEO_CATEGORIES,
  // Territories
  TERRITORIES,
  VIEW_TERRITORIES,
  CREATE_TERRITORIES,
  EDIT_TERRITORIES,
  DELETE_TERRITORIES,
  // Franchise Locations
  FRANCHISE_LOCATIONS,
  VIEW_FRANCHISE_LOCATIONS,
  CREATE_FRANCHISE_LOCATIONS,
  EDIT_FRANCHISE_LOCATIONS,
  DELETE_FRANCHISE_LOCATIONS,
  // Delivery Locations
  DELIVERY_LOCATIONS,
  VIEW_DELIVERY_LOCATIONS,
  CREATE_DELIVERY_LOCATIONS,
  EDIT_DELIVERY_LOCATIONS,
  DELETE_DELIVERY_LOCATIONS,
  // LLCs
  LLCS,
  VIEW_LLCS,
  CREATE_LLCS,
  EDIT_LLCS,
  DELETE_LLCS,
  // Items
  ITEMS,
  VIEW_ITEMS,
  CREATE_ITEMS,
  EDIT_ITEMS,
  DELETE_ITEMS,
  // Users
  USERS,
  VIEW_USERS,
  CREATE_USERS,
  EDIT_USERS,
  DELETE_USERS,
  EDIT_USER_PERMISSIONS,
  // Signature Generator
  SIGNATURE_GENERATOR,
  VIEW_SIGNATURE_GENERATOR,
  // Announcements
  ANNOUNCEMENTS,
  VIEW_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENTS,
  EDIT_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENTS
} from './constants';

export const managerPermissions = {
  // Dashboard
  [DASHBOARD]: {
    [VIEW_DASHBOARD]: true
  },
  // Orders
  [ORDERS]: {
    [VIEW_ORDERS]: true,
    [CREATE_ORDERS]: true,
    [DELETE_ORDERS]: true,
    [EDIT_ORDERS]: true,
    [SET_ORDER_STATUS]: false
  },

  // Draft Orders
  [DRAFT_ORDERS]: {
    [VIEW_DRAFT_ORDERS]: true
  },

  // Item Sales Reports
  [ITEM_SALES_REPORTS]: {
    [VIEW_ITEM_SALES_REPORTS]: true
  },

  // Iventory Reports
  [INVENTORY_REPORTS]: {
    [VIEW_INVENTORY_REPORTS]: true,
    [CREATE_INVENTORY_REPORTS]: true,
    [EDIT_INVENTORY_REPORTS]: true,
    [DELETE_INVENTORY_REPORTS]: true
  },

  // Franchise Sales Reports
  [FRANCHISE_SALES_REPORTS]: {
    [VIEW_FRANCHISE_SALES_REPORTS]: true,
    [CREATE_FRANCHISE_SALES_REPORTS]: true,
    [EDIT_FRANCHISE_SALES_REPORTS]: true,
    [DELETE_FRANCHISE_SALES_REPORTS]: true
  },

  // COGS
  [COGS_REPORTS]: {
    [VIEW_COGS_REPORTS]: false
  },

  // Fulfillment
  [FULFILLMENTS]: {
    [VIEW_FULFILLMENTS]: false,
    [SET_FULFILLMENT_STATUS]: false
  },

  // Fulfillment Notes
  [FULFILLMENT_NOTES]: {
    [VIEW_FULFILLMENT_NOTES]: false,
    [EDIT_FULFILLMENT_NOTES]: false,
    [DELETE_FULFILLMENT_NOTES]: false
  },

  // Approvals
  [APPROVALS]: {
    [VIEW_APPROVALS]: true,
    [CREATE_REQUESTS]: true
  },

  // Compliance Reports
  [COMPLIANCE_REPORTS]: {
    [VIEW_COMPLIANCE_REPORTS]: true,
    [CREATE_COMPLIANCE_REPORTS]: false,
    [EDIT_COMPLIANCE_REPORTS]: false,
    [DELETE_COMPLIANCE_REPORTS]: false,
    [SET_COMPLIANCE_REPORT_DNQ_STATUS]: false
  },

  // Draft Compliance Reports
  [DRAFT_COMPLIANCE_REPORTS]: {
    [VIEW_DRAFT_COMPLIANCE_REPORTS]: false
  },

  // Compliance Questions
  [COMPLIANCE_QUESTIONS]: {
    [VIEW_COMPLIANCE_QUESTIONS]: false,
    [CREATE_COMPLIANCE_QUESTIONS]: false,
    [CREATE_COMPLIANCE_QUESTION_SECTIONS]: false,
    [EDIT_COMPLIANCE_QUESTIONS]: false,
    [DELETE_COMPLIANCE_QUESTIONS]: false
  },

  // Mystery Shopper Report
  [MYSTERY_SHOPPER_REPORTS]: {
    [VIEW_MYSTERY_SHOPPER_REPORTS]: false
  },

  // Training Videos
  [TRAINING_VIDEOS]: {
    [VIEW_TRAINING_VIDEOS]: true,
    [CREATE_TRAINING_VIDEOS]: false,
    [CREATE_TRAINING_VIDEO_CATEGORIES]: false,
    [EDIT_TRAINING_VIDEOS]: false,
    [DELETE_TRAINING_VIDEOS]: false
  },

  // Territories
  [TERRITORIES]: {
    [VIEW_TERRITORIES]: true,
    [CREATE_TERRITORIES]: false,
    [EDIT_TERRITORIES]: false,
    [DELETE_TERRITORIES]: false
  },

  // Franchise Locations
  [FRANCHISE_LOCATIONS]: {
    [VIEW_FRANCHISE_LOCATIONS]: true,
    [CREATE_FRANCHISE_LOCATIONS]: false,
    [EDIT_FRANCHISE_LOCATIONS]: false,
    [DELETE_FRANCHISE_LOCATIONS]: false
  },

  // Delivery Locations
  [DELIVERY_LOCATIONS]: {
    [VIEW_DELIVERY_LOCATIONS]: true,
    [CREATE_DELIVERY_LOCATIONS]: false,
    [EDIT_DELIVERY_LOCATIONS]: false,
    [DELETE_DELIVERY_LOCATIONS]: false
  },

  // LLCs
  [LLCS]: {
    [VIEW_LLCS]: true,
    [CREATE_LLCS]: false,
    [EDIT_LLCS]: false,
    [DELETE_LLCS]: false
  },

  // Items
  [ITEMS]: {
    [VIEW_ITEMS]: true,
    [CREATE_ITEMS]: false,
    [DELETE_ITEMS]: false,
    [EDIT_ITEMS]: false
  },

  // Users
  [USERS]: {
    [VIEW_USERS]: true,
    [CREATE_USERS]: true,
    [DELETE_USERS]: false,
    [EDIT_USERS]: false,
    [EDIT_USER_PERMISSIONS]: false
  },

  // Signature Generator
  [SIGNATURE_GENERATOR]: {
    [VIEW_SIGNATURE_GENERATOR]: true
  },

  // Announcements
  [ANNOUNCEMENTS]: {
    [VIEW_ANNOUNCEMENTS]: false,
    [CREATE_ANNOUNCEMENTS]: false,
    [DELETE_ANNOUNCEMENTS]: false,
    [EDIT_ANNOUNCEMENTS]: false
  }
};

export default managerPermissions;
