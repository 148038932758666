import { filterRows, paginateRows, selectRow } from 'src/utils/table';
import { useFetchTerritories } from '../territories';
import { useFetchDeliveryLocationQuery, useFetchDeliveryLocationsQuery } from './delivery-locations.api';

export const useFetchDeliveryLocations = (props = { where: null }) => {
  const { where } = props;

  const { data, isLoading } = useFetchDeliveryLocationsQuery({ where });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();

  let deliveryLocations = data || [];

  deliveryLocations = deliveryLocations
    .map(deliveryLocation => {
      const {
        id = '',
        name = '',
        territoryId = '',
        address1 = '',
        address2 = '',
        city = '',
        state = '',
        zip = '',
        mobile = '',
        email = '',
        enabled = true
      } = deliveryLocation;

      const territory = territories.find(item => item.id === territoryId) || {};
      const { name: territoryName = '' } = territory;

      return {
        id,
        name,
        territoryId,
        territoryName,
        address1,
        address2,
        city,
        state,
        zip,
        mobile,
        email,
        enabled
      };
    })
    .filter(item => !!item.territoryName);

  deliveryLocations.sort((a, b) => {
    if (a.territoryName < b.territoryName) {
      return -1;
    }
    if (a.territoryName > b.territoryName) {
      return 1;
    }
    return 0;
  });

  return { data: deliveryLocations, isLoading: isLoading || loading_territories };
};

export const useFetchDeliveryLocationsTableRows = ({ id, query, filters, page, limit }) => {
  const { data, isLoading } = useFetchDeliveryLocations();

  const selected = selectRow(data, id);
  const filtered = filterRows(selected, query, filters);
  const paginated = paginateRows(filtered, page, limit);

  return { data: [selected, filtered, paginated, data], isLoading };
};

export const useFetchDeliveryLocation = ({ id }) => {
  const { data: deliveryLocation = {}, isLoading } = useFetchDeliveryLocationQuery({ id });

  const {
    name = '',
    territoryId = '',
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    zip = '',
    mobile = '',
    email = '',
    enabled = true
  } = deliveryLocation;

  return {
    data: {
      id,
      name,
      territoryId,
      address1,
      address2,
      city,
      state,
      zip,
      mobile,
      email,
      enabled
    },
    isLoading
  };
};
