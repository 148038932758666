import React from 'react';
import { useHistory } from 'react-router';
import { Box, Divider } from '@material-ui/core';

import { TabFilter } from 'src/components';
import { useFulfillmentsListPageContext } from './fulfillments-list-page.context';

export const FulfillmentsListTab = () => {
  const history = useHistory();
  const { currentTab } = useFulfillmentsListPageContext();

  const tabs = [
    {
      value: 'approved',
      label: 'Approved'
    },
    {
      value: 'fulfilled',
      label: 'Fulfilled'
    }
  ];

  return tabs.map(item => item.value).includes(currentTab) ? (
    <Box>
      <TabFilter
        value={currentTab}
        tabs={tabs}
        onChange={value => {
          history.push(`?status=${value}`);
        }}
      />

      <Divider />
    </Box>
  ) : null;
};
