import React from 'react';
import {
  Avatar,
  Link,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Typography
} from '@material-ui/core';
import { Image } from 'react-feather';

import Label from 'src/components/Label';

export const QuestionsGroupCard = ({ questions = [] }) => {
  const theme = useTheme();

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="40%">{questions[0].sectionName}</TableCell>
            <TableCell width="10%">Weight</TableCell>
            <TableCell width="10%">Grade</TableCell>
            <TableCell width="10%">Images</TableCell>
            <TableCell width="30%">Comment</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {questions.map(({ question = '', weight = '', pass = false, isNotApplicable = false, images, comment = '' }, index) => {
            return (
              <TableRow hover key={`question-row-${index}`} style={isNotApplicable ? { opacity: 0.6 } : {}}>
                <TableCell width="40%">{question}</TableCell>
                <TableCell width="10%">
                  {isNotApplicable ? (
                    <Label color="disabled">N/A</Label>
                  ) : (
                    <Label>{weight}</Label>
                  )}
                </TableCell>
                <TableCell width="10%">
                  {isNotApplicable || pass === null ? <Label color="disabled">N/A</Label> : pass ? <Label color="success">Pass</Label> : <Label color="error">Fail</Label>}
                </TableCell>
                <TableCell width="10%">
                  {images?.length ? images.map((image, index) =>
                    <Link href={image.url} target="_blank" className="link">
                      <Avatar src={image.url} variant="rounded" style={{ marginBottom: index === images?.length - 1 ? 0 : 10 }} />
                    </Link>
                  ) : (
                    <Image style={{ color: theme.palette.text.secondary }} />
                  )}
                </TableCell>
                <TableCell width="30%">
                  <Typography color="textSecondary" variant="body2" component="span" style={{ fontStyle: 'italic' }}>
                    {comment}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};
