import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core';

export const EnhancedTableCheckboxFilter = ({
  value: _value = [],
  onChange = () => {},
  onClose = () => {},
  options = []
}) => {
  const value = _value === null ? [] : _value;

  const onSelectAll = () => {
    const newValue = options.map(option => option.value);

    onChange(newValue);
  };

  const onUnselectAll = () => {
    onChange(null);
  };

  const onItem = ({ value: optionValue }) => {
    const checked = value.includes(optionValue);

    const newValue = checked ? value.filter(val => val !== optionValue) : [...value, optionValue];

    onChange(newValue);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="sticky"
        top={0}
        bgcolor="white"
        zIndex={10}
        padding={1.5}
      >
        <Button color="primary" size="small" onClick={onUnselectAll}>
          Reset
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          className="ml-2"
          onClick={onSelectAll}
          style={{
            marginLeft: 12
          }}
        >
          Select all
        </Button>
      </Box>

      <Divider />

      <Box paddingLeft={2.5} paddingRight={1.5} paddingY={1}>
        <FormControl component="fieldset">
          <FormGroup>
            {options.map(({ label, value: optionValue }, index) => (
              <FormControlLabel
                key={`filter-checkbox-${index}`}
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={value.includes(optionValue)}
                    onChange={() => {
                      onItem({ value: optionValue });
                    }}
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary" style={{ marginLeft: 4 }}>
                    {label}
                  </Typography>
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    </>
  );
};
