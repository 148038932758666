import React from 'react';
import { Table, TableContainer } from '@material-ui/core';

import { Empty } from '../Empty';
import { EnhancedTableHead } from './enhanced-table-head';
import { EnhancedTablePagination } from './enhanced-table-pagination';
import { EnhancedTableBody } from './enhanced-table-body';
import { EnhancedTableContextProvider } from './enhanced-table.context';
import { EnhancedTableActions } from './enhanced-table-actions';
import { EnhancedTableToolbar } from './enhanced-table-toolbar';
import { Loading } from '../Loading';

export const EnhancedTable = ({
  title = '',
  columns = [],
  rows = [],
  tableActionsComponent = EnhancedTableActions,
  tableActionsComponentProps = [],
  loading: { isLoading = false, text = 'Loading...' } = {},
  empty = {},
  isPaginated = true,
  isDraggable = false,
  handleDragEnd = () => {},
  isCheckable = false,
  order = 'asc',
  orderBy = null
}) => {
  if (isLoading) {
    return <Loading text={text} height={300} />;
  }

  if (rows.length === 0) {
    return <Empty {...empty} />;
  }

  return (
    <EnhancedTableContextProvider
      title={title}
      columns={columns}
      rows={rows}
      tableActionsComponent={tableActionsComponent}
      tableActionsComponentProps={tableActionsComponentProps}
      isPaginated={isPaginated}
      isDraggable={isDraggable}
      handleDragEnd={handleDragEnd}
      isCheckable={isCheckable}
      order={order}
      orderBy={orderBy}
    >
      <EnhancedTableToolbar />

      <TableContainer>
        <Table sx={{ minWidth: 750 }}>
          <EnhancedTableHead />
          <EnhancedTableBody />
        </Table>
      </TableContainer>

      {isPaginated && <EnhancedTablePagination />}
    </EnhancedTableContextProvider>
  );
};
