import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PlusCircle } from 'react-feather';
import { Button, Grid, SvgIcon } from '@material-ui/core';

import { HeaderBreadcrumbs } from 'src/components';

export const HeaderWithButtons = ({
  breadcrumbs = [],
  title = '',
  customButton1 = { label: 'Action', link: '' },
  createButton = { label: 'Create', link: '' },
  cancelButton = { label: 'Cancel', link: '' }
}) => {
  const { label: customButton1Label = 'Action', link: customButton1Link = '', ...restCustomButton1 } = customButton1;
  const { label: createButtonLabel = 'Create', link: createButtonLink = '', ...restCreateButton } = createButton;
  const { label: cancelButtonLabel = 'Cancel', link: cancelButtonLink = '', ...restCancelButton } = cancelButton;

  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item>
        <HeaderBreadcrumbs breadcrumbs={breadcrumbs} title={title} />
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          {customButton1Link && (
            <Grid item>
              <Button
                component={RouterLink}
                to={customButton1Link}
                color="secondary"
                variant="contained"
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusCircle />
                  </SvgIcon>
                }
                {...restCustomButton1}
              >
                {customButton1Label}
              </Button>
            </Grid>
          )}

          {createButtonLink && (
            <Grid item>
              <Button
                component={RouterLink}
                to={createButtonLink}
                color="secondary"
                variant="contained"
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusCircle />
                  </SvgIcon>
                }
                {...restCreateButton}
              >
                {createButtonLabel}
              </Button>
            </Grid>
          )}

          {cancelButtonLink && (
            <Grid item>
              <Button component={RouterLink} to={cancelButtonLink} {...restCancelButton}>
                {cancelButtonLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
