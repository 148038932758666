import React from 'react';
import { useHistory } from 'react-router';
import { Edit, ArrowRight } from 'react-feather';
import moment from 'moment';

import {
  useBulkDeleteMysteryShopperReportsMutation,
  useFetchFranchiseeLocations,
  useFetchMysteryShopperReports,
  useFetchTerritories
} from 'src/apis';
import { ActionsCell, EnhancedTable } from 'src/components';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

export const Table = () => {
  const history = useHistory();
  const { data = [], isLoading: loading_mysteryShopperReports } = useFetchMysteryShopperReports();
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: franchiseeLocations = [], isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();
  const [
    bulkDeleteMysteryShopperReports,
    { isLoading: loading_bulkDeleteMysteryShopperReports }
  ] = useBulkDeleteMysteryShopperReportsMutation();

  const territoryOptions = territories.map(({ id: value, name: label }) => ({ value, label }));
  const franchiseeLocationOptions = franchiseeLocations.map(({ id: value, name: label }) => ({ value, label }));

  const columns = [
    {
      id: 'dateCol',
      label: 'Created At',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      },
      sorter: (row1, row2) => {
        if (row2['dateTimestamp'] < row1['dateTimestamp']) {
          return -1;
        }

        if (row2['dateTimestamp'] > row1['dateTimestamp']) {
          return 1;
        }

        return 0;
      },
      filter: {
        type: 'date',
        func: value => row => {
          const { start, end } = value ? value : { start: null, end: null };

          if (!start || !end) return true;

          const columnValue = moment.unix(row.dateTimestamp);

          return columnValue > start && columnValue < end;
        }
      }
    },
    {
      id: 'territoryCol',
      label: 'Territory',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: territoryOptions
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.territoryId);
        }
      }
    },
    {
      id: 'franchiseeLocationCol',
      label: 'Franchisee Location',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: franchiseeLocationOptions
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.franchiseeLocationId);
        }
      }
    },
    {
      id: 'actionsCol',
      label: 'Actions',
      align: 'center',
      render: ({ actionsCol }) => <ActionsCell actions={actionsCol} />,
      sorter: false,
      filter: false
    }
  ];

  const rows = data.map(item => {
    const {
      id: mysteryShopperReportId,
      dateTimestampString: dateCol,
      territoryName: territoryCol,
      franchiseeLocationName: franchiseeLocationCol
    } = item;

    const actionsCol = [
      {
        icon: <Edit />,
        onClick: event => {
          event.stopPropagation();
          history.push(`/app/compliance-v2/mystery-shopper-reports/${mysteryShopperReportId}/edit`);
        }
      },
      {
        icon: <ArrowRight />,
        onClick: event => {
          event.stopPropagation();
          history.push(`/app/compliance-v2/mystery-shopper-reports/${mysteryShopperReportId}/view`);
        }
      }
    ];

    const row = {
      ...item,
      dateCol,
      territoryCol,
      franchiseeLocationCol,
      actionsCol
    };

    return row;
  });

  const tableActionsComponentProps = [
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={
            loading_bulkDeleteMysteryShopperReports ? <CircularProgress size={16} color="inherit" /> : <Delete />
          }
          disabled={loading_bulkDeleteMysteryShopperReports}
          onClick={() => {
            const ids = rows.map(row => row.id);

            bulkDeleteMysteryShopperReports({ ids });
          }}
        >
          Delete
        </Button>
      )
    }
  ];

  return (
    <EnhancedTable
      columns={columns}
      rows={rows}
      tableActionsComponentProps={tableActionsComponentProps}
      loading={{
        isLoading: loading_mysteryShopperReports || loading_territories || loading_franchiseeLocations,
        text: 'Loading mystery shopper reports...'
      }}
      empty={{
        description: 'No mystery shopper reports yet.',
        buttonLabel: 'Create new report',
        onClick: () => history.push(`/app/compliance-v2/mystery-shopper-reports/new`)
      }}
      isCheckable
    />
  );
};
