import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu as MenuIcon } from 'react-feather';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon } from '@material-ui/core';

import { FRANCHISEE_OWNER, SUPER_ADMIN, FULFILLMENT, MANAGER, EMPLOYEE, THEMES } from 'src/constants';
import { useCurrentUser, useFirebaseContext } from 'src/contexts/firebase-context';
import { checkPermissions } from 'src/permissions/checkPermissions';
import { VIEW_ORDERS, VIEW_FULFILLMENTS } from 'src/permissions/constants';
import Account from './Account';
import AnnouncementBar from './AnnouncementBar';
import AnnouncementsNotifications from './AnnouncementsNotifications';
import Settings from './Settings';
import ManagerAndEmployeeNotifications from './NotificationsByRole/ManagerAndEmployeeNotifications';
import FulfillmentTeamNotifications from './NotificationsByRole/FulfillmentTeamNotification';
import { ComplianceReportsNotifications } from './compliance-reports-notifications';
import { OrdersNotifications } from './orders-notifications';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { dispatchToggleAnnouncementBar, isAnnouncementShowing } = useFirebaseContext();
  const { role: userRole } = useCurrentUser();
  const user = useCurrentUser();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>

        <Hidden mdDown>
          <RouterLink to="/">
            <img style={{ width: 200 }} src={'/static/logolinearupdated.png'} alt="Cousins Maine Lobster Logo" />
          </RouterLink>
        </Hidden>

        <Box ml={2} flexGrow={1} />

        <AnnouncementsNotifications />

        {(userRole === SUPER_ADMIN || userRole === FULFILLMENT || userRole === FRANCHISEE_OWNER) && <OrdersNotifications />}

        {(userRole === SUPER_ADMIN || userRole === FULFILLMENT || userRole === FRANCHISEE_OWNER) && <ComplianceReportsNotifications />}

        {userRole === FULFILLMENT && checkPermissions(user.permissions, VIEW_FULFILLMENTS) && (
          <FulfillmentTeamNotifications />
        )}

        {(userRole === MANAGER || userRole === EMPLOYEE) && checkPermissions(user.permissions, VIEW_ORDERS) && (
          <ManagerAndEmployeeNotifications />
        )}

        <Settings />

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>

      <AnnouncementBar
        isAnnouncementShowing={isAnnouncementShowing}
        dispatchToggleAnnouncementBar={dispatchToggleAnnouncementBar}
        userId={user.id}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
