import { baseApi, bulkDeleteDocs, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';

const collectionName = 'training_videos';

export const trainingVideosApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchTrainingVideos: builder.query({
      queryFn: async ({ where } = {}) => {
        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['training-videos']
    }),

    fetchTrainingVideo: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createTrainingVideo: builder.mutation({
      queryFn: async ({ title, description, categoryIds, url }) => {
        return await createDoc(collectionName, { title, description, categoryIds, url });
      },
      invalidatesTags: ['training-videos']
    }),

    updateTrainingVideo: builder.mutation({
      queryFn: async ({ id, title, description, categoryIds, url }) => {
        return await updateDoc(collectionName, { id, title, description, categoryIds, url });
      },
      invalidatesTags: ['training-videos']
    }),

    deleteTrainingVideo: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['training-videos']
    }),

    bulkDeleteTrainingVideos: builder.mutation({
      queryFn: async ({ ids }) => {
        return await bulkDeleteDocs(collectionName, { ids });
      },
      invalidatesTags: ['training-videos']
    })
  })
});

export const {
  useFetchTrainingVideosQuery,
  useFetchTrainingVideoQuery,
  useCreateTrainingVideoMutation,
  useUpdateTrainingVideoMutation,
  useDeleteTrainingVideoMutation,
  useBulkDeleteTrainingVideosMutation
} = trainingVideosApi;
