import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { DashboardLayout } from 'src/layouts';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import {
  AccountPage,
  ComplianceReportsSelectFranchiseeLocationPage,
  ComplianceReportCreatePage,
  FulfillmentsListPage,
  ComplianceReportsPage,
  ComplianceReportViewPage,
  ComplianceReportEditPage,
  ComplianceReportDraftsPage,
  ComplianceReportDraftEditPage,
  ComplianceReportQuestionCreatePage,
  ComplianceReportQuestionsPage,
  ComplianceReportQuestionEditPage,
  ComplianceReportQuestionSectionCreatePage,
  ComplianceReportQuestionSectionEditPage,
  ComplianceReportQuestionSectionsPage,
  ComplianceMysteryShopperReportsPage,
  ComplianceMysteryShopperReportCreatePage,
  ComplianceMysteryShopperReportViewPage,
  ComplianceMysteryShopperReportEditPage,
  VideosPage,
  VideoCreatePage,
  VideoEditPage,
  VideoCategoriesPage,
  VideoCategoryCreatePage,
  VideoCategoryEditPage
} from 'src/pages';

export const routes = [
  /**
   * Unauthenticated routes
   */
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },

  /**
   * Authenticated routes
   */
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: lazy(() => import('src/custom_views/main_dashboard'))
      },
      // Account
      {
        exact: true,
        path: '/app/account/:settings',
        component: AccountPage
      },
      // Orders
      {
        exact: true,
        path: '/app/orders',
        component: lazy(() => import('src/custom_views/orders/ListView'))
      },
      {
        exact: true,
        path: '/app/new-order',
        component: lazy(() => import('src/custom_views/orders/CreateView'))
      },
      {
        exact: true,
        path: '/app/orders/:id',
        component: lazy(() => import('src/custom_views/orders/DetailView'))
      },
      {
        exact: true,
        path: '/app/orders/:id/edit',
        component: lazy(() => import('src/custom_views/orders/EditView'))
      },
      // Draft orders
      {
        exact: true,
        path: '/app/draft-orders',
        component: lazy(() => import('src/custom_views/orders/drafts/ListView'))
      },
      {
        exact: true,
        path: '/app/draft-orders/:id/edit',
        component: lazy(() => import('src/custom_views/orders/drafts/EditView'))
      },
      // Fulfillment
      {
        exact: true,
        path: '/app/fulfillments',
        component: FulfillmentsListPage
      },
      {
        exact: true,
        path: '/app/fulfillments/:id',
        component: lazy(() => import('src/pages/fulfillments/fulfillments-details/fulfillments-details-page'))
      },
      // Reports
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      // Inventory
      {
        exact: true,
        path: '/app/reports/inventory-list',
        component: lazy(() => import('src/custom_views/reports/inventory/ListView'))
      },
      {
        exact: true,
        path: '/app/reports/new-opening-inventory',
        component: lazy(() => import('src/custom_views/reports/inventory/CreateOpeningView'))
      },
      {
        exact: true,
        path: '/app/reports/new-closing-inventory/:id',
        component: lazy(() => import('src/custom_views/reports/inventory/CreateClosingView'))
      },
      {
        exact: true,
        path: '/app/reports/inventory/:id',
        component: lazy(() => import('src/custom_views/reports/inventory/DifferenceView'))
      },
      {
        exact: true,
        path: '/app/reports/inventory/:id/opening',
        component: lazy(() => import('src/custom_views/reports/inventory/OpeningReport'))
      },
      {
        exact: true,
        path: '/app/reports/inventory/:id/closing',
        component: lazy(() => import('src/custom_views/reports/inventory/ClosingReport'))
      },
      // Sales
      {
        exact: true,
        path: '/app/reports/item-sales',
        component: lazy(() => import('src/custom_views/reports/item-sales'))
      },
      {
        exact: true,
        path: '/app/reports/franchise-sales',
        component: lazy(() => import('src/custom_views/reports/franchise_sales/SalesReports'))
      },
      // Cogs
      {
        exact: true,
        path: '/app/reports/cogs',
        component: lazy(() => import('src/custom_views/reports/cogs'))
      },
      {
        exact: true,
        path: '/app/reports/cogs/profit-loss',
        component: lazy(() => import('src/custom_views/reports/cogs/ItemSalesReport'))
      },
      // Approvals
      {
        exact: true,
        path: '/app/approvals',
        component: lazy(() => import('src/custom_views/approvals/ListView'))
      },
      {
        exact: true,
        path: '/app/start-approval-request',
        component: lazy(() => import('src/custom_views/approvals/StartRequestView'))
      },
      {
        exact: true,
        path: '/app/approvals/:id',
        component: lazy(() => import('src/custom_views/approvals/DetailView'))
      },
      {
        exact: true,
        path: '/app/revise-approval-request/:formId/:id',
        component: lazy(() => import('src/custom_views/approvals/ReviseContainer'))
      },
      {
        exact: true,
        path: '/app/resubmit-approval-request/:formId/:id',
        component: lazy(() => import('src/custom_views/approvals/ResubmitContainer'))
      },
      {
        exact: true,
        path: '/app/create-approval-request/:formId',
        component: lazy(() => import('src/custom_views/approvals/FormContainer'))
      },
      // Reports
      {
        exact: true,
        path: '/app/compliance-v2/reports',
        component: ComplianceReportsPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/reports/new/select-franchisee-location',
        component: ComplianceReportsSelectFranchiseeLocationPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/reports/new/:locationType/:franchiseeLocationId',
        component: ComplianceReportCreatePage
      },
      {
        exact: true,
        path: '/app/compliance-v2/reports/:id/view/:franchiseeLocationId',
        component: ComplianceReportViewPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/reports/:id/edit/:franchiseeLocationId',
        component: ComplianceReportEditPage
      },
      // Draft reports
      {
        exact: true,
        path: '/app/compliance-v2/drafts',
        component: ComplianceReportDraftsPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/drafts/:id/edit/:franchiseeLocationId',
        component: ComplianceReportDraftEditPage
      },
      // Compliance report questions
      {
        exact: true,
        path: '/app/compliance-v2/questions',
        component: ComplianceReportQuestionsPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/questions/new',
        component: ComplianceReportQuestionCreatePage
      },
      {
        exact: true,
        path: '/app/compliance-v2/questions/:id/edit',
        component: ComplianceReportQuestionEditPage
      },
      // Compliance report question sections
      {
        exact: true,
        path: '/app/compliance-v2/question-sections',
        component: ComplianceReportQuestionSectionsPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/question-sections/new',
        component: ComplianceReportQuestionSectionCreatePage
      },
      {
        exact: true,
        path: '/app/compliance-v2/question-sections/:id/edit',
        component: ComplianceReportQuestionSectionEditPage
      },
      // Mystery shopper reports
      {
        exact: true,
        path: '/app/compliance-v2/mystery-shopper-reports',
        component: ComplianceMysteryShopperReportsPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/mystery-shopper-reports/new',
        component: ComplianceMysteryShopperReportCreatePage
      },
      {
        exact: true,
        path: '/app/compliance-v2/mystery-shopper-reports/:id/view',
        component: ComplianceMysteryShopperReportViewPage
      },
      {
        exact: true,
        path: '/app/compliance-v2/mystery-shopper-reports/:id/edit',
        component: ComplianceMysteryShopperReportEditPage
      },
      // Training
      {
        exact: true,
        path: '/app/training/videos',
        component: lazy(() => import('src/custom_views/training/videos/BrowseView'))
      },
      {
        exact: true,
        path: '/app/training/videos/:id',
        component: lazy(() => import('src/custom_views/training/videos/VideoView'))
      },
      {
        exact: true,
        path: '/app/training/videos/:id/edit',
        component: lazy(() => import('src/custom_views/training/videos/EditView'))
      },
      {
        exact: true,
        path: '/app/new-training-video',
        component: lazy(() => import('src/custom_views/training/videos/CreateView'))
      },
      {
        exact: true,
        path: '/app/new-training-video-category',
        component: lazy(() => import('src/custom_views/training/videos/CreateCategoryView'))
      },
      {
        exact: true,
        path: '/app/training-v2/videos',
        component: VideosPage
      },
      {
        exact: true,
        path: '/app/training-v2/videos/new',
        component: VideoCreatePage
      },
      {
        exact: true,
        path: '/app/training-v2/videos/:id/edit',
        component: VideoEditPage
      },
      {
        exact: true,
        path: '/app/training-v2/video-categories',
        component: VideoCategoriesPage
      },
      {
        exact: true,
        path: '/app/training-v2/video-categories/new',
        component: VideoCategoryCreatePage
      },
      {
        exact: true,
        path: '/app/training-v2/video-categories/:id/edit',
        component: VideoCategoryEditPage
      },
      // Users
      {
        exact: true,
        path: '/app/tools/users',
        component: lazy(() => import('src/custom_views/tools/users/ListView'))
      },
      {
        exact: true,
        path: '/app/tools/new-user',
        component: lazy(() => import('src/custom_views/tools/users/CreateView'))
      },
      {
        exact: true,
        path: '/app/tools/users/:id',
        component: lazy(() => import('src/custom_views/tools/users/DetailView'))
      },
      {
        exact: true,
        path: '/app/tools/users/:id/permissions',
        component: lazy(() => import('src/custom_views/tools/users/PermissionsView'))
      },
      {
        exact: true,
        path: '/app/tools/users/:id/edit',
        component: lazy(() => import('src/custom_views/tools/users/EditView'))
      },
      // Announcements
      {
        exact: true,
        path: '/app/tools/announcements',
        component: lazy(() => import('src/custom_views/tools/announcements/ListView'))
      },
      {
        exact: true,
        path: '/app/tools/new-announcement',
        component: lazy(() => import('src/custom_views/tools/announcements/CreateView'))
      },
      {
        exact: true,
        path: '/app/tools/announcements/:id/edit',
        component: lazy(() => import('src/custom_views/tools/announcements/EditView'))
      },
      // Signature generator
      {
        exact: true,
        path: '/app/tools/signature-generator',
        component: lazy(() => import('src/custom_views/tools/signature_generator'))
      },
      // Items
      {
        exact: true,
        path: '/app/tools/items',
        component: lazy(() => import('src/custom_views/tools/items/ListView'))
      },
      {
        exact: true,
        path: '/app/tools/items/:id',
        component: lazy(() => import('src/custom_views/tools/items/DetailView'))
      },
      {
        exact: true,
        path: '/app/tools/items/:id/edit',
        component: lazy(() => import('src/custom_views/tools/items/EditView'))
      },
      {
        exact: true,
        path: '/app/tools/new-item',
        component: lazy(() => import('src/custom_views/tools/items/CreateView'))
      },
      // Territories
      {
        exact: true,
        path: '/app/tools/territories',
        component: lazy(() => import('src/custom_views/tools/territories/ListView'))
      },
      {
        exact: true,
        path: '/app/tools/territories/:id',
        component: lazy(() => import('src/custom_views/tools/territories/DetailView'))
      },
      {
        exact: true,
        path: '/app/tools/territories/:id/edit',
        component: lazy(() => import('src/custom_views/tools/territories/EditView'))
      },
      {
        exact: true,
        path: '/app/tools/new-territory',
        component: lazy(() => import('src/custom_views/tools/territories/CreateView'))
      },
      // Delivery locations
      {
        exact: true,
        path: '/app/tools/delivery-locations',
        component: lazy(() => import('src/custom_views/tools/delivery_locations/ListView'))
      },
      {
        exact: true,
        path: '/app/tools/delivery-locations/:id',
        component: lazy(() => import('src/custom_views/tools/delivery_locations/DetailView'))
      },
      {
        exact: true,
        path: '/app/tools/delivery-locations/:id/edit',
        component: lazy(() => import('src/custom_views/tools/delivery_locations/EditView'))
      },
      {
        exact: true,
        path: '/app/tools/new-delivery-location',
        component: lazy(() => import('src/custom_views/tools/delivery_locations/CreateView'))
      },
      // LLCs
      {
        exact: true,
        path: '/app/tools/llcs',
        component: lazy(() => import('src/custom_views/tools/llcs/ListView'))
      },
      {
        exact: true,
        path: '/app/tools/llcs/:id',
        component: lazy(() => import('src/custom_views/tools/llcs/DetailView'))
      },
      {
        exact: true,
        path: '/app/tools/llcs/:id/edit',
        component: lazy(() => import('src/custom_views/tools/llcs/EditView'))
      },
      {
        exact: true,
        path: '/app/tools/new-llc',
        component: lazy(() => import('src/custom_views/tools/llcs/CreateView'))
      },
      // Franchisee locations
      {
        exact: true,
        path: '/app/tools/franchise-locations',
        component: lazy(() => import('src/custom_views/tools/franchise_locations/ListView'))
      },
      {
        exact: true,
        path: '/app/tools/franchise-locations/:id',
        component: lazy(() => import('src/custom_views/tools/franchise_locations/DetailView'))
      },
      {
        exact: true,
        path: '/app/tools/franchise-locations/:id/edit',
        component: lazy(() => import('src/custom_views/tools/franchise_locations/EditView'))
      },
      {
        exact: true,
        path: '/app/tools/new-franchise-location',
        component: lazy(() => import('src/custom_views/tools/franchise_locations/CreateView'))
      },
      // Fulfillment notes
      {
        exact: true,
        path: '/app/fulfillment-notes/new-note',
        component: lazy(() => import('src/custom_views/fulfillment/fulfillment_notes/CreateView'))
      },
      {
        exact: true,
        path: '/app/fulfillment-notes',
        component: lazy(() => import('src/custom_views/fulfillment/fulfillment_notes/ListView'))
      },
      {
        exact: true,
        path: '/app/fulfillment-notes/:id/create-copy',
        component: lazy(() => import('src/custom_views/fulfillment/fulfillment_notes/CreateCopyView'))
      },
      {
        exact: true,
        path: '/app/fulfillment-notes/:id/edit',
        component: lazy(() => import('src/custom_views/fulfillment/fulfillment_notes/EditView'))
      },
      // Redirect
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  /**
   * Documents
   */
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  /**
   * Redirects
   */
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView'))
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];
