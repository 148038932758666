import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

/**
 * From slices
 */
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';

/**
 * From apis
 */
import {
  complianceReportQuestionsApi,
  complianceReportsApi,
  complianceReportSectionsApi,
  mysteryShopperReportsApi,
  deliveryLocationsApi,
  franchiseeLocationsApi,
  itemsApi,
  llcsApi,
  orderDraftsApi,
  ordersApi,
  teamContactsApi,
  territoriesApi,
  trainingVideoCategoriesApi,
  trainingVideosApi,
  usersApi
} from 'src/apis';

const rootReducer = combineReducers({
  form: formReducer,

  /**
   * From slices
   */
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,

  /**
   * From apis
   */
  [complianceReportQuestionsApi.reducerPath]: complianceReportQuestionsApi.reducer,
  [complianceReportsApi.reducerPath]: complianceReportsApi.reducer,
  [complianceReportSectionsApi.reducerPath]: complianceReportSectionsApi.reducer,
  [mysteryShopperReportsApi.reducerPath]: mysteryShopperReportsApi.reducer,
  [deliveryLocationsApi.reducerPath]: deliveryLocationsApi.reducer,
  [franchiseeLocationsApi.reducerPath]: franchiseeLocationsApi.reducer,
  [itemsApi.reducerPath]: itemsApi.reducer,
  [llcsApi.reducerPath]: llcsApi.reducer,
  [orderDraftsApi.reducerPath]: orderDraftsApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [teamContactsApi.reducerPath]: teamContactsApi.reducer,
  [territoriesApi.reducerPath]: territoriesApi.reducer,
  [trainingVideoCategoriesApi.reducerPath]: trainingVideoCategoriesApi.reducer,
  [trainingVideosApi.reducerPath]: trainingVideosApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer
});

export default rootReducer;
