import React, { useEffect } from 'react';

import { renderRoutes } from './renderRoutes';
import { routes } from './routes.config';
import { useLocation } from 'react-router-dom';
import { useFirebaseContext } from 'src/contexts/firebase-context';
import { APP_VERSION, APP_VERSION_CHECK_REFRESH_COUNT_LS_KEY, APP_VERSION_CHECK_REFRESH_MAX_COUNT } from 'src/constants';
import * as SemVer from 'semver';
import { useSnackbar } from 'notistack';

export const RootNavigator = () => {
  const { getAppConfig } = useFirebaseContext();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  console.log('APP_VERSION', APP_VERSION);

  const getConfig = async () => {
    const appConfig = await getAppConfig();
    const isLessThanCurrentVersion = SemVer.lt(APP_VERSION, appConfig?.appVersion);

    if (isLessThanCurrentVersion) {
      const refreshCount = Number(localStorage.getItem(APP_VERSION_CHECK_REFRESH_COUNT_LS_KEY)) || 0;

      if (refreshCount >= APP_VERSION_CHECK_REFRESH_MAX_COUNT) {
        enqueueSnackbar('The app version is outdated, please hard refresh the page using cmd+shift+r (ctrl+shift+r on windows)', {
          variant: 'error',
          persist: true
        });
      } else {
        const newRefreshCount = refreshCount + 1;

        localStorage.setItem(APP_VERSION_CHECK_REFRESH_COUNT_LS_KEY, newRefreshCount)
        enqueueSnackbar('The app version is outdated, reloading the app to get the latest version', {
          variant: 'error'
        });

        // wait for 1.5 sec to show toast before reloading
        await new Promise(resolve => setTimeout(resolve, 1500));
        window.location.reload();
      }
    } else {
      localStorage.removeItem(APP_VERSION_CHECK_REFRESH_COUNT_LS_KEY)
    }
  }

  useEffect(() => {
    getConfig();
  }, [location])

  return <>{renderRoutes(routes)}</>;
};
