import React from 'react';

import { useAccountPageContext } from './account-page.context';
import { GeneralSettings } from './general-settings';
import { NotificationsSettings } from './notifications-settings';
import { PasswordSettings } from './password-settings';

export const AccountTabContent = () => {
  const { currentTab } = useAccountPageContext();

  if (currentTab === 'general') {
    return <GeneralSettings />;
  }

  if (currentTab === 'password') {
    return <PasswordSettings />;
  }

  if (currentTab === 'notifications') {
    return <NotificationsSettings />;
  }

  return null;
};
