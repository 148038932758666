import React from 'react';
import { TablePagination } from '@material-ui/core';

import { useEnhancedTableContext } from './enhanced-table.context';

export const EnhancedTablePagination = () => {
  const { rows, page, rowsPerPage, updateTableStatus } = useEnhancedTableContext();

  const handleChangePage = (event, page) => {
    updateTableStatus({ page });
  };

  const handleChangeRowsPerPage = event => {
    updateTableStatus({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  return (
    <TablePagination
      rowsPerPageOptions={[25, 50, 75]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};
