import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useQueryValues } from 'src/hooks/route.hook';

export const FulfillmentsListPageContext = createContext();

export const FulfillmentsListPageContextProvider = ({ children }) => {
  const history = useHistory();
  const { status: currentTab } = useQueryValues(['status']);

  const isValidTab = ['approved', 'fulfilled'].includes(currentTab);

  useEffect(() => {
    if (!isValidTab) {
      history.push('?status=approved');
    }
  }, [isValidTab]);

  return (
    <FulfillmentsListPageContext.Provider
      value={{
        currentTab
      }}
    >
      {children}
    </FulfillmentsListPageContext.Provider>
  );
};

export const useFulfillmentsListPageContext = () => {
  return useContext(FulfillmentsListPageContext);
};
