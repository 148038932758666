import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  BarChart2 as BarChart2Icon,
  BarChart as BarChartIcon,
  Bell as BellIcon,
  Edit as EditIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Clipboard as ClipboardIcon,
  Tool as ToolIcon,
  Send as SendIcon,
  Layers as LayersIcon,
  Youtube as YoutubeIcon,
  Truck as TruckIcon,
  Briefcase as BriefcaseIcon,
  ShoppingBag as ShoppingBagIcon,
  Map as MapIcon,
  MapPin as MapPinIcon,
  Archive as ArchiveIcon,
  Edit3 as Edit3Icon,
  Package as PackageIcon,
  HelpCircle as HelpCircleIcon,
  CheckSquare as CheckSquareIcon,
  List as ListIcon
} from 'react-feather';
// Importing all constants for permission checking, going to remove NavItem componenet based on if the user has this specific permission
import {
  // Dashboard
  VIEW_DASHBOARD,
  // Orders
  VIEW_ORDERS,
  CREATE_ORDERS,
  // Draft Orders
  VIEW_DRAFT_ORDERS,
  // Item Sales Reports
  VIEW_ITEM_SALES_REPORTS,
  // InventoryReports
  VIEW_INVENTORY_REPORTS,
  // Approvals
  VIEW_APPROVALS,
  CREATE_REQUESTS,
  // Compliance Reports
  VIEW_COMPLIANCE_REPORTS,
  VIEW_DRAFT_COMPLIANCE_REPORTS,
  // Compliance Questions
  VIEW_COMPLIANCE_QUESTIONS,
  // Mystery Shopper Reports
  VIEW_MYSTERY_SHOPPER_REPORTS,
  // Training Videos
  VIEW_TRAINING_VIDEOS,
  CREATE_TRAINING_VIDEOS,
  // Territories
  VIEW_TERRITORIES,
  // Franchise Locations
  VIEW_FRANCHISE_LOCATIONS,
  // Delivery Locations
  VIEW_DELIVERY_LOCATIONS,
  // LLCs
  VIEW_LLCS,
  // Items
  VIEW_ITEMS,
  // Users
  VIEW_USERS,
  // Signature Generator
  VIEW_SIGNATURE_GENERATOR,
  // Announcements
  CREATE_ANNOUNCEMENTS,
  VIEW_ANNOUNCEMENTS
} from 'src/permissions/constants';

export const adminSections = [
  {
    subheader: '',
    items: [{ title: 'Dashboard', icon: PieChartIcon, href: '/app', permission: VIEW_DASHBOARD }]
  },
  {
    subheader: 'Orders & Reports',
    items: [
      {
        title: 'Orders',
        icon: ReceiptIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Your Orders',
            icon: PieChartIcon,
            href: '/app/orders',
            permission: VIEW_ORDERS
          },
          {
            title: 'New Order',
            icon: Edit3Icon,
            href: '/app/new-order',
            permission: CREATE_ORDERS
          },
          {
            title: 'Drafts',
            icon: ArchiveIcon,
            href: '/app/draft-orders',
            permission: VIEW_DRAFT_ORDERS
          }
        ]
      },
      {
        title: 'Reports',
        icon: BarChartIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Item Sales Reports',
            icon: BarChart2Icon,
            href: '/app/reports/item-sales',
            permission: VIEW_ITEM_SALES_REPORTS
          }
        ]
      }
    ]
  },
  {
    subheader: 'Compliance',
    items: [
      {
        title: 'Compliance',
        icon: ClipboardIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Compliance Reports',
            icon: CheckSquareIcon,
            href: '/app/compliance-v2/reports?status=all',
            permission: VIEW_COMPLIANCE_REPORTS
          },
          {
            title: 'Drafts',
            icon: ArchiveIcon,
            href: '/app/compliance-v2/drafts?type=all',
            permission: VIEW_DRAFT_COMPLIANCE_REPORTS
          },
          {
            title: 'Compliance Questions',
            icon: HelpCircleIcon,
            href: '/app/compliance-v2/questions?type=all',
            permission: VIEW_COMPLIANCE_QUESTIONS
          },
          // {
          //   title: 'Mystery Shopper Reports',
          //   icon: EditIcon,
          //   href: '/app/compliance-v2/mystery-shopper-reports',
          //   permission: VIEW_MYSTERY_SHOPPER_REPORTS
          // }
        ]
      }
    ]
  },
  {
    subheader: 'Training',
    items: [
      {
        title: 'Training',
        icon: EditIcon,
        items: [
          {
            title: 'Videos',
            icon: YoutubeIcon,
            href: '/app/training-v2/videos',
            permission: VIEW_TRAINING_VIDEOS
          },
          {
            title: 'Add Video',
            icon: LayersIcon,
            href: '/app/training-v2/videos/new',
            permission: CREATE_TRAINING_VIDEOS
          }
        ]
      }
    ]
  },
  {
    subheader: 'Approvals',
    items: [
      {
        title: 'Approvals',
        icon: EditIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Approvals',
            icon: PieChartIcon,
            href: '/app/approvals',
            permission: VIEW_APPROVALS
          },
          {
            title: 'New Request',
            icon: ShoppingBagIcon,
            href: '/app/start-approval-request',
            permission: CREATE_REQUESTS
          }
        ]
      },
    ]
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Tools',
        icon: ToolIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Territories',
            icon: MapIcon,
            href: '/app/tools/territories',
            permission: VIEW_TERRITORIES
          },
          {
            title: 'Franchise Locations',
            icon: MapPinIcon,
            href: '/app/tools/franchise-locations',
            permission: VIEW_FRANCHISE_LOCATIONS
          },
          {
            title: 'Delivery Locations',
            icon: TruckIcon,
            href: '/app/tools/delivery-locations',
            permission: VIEW_DELIVERY_LOCATIONS
          },
          {
            title: 'LLCs',
            icon: BriefcaseIcon,
            href: '/app/tools/llcs',
            permission: VIEW_LLCS
          },
          {
            title: 'Items',
            icon: PackageIcon,
            href: '/app/tools/items',
            permission: VIEW_ITEMS
          },
          {
            title: 'Users',
            icon: UsersIcon,
            href: '/app/tools/users',
            permission: VIEW_USERS
          },
          {
            title: 'Signature Generator',
            icon: SendIcon,
            href: '/app/tools/signature-generator',
            permission: VIEW_SIGNATURE_GENERATOR
          },
          {
            title: 'Create Announcement',
            icon: BellIcon,
            href: '/app/tools/new-announcement',
            permission: CREATE_ANNOUNCEMENTS
          },
          {
            title: 'Announcements',
            icon: ListIcon,
            href: '/app/tools/announcements',
            permission: VIEW_ANNOUNCEMENTS
          }
        ]
      }
    ]
  }
];
