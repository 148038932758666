import React from 'react';
import { Divider, Card, CardHeader, CardContent, Typography } from '@material-ui/core';

export const NoteCard = ({ title = '', note = '' }) => {
  return (
    <Card>
      <CardHeader title={title} />

      <Divider />

      <CardContent>
        <Typography>{note}</Typography>
      </CardContent>
    </Card>
  );
};
