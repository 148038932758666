import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

import {
  useCreateMysteryShopperReportMutation,
  useFetchMysteryShopperReport,
  useUpdateMysteryShopperReportMutation
} from 'src/apis';
import { FilesDropzone, FranchiseeLocationSelector, Loading, TerritorySelector } from 'src/components';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { useParamValues } from 'src/hooks/route.hook';

export const MysteryShopperReportForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id: current_userId } = useCurrentUser();
  const { id } = useParamValues(['id']);
  const {
    data: {
      userId: mysteryShopperReport_userId = '',
      territoryId: mysteryShopperReport_territoryId = '',
      franchiseeLocationId: mysteryShopperReport_franchiseeLocationId = '',
      dateTimestamp: mysteryShopperReport_date = null,
      text: mysteryShopperReport_text = '',
      files: mysteryShopperReport_files = []
    },
    isLoading: loading_mysteryShopperReport
  } = useFetchMysteryShopperReport({ id });
  const [
    createMysteryShopperReportMutation,
    { isLoading: loading_createMysteryShopperReportMutation }
  ] = useCreateMysteryShopperReportMutation();
  const [
    updateMysteryShopperReportMutation,
    { isLoading: loading_updateMysteryShopperReportMutation }
  ] = useUpdateMysteryShopperReportMutation();

  const userId = id ? mysteryShopperReport_userId : current_userId;
  const territoryId = id ? mysteryShopperReport_territoryId : '';
  const franchiseeLocationId = id ? mysteryShopperReport_franchiseeLocationId : '';
  const date = id ? moment.unix(mysteryShopperReport_date) : null;
  const text = id ? mysteryShopperReport_text : '';
  const files = id ? mysteryShopperReport_files : [];

  const submitFunc = id ? updateMysteryShopperReportMutation : createMysteryShopperReportMutation;
  const successMessage = id ? 'Mystery Shopper Report updated.' : 'Mystery Shopper Report created.';
  const errorMessage = id ? 'Mystery Shopper Report update failed.' : 'Mystery Shopper Report creation failed.';
  const submitButtonLabel = id ? 'Update' : 'Submit';
  const isSubmitting = id ? loading_updateMysteryShopperReportMutation : loading_createMysteryShopperReportMutation;

  const formManager = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId,
      territoryId,
      franchiseeLocationId,
      date,
      text,
      files
    },
    validationSchema: Yup.object().shape({
      territoryId: Yup.string()
        .nullable()
        .required('Select a territory'),
      franchiseeLocationId: Yup.string()
        .nullable()
        .required('Select a franchisee location'),
      date: Yup.date()
        .nullable()
        .required('Date must be selected'),
      text: Yup.string()
        .nullable()
        .required('Input report details'),
      files: Yup.array().nullable()
    }),
    onSubmit: values => {
      const submitFuncProps = id ? { id, ...values } : values;

      submitFunc(submitFuncProps)
        .then(() => {
          enqueueSnackbar(successMessage, { variant: 'success' });

          history.push('/app/compliance-v2/mystery-shopper-reports');
        })
        .catch(err => {
          enqueueSnackbar(err?.message || errorMessage, { variant: 'error' });
        })
        .finally();
    }
  });

  if (loading_mysteryShopperReport) {
    return <Loading text="Processing initialization..." height={500} />;
  }

  return (
    <form onSubmit={formManager.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Questions Details" />

            <Divider />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TerritorySelector
                    value={formManager.values.territoryId}
                    onChange={value => formManager.setFieldValue('territoryId', value)}
                    error={formManager.touched.territoryId && formManager.errors.territoryId}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FranchiseeLocationSelector
                    value={formManager.values.franchiseeLocationId}
                    onChange={value => formManager.setFieldValue('franchiseeLocationId', value)}
                    error={formManager.touched.franchiseeLocationId && formManager.errors.franchiseeLocationId}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date of Report"
                    fullWidth
                    name="date"
                    value={formManager.values.date}
                    onChange={value => formManager.setFieldValue('date', value)}
                    error={!!(formManager.touched.date && formManager.errors.date)}
                    helperText={formManager.touched.date && formManager.errors.date}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={5}
                    label="Report Details"
                    name="text"
                    value={formManager.values.text}
                    onChange={formManager.handleChange}
                    error={!!(formManager.touched.text && formManager.errors.text)}
                    helperText={formManager.touched.text && formManager.errors.text}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FilesDropzone files={formManager.values.files} setFieldValue={formManager.setFieldValue} />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size="large"
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
                  >
                    {submitButtonLabel}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
