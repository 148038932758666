import React from 'react';
import { useHistory } from 'react-router';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Edit } from 'react-feather';

import { useBulkDeleteComplianceReportSectionsMutation, useFetchComplianceReportSectionsQuery } from 'src/apis';
import { ActionsCell, EnhancedTable } from 'src/components';

export const Table = () => {
  const history = useHistory();
  const { data: sections = [], isLoading: loading_sections } = useFetchComplianceReportSectionsQuery();
  const [bulkDelete, { isLoading: loading_bulkDelete }] = useBulkDeleteComplianceReportSectionsMutation();

  const columns = [
    {
      id: 'sectionCol',
      label: 'Section',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      }
    },
    {
      id: 'actionsCol',
      label: 'Actions',
      align: 'center',
      render: ({ actionsCol }) => <ActionsCell actions={actionsCol} />,
      sorter: false,
      filter: false
    }
  ];

  const rows = sections.map(item => {
    const { id: sectionId, name: sectionCol } = item;

    const actionsCol = [
      {
        icon: <Edit />,
        onClick: event => {
          event.stopPropagation();
          history.push(`/app/compliance-v2/question-sections/${sectionId}/edit`);
        }
      }
    ];

    const row = {
      ...item,
      sectionCol,
      actionsCol
    };

    return row;
  });

  const tableActionsComponentProps = [
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={loading_bulkDelete ? <CircularProgress size={16} color="inherit" /> : <Delete />}
          disabled={loading_bulkDelete}
          onClick={() => {
            const ids = rows.map(row => row.id);

            bulkDelete({ ids });
          }}
        >
          Delete
        </Button>
      )
    }
  ];

  return (
    <EnhancedTable
      title="Select rows to delete"
      columns={columns}
      rows={rows}
      tableActionsComponentProps={tableActionsComponentProps}
      loading={{
        isLoading: loading_sections || loading_sections,
        text: 'Loading question sections...'
      }}
      empty={{
        description: 'No question sections yet.',
        buttonLabel: 'Create new question section',
        onClick: () => history.push(`/app/compliance-v2/question-sections/new`)
      }}
      isCheckable
    />
  );
};
