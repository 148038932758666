import {
  baseApi,
  bulkDeleteDocs,
  bulkUpdateDocs,
  createDoc,
  deleteDoc,
  getCollection,
  getDoc,
  updateDoc
} from '../base';

const collectionName = 'compliance_report_sections';

export const complianceReportSectionsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchComplianceReportSections: builder.query({
      queryFn: async ({ where } = {}) => {
        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['compliance-report-sections']
    }),

    fetchComplianceReportSection: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createComplianceReportSection: builder.mutation({
      queryFn: async ({ name }) => {
        try {
          const { data: sections = [] } = await getCollection(collectionName);

          if (sections.findIndex(item => item.name.toLowerCase() === name.toLowerCase()) >= 0) {
            throw Error('The section name has been already used.');
          }

          let maxOrderInRestaurant = Math.max(...sections.map(({ orderInRestaurant = 0 }) => orderInRestaurant));
          if (maxOrderInRestaurant < 0) {
            maxOrderInRestaurant = 0;
          }
          maxOrderInRestaurant += 1;

          let maxOrderInTruck = Math.max(...sections.map(({ orderInTruck = 0 }) => orderInTruck));
          if (maxOrderInTruck < 0) {
            maxOrderInTruck = 0;
          }
          maxOrderInTruck += 1;

          return await createDoc(collectionName, {
            name,
            orderInRestaurant: maxOrderInRestaurant,
            orderInTruck: maxOrderInTruck
          });
        } catch (err) {
          throw err;
        }
      },
      invalidatesTags: ['compliance-report-sections']
    }),

    updateComplianceReportSection: builder.mutation({
      queryFn: async ({ id, name, orderInRestaurant, orderInTruck }) => {
        try {
          const { data: sections = [] } = await getCollection(collectionName);

          if (sections.findIndex(item => item.name.toLowerCase() === name.toLowerCase()) >= 0) {
            throw Error('The section name has been already used.');
          }

          return await updateDoc(collectionName, { id, name, orderInRestaurant, orderInTruck });
        } catch (err) {
          throw err;
        }
      },
      invalidatesTags: ['compliance-report-sections']
    }),

    bulkUpdateComplianceReportSections: builder.mutation({
      queryFn: async ({ sections }) => {
        return await bulkUpdateDocs(collectionName, sections);
      },
      invalidatesTags: ['compliance-report-sections']
    }),

    deleteComplianceReportSection: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['compliance-report-sections']
    }),

    bulkDeleteComplianceReportSections: builder.mutation({
      queryFn: async ({ ids }) => {
        return await bulkDeleteDocs(collectionName, { ids });
      },
      invalidatesTags: ['compliance-report-sections']
    })
  })
});

export const {
  useFetchComplianceReportSectionsQuery,
  useFetchComplianceReportSectionQuery,
  useCreateComplianceReportSectionMutation,
  useUpdateComplianceReportSectionMutation,
  useBulkUpdateComplianceReportSectionsMutation,
  useDeleteComplianceReportSectionMutation,
  useBulkDeleteComplianceReportSectionsMutation
} = complianceReportSectionsApi;
