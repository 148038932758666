import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useFetchTerritories } from 'src/apis';
import { Loading } from 'src/components';

export const TerritorySelector = ({ value = '', onChange = () => {}, label = 'Select Territory', error = '' }) => {
  const { data: territories, loading_territories } = useFetchTerritories();

  return (
    <Autocomplete
      options={territories.map(item => item.id)}
      getOptionLabel={option => {
        const { name = '' } = territories.find(item => item.id === option) || {};

        return name;
      }}
      getOptionSelected={(option, value) => option === value}
      value={value}
      onChange={(_, value) => onChange(value)}
      renderInput={params => (
        <TextField {...params} variant="outlined" label={label} error={!!error} helperText={error} />
      )}
      loading={loading_territories}
      loadingText={<Loading text="Loading territories..." />}
    />
  );
};
