import * as React from 'react';
import { IMaskInput } from 'react-imask';

export const PhoneNumberMask = ({ onChange, ...rest }) => (
  <IMaskInput
    unmask
    {...rest}
    mask="(#00) 000-0000"
    definitions={{
      '#': /[1-9]/
    }}
    onAccept={value => onChange({ target: { name: rest.name, value } })}
    overwrite
  />
);

export const PhoneNumberMaskHyphen = ({ onChange, ...rest }) => (
  <IMaskInput
    {...rest}
    mask="#00-000-0000"
    definitions={{
      '#': /[1-9]/
    }}
    onAccept={value => onChange({ target: { name: rest.name, value } })}
    overwrite
  />
);
