import {
  addDoc,
  collection,
  doc,
  deleteDoc as fDeleteDoc,
  updateDoc as fUpdateDoc,
  getDoc as fGetDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import moment from 'moment';

import { db } from 'src/lib/firebase';
import { rollbarClient } from 'src/utils/rollbarClient';

const getDocDetails = doc => {
  const docDetails = doc.data();
  const { createdAt = {}, updatedAt: _updatedAt } = docDetails;

  const createdAtTimestamp = createdAt?.seconds || 0;
  const createdAtTimestampString = createdAtTimestamp
    ? moment.unix(createdAtTimestamp).format('DD MMM YYYY | hh:mm')
    : '-';
  const updatedAt = _updatedAt || createdAt;
  const updatedAtTimestamp = updatedAt?.seconds || 0;
  const updatedAtTimestampString = updatedAtTimestamp
    ? moment.unix(updatedAtTimestamp).format('DD MMM YYYY | hh:mm')
    : '-';

  return {
    ...docDetails,
    id: doc.id,
    createdAt,
    createdAtTimestamp,
    createdAtTimestampString,
    updatedAt,
    updatedAtTimestamp,
    updatedAtTimestampString
  };
};

export const getCollection = async (path, options) => {
  try {
    const cRef = collection(db, path);
    let cQuery = query(cRef);

    if (options?.where) {
      cQuery = query(cQuery, where(...options.where));
    }

    let refs = null;

    try {
      refs = await getDocs(cQuery);
    } catch (e) {
      console.log('getCollection e', e);
    }
    const data = refs?.docs?.map(doc => getDocDetails(doc));

    return { data: data || [] };
  } catch (error) {
    return { error };
  }
};

export const getDoc = async (path, { id } = {}) => {
  try {
    const docRef = doc(db, path, id);
    const ref = await fGetDoc(docRef);
    const data = getDocDetails(ref);

    return { data: data || null };
  } catch (error) {
    console.log('getDoc error', error);
    return { error };
  }
};

export const createDoc = async (path, data) => {
  try {
    const { docId, ...rest } = data;

    let ref = null;

    if (!docId) {
      const cRef = collection(db, path);

      ref = await addDoc(cRef, {
        ...rest,
        createdAt: serverTimestamp()
      });
    } else {
      const docRef = doc(db, path, docId);

      ref = await setDoc(docRef, {
        ...rest,
        createdAt: serverTimestamp()
      });
    }

    return { data: ref || docId };
  } catch (error) {
    console.error('error => ', error);
    rollbarClient.error('base.util createDoc', error);

    return { error };
  }
};

export const updateDoc = async (path, { id, ...rest }) => {
  try {
    const docRef = doc(db, path, id);

    await fUpdateDoc(docRef, {
      ...rest,
      updatedAt: serverTimestamp()
    });

    return { data: 'updated' };
  } catch (error) {
    console.log('updateDoc error', error);
    rollbarClient.error('base.util updateDoc', error);

    return { error };
  }
};

export const bulkUpdateDocs = async (path, data = []) => {
  try {
    const batch = writeBatch(db);

    data.forEach(({ id, ...rest }) => {
      const docRef = doc(db, path, id);

      batch.update(docRef, { ...rest, updatedAt: serverTimestamp() });
    });

    try {
      await batch.commit();
      return { data: 'bulk updated' };
    } catch (error) {
      return { error };
    }
  } catch (error) {
    rollbarClient.error('base.util bulkUpdateDocs', error);

    return { error };
  }
};

export const deleteDoc = async (path, { id }) => {
  try {
    const docRef = doc(db, path, id);

    await fDeleteDoc(docRef);

    return { data: 'deleted' };
  } catch (error) {
    rollbarClient.error('base.util deleteDoc', error);

    return { error };
  }
};

export const bulkDeleteDocs = async (path, { ids = [] }) => {
  try {
    const batch = writeBatch(db);

    ids.forEach(id => {
      const docRef = doc(db, path, id);

      batch.delete(docRef);
    });

    try {
      await batch.commit();
      return { data: 'bulk deleted' };
    } catch (error) {
      return { error };
    }
  } catch (error) {
    rollbarClient.error('base.util bulkDeleteDocs', error);

    return { error };
  }
};
