import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ComplianceReportFormContext, ComplianceReportFormContextProvider } from './compliance-report-form.context';
import { NoQuestionsCard } from './no-questions-card';
import { FormActions } from './form-actions';
import { FormNotes } from './form-notes';
import { Sections } from './sections';
import { OrganizationNote } from './organization-note';
import { Loading } from 'src/components';

const useStyles = makeStyles({
  actionsContainerSticky: {
    position: 'sticky',
    top: '1rem',
    minWidth: '275'
  },
  actionsContainerFixed: {
    position: 'fixed',
    bottom: '25px',
    zIndex: 999
  },
});

const LoadingCover = () => {
  return (
    <div style={{ height: '100%', backgroundColor: '#000', opacity: 0.4, position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, pointerEvents: 'none', zIndex: 1300 }}>
      <Loading text='Updating compliance form...' height="100vh" textStyle={{ color: '#fff' }} />
    </div>
  )
}

export const ComplianceReportForm = ({ isDraft = false }) => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery('(min-width: 1280px)')

  return (
    <ComplianceReportFormContextProvider isDraft={isDraft}>
      <ComplianceReportFormContext.Consumer>
        {({ autosaveReport, questionsCount, organizable, isOrganizing, isInitializing, isSubmitting, hasError }) => {
          const isQuestions = questionsCount > 0;

          if (isInitializing) {
            return (
              <LoadingCover />
            )
          }

          return (
            <>
              <form onSubmit={autosaveReport}>
                <Grid container spacing={3} alignItems={'flex-start'}>
                  {organizable && <OrganizationNote />}

                  {!isQuestions && (
                    <Grid item xs={12} lg={8}>
                      <NoQuestionsCard />
                    </Grid>
                  )}

                  {isQuestions && (
                    <Grid item xs={12} lg={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Sections />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    className={isLargeScreen ? classes.actionsContainerSticky : ''}
                    item
                    xs={12}
                    lg={4}
                    style={isOrganizing ? { opacity: 0.5, pointerEvents: 'none' } : {}}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormNotes />
                      </Grid>

                      {isLargeScreen && (
                        <Grid item xs={12}>
                          <FormActions />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {!isLargeScreen && (
                    <Grid className={classes.actionsContainerFixed} item xs={12}>
                      <FormActions />
                    </Grid>
                  )}
                </Grid>
              </form>
              {(isSubmitting || hasError) && (
                <LoadingCover />
              )}
            </>
          );
        }}
      </ComplianceReportFormContext.Consumer>
    </ComplianceReportFormContextProvider>
  );
};
