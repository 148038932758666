import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField } from '@material-ui/core';

import {
  useCreateComplianceReportSectionMutation,
  useFetchComplianceReportSectionQuery,
  useUpdateComplianceReportSectionMutation
} from 'src/apis';
import { useParamValues } from 'src/hooks/route.hook';
import { Loading } from 'src/components';

export const SectionForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParamValues(['id']);
  const {
    data: { name: _name = '', orderInRestaurant = 1, orderInTruck = 1 } = {},
    isLoading: loading_section
  } = useFetchComplianceReportSectionQuery({ id });
  const [
    createSection,
    { isLoading: loading_create, isError: error_create, isSuccess: success_create, error: error_content_create }
  ] = useCreateComplianceReportSectionMutation();
  const [
    updateSection,
    { isLoading: loading_update, isError: error_update, isSuccess: success_update, error: error_content_update }
  ] = useUpdateComplianceReportSectionMutation();

  const name = id ? _name : '';
  const mutate = id ? updateSection : createSection;
  const successMessage = id ? 'Compliance report section updated.' : 'Compliance report section created.';
  const errorMessage = id ? 'Compliance report section update failed.' : 'Compliance report section creation failed.';
  const submitButtonLabel = id ? 'Update Compliance Report Section' : 'Create Compliance Report Section';
  const isSubmitting = id ? loading_update : loading_create;
  const isError = id ? error_update : error_create;
  const isSuccess = id ? success_update : success_create;
  const error = id ? error_content_update : error_content_create;

  const formManager = useFormik({
    enableReinitialize: true,
    initialValues: {
      name
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Section title is required')
    }),
    onSubmit: ({ name }) => {
      const mutateProps = id ? { id, name, orderInRestaurant, orderInTruck } : { name };

      mutate(mutateProps);
    }
  });

  useEffect(() => {
    if (!isSubmitting && isError && !isSuccess) {
      enqueueSnackbar(error?.message || errorMessage, {
        variant: 'error'
      });
    }

    if (!isSubmitting && !isError && isSuccess) {
      enqueueSnackbar(successMessage, {
        variant: 'success'
      });

      history.push('/app/compliance-v2/question-sections');
    }
  }, [isSubmitting, isError, isSuccess]);

  if (loading_section) {
    return <Loading text="Processing initialization..." height={500} />;
  }

  return (
    <form onSubmit={formManager.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Section Details" />

            <Divider />

            <CardContent>
              <TextField
                variant="outlined"
                fullWidth
                label="Create compliance question section"
                name="name"
                onChange={formManager.handleChange('name')}
                value={formManager.values.name}
                error={Boolean(formManager.touched.name && formManager.errors.name)}
                helperText={formManager.touched.name && formManager.errors.name}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
          >
            {submitButtonLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
