import { baseApi, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';
import {
  collection,
  doc,
  getDoc as fGetDoc,
  getDocs,
  addDoc,
  updateDoc as fUpdateDoc,
  deleteDoc as fDeleteDoc,
  query
} from 'firebase/firestore';
import { db } from 'src/lib/firebase';

/**
 * recommended to use
 */
const collectionName = 'llcs';

export const llcsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchLlcs: builder.query({
      queryFn: async (props = { where: null }) => {
        const { where } = props;

        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['llcs']
    }),

    fetchLlc: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createLlc: builder.mutation({
      queryFn: async ({ name, territoryIds }) => {
        return await createDoc(collectionName, {
          name,
          territoryIds
        });
      },
      invalidatesTags: ['llcs']
    }),

    updateLlc: builder.mutation({
      queryFn: async ({ id, name, territoryIds }) => {
        return await updateDoc(collectionName, {
          id,
          name,
          territoryIds
        });
      },
      invalidatesTags: ['llcs']
    }),

    deleteLlc: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['llcs']
    })
  })
});

export const {
  useFetchLlcsQuery,
  useFetchLlcQuery,
  useCreateLlcMutation,
  useUpdateLlcMutation,
  useDeleteLlcMutation
} = llcsApi;

/**
 * not recommended to use
 */
export const useLLCsApi = () => {
  const getAllLLCs = () => {
    const cRef = collection(db, 'llcs');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const createLLC = llc => {
    const { name, territories } = llc;
    const cRef = collection(db, 'llcs');

    return addDoc(cRef, {
      name,
      territories
    });
  };

  const getLLCById = id => {
    const docRef = doc(db, 'llcs', id);

    return fGetDoc(docRef);
  };

  const updateLLCById = (id, llc) => {
    const { name, territories } = llc;
    const docRef = doc(db, 'llcs', id);

    return fUpdateDoc(docRef, {
      name,
      territories
    });
  };

  const deleteLLCById = id => {
    const docRef = doc(db, 'llcs', id);

    return fDeleteDoc(docRef);
  };

  return {
    getAllLLCs,
    createLLC,
    getLLCById,
    updateLLCById,
    deleteLLCById
  };
};
