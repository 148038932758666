import React, { createContext, useContext } from 'react';

export const PageContext = createContext();

export const PageContextProvider = ({ children }) => {
  return <PageContext.Provider>{children}</PageContext.Provider>;
};

export const usePageContext = () => {
  return useContext(PageContext);
};
