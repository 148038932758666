export const filterRows = ({ rows, filters }) => {
  let filteredRows = [...rows];

  for (const [columnId, { value, func }] of Object.entries(filters)) {
    const defaultFilterFunc = value => row => {
      let columnVal = row[columnId];

      if (typeof columnVal === 'string') {
        columnVal = columnVal.trim().toLowerCase();
      }

      return columnVal.includes(value);
    };

    let filterVal = value === null ? '' : value;

    if (typeof filterVal === 'string') {
      filterVal = filterVal.trim().toLowerCase();
    }

    const filterFunc = (func || defaultFilterFunc)(filterVal);

    filteredRows = filteredRows.filter(filterFunc);
  }

  return filteredRows;
};

export const sortRows = ({ rows, columns, orderBy, order }) => {
  let sortedRows = [...rows];

  if (orderBy) {
    const { sorter = true } = columns.find(col => col.id === orderBy) || {};

    if (sorter !== false) {
      const descendingComparator =
        sorter === true
          ? (row1, row2, orderBy) => {
              if (row2[orderBy] < row1[orderBy]) {
                return -1;
              }

              if (row2[orderBy] > row1[orderBy]) {
                return 1;
              }

              return 0;
            }
          : sorter;

      const compareFn =
        order === 'desc'
          ? (row1, row2) => descendingComparator(row1, row2, orderBy)
          : (row1, row2) => -descendingComparator(row1, row2, orderBy);

      sortedRows = sortedRows.sort(compareFn);
    }
  }

  return sortedRows;
};

export const paginateRows = ({ rows, page, rowsPerPage }) => {
  const paginatedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return paginatedRows;
};

export const checkIsFiltered = ({ filters }) => {
  for (const { value } of Object.values(filters)) {
    if (Array.isArray(value) && value.length > 0) return true;

    if (!Array.isArray(value) && value) return true;
  }

  return false;
};

export const initFilters = ({ filters }) => {
  return Object.entries(filters).reduce(
    (prev, [columnId, { value, ...rest }]) => ({
      ...(prev || {}),
      [columnId]: { value: null, ...rest }
    }),
    {}
  );
};
