import React from 'react';
import { Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { QuestionForm } from '../question-form';

export const ComplianceReportQuestionEditPage = () => {
  return (
    <DashboardPageContainer title="Edit Compliance Report Question">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Compliance Questions',
                link: '/app/compliance-v2/questions'
              },
              {
                label: 'Edit Question'
              }
            ]}
            title={'Edit Compliance Question'}
            cancelButton={{ link: '/app/compliance-v2/questions' }}
          />
        </Grid>

        <Grid item xs={12}>
          <QuestionForm />
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
