import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { FULFILLMENT, SUPER_ADMIN } from 'src/constants';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { useParamValues } from 'src/hooks/route.hook';

export const AccountPageContext = createContext();

export const AccountPageContextProvider = ({ children }) => {
  const history = useHistory();
  const { settings: currentTab } = useParamValues(['settings']);
  const { role } = useCurrentUser();

  const isValidTab = ((role === SUPER_ADMIN || role === FULFILLMENT)
    ? ['general', 'password', 'notifications']
    : ['general', 'password']
  ).includes(currentTab);

  useEffect(() => {
    if (!isValidTab) {
      history.push('general');
    }
  }, [isValidTab]);

  return (
    <AccountPageContext.Provider
      value={{
        currentTab
      }}
    >
      {children}
    </AccountPageContext.Provider>
  );
};

export const useAccountPageContext = () => {
  return useContext(AccountPageContext);
};
