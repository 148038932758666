import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';

import { useComplianceReportFormContext } from './compliance-report-form.context';

export const FormNotes = () => {
  const { localHighlightNotes, localLowlightNotes, notesRef } = useComplianceReportFormContext();
  const [positivesText, setPositivesText] = useState(localHighlightNotes);
  const [opportunitesText, setOpportunitesText] = useState(localLowlightNotes);
  const isInitialRender = useRef(true);

  useEffect(() => {
    setPositivesText(localHighlightNotes);
  }, [localHighlightNotes]);

  useEffect(() => {
    setOpportunitesText(localLowlightNotes);
  }, [localLowlightNotes]);

  const debouncedHighlightUpdate = useCallback(_.debounce(async (e) => {
    // console.log('debouncedFormUpdate e.target.value', e.target.value);
    notesRef.current.highlightNotes = e.target.value;
    // debouncedAutosave();
  }, 150), []);
  const debouncedLowlightUpdate = useCallback(_.debounce(async (e) => {
    // console.log('debouncedFormUpdate e.target.value', e.target.value);
    notesRef.current.lowlightNotes = e.target.value;
    // debouncedAutosave();
  }, 150), []);

  const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiFormLabel-root": {
        paddingRight: '25px',
        lineHeight: '22px'
      },
      "& .MuiFormLabel-root.Mui-focused": {
        paddingRight: '0px'
      }
    }
  }));
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Positives & Highlights" />

          <Divider />

          <CardContent>
            <TextField
              fullWidth
              label="Please note positives and highlights here"
              variant="outlined"
              rows={6}
              multiline
              name="highlightNotes"
              value={positivesText}
              onChange={(e) => {
                e.persist();
                setPositivesText(e.target.value);
                debouncedHighlightUpdate(e);
              }}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title="Opportunities for Improvement" />

          <Divider />

          <CardContent>
            <TextField
              fullWidth
              label="Please note opportunities for improvement here"
              variant="outlined"
              rows={6}
              multiline
              name="lowlightNotes"
              value={opportunitesText}
              onChange={(e) => {
                e.persist();
                setOpportunitesText(e.target.value);
                debouncedLowlightUpdate(e);
              }}
              className={classes.root}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
