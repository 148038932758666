import { SUPER_ADMIN, FRANCHISEE_OWNER, FULFILLMENT } from 'src/constants';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { filterRows, paginateRows, selectRow } from 'src/utils/table';
import { useFetchDeliveryLocationsQuery } from '../delivery-locations';
import { useFetchFranchiseeLocationsQuery } from '../franchisee-locations';
import { useFetchUsersQuery } from '../users';
import { useFetchTerritoriesQuery, useFetchTerritoryQuery } from './territories.api';

export const useFetchTerritories = () => {
  const { id, role, territories: assignedTerritories = [] } = useCurrentUser();

  const userId = role === FRANCHISEE_OWNER ? id : null;

  const { data, isLoading } = useFetchTerritoriesQuery({ userId });
  const { data: allUsers = [], isLoading: loading_users } = useFetchUsersQuery();
  const {
    data: allFranchiseeLocations = [],
    isLoading: loading_franchiseeLocations
  } = useFetchFranchiseeLocationsQuery();
  const { data: allDeliveryLocations = [], isLoading: loading_deliveryLocations } = useFetchDeliveryLocationsQuery();

  let territories = data || [];

  if (![SUPER_ADMIN, FULFILLMENT, FRANCHISEE_OWNER].includes(role)) {
    territories = territories.filter(({ id }) => assignedTerritories.includes(id));
  }

  territories = territories.map(territory => {
    const { id = null, name = '', userIds = [], franchiseeLocationIds = [], deliveryLocationIds = [] } = territory;

    const users = allUsers
      .filter(user => userIds.includes(user.id))
      .map(({ firstName: firstNameVal = '', lastName: lastNameVal = '', ...rest }) => {
        const firstName = firstNameVal.trim();
        const lastName = lastNameVal.trim();
        const name = `${firstName} ${lastName}`.trim() || 'No name';

        return {
          ...rest,
          firstName,
          lastName,
          name
        };
      });

    const franchiseLocations = allFranchiseeLocations.filter(franchiseeLocation =>
      franchiseeLocationIds.includes(franchiseeLocation.id)
    );

    const trucks = franchiseLocations.filter(franchiseeLocation => franchiseeLocation.type === 'truck').length;
    const restaurants = franchiseLocations.filter(franchiseeLocation => franchiseeLocation.type === 'restaurant')
      .length;

    const deliveryLocations = allDeliveryLocations.filter(deliveryLocation =>
      deliveryLocationIds.includes(deliveryLocation.id)
    ).length;

    return {
      id,
      name,
      userIds,
      users,
      franchiseeLocationIds,
      deliveryLocationIds,
      deliveryLocations,
      trucks,
      restaurants
    };
  });

  territories.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return { data: territories, isLoading };
};

/**
 *
 * @param {string} id - The territory id to show order specific to that territory
 * @param {string} query
 * @param {Object} filters
 * @param {number} page
 * @param {number} limit
 * @returns {Object}
 */
export const useTerritoriesTableRows = ({ id, query, filters, page, limit }) => {
  const { data, isLoading } = useFetchTerritories();

  const selected = selectRow(data, id);
  const filtered = filterRows(selected, query, filters);
  const paginated = paginateRows(filtered, page, limit);

  return { data: [selected, filtered, paginated, data], isLoading };
};

export const useFetchTerritory = ({ id }) => {
  const { data: territory = {}, isLoading } = useFetchTerritoryQuery({ id });

  const { name = '', users = [], franchiseLocations = [], deliveryLocations = [] } = territory;

  return {
    data: {
      id,
      name,
      users,
      franchiseLocations,
      deliveryLocations
    },
    isLoading
  };
};
