import React from 'react';
import { Box, IconButton, TextField } from '@material-ui/core';
import { Clear, FormatClear } from '@material-ui/icons';

export const EnhancedTableTextFilter = ({
  value: _value = '',
  onChange = () => {},
  onClose = () => {},
  placeholder = 'Enter keyword...'
}) => {
  const Icon = value ? FormatClear : Clear;
  const iconProps = {
    fontSize: 'small'
  };

  const value = _value === null ? '' : _value;

  return (
    <Box display="flex" alignItems="center" padding={1.5}>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        style={{ minWidth: 240 }}
      />

      <Box marginLeft={1} marginRight={-0.5}>
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            if (value) {
              onChange(null);
            } else {
              onClose();
            }
          }}
        >
          <Icon {...iconProps} />
        </IconButton>
      </Box>
    </Box>
  );
};
