import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword as fCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as fSignInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import { useFirebaseContext } from 'src/contexts/firebase-context';
import { auth } from 'src/lib/firebase';

export const useAuthApi = () => {
  const { firebase } = useFirebaseContext();

  const createUserWithEmailAndPassword = async (email, password) => {
    return fCreateUserWithEmailAndPassword(auth, email, password);
  };

  const signInWithEmailAndPassword = async (email, password) => {
    return fSignInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    return signInWithPopup(auth, provider);
  };

  const logout = () => {
    return signOut(auth);
  };

  return {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithGoogle,
    logout
  };
};
