import { baseApi, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';
import {
  arrayRemove,
  doc,
  updateDoc as fUpdateDoc,
  deleteDoc as fDeleteDoc,
  collection,
  addDoc,
  arrayUnion,
  writeBatch,
  query,
  getDocs,
  getDoc as fGetDoc
} from 'firebase/firestore';
import { db } from 'src/lib/firebase';

/**
 * recommended to use
 */
const collectionName = 'franchise_locations';

export const franchiseeLocationsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchFranchiseeLocations: builder.query({
      queryFn: async () => {
        return await getCollection(collectionName);
      },
      providesTags: ['franchise-locations']
    }),

    fetchFranchiseeLocation: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createFranchiseeLocation: builder.mutation({
      queryFn: async ({ territoryId, name, type, enabled }) => {
        return await createDoc(collectionName, { territoryId, name, type, enabled });
      },
      invalidatesTags: ['franchise-locations']
    }),

    updateFranchiseeLocation: builder.mutation({
      queryFn: async ({ id, name, territoryId, type, enabled }) => {
        return await updateDoc(collectionName, { id, name, territoryId, type, enabled });
      },
      invalidatesTags: ['franchise-locations']
    }),

    deleteFranchiseeLocation: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['franchise-locations']
    })
  })
});

export const {
  useFetchFranchiseeLocationsQuery,
  useFetchFranchiseeLocationQuery,
  useCreateFranchiseeLocationMutation,
  useUpdateFranchiseeLocationMutation,
  useDeleteFranchiseeLocationMutation
} = franchiseeLocationsApi;

/**
 * not recommended to use
 */
export const useFranchiseeLocationsApi = () => {
  const deleteFranchiseLocationFromTerritory = (territoryId, locationToBeRemoved) => {
    const docRef = doc(db, 'territories', territoryId);

    return fUpdateDoc(docRef, {
      franchiseLocations: arrayRemove(locationToBeRemoved)
    });
  };

  const deleteUniqueFranchiseLocation = id => {
    const docRef = doc(db, 'franchise_locations', id);

    return fDeleteDoc(docRef);
  };

  const createFranchiseLocation = franchiseLocation => {
    const { territoryId, name, franchiseLocationName, franchiseLocationType, enabled } = franchiseLocation;
    const cRef = collection(db, 'franchise_locations');

    return addDoc(cRef, {
      territoryId,
      name,
      franchiseLocationName,
      franchiseLocationType,
      enabled
    });
  };

  const addFranchiseLocationToTerritory = (territoryId, franchiseLocation) => {
    const { franchiseLocationName, franchiseLocationType, enabled, id } = franchiseLocation;
    const docRef = doc(db, 'territories', territoryId);

    return fUpdateDoc(docRef, {
      franchiseLocations: arrayUnion({
        id,
        franchiseLocationName,
        franchiseLocationType,
        enabled
      })
    });
  };

  const removeFranchiseLocationAndAddToNewTerritory = (
    oldTerritoryId,
    newTerritoryId,
    oldFranchiseLocation,
    newFranchiseLocation
  ) => {
    const batch = writeBatch(db);

    // Remove
    const oldTerritoryRef = doc(db, 'territories', oldTerritoryId);

    // Add
    const newTerritoryRef = doc(db, 'territories', newTerritoryId);

    batch.update(oldTerritoryRef, 'franchiseLocations', arrayRemove(oldFranchiseLocation));
    batch.update(newTerritoryRef, 'franchiseLocations', arrayUnion(newFranchiseLocation));

    return batch.commit();
  };

  const getAllFranchiseLocations = () => {
    const cRef = collection(db, 'franchise_locations');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const getUniqueFranchiseLocation = id => {
    const docRef = doc(db, 'franchise_locations', id);

    return fGetDoc(docRef);
  };

  const updateUniqueFranchiseLocation = (id, franchiseLocation) => {
    const { territoryId, name, franchiseLocationName, franchiseLocationType, enabled } = franchiseLocation;
    const docRef = doc(db, 'franchise_locations', id);

    return fUpdateDoc(docRef, {
      territoryId,
      name,
      franchiseLocationName,
      franchiseLocationType,
      enabled
    });
  };

  return {
    deleteFranchiseLocationFromTerritory,
    deleteUniqueFranchiseLocation,
    createFranchiseLocation,
    addFranchiseLocationToTerritory,
    removeFranchiseLocationAndAddToNewTerritory,
    getAllFranchiseLocations,
    getUniqueFranchiseLocation,
    updateUniqueFranchiseLocation
  };
};
