import React from 'react';
import { Grid } from '@material-ui/core';

import { useEnhancedTableContext } from './enhanced-table.context';

export const EnhancedTableActions = ({ actions }) => {
  const { rows, selected } = useEnhancedTableContext();

  const selectedRows = rows.filter(item => selected.includes(item.id));

  return (
    <Grid container spacing={2}>
      {actions.map((action, index) => (
        <Grid key={`enhanced-table-head-action-${index}`} item>
          {action.render({ rows: selectedRows })}
        </Grid>
      ))}
    </Grid>
  );
};
