import React from 'react';
import { Box, ButtonBase, IconButton, useTheme } from '@material-ui/core';
import { AddAPhoto, Close } from '@material-ui/icons';
import Resizer from "react-image-file-resizer";

export const ImageSelector = ({ imgUrl = '', onSelectFile = () => {} }) => {
  const theme = useTheme();

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1500,
        1500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });

  return (
    <Box display={'flex'}>
      <Box position={'relative'}>
        <ButtonBase component="label">
          <input
            type="file"
            hidden
            onChange={async event => {
              const [file] = event.target.files || [null];

              if (file) {
                onSelectFile({ file, resizeFile });
              }
            }}
          />

          <Box
            border={`1px ${imgUrl ? 'solid' : 'dashed'} ${theme.palette.text.disabled}`}
            width={80}
            height={80}
            borderRadius={4}
            color={theme.palette.text.disabled}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            overflow={'hidden'}
          >
            {!imgUrl && <AddAPhoto style={{ fontSize: 30 }} />}

            {imgUrl && (
              <img
                src={imgUrl}
                alt="Attatched file"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            )}
          </Box>
        </ButtonBase>

        {imgUrl && (
          <Box
            position={'absolute'}
            top={-12}
            right={-12}
            bgcolor={theme.palette.secondary.main}
            borderRadius={'100%'}
            onClick={e => {
              e.stopPropagation();
              onSelectFile(null);
            }}
          >
            <IconButton size="small" style={{ color: 'white' }}>
              <Close style={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
