export const groupBySection = (questions = [], property = '') => {
  if (!property) return {};

  if (questions.length === 0) return {};

  const result = questions.reduce(function(memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }

    memo[x[property]].push(x);

    return memo;
  }, {});

  return result;
};

export const groupToArray = questionsGroup => {
  const keys = Object.keys(questionsGroup);

  return keys.reduce((prev, current) => {
    const questions = questionsGroup[current];

    return [...prev, ...questions];
  }, []);
};
