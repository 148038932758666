import { sortByOrder } from 'src/utils/sort';
import { useFetchTrainingVideoCategoriesQuery } from './video-categories.api';

export const useFetchTrainingVideoCategories = () => {
  const { data: _categories = [], ...rest } = useFetchTrainingVideoCategoriesQuery();
  let categories = [..._categories];

  categories.sort(sortByOrder);

  return { data: categories, ...rest };
};
