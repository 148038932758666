import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';

export const HeaderBreadcrumbsLink = ({ link, label }) => {
  return (
    <Link variant="body1" color="inherit" to={link} component={RouterLink}>
      {label}
    </Link>
  );
};

HeaderBreadcrumbsLink.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string
};
