import React from 'react';
import { Divider, Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { MysteryShopperReportView } from './mystery-shopper-report-view';

export const ComplianceMysteryShopperReportViewPage = () => {
  return (
    <DashboardPageContainer title="Mystery Shopper Report Details">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Mystery Shopper Reports',
                link: '/app/compliance-v2/mystery-shopper-reports'
              },
              {
                label: 'Details'
              }
            ]}
            title={'Details'}
            cancelButton={{ link: '/app/compliance-v2/mystery-shopper-reports' }}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <MysteryShopperReportView />
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
