import React from 'react';
import { Box, Button } from '@material-ui/core';

import { useNotificationsContext } from './notifications.context';

export const NotificationsActions = () => {
  const { onMarkAllAsRead: _onMarkAllAsRead, updateContext } = useNotificationsContext();

  const onMarkAllAsRead = () => {
    _onMarkAllAsRead();
    updateContext({ open: false, page: 0 });
  };

  return (
    <Box p={2} display="flex" justifyContent="center">
      <Button variant="contained" fullWidth color="secondary" onClick={onMarkAllAsRead}>
        Mark all as read
      </Button>
    </Box>
  );
};
