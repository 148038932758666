import {
  baseApi,
  bulkDeleteDocs,
  bulkUpdateDocs,
  createDoc,
  deleteDoc,
  getCollection,
  getDoc,
  updateDoc
} from '../base';

const collectionName = 'training_video_categories';

export const trainingVideoCategoriesApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchTrainingVideoCategories: builder.query({
      queryFn: async ({ where } = {}) => {
        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['training-video-categories']
    }),

    fetchTrainingVideoCategory: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createTrainingVideoCategory: builder.mutation({
      queryFn: async ({ name }) => {
        try {
          const { data: categories = [] } = await getCollection(collectionName);

          let maxOrder = Math.max(...categories.map(({ order = 0 }) => order));
          if (maxOrder < 0) {
            maxOrder = 0;
          }

          const order = maxOrder + 1;

          if (categories.findIndex(item => item.name.toLowerCase() === name.toLowerCase()) < 0) {
            return await createDoc(collectionName, { name, order });
          } else {
            throw Error('The category name has been already used.');
          }
        } catch (err) {
          throw err;
        }
      },
      invalidatesTags: ['training-video-categories']
    }),

    updateTrainingVideoCategory: builder.mutation({
      queryFn: async ({ id, name, order }) => {
        try {
          const { data: categories = [] } = await getCollection(collectionName);

          if (categories.findIndex(item => item.name.toLowerCase() === name.toLowerCase()) < 0) {
            return await updateDoc(collectionName, { id, name, order });
          } else {
            throw Error('The category name has been already used.');
          }
        } catch (err) {
          throw err;
        }
      },
      invalidatesTags: ['training-video-categories']
    }),

    bulkUpdateTrainingVideoCategories: builder.mutation({
      queryFn: async ({ categories }) => {
        return await bulkUpdateDocs(collectionName, categories);
      },
      invalidatesTags: ['training-video-categories']
    }),

    deleteTrainingVideoCategory: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['training-video-categories']
    }),

    bulkDeleteTrainingVideoCategories: builder.mutation({
      queryFn: async ({ ids }) => {
        return await bulkDeleteDocs(collectionName, { ids });
      },
      invalidatesTags: ['training-video-categories']
    })
  })
});

export const {
  useFetchTrainingVideoCategoriesQuery,
  useFetchTrainingVideoCategoryQuery,
  useCreateTrainingVideoCategoryMutation,
  useUpdateTrainingVideoCategoryMutation,
  useBulkUpdateTrainingVideoCategoriesMutation,
  useDeleteTrainingVideoCategoryMutation,
  useBulkDeleteTrainingVideoCategoriesMutation
} = trainingVideoCategoriesApi;
