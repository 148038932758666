import React from 'react';
import { Receipt } from '@material-ui/icons';

import { Notifications } from '../notifications';
import { useNotifications } from './useNotifications';

export const OrdersNotifications = () => {
  const { data: { list = [], onMarkAllAsRead = () => {} } = {} } = useNotifications();

  return <Notifications icon={<Receipt />} title="Orders" list={list} onMarkAllAsRead={onMarkAllAsRead} />;
};
