import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import {
  useCreateTrainingVideoCategoryMutation,
  useFetchTrainingVideoCategoryQuery,
  useUpdateTrainingVideoCategoryMutation
} from 'src/apis';
import { useParamValues } from 'src/hooks/route.hook';

export const useVideoCategoryForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParamValues(['id']);
  const { data: { name: _name, order } = {}, isLoading: isInitializing } = useFetchTrainingVideoCategoryQuery({ id });
  const [
    create,
    { isLoading: loading_create, isError: error_create, error: error_details_create, isSuccess: success_create }
  ] = useCreateTrainingVideoCategoryMutation();
  const [
    update,
    { isLoading: loading_update, isError: error_update, error: error_details_update, isSuccess: success_update }
  ] = useUpdateTrainingVideoCategoryMutation();

  const name = id ? _name : '';
  const mutate = id ? update : create;
  const successMessage = id ? 'Training video category updated.' : 'Training video category created.';
  const errorMessage = id ? 'Training video category update failed.' : 'Training video category creation failed.';
  const isSubmitting = id ? loading_update : loading_create;
  const isError = id ? error_update : error_create;
  const error = id ? error_details_update : error_details_create;
  const isSuccess = id ? success_update : success_create;

  const submitButtonLabel = id ? 'Update Category' : 'Create Category';

  const formManager = useFormik({
    enableReinitialize: true,
    initialValues: { name },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Category title is required')
    }),
    onSubmit: ({ name }) => {
      const mutateProps = id ? { id, name, order } : { name };

      mutate(mutateProps);
    }
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.message || errorMessage, { variant: 'error' });
    } else if (!isSubmitting && isSuccess) {
      enqueueSnackbar(successMessage, { variant: 'success' });
      history.push('/app/training-v2/video-categories');
    }
  }, [isSubmitting, isError, isSuccess]);

  return [{ formManager, submitButtonLabel, isInitializing, isSubmitting }];
};
