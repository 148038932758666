import React from 'react';
import { Box, ButtonBase, CircularProgress, IconButton, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export const QuestionImage = ({ imgUrl = "", onButtonPress = () => {} }) => {
  const theme = useTheme();

  const imageContent = imgUrl ? (
    <img
      src={imgUrl}
      alt="Attached file"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }}
    />
  ) : (
    <CircularProgress />
  )

  return (
    <Box display={'inline-block'} width="80px" marginRight="20px">
      <Box position={'relative'}>
        <ButtonBase component="label">
          <Box
            border={`1px ${imgUrl ? 'solid' : 'dashed'} ${theme.palette.text.disabled}`}
            width={80}
            height={80}
            borderRadius={4}
            color={theme.palette.text.disabled}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            overflow={'hidden'}
          >
            {imageContent}
          </Box>
        </ButtonBase>

        <Box
          position={'absolute'}
          top={-12}
          right={-12}
          bgcolor={theme.palette.secondary.main}
          borderRadius={'100%'}
          onClick={e => {
            e.stopPropagation();
            onButtonPress(imgUrl);
          }}
        >
          <IconButton size="small" style={{ color: 'white' }}>
            <Close style={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
