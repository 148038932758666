import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

export const Empty = ({ description = 'No table data', buttonLabel = 'Click here', onClick }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign={'center'}
      padding={3}
      minHeight={300}
    >
      <Typography color="textSecondary">{description}</Typography>

      {onClick && (
        <Box marginTop={3}>
          <Button variant="contained" color="secondary" onClick={onClick}>
            {buttonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};
