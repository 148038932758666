import { db } from 'src/lib/firebase';
import { baseApi, getCollection } from '../base';
import { doc, writeBatch } from 'firebase/firestore';

const collectionName = 'team_contacts';

export const teamContactsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchTeamContacts: builder.query({
      queryFn: async () => {
        return await getCollection(collectionName);
      },
      providesTags: ['team-contacts']
    }),

    updateTeamContacts: builder.mutation({
      queryFn: async ({
        ordersTeamEmail = '',
        ordersTeamPhone = '',
        fulfillmentTeamEmail = '',
        fulfillmentTeamPhone = '',
        complianceTeamEmail = '',
        complianceTeamPhone = ''
      }) => {
        const teamContacts = [
          {
            id: 'orders_team',
            email: ordersTeamEmail,
            phone: ordersTeamPhone
          },
          {
            id: 'fulfillment_team',
            email: fulfillmentTeamEmail,
            phone: fulfillmentTeamPhone
          },
          {
            id: 'compliance_team',
            email: complianceTeamEmail,
            phone: complianceTeamPhone
          }
        ];

        const batch = writeBatch(db);

        teamContacts.forEach(({ id, ...rest }) => {
          const docRef = doc(db, collectionName, id);

          batch.update(docRef, rest);
        });

        try {
          await batch.commit();
          return { data: 'updated' };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['team-contacts']
    })
  })
});

export const { useFetchTeamContactsQuery, useUpdateTeamContactsMutation } = teamContactsApi;
