import React from 'react';
import { InputAdornment, SvgIcon, TextField } from '@material-ui/core';
import { Search } from 'react-feather';

export const TextFilter = () => {
  return (
    <TextField
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon fontSize="small" color="action">
              <Search />
            </SvgIcon>
          </InputAdornment>
        )
      }}
      placeholder="Search orders"
      variant="outlined"
    />
  );
};
