import React from 'react';
import { Card, Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { Table } from './table';
import { Tab } from './tab';
import { PageContextProvider } from './context';
import { checkPermissions, CREATE_COMPLIANCE_REPORTS } from 'src/permissions';
import { useCurrentUser } from 'src/contexts/firebase-context';

export const ComplianceReportsPage = () => {
  const { permissions } = useCurrentUser();
  let createButton;

  if (checkPermissions(permissions, CREATE_COMPLIANCE_REPORTS)) {
    createButton = { label: 'New Report', link: '/app/compliance-v2/reports/new/select-franchisee-location' };
  } else {
    createButton = { label: 'View Sample Report', link: '/app/compliance-v2/reports/new/select-franchisee-location' };
  }

  return (
    <DashboardPageContainer title="Compliance Reports" maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Compliance',
                link: '/app/compliance-v2/reports?status=all'
              },
              {
                label: 'Reports'
              }
            ]}
            title={'Compliance Reports'}
            createButton={createButton}
          />
        </Grid>

        <PageContextProvider>
          <Grid item xs={12}>
            <Card>
              <Tab />

              <Table />
            </Card>
          </Grid>
        </PageContextProvider>
      </Grid>
    </DashboardPageContainer>
  );
};
