import { EMPLOYEE, FRANCHISEE_OWNER, FULFILLMENT, MANAGER, SUPER_ADMIN } from 'src/constants';

import { baseApi, getCollection, getDoc } from '../base';
import { collection, doc, getDoc as fGetDoc, getDocs, query, updateDoc, where, writeBatch } from 'firebase/firestore';
import { auth, db } from 'src/lib/firebase';
import { getIdToken } from 'firebase/auth';

/**
 * recommended to use
 */
const collectionName = 'users';

export const usersApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchUsers: builder.query({
      queryFn: async (props = { role: 'all' }) => {
        const { role } = props;

        let options = {};

        const where =
          role === SUPER_ADMIN ||
          role === FRANCHISEE_OWNER ||
          role === EMPLOYEE ||
          role === MANAGER ||
          role === FULFILLMENT
            ? ['role', '==', role]
            : null;

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['users']
    }),

    fetchUser: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    })
  })
});

export const { useFetchUsersQuery, useFetchUserQuery } = usersApi;

/**
 * not recommended to use
 */
export const useUsersApi = () => {
  const updateUserTerritoryAssignment = async (userId, territoryIds) => {
    // giving user territory ID?
    const docRef = doc(db, 'users', userId);

    return updateDoc(docRef, { assignedTerritories: territoryIds });
  };

  const updateUserPermissions = (userId, permissions) => {
    const docRef = doc(db, 'users', userId);

    return updateDoc(docRef, { permissions });
  }

  const deleteUserFromTerritories = async userId => {
    const batch = writeBatch(db);
    const cRef = collection(db, 'territories');
    const cQuery = query(
      cRef,
      where('user_id', '==', userId)
    );
    const refs = await getDocs(cQuery);

    refs.forEach(ref => {
      const docRef = doc(db, 'territories', ref.id);

      batch.update(docRef, {
        user_email: '',
        user_first: '',
        user_last: '',
        user_id: '',
        user_mobile: ''
      });
    });

    return batch.commit();
  };

  const reinitializeIdToken = () => {
    // TODO: v9 refactor auth - make sure this works
    return getIdToken(auth.currentUser, true);
  };

  const getUniqueUser = id => {
    const docRef = doc(db, 'users', id);

    return fGetDoc(docRef);
  };

  const getAllUsers = async () => {
    const cRef = collection(db, 'users');
    const cQuery = query(cRef);
    const refs = await getDocs(cQuery);

    return refs.docs.map(user => ({ ...user.data(), id: user.id }));
  };

  const getAllUsersByRole = role => {
    const cRef = collection(db, 'users');
    const cQuery = query(
      cRef,
      where('role', '==', role)
    );
    return getDocs(cQuery);
  };

  return {
    updateUserTerritoryAssignment,
    updateUserPermissions,
    deleteUserFromTerritories,
    reinitializeIdToken,
    getUniqueUser,
    getAllUsers,
    getAllUsersByRole
  };
};
