import React, { useEffect, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { dateRangeOptions, getDateRange } from './utils';

export const DateRangeSelector = ({
  value: { range: _range = null, start: _start = null, end: _end = null } = {},
  onChange = () => {},
  placeholder = 'Select Date Range...'
} = {}) => {
  const [{ range, start, end }, setState] = useState({ range: _range, start: _start, end: _end });

  useEffect(() => {
    if (range && start && end) {
      onChange({ range, start, end });
    }
  }, [range, start, end]);

  const handleChangeRange = (event, range) => {
    if (range === 'custom') {
      setState(prev => ({ ...prev, range }));
    } else {
      const { start, end } = getDateRange(range);
      setState({ range, start, end });
    }
  };

  const handleChangeStart = newValue => {
    const start = newValue.clone().startOf('day');

    setState(prev => {
      if (!prev.start || start.format('YYYY-MM-DD') !== prev.start.format('YYYY-MM-DD')) {
        return {
          range: 'custom',
          start,
          end: !prev.end || (prev.end && prev.end < start) ? newValue.clone().endOf('day') : prev.end
        };
      }

      return prev;
    });
  };

  const handleChangeEnd = newValue => {
    const end = newValue.clone().endOf('day');

    setState(prev => {
      if (!prev.end || end.format('YYYY-MM-DD') !== prev.end.format('YYYY-MM-DD')) {
        return {
          range: 'custom',
          end,
          start: !prev.start || (prev.start && prev.start > end) ? newValue.clone().startOf('day') : prev.start
        };
      }

      return prev;
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          value={range === null ? '' : range}
          onChange={handleChangeRange}
          options={dateRangeOptions.map(option => option.value)}
          getOptionLabel={optionValue => dateRangeOptions.find(option => option.value === optionValue)?.label || ''}
          renderInput={params => (
            <TextField {...params} label="Date Range" placeholder={placeholder} variant="outlined" />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <DatePicker
          fullWidth
          autoOk
          variant="inline"
          inputVariant="outlined"
          label="From"
          name="start"
          format="YYYY-MM-DD"
          value={start}
          onChange={handleChangeStart}
        />
      </Grid>

      <Grid item xs={6}>
        <DatePicker
          fullWidth
          autoOk
          variant="inline"
          inputVariant="outlined"
          label="To"
          name="end"
          format="YYYY-MM-DD"
          value={end}
          onChange={handleChangeEnd}
        />
      </Grid>
    </Grid>
  );
};
