import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseApi = createApi({
  baseQuery: fakeBaseQuery,

  refetchOnFocus: true,

  refetchOnMountOrArgChange: true,

  tagTypes: [
    'territories',
    'franchise-locations',
    'delivery-locations',
    'items',
    'users',
    'llcs',
    'orders',
    'order',
    'order-drafts',
    'order-draft',
    'team-contacts',
    'compliance-report-sections',
    'compliance-report-questions',
    'compliance-reports',
    'compliance-report',
    'compliance-report-drafts',
    'mystery-shopper-reports',
    'training-video-categories',
    'training-videos'
  ],

  endpoints: () => ({})
});
