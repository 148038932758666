import { baseApi, bulkUpdateDocs, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';
import {
  collection,
  doc,
  getDocs,
  getDoc as fGetDoc,
  updateDoc as fUpdateDoc,
  query,
  writeBatch,
  addDoc
} from 'firebase/firestore';
import { db } from 'src/lib/firebase';

/**
 * recommended to use
 */
const collectionName = 'items';

export const itemsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchItems: builder.query({
      queryFn: async () => {
        return await getCollection(collectionName);
      },
      providesTags: ['items']
    }),

    fetchItem: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createItem: builder.mutation({
      queryFn: async ({ itemDesc, casesPerPallet, casesPerOvernight, sku, status, price, salePrice, isOnSale }) => {
        const { data: questions = [] } = await getCollection(collectionName);

        let maxOrder = Math.max(...questions.map(({ order = 0 }) => order));

        if (maxOrder < 0) {
          maxOrder = 0;
        }

        maxOrder += 1;

        return await createDoc(collectionName, {
          itemDesc,
          casesPerPallet,
          casesPerOvernight,
          sku,
          status,
          price,
          salePrice,
          isOnSale,
          order: maxOrder
        });
      },
      invalidatesTags: ['items']
    }),

    updateItem: builder.mutation({
      queryFn: async ({
        id,
        itemDesc,
        casesPerPallet,
        casesPerOvernight,
        sku,
        status,
        price,
        salePrice,
        isOnSale,
        order
      }) => {
        return await updateDoc(collectionName, {
          id,
          itemDesc,
          casesPerPallet,
          casesPerOvernight,
          sku,
          status,
          price,
          salePrice,
          isOnSale,
          order
        });
      },
      invalidatesTags: ['items']
    }),

    bulkUpdateItems: builder.mutation({
      queryFn: async ({ items }) => {
        return await bulkUpdateDocs(collectionName, items);
      },
      invalidatesTags: ['items']
    }),

    deleteItem: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['items']
    })
  })
});

export const {
  useFetchItemsQuery,
  useFetchItemQuery,
  useCreateItemMutation,
  useUpdateItemMutation,
  useBulkUpdateItemsMutation,
  useDeleteItemMutation
} = itemsApi;

/**
 * not recommended to use
 */
export const useItemsApi = () => {
  const getAllItems = () => {
    const cRef = collection(db, 'items');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  }

  const deleteSelectedItems = selectedItems => {
    const batch = writeBatch(db);

    selectedItems.forEach(id => {
      const docRef = doc(db, 'items', id);

      batch.delete(docRef);
    });

    return batch.commit();
  };

  const getUniqueItem = id => {
    const docRef = doc(db, 'items', id);

    return fGetDoc(docRef);
  };

  const updateUniqueItem = (id, item) => {
    const { itemDesc, sku, status, price, salePrice, isOnSale } = item;
    const docRef = doc(db, 'items', id);

    return fUpdateDoc(docRef, {
      itemDesc,
      sku,
      price,
      status,
      salePrice,
      isOnSale
    });
  };

  const createNewItem = item => {
    const { itemDesc, sku, status, salePrice, isOnSale } = item;
    const cRef = collection(db, 'items');

    return addDoc(cRef, {
      itemDesc,
      sku,
      status,
      salePrice,
      isOnSale
    });
  };

  return {
    getAllItems,
    deleteSelectedItems,
    getUniqueItem,
    updateUniqueItem,
    createNewItem
  };
};
