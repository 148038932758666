import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';

import { HeaderBreadcrumbs, Page } from 'src/components';

import { AccountPageContextProvider } from './account-page.context';
import { AccountTab } from './account-tab';
import { AccountTabContent } from './account-tab-content';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

export const AccountPage = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Fulfillments">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderBreadcrumbs
              breadcrumbs={[
                {
                  label: 'Dashboard',
                  link: '/app'
                },
                {
                  label: 'Account'
                }
              ]}
              title={'Settings'}
            />
          </Grid>

          <AccountPageContextProvider>
            <Grid item xs={12}>
              <AccountTab />
            </Grid>

            <Grid item xs={12}>
              <AccountTabContent />
            </Grid>
          </AccountPageContextProvider>
        </Grid>
      </Container>
    </Page>
  );
};
