import { sortByOrder } from 'src/utils/sort';
import { filterRows, paginateRows, selectRow } from 'src/utils/table';
import { useFetchItemQuery, useFetchItemsQuery } from './items.api';

const getItemDetails = ({ item }) => {
  const { casesPerPallet = 1, casesPerOvernight = 1, status = '' } = item;

  const statusLabel =
    status === 'active'
      ? 'Active'
      : status === 'out_of_stock'
      ? 'Out of Stock'
      : status === 'discontinued'
      ? 'Discontinued'
      : '';

  return { ...item, casesPerPallet, casesPerOvernight, statusLabel };
};

export const useFetchItems = () => {
  const { data: _items = [], ...rest } = useFetchItemsQuery();
  let items = _items.map(item => getItemDetails({ item }));

  items.sort(sortByOrder);

  return { data: items, ...rest };
};

export const useFetchItem = ({ id }) => {
  const { data: item, ...rest } = useFetchItemQuery({ id });

  const data = item ? getItemDetails({ item }) : undefined;

  return { data, ...rest };
};

export const useFetchItemsTableRows = ({ id, query, filters, page, limit }) => {
  const { data, isLoading } = useFetchItems();

  const selected = selectRow(data, id);
  const filtered = filterRows(selected, query, filters);
  const paginated = paginateRows(filtered, page, limit);

  return { data: [selected, filtered, paginated, data], isLoading };
};

export const useFetchItemsByStatus = () => {
  const { data: all = [], isLoading } = useFetchItems();

  const active = all.filter(item => item.status === 'active');
  const outOfStock = all.filter(item => item.status === 'out_of_stock');
  const discontinued = all.filter(item => item.status === 'discontinued');

  return { data: { all, active, outOfStock, discontinued }, isLoading };
};
