import React from 'react';
import { Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField } from '@material-ui/core';

import { Loading } from 'src/components';
import { useVideoCategoryForm } from './useVideoCategoryForm';

export const VideoCategoryForm = () => {
  const [{ formManager, submitButtonLabel, isInitializing, isSubmitting }] = useVideoCategoryForm();

  if (isInitializing) {
    return <Loading text="Processing initialization..." height={500} />;
  }

  return (
    <form onSubmit={formManager.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Category Details" />

            <Divider />

            <CardContent>
              <TextField
                variant="outlined"
                fullWidth
                label="Category Title"
                name="name"
                onChange={formManager.handleChange('name')}
                value={formManager.values.name}
                error={Boolean(formManager.touched.name && formManager.errors.name)}
                helperText={formManager.touched.name && formManager.errors.name}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
          >
            {submitButtonLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
