import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, CardContent, Checkbox, Grid, styled, Switch, TextField, Typography, useTheme } from '@material-ui/core';

import { ImageSelector } from 'src/components';
import { reorder } from 'src/utils/dnd';
import { useComplianceReportFormContext } from './compliance-report-form.context';
import { QuestionImage } from './question-image';
import { QuestionComment } from './question-comment';
import firebase from 'src/lib/firebase';
import { Question } from './question';

export const Questions = ({ sectionId, questions, dragging, setDragging }) => {
  const theme = useTheme();
  // const {
  //   id: reportId,
  //   toggleQuestionPass,
  //   toggleQuestionIsNotApplicable,
  //   addQuestionNoMarkedReason,
  //   updateQuestions,
  //   isOrganizing,
  //   isUpdatingRef
  // } = useComplianceReportFormContext();

  // const handleDragEnd = result => {
  //   setDragging(false);

  //   if (!result.destination) {
  //     return;
  //   }

  //   if (result.destination.index === result.source.index) {
  //     return;
  //   }

  //   const question = questions.find(({ id } = {}) => id === result.draggableId);

  //   if (question) {
  //     const items = reorder(questions, result.source.index, result.destination.index);

  //     updateQuestions({ sectionId, questions: items });
  //   }
  // };

  // console.log('questions', questions);

  return questions.map((question, questionIndex) => (
    <Question
      key={question.id}
      question={question}
      questionIndex={questionIndex}
      sectionId={sectionId}
    />
  ));

  // return (
  //   <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={() => setDragging(true)}>
  //     <Droppable droppableId={`droppable-section-${sectionId}`}>
  //       {({ innerRef: droppable_innerRef, placeholder: droppable_placeholder }) => (
  //         <div ref={droppable_innerRef}>
  //           {questions.map(({ id: questionId, question, pass, isNotApplicable = false, images, comment = '' }, questionIndex) => (
  //             <Draggable
  //               key={`draggable-question-${questionId}`}
  //               draggableId={questionId}
  //               index={questionIndex}
  //               isDragDisabled={!isOrganizing}
  //             >
  //               {({ innerRef: draggable_innerRef, draggableProps, dragHandleProps }, snapshot) => {
  //                 return (
  //                   <QuestionContainer
  //                     key={questionId}
  //                     ref={draggable_innerRef}
  //                     {...draggableProps}
  //                     {...dragHandleProps}
  //                     dragging={dragging ? 'yes' : 'no'}
  //                     style={{
  //                       ...draggableProps.style,
  //                       ...(snapshot.isDragging
  //                         ? { backgroundColor: theme.palette.grey[100], boxShadow: theme.shadows[1] }
  //                         : {})
  //                     }}
  //                     hoverdisabled={!isOrganizing ? 'yes' : 'no'}
  //                   >
  //                     <CardContent>
  //                       <Grid container direction="column" spacing={2}>
  //                         <Grid item xs={12}>
  //                           <Grid container alignItems="flex-start" justify="space-between">
  //                             <Grid item style={{ flex: 1 }}>
  //                               <Typography variant="overline" color="textSecondary">
  //                                 {question}
  //                               </Typography>
  //                             </Grid>

  //                             <Grid item style={(isOrganizing || isNotApplicable) ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
  //                               <Grid container alignItems="center" justify="flex-end">
  //                                 <Grid item>
  //                                   <Typography variant="overline" color="textSecondary">
  //                                     No
  //                                   </Typography>
  //                                 </Grid>

  //                                 <Grid item>
  //                                   <Switch
  //                                     checked={pass}
  //                                     onChange={() => toggleQuestionPass({ sectionId, questionId })}
  //                                   />
  //                                 </Grid>

  //                                 <Grid item>
  //                                   <Typography variant="overline" color="textSecondary">
  //                                     Yes
  //                                   </Typography>
  //                                 </Grid>
  //                               </Grid>
  //                             </Grid>
  //                             <Grid item>
  //                               <Grid container alignItems="center" justify="flex-end">
  //                                 <Grid item>
  //                                   <Checkbox
  //                                     checked={isNotApplicable}
  //                                     name="questionIsNotApplicable"
  //                                     onChange={() => toggleQuestionIsNotApplicable({ sectionId, questionId })}
  //                                   />
  //                                 </Grid>
  //                                 <Grid item>
  //                                   <Typography variant="overline" color="textSecondary">
  //                                     N/A
  //                                   </Typography>
  //                                 </Grid>
  //                               </Grid>
  //                             </Grid>
  //                           </Grid>
  //                         </Grid>

  //                         <Grid item xs={12} style={isNotApplicable ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
  //                           <Grid container spacing={2}>
  //                             <Grid item style={isOrganizing ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
  //                               <ImageSelector
  //                                 onSelectFile={file => {
  //                                   const newQuestions = [...questions];
  //                                   const currentQuestion = newQuestions[questionIndex];

  //                                   if (currentQuestion?.images && ((currentQuestion?.images?.file || currentQuestion?.images?.url))) {
  //                                     currentQuestion.images = [currentQuestion.images];
  //                                   } else if (!currentQuestion?.images?.length) {
  //                                     currentQuestion.images = [];
  //                                   }

  //                                   const images = [...currentQuestion.images];
  //                                   const imageId = images.length ? images.length + 1 : 1;
  //                                   images.push({ file, id: imageId });
  //                                   currentQuestion.images = images;

  //                                   updateQuestions({ sectionId, questions: newQuestions });
  //                                 }}
  //                               />
  //                             </Grid>

  //                             <QuestionComment
  //                               comment={comment}
  //                               pass={pass}
  //                               updateComment={text => {
  //                                 addQuestionNoMarkedReason({
  //                                   sectionId,
  //                                   questionId,
  //                                   comment: text
  //                                 })
  //                               }}
  //                               isUpdatingRef={isUpdatingRef}
  //                             />
  //                           </Grid>
  //                         </Grid>
  //                         <Grid item xs={12}>
  //                           {(questions[questionIndex]?.images?.length
  //                             ? questions[questionIndex]?.images
  //                             : (questions[questionIndex]?.images?.file || questions[questionIndex]?.images?.url)
  //                             ? [questions[questionIndex]?.images]
  //                             : null)?.map((image, index) => {
  //                             return (
  //                               <QuestionImage
  //                                 imgUrl={
  //                                   image && image.file
  //                                     ? URL.createObjectURL(image.file)
  //                                     : image && image.url
  //                                     ? image.url
  //                                     : ''
  //                                 }
  //                                 key={`${image.url}-${index}`}
  //                                 onButtonPress={url => {
  //                                   const newQuestions = [...questions];
  //                                   const currentQuestion = newQuestions[questionIndex];
  //                                   const images = currentQuestion.images
  //                                     .filter(img => {
  //                                       if (img.url) {
  //                                         return img.url !== url;
  //                                       }

  //                                       return img.id !== image.id;
  //                                     });

  //                                   currentQuestion.images = images;

  //                                   updateQuestions({ sectionId, questions: newQuestions });
  //                                 }}
  //                               />
  //                             )
  //                           })}
  //                         </Grid>
  //                       </Grid>
  //                     </CardContent>
  //                   </QuestionContainer>
  //                 );
  //               }}
  //             </Draggable>
  //           ))}

  //           {droppable_placeholder}
  //         </div>
  //       )}
  //     </Droppable>
  //   </DragDropContext>
  // );
};

const QuestionContainer = styled(Box)(({ theme, dragging, hoverdisabled }) => ({
  '&:hover': {
    backgroundColor: 'unset'
  }
}));
