import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  BarChart2 as BarChart2Icon,
  BarChart as BarChartIcon,
  Edit as EditIcon,
  PieChart as PieChartIcon,
  Clipboard as ClipboardIcon,
  Tool as ToolIcon,
  Send as SendIcon,
  Layers as LayersIcon,
  Youtube as YoutubeIcon,
  ShoppingBag as ShoppingBagIcon,
  Archive as ArchiveIcon,
  Edit3 as Edit3Icon,
  HelpCircle as HelpCircleIcon,
  CheckSquare as CheckSquareIcon
} from 'react-feather';
// Importing all constants for permission checking, going to remove NavItem componenet based on if the user has this specific permission
import {
  // Dashboard
  VIEW_DASHBOARD,
  // Orders
  VIEW_ORDERS,
  CREATE_ORDERS,
  // Draft Orders
  VIEW_DRAFT_ORDERS,
  // Item Sales Reports
  VIEW_ITEM_SALES_REPORTS,
  // InventoryReports
  VIEW_INVENTORY_REPORTS,
  // Approvals
  VIEW_APPROVALS,
  CREATE_REQUESTS,
  // Compliance Reports
  VIEW_COMPLIANCE_REPORTS,
  VIEW_DRAFT_COMPLIANCE_REPORTS,
  VIEW_SAMPLE_COMPLIANCE_REPORTS,
  // Compliance Questions
  VIEW_COMPLIANCE_QUESTIONS,
  // Mystery Shopper Reports
  VIEW_MYSTERY_SHOPPER_REPORTS,
  // Training Videos
  VIEW_TRAINING_VIDEOS,
  CREATE_TRAINING_VIDEOS,
  // Signature Generator
  VIEW_SIGNATURE_GENERATOR
} from 'src/permissions/constants';

export const franchiseeSections = [
  {
    subheader: '',
    items: [{ title: 'Dashboard', icon: PieChartIcon, href: '/app', permission: VIEW_DASHBOARD }]
  },
  {
    subheader: 'Orders & Reports',
    items: [
      {
        title: 'Orders',
        icon: ReceiptIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Your Orders',
            icon: PieChartIcon,
            href: '/app/orders',
            permission: VIEW_ORDERS
          },
          {
            title: 'New Order',
            icon: Edit3Icon,
            href: '/app/new-order',
            permission: CREATE_ORDERS
          },
          {
            title: 'Drafts',
            icon: ArchiveIcon,
            href: '/app/draft-orders',
            permission: VIEW_DRAFT_ORDERS
          }
        ]
      },
      {
        title: 'Reports',
        icon: BarChartIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Item Sales Reports',
            icon: BarChart2Icon,
            href: '/app/reports/item-sales',
            permission: VIEW_ITEM_SALES_REPORTS
          }
        ]
      }
    ]
  },
  {
    subheader: 'Compliance',
    items: [
      {
        title: 'Compliance',
        icon: ClipboardIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Compliance Reports',
            icon: CheckSquareIcon,
            href: '/app/compliance-v2/reports?status=all',
            permission: VIEW_COMPLIANCE_REPORTS
          },
          {
            title: 'Sample Compliance Reports',
            icon: BarChartIcon,
            href: '/app/compliance-v2/reports/new/select-franchisee-location',
            permission: VIEW_SAMPLE_COMPLIANCE_REPORTS
          },
          {
            title: 'Drafts',
            icon: ArchiveIcon,
            href: '/app/compliance-v2/drafts?type=all',
            permission: VIEW_DRAFT_COMPLIANCE_REPORTS
          },
          {
            title: 'Compliance Questions',
            icon: HelpCircleIcon,
            href: '/app/compliance-v2/questions?type=all',
            permission: VIEW_COMPLIANCE_QUESTIONS
          },
          // {
          //   title: 'Mystery Shopper Reports',
          //   icon: EditIcon,
          //   href: '/app/compliance-v2/mystery-shopper-reports',
          //   permission: VIEW_MYSTERY_SHOPPER_REPORTS
          // }
        ]
      }
    ]
  },
  {
    subheader: 'Training',
    items: [
      {
        title: 'Training',
        icon: EditIcon,
        items: [
          {
            title: 'Videos',
            icon: YoutubeIcon,
            href: '/app/training-v2/videos',
            permission: VIEW_TRAINING_VIDEOS
          }
        ]
      }
    ]
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Tools',
        icon: ToolIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Signature Generator',
            icon: SendIcon,
            href: '/app/tools/signature-generator',
            permission: VIEW_SIGNATURE_GENERATOR
          }
        ]
      }
    ]
  }
];
