import React from 'react';
import { Card, Grid, SvgIcon } from '@material-ui/core';
import { Settings } from 'react-feather';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { Table } from './table';
import { Tab } from './tab';
import { PageContextProvider } from './context';

export const ComplianceReportQuestionsPage = () => {
  return (
    <DashboardPageContainer title="Compliance Report Questions" maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Compliance Questions',
                link: '/app/compliance-v2/questions'
              }
            ]}
            title={'Compliance Questions'}
            customButton1={{
              label: 'Question Sections',
              link: '/app/compliance-v2/question-sections',
              startIcon: (
                <SvgIcon fontSize="small">
                  <Settings width={22} />
                </SvgIcon>
              )
            }}
            createButton={{ label: 'New Question', link: '/app/compliance-v2/questions/new' }}
          />
        </Grid>

        <PageContextProvider>
          <Grid item xs={12}>
            <Card>
              <Tab />

              <Table />
            </Card>
          </Grid>
        </PageContextProvider>
      </Grid>
    </DashboardPageContainer>
  );
};
