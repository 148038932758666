import React from 'react';
import { Box, Grid, Link, Paper, Typography } from '@material-ui/core';
import moment from 'moment';

import { useFetchMysteryShopperReport } from 'src/apis';
import { Logo } from 'src/components';
import { useParamValues } from 'src/hooks/route.hook';

export const MysteryShopperReportView = () => {
  const { id } = useParamValues(['id']);
  const {
    data: {
      userName: ownerName,
      dateTimestamp = 0,
      territoryName = '',
      franchiseeLocationName = '',
      text = '',
      files = []
    }
  } = useFetchMysteryShopperReport({ id });

  const dateOfIssue = dateTimestamp ? moment.unix(dateTimestamp).format('DD MMM YYYY') : '-';

  return (
    <Paper>
      <Box padding={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Logo />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography gutterBottom variant="h5" color="textPrimary">
                  Owner Name
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {ownerName}
                </Typography>
              </Grid>

              <Grid item>
                <Typography gutterBottom variant="h5" color="textPrimary">
                  Date of issue
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {dateOfIssue}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography gutterBottom variant="h5" color="textPrimary">
                  Territory
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {territoryName}
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography gutterBottom variant="h5" color="textPrimary">
                  Franchise
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {franchiseeLocationName}
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography gutterBottom variant="h5" color="textPrimary">
                  Report Details
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {text}
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" color="textPrimary">
              Files
            </Typography>

            {files.length === 0 && <Typography color="textSecondary">No files attached</Typography>}

            {files.length > 0 && (
              <Box display="flex">
                <Box>
                  {files.map(({ url = '', name = '' }) => (
                    <Link key={url} target="_blank" color="primary" variant="overline" href={url}>
                      <li>{name}</li>
                    </Link>
                  ))}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
