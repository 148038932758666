import { useFetchTeamContactsQuery } from './team-contacts.api';

export const useTeamContacts = () => {
  const { data = [], ...rest } = useFetchTeamContactsQuery();

  const emptyContact = { email: '', phone: '' };

  const ordersTeam = data.find(item => item.id === 'orders_team') || emptyContact;
  const fulfillmentTeam = data.find(item => item.id === 'fulfillment_team') || emptyContact;
  const complianceTeam = data.find(item => item.id === 'compliance_team') || emptyContact;

  const { email: ordersTeamEmail, phone: ordersTeamPhone } = ordersTeam;
  const { email: fulfillmentTeamEmail, phone: fulfillmentTeamPhone } = fulfillmentTeam;
  const { email: complianceTeamEmail, phone: complianceTeamPhone } = complianceTeam;

  const teamContacts = {
    ordersTeamEmail,
    ordersTeamPhone,
    fulfillmentTeamEmail,
    fulfillmentTeamPhone,
    complianceTeamEmail,
    complianceTeamPhone
  };

  return { data: teamContacts, ...rest };
};
