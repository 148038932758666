import React, { createContext, useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { useBulkUpdateTrainingVideoCategoriesMutation, useFetchTrainingVideoCategories } from 'src/apis';
import { Loading } from 'src/components';

export const PageContext = createContext();

export const PageContextProvider = ({ children }) => {
  const {
    data: _categories = [],
    isLoading: loading_categories,
    isFetching: fetching_categories
  } = useFetchTrainingVideoCategories();
  const [bulkUpdateCategories] = useBulkUpdateTrainingVideoCategoriesMutation();
  const [{ categories, isOrganizing }, setContextValue] = useState({ categories: [], isOrganizing: false });
  const setIsOrganizing = isOrganizing => setContextValue(prev => ({ ...prev, isOrganizing }));

  useEffect(() => {
    if (!fetching_categories) {
      setContextValue(prev => ({ ...prev, categories: _categories }));
    }
  }, [fetching_categories]);

  const updateCategories = categories => {
    setContextValue(prev => ({ ...prev, categories }));

    const newCategories = categories.map((category, index) => ({ ...category, order: index + 1 }));

    bulkUpdateCategories({ categories: newCategories });
  };

  if (loading_categories) {
    return (
      <Box width="100%">
        <Loading text="Loading video categories" height={300} />
      </Box>
    );
  }

  return (
    <PageContext.Provider value={{ categories, updateCategories, isOrganizing, setIsOrganizing }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  return useContext(PageContext);
};
