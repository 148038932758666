import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { ComplianceReportForm } from '../../compliance-report-form';
import { useFirebaseContext } from 'src/contexts/firebase-context';
import { useParamValues } from 'src/hooks/route.hook';

export const ComplianceReportCreatePage = () => {
  const { getUniqueFranchiseLocation } = useFirebaseContext();
  const { franchiseeLocationId } = useParamValues([
    'franchiseeLocationId'
  ]);
  const [franchiseLocation, setFranchiseLocation] = useState();

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const franchiseLocationDoc = await getUniqueFranchiseLocation(franchiseeLocationId);

    setFranchiseLocation(franchiseLocationDoc.data());
  }

  return (
    <DashboardPageContainer title="Create Compliance Report">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Compliance Reports',
                link: '/app/compliance-v2/reports?status=all'
              },
              {
                label: 'Select Franchise',
                link: '/app/compliance-v2/reports/new/select-franchisee-location'
              },
              {
                label: 'New Report'
              }
            ]}
            title={`Create A Compliance Report For ${franchiseLocation?.name ? franchiseLocation?.name : ''}`}
            cancelButton={{ link: '/app/compliance-v2/reports?status=all' }}
          />
        </Grid>

        <Grid item xs={12}>
          <ComplianceReportForm />
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
