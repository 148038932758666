import React from 'react';
import { useHistory } from 'react-router';
import { Grid, Paper } from '@material-ui/core';

import { useFetchTrainingVideoCategories } from 'src/apis';
import { DashboardPageContainer, Empty, HeaderWithButtons } from 'src/components';
import { VideoForm } from '../video-form';

export const VideoEditPage = () => {
  const history = useHistory();
  const { data: categories = [], isLoading } = useFetchTrainingVideoCategories();

  return (
    <DashboardPageContainer title="Edit Video" maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Training Videos',
                link: '/app/training-v2/videos'
              },
              {
                label: 'Edit Video'
              }
            ]}
            title={'Edit Video'}
            cancelButton={{ link: '/app/training-v2/videos' }}
          />
        </Grid>

        <Grid item xs={12}>
          {!isLoading && categories.length === 0 ? (
            <Paper>
              <Empty
                description="There is no video category."
                buttonLabel="Create a category"
                onClick={() => history.push('/app/training-v2/video-categories/new')}
              />
            </Paper>
          ) : (
            <VideoForm />
          )}
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
