import firebase from 'firebase/compat/app';
import { initializeApp, getApp } from "firebase/app"
import 'firebase/compat/auth';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import 'firebase/compat/firestore';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import 'firebase/compat/functions';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import 'firebase/compat/storage';
import { getStorage } from "firebase/storage";
import { firebaseConfig } from 'src/config';

const initApp = (config) => {
  try {
    return getApp();
  } catch (e) {
    console.log("🚀 ~ file: firebase.js:13 ~ initApp ~ e:", e)
    return initializeApp(config);
  }
}

export const firebaseApp = initApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
export const functions = getFunctions(getApp());
export const storage = getStorage();

console.log("🚀 ~ file: firebase.js:14 ~ firebaseApp:", firebaseApp)

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

/**
 * v8 Local firebase emulator config
 * DEPRECATED
 */
// if (process.env.NODE_ENV === 'development') {
//   firebase.functions().useFunctionsEmulator('http://localhost:5001');
//   firebase.firestore().settings({ host: 'localhost:8080', ssl: false, experimentalForceLongPolling: true });
//   firebase.auth().useEmulator('http://localhost:9099');
// }

// /**
//  * Local firebase emulator config
//  */
if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}

export default firebase;
