import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createContext, useContext } from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useRollbar } from '@rollbar/react';

import {
  useBulkUpdateComplianceReportQuestionsMutation,
  useBulkUpdateComplianceReportSectionsMutation,
  useCreateComplianceReportMutation,
  useFetchComplianceReport,
  useFetchComplianceReportQuestions,
  useUpdateComplianceReportMutation
} from 'src/apis';
import { Loading } from 'src/components';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { FULFILLMENT, SUPER_ADMIN } from 'src/constants';
import { useParamValues, useQueryValues } from 'src/hooks/route.hook';
import { questionsToSections, sectionsToQuestions } from './utils';
import { Button } from '@material-ui/core';

const AUTOSAVE_TIMEOUT = 60000;

export const ComplianceReportFormContext = createContext();

export const ComplianceReportFormContextProvider = ({
  children = null,
  isDraft: isDraftPage = false
 }) => {
  const rollbar = useRollbar();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id: current_userId, role: userRole } = useCurrentUser();
  const [{ sections, isOrganizing }, setContextValue] = useState({ sections: [], isOrganizing: false });
  const [hasError, setHasError] = useState(false);
  const { draftId } = useQueryValues(['draftId']);
  const isDraft = isDraftPage || !!draftId;
  const isInitialRender = useRef(true);
  const isUpdating = useRef(false);
  const sectionsRef = useRef();
  const notesRef = useRef();
  const loadingText = isInitialRender.current ? 'Processing initialization...' : 'Updating compliance form...';

  const snackbarAction = snackbarId => (
    <>
      <Button
        onClick={() => onDismissSnackbar(snackbarId)}
        color="secondary"
        variant="contained"
      >
        Dismiss
      </Button>
    </>
  );

  const onDismissSnackbar = (snackbarId) => {
    closeSnackbar(snackbarId);
    setHasError(false);
  }

  const { locationType, franchiseeLocationId: url_franchiseeLocationId, id: url_id } = useParamValues([
    'locationType',
    'franchiseeLocationId',
    'id'
  ]);
  const id = url_id || draftId;

  const {
    data: {
      userId: report_userId = '',
      franchiseeLocationId: report_franchiseeLocationId = '',
      questions: report_questions = [],
      highlightNotes: report_highlightNotes = '',
      lowlightNotes: report_lowlightNotes = '',
      readRecipients = []
    },
    isLoading: loading_complianceReport
  } = useFetchComplianceReport({ id, isDraft });

  const {
    data: { all: all_questions = [] },
    isLoading: loading_questions
  } = useFetchComplianceReportQuestions({ where: ['type', '==', locationType] });

  const [createReport, { isLoading: loading_createReport }] = useCreateComplianceReportMutation();
  const [updateReport, { isLoading: loading_updateReport }] = useUpdateComplianceReportMutation();
  const [createDraft, { isLoading: loading_createDraft }] = useCreateComplianceReportMutation();
  const [publishDraft, { isLoading: loading_publishDraft }] = useUpdateComplianceReportMutation();
  const [bulkUpdateComplianceReportSections] = useBulkUpdateComplianceReportSectionsMutation();
  const [bulkUpdateComplianceReportQuestions] = useBulkUpdateComplianceReportQuestionsMutation();

  const userId = id ? report_userId : current_userId;
  const franchiseeLocationId = id ? report_franchiseeLocationId : url_franchiseeLocationId;
  const questions = id ? report_questions : all_questions.map(question => ({ ...question, pass: true, isNotApplicable: false }));
  const questionsCount = questions.length;
  const highlightNotes = id ? report_highlightNotes : '';
  const lowlightNotes = id ? report_lowlightNotes : '';

  const [localHighlightNotes, setLocalHighlightNotes] = useState(highlightNotes);
  const [localLowlightNotes, setLocalLowlightNotes] = useState(lowlightNotes);

  // organizing only enabled for new reports
  const organizable = (userRole === SUPER_ADMIN || userRole === FULFILLMENT) && !id && questionsCount > 1;

  const _sections = questionsToSections({ questions });

  const submitReport = id ? updateReport : createReport;
  const successMessage = id ? 'Compliance report updated.' : 'Compliance report created.';
  const errorMessage = id ? 'Compliance report update failed.' : 'Compliance report creation failed.';
  const submitButtonLabel = id ? 'Update' : 'Submit';
  const isInitializing = !id;
  const isSubmitting = id ? loading_updateReport : loading_createReport;

  const autosaveReport = async () => {
    if (isUpdating.current) {
      console.log('is updating will retry', isUpdating.current);
      setTimeout(() => {
        autosaveReport();
      }, 300);
      return;
    }
    const values = {
      userId,
      franchiseeLocationId,
      highlightNotes: notesRef.current.highlightNotes,
      lowlightNotes: notesRef.current.lowlightNotes,
    };

    console.log('autosaveReport isDraft id loading_form', isDraft, id, loading_form);

    if ((isDraft && id) || (!isDraft && id)) {
      console.log('autosaveReport');
      saveReport({ values, shouldNavigate: false });
    } else {
      const saveDraftRes = await onSaveDraft();

      if (saveDraftRes?.data) {
        history.push(`?draftId=${saveDraftRes.data}`);
      }

      console.log('autosaveReport saveDraftRes', saveDraftRes);
    }
  }
  const autosaveRef = useRef(autosaveReport);

  const debouncedAutosave = useCallback(_.debounce(async e => {
    console.log('debouncedAutosave');
    autosaveRef.current();
  }, AUTOSAVE_TIMEOUT), []);

  useEffect(() => {
    if (_sections.length > 0) {
      setContextValue(prev => ({ ...prev, sections: _sections }));
      sectionsRef.current = _sections;
      setLocalHighlightNotes(highlightNotes);
      setLocalLowlightNotes(lowlightNotes);
      notesRef.current = {
        highlightNotes,
        lowlightNotes
      }
    }
    isInitialRender.current = false;
  }, [_sections.length]);

  useEffect(() => {
    if (sections.length > 0 && !id) {
      autosaveReport();
    }
  }, [sections.length]);

  // useEffect(() => {
  //   if (sections.length > 0) {
  //     if (!loading_form) {
  //       autosaveRef.current = autosaveReport;
  //       debouncedAutosave();
  //     }
  //   }
  // }, [sections]);

  const handleError = ({ error, message }) => {
    setHasError(true);
    enqueueSnackbar(error?.message || message, {
      variant: 'error',
      action: snackbarAction,
      persist: true
    });
  }

  const saveReport = async ({ values, shouldNavigate = true }) => {
    if (isUpdating.current) {
      console.log('is updating will retry', isUpdating.current);
      setTimeout(() => {
        saveReport({ values, shouldNavigate });
      }, 300);
      return;
    }

    console.log('saveReport values', values);

    const newQuestions = sectionsToQuestions({ sections: sectionsRef.current });

    const report = {
      userId,
      franchiseeLocationId,
      questions: newQuestions,
      highlightNotes: notesRef.current.highlightNotes,
      lowlightNotes: notesRef.current.lowlightNotes,
      readRecipients
    };

    let submitReportProps = id ? { id, ...report } : report;
    submitReportProps = { ...submitReportProps, isDraft };

    console.log('saveReport submitReportProps', submitReportProps);

    submitReport(submitReportProps)
      .then((res) => {
        if (!res?.error) {
          enqueueSnackbar(successMessage, {
            variant: 'success'
          });

          if (shouldNavigate && !isDraft) {
            debouncedAutosave.cancel();
            history.push('/app/compliance-v2/reports?status=all');
          }
        } else {
          handleError({
            error: res.error,
            message: errorMessage
          });
          rollbar.error(errorMessage, res.error);
        }
      })
      .catch(err => {
        handleError({
          error: err,
          message: errorMessage
        });
        rollbar.error(errorMessage, err);
      });
  };

  const onSaveDraft = async ({ shouldNavigate = false } = {}) => {
    const newQuestions = sectionsToQuestions({ sections: sectionsRef.current });
    const report = {
      userId,
      franchiseeLocationId,
      questions: newQuestions,
      highlightNotes: notesRef.current.highlightNotes,
      lowlightNotes: notesRef.current.lowlightNotes,
      readRecipients,

      // When creating a draft
      isDraft: true
    };

    console.log('onSaveDraft report', report);

    return createDraft(report)
      .then((res) => {
        if (!res?.error) {
          console.log('onSaveDraft res', res);
          // throw new Error();

          enqueueSnackbar('Compliance form draft created', {
            variant: 'success'
          });

          if (shouldNavigate) {
            debouncedAutosave.cancel();
            history.push('/app/compliance-v2/drafts?type=all');
          }

          return res;
        } else {
          handleError({
            error: res.error,
            message: 'Compliance report draft creation failed.'
          });
          rollbar.error('Compliance report draft creation failed.', res.error);
        }
      })
      .catch(err => {
        handleError({
          error: err,
          message: 'Compliance report draft creation failed.'
        });
        rollbar.error('Compliance report draft creation failed.', err);
      })
      .finally();
  };

  const onPublishDraft = () => {
    if (isUpdating.current) {
      console.log('is updating will retry', isUpdating.current);
      setTimeout(() => {
        onPublishDraft();
      }, 300);
      return;
    }

    const newQuestions = sectionsToQuestions({ sections: sectionsRef.current });
    const report = {
      id,
      userId,
      franchiseeLocationId,
      questions: newQuestions,
      highlightNotes: notesRef.current.highlightNotes,
      lowlightNotes: notesRef.current.lowlightNotes,  
      readRecipients,

      // When publishing a draft
      isDraft: false
    };

    publishDraft(report)
      .then((res) => {
        if (!res?.error) {
          // throw new Error();
          debouncedAutosave.cancel();
          enqueueSnackbar('Compliance form draft published', {
            variant: 'success'
          });
          history.push('/app/compliance-v2/reports?status=all');
        } else {
          handleError({
            error: res.error,
            message: 'Compliance report draft publish failed.'
          });
          rollbar.error('Compliance report draft publish failed.', res.error);
        }
      })
      .catch(err => {
        handleError({
          error: err,
          message: 'Compliance report draft publish failed.'
        });
        rollbar.error('Compliance report draft publish failed.', err);
      })
      .finally();
  };

  const toggleQuestionPass = ({ sectionId = '', questionId = '' } = {}) => {
    if (sectionId && questionId) {
      const sections = sectionsRef.current;
      const sectionIndex = sections.findIndex(({ section: { id = '' } = {} } = {}) => id === sectionId);

      if (sectionIndex >= 0) {
        const { questions = [] } = sections[sectionIndex];
        const questionIndex = questions.findIndex(({ id = '' } = {}) => id === questionId);

        if (questionIndex >= 0) {
          const newSections = [...sections];
          newSections[sectionIndex].questions[questionIndex].pass = !newSections[sectionIndex].questions[questionIndex]
            .pass;

          sectionsRef.current = newSections;
        }
      }
    }
  };

  const toggleQuestionIsNotApplicable = ({ sectionId = '', questionId = '' } = {}) => {
    if (sectionId && questionId) {
      const sections = sectionsRef.current;
      const sectionIndex = sections.findIndex(({ section: { id = '' } = {} } = {}) => id === sectionId);

      if (sectionIndex >= 0) {
        const { questions = [] } = sections[sectionIndex];
        const questionIndex = questions.findIndex(({ id = '' } = {}) => id === questionId);

        if (questionIndex >= 0) {
          const newSections = [...sections];
          const prevValue = newSections[sectionIndex].questions[questionIndex].isNotApplicable || false;
          newSections[sectionIndex].questions[questionIndex].isNotApplicable = !prevValue;

          sectionsRef.current = newSections;
        }
      }
    }
  };

  const addQuestionNoMarkedReason = ({ sectionId = '', questionId = '', comment = '' } = {}) => {
    if (sectionId && questionId) {
      const sections = sectionsRef.current;
      const sectionIndex = sections.findIndex(({ section: { id = '' } = {} } = {}) => id === sectionId);

      if (sectionIndex >= 0) {
        const { questions = [] } = sections[sectionIndex];
        const questionIndex = questions.findIndex(({ id = '' } = {}) => id === questionId);

        if (questionIndex >= 0) {
          const newSections = [...sections];
          newSections[sectionIndex].questions[questionIndex].comment = comment;

          sectionsRef.current = newSections;
        }
      }
    }
    isUpdating.current = false;
  };

  const loading_form = id ? loading_complianceReport : loading_questions;

  const updateSections = ({ sections }) => {
    sectionsRef.current = sections;

    const { type } = sections[0]?.questions[0];

    if (type) {
      const orderKey = type === 'restaurant' ? 'orderInRestaurant' : 'orderInTruck';
      const newSections = sections.map(({ section: { id, name, orderInRestaurant, orderInTruck } }, index) => ({
        id,
        name,
        orderInRestaurant,
        orderInTruck,
        [orderKey]: index + 1
      }));

      bulkUpdateComplianceReportSections({ sections: newSections });
    }
  };

  const updateQuestions = ({ sectionId, question }) => {
    const sections = sectionsRef.current;
    const sectionIndex = sections.findIndex(({ section: { id = '' } = {} } = {}) => id === sectionId);
    const section = sections[sectionIndex];

    if (sectionIndex >= 0) {
      const newQuestions = section?.questions.map((q, index) => {

        if (question.id === q.id) {
          return question;
        }

        return q;
      });
      const newSections = sections.map((section, index) => {
        if (index === sectionIndex) {
          return { ...section, questions: newQuestions };
        }

        return section;
      });

      sectionsRef.current = newSections;

      // const newQuestions = newSections
      //   .reduce((prev = [], { questions = [] } = {}) => {
      //     return [...prev, ...questions];
      //   }, [])
      //   .map(({ id, question, sectionId, type, weight } = {}, index) => ({
      //     id,
      //     question,
      //     sectionId,
      //     type,
      //     weight,
      //     order: index + 1
      //   }));

      // bulkUpdateComplianceReportQuestions({ questions: newQuestions });
    }
  };

  const updateIsOrganizing = isOrganizing => {
    setContextValue(prev => ({ ...prev, isOrganizing }));
  };

  if (loading_form) {
    return <Loading text={loadingText} height={500} />;
  }

  return (
    <ComplianceReportFormContext.Provider
      value={{
        id,
        locationType,
        submitButtonLabel,
        isInitializing,
        isSubmitting,
        hasError,
        loading_createDraft,
        loading_publishDraft,
        sections,
        questionsCount,
        organizable,
        isOrganizing,
        isDraft,
        isUpdatingRef: isUpdating,
        notesRef,
        localHighlightNotes,
        localLowlightNotes,
        updateSections,
        updateQuestions,
        updateIsOrganizing,
        toggleQuestionPass,
        toggleQuestionIsNotApplicable,
        addQuestionNoMarkedReason,
        onSaveDraft,
        onPublishDraft,
        autosaveReport,
        debouncedAutosave,
        autosaveReport,
        autosaveRef
      }}
    >
      {children}
    </ComplianceReportFormContext.Provider>
  );
};

export const useComplianceReportFormContext = () => {
  return useContext(ComplianceReportFormContext);
};
