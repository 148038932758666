import { useContext } from 'react';

import { EMPLOYEE, FRANCHISEE_OWNER, FULFILLMENT, MANAGER, SUPER_ADMIN } from 'src/constants';
import {
  adminPermissions,
  franchiseePermissions,
  managerPermissions,
  employeePermissions,
  fulfillmentPermissions
} from 'src/permissions';
import { FirebaseContext } from './Firebase.context';

export const useFirebaseContext = () => {
  return useContext(FirebaseContext);
};

export const useCurrentUser = () => {
  const { user } = useFirebaseContext();

  const {
    id = null,
    avatar = '',
    email = '',
    firstName: firstNameVal = '',
    lastName: lastNameVal = '',
    mobile = '',
    timezone,
    tier = 'Premium',
    role = FULFILLMENT,
    permissions: _permissions,
    disabled = false,
    territoryIds = [],
    franchiseeLocationId = '',
    location = '',
    createdAt
  } = user;

  const firstName = firstNameVal.trim();
  const lastName = lastNameVal.trim();
  const name = `${firstName} ${lastName}`.trim() || email;

  let roleName = '';
  let permissions = _permissions;

  if (!permissions) {
    switch (role) {
      case SUPER_ADMIN: {
        permissions = adminPermissions;
        roleName = 'Super Admin';
        break;
      }

      case FRANCHISEE_OWNER: {
        permissions = franchiseePermissions;
        roleName = 'Franchisee Owner';
        break;
      }

      case MANAGER: {
        permissions = managerPermissions;
        roleName = 'Manager';
        break;
      }

      case EMPLOYEE: {
        permissions = employeePermissions;
        roleName = 'Employee';
        break;
      }

      case FULFILLMENT: {
        permissions = fulfillmentPermissions;
        roleName = 'Fulfillment';
        break;
      }
    }
  }

  return {
    id,
    avatar,
    email,
    firstName,
    lastName,
    name,
    mobile,
    timezone,
    tier,
    role,
    roleName,
    permissions,
    disabled,
    territoryIds,
    franchiseeLocationId,
    location,
    createdAt
  };
};
