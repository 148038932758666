import React, { useRef, useState } from 'react';
import { Box, CardContent, Checkbox, Grid, styled, Switch, Typography, useTheme } from '@material-ui/core';

import { ImageSelector } from 'src/components';
import { useComplianceReportFormContext } from './compliance-report-form.context';
import { QuestionImage } from './question-image';
import { QuestionComment } from './question-comment';
import { storage } from 'src/lib/firebase';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';

const QuestionContainer = styled(Box)({
  '&:hover': {
    backgroundColor: 'unset'
  }
});

export const Question = ({
  sectionId,
  question,
  questionIndex
}) => {
  const [localQuestion, setLocalQuestion] = useState({ ...question });
  const theme = useTheme();
  const {
    id: reportId,
    toggleQuestionPass,
    toggleQuestionIsNotApplicable,
    addQuestionNoMarkedReason,
    updateQuestions,
    isOrganizing,
    isUpdatingRef
  } = useComplianceReportFormContext();
  const {
    id: questionId,
    question: questionText,
    pass,
    isNotApplicable = false,
    images: questionImages,
    comment = ''
  } = localQuestion;
  const images = questionImages?.length
    ? questionImages
    : (questionImages?.file || questionImages?.url)
    ? [questionImages]
    : [];
  const imagesRef = useRef([...images]);
  const questionRef = useRef({ ...localQuestion });

  const handleSelectFile = async ({ file, resizeFile, questionIndex }) => {
    const images = imagesRef.current;
    const imageId = images.length ? images.length + 1 : 1;
    const questionImages = [
      ...images,
      { fileName: file.name, imageId }
    ];

    imagesRef.current = questionImages;
    setLocalQuestion((prev) => ({
      ...prev,
      images: questionImages
    }))

    resizeAndUploadFile({ file, imageId, resizeFile, questionIndex })
  }

  const resizeAndUploadFile = async ({ file, imageId, resizeFile, questionIndex }) => {
    try {
      const name = `compliance_reports/${reportId}/${file.name}`;
      const storageRef = ref(storage, name);
      const image = await resizeFile(file);

      const uploadTask = await uploadString(storageRef, image, 'data_url');
      const url = await getDownloadURL(uploadTask.ref);
      const imageData = { fileName: file.name, url };

      updateQuestionImages({ imageData, imageId, questionIndex });
    } catch (e) {
      console.log('resizeAndUploadFile error', e);

      handleRemoveImage({ image: { imageId } })
    }
  };

  const updateQuestionImages = ({ imageData, imageId }) => {
    const newImages = imagesRef.current?.map(img => {
      if (img.imageId === imageId) {
        return imageData
      }

      return img;
    });

    imagesRef.current = newImages;
    updateQuestions({ sectionId, question: { ...questionRef.current, images: newImages } });
    setLocalQuestion((prev) => ({
      ...prev,
      images: newImages
    }));
  }

  const handleQuestionPassToggle = () => {
    questionRef.current.pass = !questionRef.current.pass;
    setLocalQuestion(prev => ({
      ...prev,
      pass: !prev.pass
    }));
    toggleQuestionPass({ sectionId, questionId });
  }


  const handleQuestionNotApplicableCheckbox = () => {
    questionRef.current.isNotApplicable = !questionRef.current.isNotApplicable;
    setLocalQuestion(prev => ({
      ...prev,
      isNotApplicable: !prev.isNotApplicable
    }));
    toggleQuestionIsNotApplicable({ sectionId, questionId });
  }

  const handleQuestionCommentOnChange = (text) => {
    questionRef.current.comment = text;
    setLocalQuestion(prev => ({
      ...prev,
      comment: text
    }));
    addQuestionNoMarkedReason({
      sectionId,
      questionId,
      comment: text
    });
  }

  const handleRemoveImage = ({ image, url }) => {
    const images = imagesRef.current;
    const newImages = images
      .filter(img => {
        if (img.url) {
          return img.url !== url;
        }

        return img.imageId !== image.imageId;
      });

    imagesRef.current = newImages;
    updateQuestions({ sectionId, question: { ...questionRef.current, images: newImages }});
    setLocalQuestion((prev) => ({
      ...prev,
      images: newImages
    }));
  }

  return (
    <QuestionContainer>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start" justify="space-between">
              <Grid item style={{ flex: 1 }}>
                <Typography variant="overline" color="textSecondary">
                  {questionText}
                </Typography>
              </Grid>

              <Grid item style={(isOrganizing || isNotApplicable) ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item>
                    <Typography variant="overline" color="textSecondary">
                      No
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Switch
                      checked={pass}
                      onChange={handleQuestionPassToggle}
                    />
                  </Grid>

                  <Grid item>
                    <Typography variant="overline" color="textSecondary">
                      Yes
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item>
                    <Checkbox
                      checked={isNotApplicable}
                      name="questionIsNotApplicable"
                      onChange={handleQuestionNotApplicableCheckbox}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="overline" color="textSecondary">
                      N/A
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={isNotApplicable ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
            <Grid container spacing={2}>
              <Grid item style={isOrganizing ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                <ImageSelector
                  onSelectFile={({ file, resizeFile }) => {
                    handleSelectFile({ file, resizeFile, questionIndex })
                  }}
                />
              </Grid>

              <QuestionComment
                comment={comment}
                pass={pass}
                updateComment={text => handleQuestionCommentOnChange(text)}
                isUpdatingRef={isUpdatingRef}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {images.map((image, index) => {
              const imgUrl = image && image.file
                ? URL.createObjectURL(image.file)
                : image && image.url
                ? image.url
                : '';

              return (
                <QuestionImage
                  imgUrl={imgUrl}
                  key={`${image.url}-${index}`}
                  onButtonPress={url => {
                    handleRemoveImage({ image, url })
                  }}
                />
              )
            })}
          </Grid>
        </Grid>
      </CardContent>
    </QuestionContainer>
  )
};
