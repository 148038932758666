import moment from 'moment';

export const dateRangeOptions = [
  {
    value: 'custom',
    label: 'Custom'
  },
  {
    value: 'today',
    label: 'Today'
  },
  {
    value: 'yesterday',
    label: 'Yesterday'
  },
  {
    value: 'week',
    label: 'Week'
  },
  {
    value: 'last7',
    label: 'Last 7 Days'
  },
  {
    value: 'last30',
    label: 'Last 30 Days'
  },
  {
    value: 'last90',
    label: 'Last 90 Days'
  },
  {
    value: 'weekToDate',
    label: 'Week To Date'
  },
  {
    value: 'monthToDate',
    label: 'Month To Date'
  },
  {
    value: 'yearToDate',
    label: 'Year To Date'
  },
  {
    value: 'lastMonth',
    label: 'Last Month'
  },
  {
    value: 'lastYear',
    label: 'Last Year'
  }
];

export const getDateRange = dateRange => {
  switch (dateRange) {
    case 'today': {
      return {
        start: moment()
          .clone()
          .startOf('day'),
        end: moment()
          .clone()
          .endOf('day')
      };
    }

    case 'yesterday': {
      return {
        start: moment()
          .clone()
          .subtract(1, 'day')
          .startOf('day'),
        end: moment()
          .clone()
          .subtract(1, 'day')
          .endOf('day')
      };
    }

    case 'week': {
      return {
        start: moment()
          .clone()
          .startOf('isoWeek'),
        end: moment()
          .clone()
          .endOf('isoWeek')
      };
    }

    case 'last7': {
      return {
        start: moment()
          .clone()
          .subtract(7, 'day')
          .startOf('day'),
        end: moment()
          .clone()
          .endOf('day')
      };
    }

    case 'last30': {
      return {
        start: moment()
          .clone()
          .subtract(30, 'day')
          .startOf('day'),
        end: moment()
          .clone()
          .endOf('day')
      };
    }

    case 'last90': {
      return {
        start: moment()
          .clone()
          .subtract(90, 'day')
          .startOf('day'),
        end: moment()
          .clone()
          .endOf('day')
      };
    }

    case 'lastMonth': {
      return {
        start: moment()
          .clone()
          .subtract(1, 'months')
          .startOf('month'),
        end: moment()
          .clone()
          .subtract(1, 'months')
          .endOf('month')
      };
    }

    case 'lastYear': {
      return {
        start: moment()
          .clone()
          .subtract(1, 'years')
          .startOf('year'),
        end: moment()
          .clone()
          .subtract(1, 'years')
          .endOf('year')
      };
    }

    case 'weekToDate': {
      return {
        start: moment()
          .clone()
          .startOf('isoWeek'),
        end: moment()
          .clone()
          .endOf('day')
      };
    }

    case 'monthToDate': {
      return {
        start: moment()
          .clone()
          .startOf('month'),
        end: moment()
          .clone()
          .endOf('day')
      };
    }

    case 'yearToDate': {
      return {
        start: moment()
          .clone()
          .startOf('year'),
        end: moment()
          .clone()
          .endOf('day')
      };
    }

    default:
      return {
        start: null,
        end: null
      };
  }
};
