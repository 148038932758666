import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import _ from 'lodash';

export const QuestionComment = ({ comment = "", pass, updateComment }) => {
  const [text, setText] = useState(comment);

  const debouncedQuestionUpdate = useCallback(_.debounce(text => {
    updateComment(text);
  }, 150), []);

  const placeholder = `Add reason why marked as "${pass ? 'Yes' : 'No'}"`;

  return (
    <Grid item style={{ flex: 1 }}>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        label="Comment"
        placeholder={placeholder}
        InputProps={{ style: { minHeight: 80, alignItems: 'flex-start' } }}
        value={text}
        onChange={e => {
          e.persist();
          setText(e.target.value);
          debouncedQuestionUpdate(e.target.value);
        }}
      />
    </Grid>
  );
};
