import { useFetchTrainingVideoCategories } from './video-categories.hook';
import { useFetchTrainingVideoQuery, useFetchTrainingVideosQuery } from './videos.api';

const getVideoDetails = ({ video, categories: allCategories }) => {
  const { categoryIds = [] } = video;
  const categories = allCategories.filter(({ id }) => categoryIds.includes(id));

  return { ...video, categories };
};

export const useFetchTrainingVideos = ({ category }) => {
  const { data: videos = [], isLoading: loading_videos, ...rest } = useFetchTrainingVideosQuery();
  const { data: categories = [], isLoading: loading_categories } = useFetchTrainingVideoCategories();

  let data = videos.map(video => getVideoDetails({ video, categories }));

  if (category && category !== 'all') {
    data = data.filter(({ categories }) => categories.filter(({ name }) => name === category).length > 0);
  }

  return { data, isLoading: loading_videos || loading_categories, ...rest };
};

export const useFetchTrainingVideo = ({ id }) => {
  const { data: video = {}, isLoading: loading_video, ...rest } = useFetchTrainingVideoQuery({ id });
  const { data: categories = [], isLoading: loading_categories } = useFetchTrainingVideoCategories();

  const data = getVideoDetails({ video, categories });

  return { data, isLoading: loading_video || loading_categories, ...rest };
};
