import React from 'react';
import { Box, IconButton, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { useNotificationsContext } from './notifications.context';

export const NotificationsPagination = () => {
  const { title: _title, page, rowsPerPage, list, paginatedList, updateContext } = useNotificationsContext();

  const onNext = () => {
    updateContext({ page: page + 1 });
  };

  const onPrev = () => {
    updateContext({ page: page - 1 });
  };

  const title = `${_title}${list.length > 0 ? ' (' + list.length + ')' : ''}`;

  return (
    <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5" color="textPrimary">
        {title}
      </Typography>

      <Box>
        {page > 0 && (
          <Tooltip title="Previous">
            <IconButton onClick={onPrev}>
              <SvgIcon fontSize="small">
                <ChevronLeft />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}

        {list.length > (page + 1) * rowsPerPage + paginatedList.length && (
          <Tooltip title="Next">
            <IconButton onClick={onNext}>
              <SvgIcon fontSize="small">
                <ChevronRight />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
