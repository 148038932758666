import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';

import { TabFilter } from 'src/components';
import { usePageContext } from './context';

export const Tab = () => {
  const history = useHistory();
  const { tabs: _tabs, currentTab, handleChangePage } = usePageContext();
  const classes = useStyles();

  const tabs = _tabs.map(item => ({ value: item, label: item }));

  return tabs.map(item => item.value).includes(currentTab) ? (
    <TabFilter
      value={currentTab}
      tabs={tabs}
      onChange={value => {
        history.push(`?category=${value}`);
        handleChangePage(null, 0);
      }}
      classes={{ scrollButtons: classes.scrollButtons }}
    />
  ) : null;
};

const useStyles = makeStyles({
  scrollButtons: {
    '&.MuiTabScrollButton-root.Mui-disabled': {
      opacity: 0.2
    }
  }
});
