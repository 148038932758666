import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const AutocompleteSorter = () => {
  return (
    <Autocomplete
      options={[]}
      renderInput={params => <TextField {...params} label="Select option" variant="outlined" />}
    />
  );
};
