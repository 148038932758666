import React from 'react';
import { Card, Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { Table } from './table';
import { PageContextProvider } from './context';

export const ComplianceMysteryShopperReportsPage = () => {
  return (
    <DashboardPageContainer title="Mystery Shopper Reports" maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Mystery Shopper Reports',
                link: '/app/compliance-v2/mystery-shopper-reports'
              }
            ]}
            title={'Reports'}
            createButton={{ label: 'New Report', link: '/app/compliance-v2/mystery-shopper-reports/new' }}
          />
        </Grid>

        <PageContextProvider>
          <Grid item xs={12}>
            <Card>
              <Table />
            </Card>
          </Grid>
        </PageContextProvider>
      </Grid>
    </DashboardPageContainer>
  );
};
