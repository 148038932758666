import React from 'react';
import { Box, Card, Typography, fade, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    backgroundColor: fade(theme.palette.info.main, 0.1)
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.info.main,
    color: '#FFF',
    height: 48,
    width: 48,
    borderRadius: '50%'
  }
}));

export const FulfillmentNote = ({ note = 'No fulfillment note' }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Info className={classes.avatar} />

      <Box flexGrow={1}>
        <Typography variant="overline" color="textPrimary">
          Fulfillment Notes
        </Typography>

        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography gutterBottom variant="h4" color="textSecondary">
            {note}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
