import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from 'react-feather';
import { useCurrentUser, useFirebaseContext } from 'src/contexts/firebase-context';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const ref = useRef(null);
  const [announcements, setAnnouncements] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [hasUnreadAnnouncementNotifications, setHasUnreadAnnouncementNotifications] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const {
    getRecentAnnouncementNotifications,
    markAllAnnouncementNotificationsRead,
    checkForUnreadAnnouncementNotification,
    userReadAnnouncementNotifications,
    getNextPaginationAnnouncementNotifications,
    getPreviousPaginationAnnouncementNotifications
  } = useFirebaseContext();
  const user = useCurrentUser();

  const handleOpen = () => {
    setOpen(true);
    checkForUnreadAnnouncementNotification(user.id)
      .then(check => setHasUnreadAnnouncementNotifications(check))
      .catch(err => console.error(err));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = useCallback(async () => {
    try {
      const response = await getRecentAnnouncementNotifications();
      const check = await checkForUnreadAnnouncementNotification(user.id);

      if (isMountedRef.current) {
        setAnnouncements(response.docs);
        setHasUnreadAnnouncementNotifications(check);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getData();
  }, [getData]);

  // useEffect(() => {
  //   // Make call to set user id readRecipients
  //   userReadAnnouncementNotifications(announcements, user.id)
  //     .then(() =>
  //       checkForUnreadAnnouncementNotification(user.id)
  //         .then(check => {
  //           setHasUnreadAnnouncementNotifications(check);
  //         })
  //         .catch(err => console.error(err))
  //     )
  //     .catch(err => console.error(err));
  // }, []);

  return (
    <>
      <Tooltip title="Announcements">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={() => {
            handleOpen();
            userReadAnnouncementNotifications(announcements, user.id)
              .then(() =>
                checkForUnreadAnnouncementNotification(user.id)
                  .then(check => {
                    setHasUnreadAnnouncementNotifications(check);
                  })
                  .catch(err => console.error(err))
              )
              .catch(err => console.error(err));
          }}
        >
          {hasUnreadAnnouncementNotifications ? (
            <Badge variant="dot" color="error">
              <SvgIcon>
                <BellIcon />
              </SvgIcon>
            </Badge>
          ) : (
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color="textPrimary">
            Announcements
          </Typography>
          <Box>
            {currentPagination > 1 && (
              <Tooltip title="Previous">
                <IconButton
                  onClick={async () => {
                    const paginatedNotifications = await getPreviousPaginationAnnouncementNotifications(
                      announcements[0]
                    );
                    if (paginatedNotifications.docs.length) {
                      setCurrentPagination(prev => prev - 1);
                      setAnnouncements(paginatedNotifications.docs);
                      userReadAnnouncementNotifications(paginatedNotifications.docs, user.id)
                        .then(() =>
                          checkForUnreadAnnouncementNotification(user.id)
                            .then(check => {
                              setHasUnreadAnnouncementNotifications(check);
                            })
                            .catch(err => console.error(err))
                        )
                        .catch(err => console.error(err));
                    }
                  }}
                >
                  <SvgIcon fontSize="small">
                    <ChevronLeftIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Next">
              <IconButton
                onClick={async () => {
                  const paginatedNotifications = await getNextPaginationAnnouncementNotifications(
                    announcements[announcements.length - 1]
                  );

                  if (paginatedNotifications.docs.length) {
                    setCurrentPagination(prev => prev + 1);
                    setAnnouncements(paginatedNotifications.docs);
                    userReadAnnouncementNotifications(paginatedNotifications.docs, user.id)
                      .then(() =>
                        checkForUnreadAnnouncementNotification(user.id)
                          .then(check => {
                            setHasUnreadAnnouncementNotifications(check);
                          })
                          .catch(err => console.error(err))
                      )
                      .catch(err => console.error(err));
                  }
                }}
              >
                <SvgIcon fontSize="small">
                  <ChevronRightIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider />
        {announcements.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {announcements.map(doc => {
                const { createdAt, announcement } = doc.data();
                return (
                  <ListItem component={RouterLink} divider key={doc.id} to="#">
                    <ListItemText
                      primary={announcement}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary'
                      }}
                      secondary={moment.unix(createdAt.seconds).fromNow()}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box p={3} display="flex" justifyContent="center">
              <Button
                onClick={() => {
                  markAllAnnouncementNotificationsRead(user.id)
                    .then(() => {
                      checkForUnreadAnnouncementNotification(user.id)
                        .then(check => setHasUnreadAnnouncementNotifications(check))
                        .catch(err => console.error(err));
                    })
                    .catch(err => console.error(err));
                }}
                size="small"
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
