import React from 'react';
import { Grid } from '@material-ui/core';

import { UserBasicInfo } from './user-basic-info';
import { GeneralSettingsForm } from './general-settings-form/general-settings-form';

export const GeneralSettings = () => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <UserBasicInfo />
      </Grid>

      <Grid item lg={8} md={6} xl={9} xs={12}>
        <GeneralSettingsForm />
      </Grid>
    </Grid>
  );
};
