import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TableBody, TableCell, TableRow, Checkbox } from '@material-ui/core';

import { useEnhancedTableContext } from './enhanced-table.context';

export const EnhancedTableBody = () => {
  const {
    columns,
    isPaginated,
    isDraggable,
    isCheckable,
    sortedRows,
    paginatedRows,
    selected,
    updateTableStatus,
    handleDragEnd: _handleDragEnd
  } = useEnhancedTableContext();
  const [isDragging, setIsDragging] = useState(false);

  const isSelected = id => selected.indexOf(id) !== -1;

  const onRow = id => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    updateTableStatus({ selected: newSelected });
  };

  const handleDragEnd = (result, provided) => {
    setIsDragging(false);

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    _handleDragEnd(result);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={() => setIsDragging(true)}>
      <Droppable droppableId="droppable">
        {droppableProvided => (
          <>
            <TableBody ref={droppableProvided.innerRef}>
              {(isPaginated ? paginatedRows : sortedRows).map((row, rowIndex) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <Draggable
                    key={`enhanced-table-draggable-row-${row.id}`}
                    draggableId={row.id}
                    index={rowIndex}
                    isDragDisabled={!isDraggable}
                  >
                    {(draggableProvided, snapshot) => {
                      return (
                        <>
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            hover
                            role="checkbox"
                            selected={isItemSelected}
                            onClick={isCheckable ? () => onRow(row.id) : () => {}}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none'
                            }}
                          >
                            {isCheckable && !isDraggable && (
                              <LockedCell isDragOccurring={isDragging} padding="checkbox">
                                <Checkbox color="secondary" checked={isItemSelected} />
                              </LockedCell>
                            )}

                            {columns.map(({ id, label, render, sorter, filter, ...rest }, colIndex) => {
                              let cellProps = rest || {};
                              let style = cellProps.style || {};

                              cellProps = {
                                ...cellProps,
                                scope: cellProps.scope ? cellProps.scope : 'row',
                                style: {
                                  ...style,
                                  paddingLeft: style.paddingLeft ? style.paddingLeft : 8,
                                  paddingRight: style.paddingRight ? style.paddingRight : 8,
                                  paddingTop: style.paddingTop ? style.paddingTop : 4,
                                  paddingBottom: style.paddingBottom ? style.paddingBottom : 4
                                }
                              };

                              return (
                                <LockedCell
                                  isDragOccurring={isDragging}
                                  key={`enhanced-table-row-${rowIndex}-col-${colIndex}`}
                                  {...cellProps}
                                >
                                  {render ? render(row) : row[id]}
                                </LockedCell>
                              );
                            })}
                          </TableRow>
                        </>
                      );
                    }}
                  </Draggable>
                );
              })}

              {droppableProvided.placeholder}
            </TableBody>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

class LockedCell extends React.Component {
  ref;

  getSnapshotBeforeUpdate(prevProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting = this.props.isDragOccurring && !prevProps.isDragOccurring;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height
    };

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragOccurring) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty('height');
    ref.style.removeProperty('width');
  }

  setRef = ref => {
    this.ref = ref;
  };

  render() {
    const { isDragOccurring, ...rest } = this.props;

    return (
      <TableCell ref={this.setRef} {...rest}>
        {this.props.children}
      </TableCell>
    );
  }
}
