import React from 'react';
import { useHistory } from 'react-router';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Edit, ArrowRight } from 'react-feather';
import moment from 'moment';

import {
  useBulkDeleteComplianceReportsMutation,
  useFetchComplianceReports,
  useFetchFranchiseeLocations,
  useFetchTerritories
} from 'src/apis';
import { ActionsCell, EnhancedTable, LabelCell } from 'src/components';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { COMPLIANCE_REPORT_STATUS_MAP, SUPER_ADMIN } from 'src/constants';
import { usePageContext } from './context';
import { checkPermissions, CREATE_COMPLIANCE_REPORTS, EDIT_COMPLIANCE_REPORTS } from 'src/permissions';

export const Table = () => {
  const history = useHistory();
  const { permissions } = useCurrentUser();
  const {
    data: reports = { all: [], dnq: [], passed: [], failed: [] },
    isLoading: loading_reports
  } = useFetchComplianceReports({ where: ['status', '==', COMPLIANCE_REPORT_STATUS_MAP.PUBLISHED] });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: franchiseeLocations = [], isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();
  const { currentTab } = usePageContext();
  const [bulkDeleteReports, { isLoading: loading_bulkDeleteReports }] = useBulkDeleteComplianceReportsMutation();

  const territoryOptions = territories.map(({ id: value, name: label }) => ({ value, label }));
  const franchiseeLocationOptions = franchiseeLocations.map(({ id: value, name: label }) => ({ value, label }));

  const columns = [
    {
      id: 'serialNoCol',
      label: 'Report #',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      },
      sorter: (row1, row2) => {
        if (row2['serialNo'] < row1['serialNo']) {
          return -1;
        }

        if (row2['serialNo'] > row1['serialNo']) {
          return 1;
        }

        return 0;
      },
      filter: false
    },
    {
      id: 'dateCol',
      label: 'Date Created',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      },
      sorter: (row1, row2) => {
        if (row2['createdAtTimestamp'] < row1['createdAtTimestamp']) {
          return -1;
        }

        if (row2['createdAtTimestamp'] > row1['createdAtTimestamp']) {
          return 1;
        }

        return 0;
      },
      filter: {
        type: 'date',
        func: value => row => {
          const { start, end } = value ? value : { start: null, end: null };

          if (!start || !end) return true;

          const columnValue = moment.unix(row.createdAtTimestamp);

          return columnValue > start && columnValue < end;
        }
      }
    },
    {
      id: 'territoryCol',
      label: 'Territory',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: territoryOptions
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.territoryId);
        }
      }
    },
    {
      id: 'franchiseeLocationCol',
      label: 'Franchisee Location',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: franchiseeLocationOptions
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.franchiseeLocationId);
        }
      }
    },
    {
      id: 'franchiseeTypeCol',
      label: 'Franchisee Type',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: [
            {
              value: 'truck',
              label: 'Truck'
            },
            {
              value: 'restaurant',
              label: 'Restaurant'
            }
          ]
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.franchiseeLocationType);
        }
      }
    },
    {
      id: 'scoreCol',
      label: 'Score',
      render: ({ result, score, scoreName }) => (
        <LabelCell color={result === 'dnq' ? 'error' : score >= 60 ? 'success' : 'warning'} label={scoreName} />
      ),
      sorter: (row1, row2) => {
        if (row2['score'] < row1['score']) {
          return -1;
        }

        if (row2['score'] > row1['score']) {
          return 1;
        }

        return 0;
      },
      filter: false
    },
    {
      id: 'resultCol',
      label: 'Result',
      render: ({ result, resultName }) => (
        <LabelCell color={result === 'dnq' ? 'error' : result === 'pass' ? 'success' : 'warning'} label={resultName} />
      ),
      sorter: false,
      filter: false
    },
    {
      id: 'actionsCol',
      label: 'Actions',
      align: 'center',
      render: ({ actionsCol }) => <ActionsCell actions={actionsCol} />,
      sorter: false,
      filter: false
    }
  ];

  const rows = (reports[currentTab] || []).map(item => {
    const {
      id: reportId = '',
      scoreName: scoreCol,
      resultName: resultCol,
      createdAtTimestampString: dateCol,
      territoryName: territoryCol,
      franchiseeLocationName: franchiseeLocationCol,
      franchiseeLocationTypeName: franchiseeTypeCol,
      franchiseeLocationId,
      serialNo: serialNoCol
    } = item;

    let actionsCol = [
      {
        icon: <ArrowRight />,
        onClick: event => {
          event.stopPropagation();
          history.push(`/app/compliance-v2/reports/${reportId}/view/${franchiseeLocationId}`);
        }
      }
    ];

    if (checkPermissions(permissions, EDIT_COMPLIANCE_REPORTS)) {
      actionsCol = [
        {
          icon: <Edit />,
          onClick: event => {
            event.stopPropagation();
            history.push(`/app/compliance-v2/reports/${reportId}/edit/${franchiseeLocationId}`);
          }
        },
        ...actionsCol
      ];
    }

    const row = {
      ...item,
      serialNoCol,
      dateCol,
      territoryCol,
      franchiseeLocationCol,
      franchiseeTypeCol,
      scoreCol,
      resultCol,
      actionsCol
    };

    return row;
  });

  const tableActionsComponentProps = [
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={loading_bulkDeleteReports ? <CircularProgress size={16} color="inherit" /> : <Delete />}
          disabled={loading_bulkDeleteReports}
          onClick={() => {
            const ids = rows.map(row => row.id);

            bulkDeleteReports({ ids });
          }}
        >
          Delete
        </Button>
      )
    }
  ];

  let tableEmptyProps = {
    description:
      currentTab === 'passed'
        ? 'No passed report yet.'
        : currentTab === 'failed'
        ? 'No failed report yet.'
        : currentTab === 'dnq'
        ? 'No DNQ report yet.'
        : ''
  }

  if (checkPermissions(permissions, CREATE_COMPLIANCE_REPORTS)) {
    tableEmptyProps = {
      buttonLabel: 'Create new report',
      onClick: () => history.push(`/app/compliance-v2/reports/new/select-franchisee-location`),
      ...tableEmptyProps
    }
  }

  return (
    <EnhancedTable
      title={rows.length > 0 ? 'Select rows to delete' : ''}
      columns={columns}
      rows={rows}
      tableActionsComponentProps={tableActionsComponentProps}
      order="desc"
      orderBy="dateCol"
      loading={{
        isLoading: loading_reports || loading_territories || loading_franchiseeLocations,
        text:
          currentTab === 'passed'
            ? 'Loading passed reports...'
            : currentTab === 'failed'
            ? 'Loading failed reports...'
            : currentTab === 'dnq'
            ? 'Loading DNQ reports...'
            : ''
      }}
      empty={tableEmptyProps}
      isCheckable
    />
  );
};
