import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useQueryValues } from 'src/hooks/route.hook';

export const PageContext = createContext();

export const PageContextProvider = ({ children }) => {
  const history = useHistory();
  const { status: currentTab } = useQueryValues(['status']);

  const isValidTab = ['all', 'passed', 'failed', 'dnq'].includes(currentTab);

  useEffect(() => {
    if (!isValidTab) {
      history.push('?status=all');
    }
  }, [isValidTab]);

  return <PageContext.Provider value={{ currentTab }}>{children}</PageContext.Provider>;
};

export const usePageContext = () => {
  return useContext(PageContext);
};
