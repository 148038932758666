import React, { useState } from 'react';
import { Badge, IconButton, Popover } from '@material-ui/core';
import { DateRange, FilterList, Search } from '@material-ui/icons';

import {
  EnhancedTableCheckboxFilter,
  EnhancedTableDateFilter,
  EnhancedTableTextFilter
} from './enhanced-table-filters';
import { useEnhancedTableContext } from './enhanced-table.context';

export const HeadFilterButton = ({ filter, columnId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters, updateTableStatus } = useEnhancedTableContext();

  const Icon =
    filter === true ? Search : filter.type === 'checkbox' ? FilterList : filter.type === 'date' ? DateRange : Search;

  const value = filters[columnId]?.value || null;

  let isFiltered = !!value;

  if (Array.isArray(value)) {
    isFiltered = value.length !== 0;
  }

  const onChange = value => {
    const newFilters = { ...(filters || {}) };

    newFilters[columnId] = { ...newFilters[columnId], value };

    updateTableStatus({ filters: newFilters });
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const FilterComponent =
    filter === true
      ? EnhancedTableTextFilter
      : filter.component ||
        (filter.type === 'checkbox'
          ? EnhancedTableCheckboxFilter
          : filter.type === 'date'
          ? EnhancedTableDateFilter
          : EnhancedTableTextFilter);

  const filterComponentProps = {
    value,
    onChange,
    onClose,
    ...(filter === true ? {} : filter.componentProps || {})
  };

  return (
    <>
      <IconButton component="span" size="small" onClick={e => setAnchorEl(e.currentTarget)}>
        <Badge
          color="secondary"
          variant="dot"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          invisible={!isFiltered}
        >
          <Icon fontSize="inherit" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <FilterComponent {...filterComponentProps} />
      </Popover>
    </>
  );
};
