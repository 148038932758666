import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useNotificationsContext } from './notifications.context';

export const NotificationsList = () => {
  const { list, paginatedList } = useNotificationsContext();

  if (list.length === 0) {
    return (
      <Box p={2} minHeight={100} display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6" color="textSecondary" style={{ textAlign: 'center' }}>
          There are no notifications
        </Typography>
      </Box>
    );
  }

  return (
    <List disablePadding>
      {paginatedList.map(({ title, subtitle, link }, index) => (
        <ListItem component={RouterLink} divider key={`notification-list-item-${index}`} to={link} target="_blank">
          <ListItemText
            primary={title}
            primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
            secondary={subtitle}
          />
        </ListItem>
      ))}
    </List>
  );
};
