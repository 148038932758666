import React, { createContext, useContext, useEffect } from 'react';

import { EnhancedTableActions } from './enhanced-table-actions';
import { filterRows, paginateRows, sortRows } from './utils';

const INITIAL_ROWS_PER_PAGE = 25;

export const EnhancedTableContext = createContext({
  /**
   * Table title
   */
  title: '',

  /**
   * Columns
   *
   * 1. sorter
   * if true => sort by default compareFn
   * if false => disable sorting
   * if function => sort by function
   */
  columns: [
    // {
    //   id: string,
    //   label: string,
    //   style: object,
    //   sorter: true | false | (a, b, orderBy) => {}
    //   filter: true | false | {
    //      type?: 'checkbox' | 'date' | 'unknown',
    //      component?: Component,
    //      componentProps?: {},
    //      func?: (value: string | any[]) => (row) => boolean
    //   }
    // },
  ],

  /**
   * Rows
   */
  rows: [],
  filteredRows: [],
  sortedRows: [],
  paginatedRows: [],

  /**
   * Sort
   */
  order: 'asc',
  orderBy: null,

  /**
   * Filters
   *
   * {
   *    [columnId]: { value: string | any[], func?: custom filter function }
   * }
   */
  filters: {},

  /**
   * Selected rows ids
   */
  selected: [],

  /**
   * Pagination active page
   */
  page: 0,

  /**
   * Pagination rows count per page
   */
  rowsPerPage: INITIAL_ROWS_PER_PAGE,

  /**
   * Bulk actions component and props
   */
  tableActionsComponent: EnhancedTableActions,
  tableActionsComponentProps: [],

  /**
   * Update enhanced table context status
   */
  updateTableStatus: () => {}
});

export const EnhancedTableContextProvider = ({
  title = '',
  columns = [],
  rows = [],
  tableActionsComponent = EnhancedTableActions,
  tableActionsComponentProps = [],
  isPaginated = true,
  isDraggable = false,
  handleDragEnd = () => {},
  isCheckable = false,
  children = null,
  order = 'asc',
  orderBy = null
}) => {
  const [{ order: sort, orderBy: sortBy, filters, selected, page, rowsPerPage }, setEnhancedTableState] = React.useState({
    order,
    orderBy,
    selected: [],
    filters: {},
    page: 0,
    rowsPerPage: INITIAL_ROWS_PER_PAGE
  });

  const updateTableStatus = value => {
    setEnhancedTableState(prev => ({ ...prev, ...value }));
  };

  useEffect(() => {
    if (columns.length > 0 && Object.keys(filters).length === 0) {
      const filtersBasedonColumns = columns.reduce((prev, curr) => {
        const { filter = true } = curr;

        if (filter === false) return prev;

        let value = null;

        if (filter.type === 'checkbox') {
          value = [];
        }

        return { ...(prev || {}), [curr.id]: { ...filter, value } };
      }, {});

      updateTableStatus({ filters: filtersBasedonColumns });
    }
  }, [columns]);

  useEffect(() => {
    if (selected.length > 0) {
      const selectedRowIds = rows.filter(item => selected.includes(item.id)).map(item => item.id);

      updateTableStatus({ selected: selectedRowIds });
    }
  }, [rows]);

  useEffect(() => {
    if (isDraggable) {
      updateTableStatus({
        order,
        orderBy,
        selected: [],
        filters: {},
        page: 0,
        rowsPerPage: INITIAL_ROWS_PER_PAGE
      });
    }
  }, [isDraggable]);

  const filteredRows = filterRows({ rows, filters });

  const sortedRows = sortRows({ rows: filteredRows, columns, orderBy: sortBy, order: sort });

  const paginatedRows = paginateRows({ rows: sortedRows, page, rowsPerPage });

  return (
    <EnhancedTableContext.Provider
      value={{
        title,
        columns,
        rows,
        isPaginated,
        isDraggable,
        isCheckable,
        filteredRows,
        sortedRows,
        paginatedRows,
        order: sort,
        orderBy: sortBy,
        filters,
        selected,
        page,
        rowsPerPage,
        tableActionsComponent,
        tableActionsComponentProps,
        updateTableStatus,
        handleDragEnd
      }}
    >
      {children}
    </EnhancedTableContext.Provider>
  );
};

export const useEnhancedTableContext = () => {
  return useContext(EnhancedTableContext);
};
