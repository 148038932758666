import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
  useCreateComplianceReportQuestionMutation,
  useFetchComplianceReportQuestion,
  useFetchComplianceReportSectionsQuery,
  useUpdateComplianceReportQuestionMutation
} from 'src/apis';
import { Loading } from 'src/components';
import { useParamValues } from 'src/hooks/route.hook';

const types = [
  {
    value: 'restaurant',
    name: 'Restaurant'
  },
  {
    value: 'truck',
    name: 'Truck'
  }
];

const weights = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 'dnq'];

export const QuestionForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParamValues(['id']);
  const {
    data: { type: _type, sectionId: _sectionId, weight: _weight, question: _question },
    isLoading: loading_question
  } = useFetchComplianceReportQuestion({ id });
  const { data: sections = [], isLoading: loading_sections } = useFetchComplianceReportSectionsQuery();
  const [createQuestion, { isLoading: loading_createQuestion }] = useCreateComplianceReportQuestionMutation();
  const [updateQuestion, { isLoading: loading_updateQuestion }] = useUpdateComplianceReportQuestionMutation();

  const type = id ? _type : '';
  const sectionId = id ? _sectionId : '';
  const weight = id ? _weight : '';
  const question = id ? _question : '';

  const submitQuestion = id ? updateQuestion : createQuestion;
  const successMessage = id ? 'Compliance report question updated.' : 'Compliance report question created.';
  const errorMessage = id ? 'Compliance report question update failed.' : 'Compliance report question creation failed.';
  const submitButtonLabel = id ? 'Update Question' : 'Create Question';
  const isSubmitting = id ? loading_updateQuestion : loading_createQuestion;

  const formManager = useFormik({
    enableReinitialize: true,
    initialValues: {
      type,
      sectionId,
      weight,
      question
    },
    validationSchema: Yup.object().shape({
      type: Yup.string()
        .nullable()
        .required('Select a franchisee type'),
      sectionId: Yup.string()
        .nullable()
        .required('Select a section'),
      weight: Yup.string()
        .nullable()
        .required('Select weight'),
      question: Yup.string()
        .nullable()
        .required('Input question text')
    }),
    onSubmit: values => {
      const submitQuestionProps = id ? { id, ...values } : values;

      submitQuestion(submitQuestionProps)
        .then(() => {
          enqueueSnackbar(successMessage, {
            variant: 'success'
          });

          history.push('/app/compliance-v2/questions');
        })
        .catch(() => {
          enqueueSnackbar(errorMessage, {
            variant: 'error'
          });
        })
        .finally();
    }
  });

  if (loading_question) {
    return <Loading text="Processing initialization..." height={500} />;
  }

  return (
    <form onSubmit={formManager.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Questions Details" />

            <Divider />

            <CardContent>
              {sections.length === 0 && (
                <Box textAlign={'center'} padding={3}>
                  <Typography color="textSecondary">
                    No question sections that already exist. Create some sections to continue.
                  </Typography>

                  <Box marginTop={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        history.push('/app/compliance-v2/question-sections/new');
                      }}
                    >
                      Create a section
                    </Button>
                  </Box>
                </Box>
              )}

              {sections.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={types.map(t => t.value)}
                      value={formManager.values.type || ""}
                      onChange={(_, option) => formManager.setFieldValue('type', option)}
                      getOptionLabel={option => {
                        const { name = '' } = types.find(item => item.value === option) || {};

                        return name;
                      }}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="What type of franchisee is this question for?"
                          error={!!(formManager.touched.type && formManager.errors.type)}
                          helperText={formManager.touched.type && formManager.errors.type}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      options={sections.map(item => item.id)}
                      value={formManager.values.sectionId || ""}
                      onChange={(_, newValue) => formManager.setFieldValue('sectionId', newValue)}
                      getOptionLabel={option => {
                        const { name = '' } = sections.find(item => item.id === option) || {};

                        return name;
                      }}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Which Section does this question belong to?"
                          error={!!(formManager.touched.sectionId && formManager.errors.sectionId)}
                          helperText={formManager.touched.sectionId && formManager.errors.sectionId}
                        />
                      )}
                      loading={loading_sections}
                      loadingText={<Loading text="Loading question sections..." />}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      options={weights}
                      value={formManager.values.weight || ""}
                      onChange={(_, newValue) => formManager.setFieldValue('weight', newValue)}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Value or score of the question"
                          error={!!(formManager.touched.weight && formManager.errors.weight)}
                          helperText={formManager.touched.weight && formManager.errors.weight}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={2}
                      placeholder="What question would you like to include in compliance reports?"
                      label="What question would you like to include in compliance reports?"
                      name="question"
                      value={formManager.values.question}
                      onChange={e => formManager.setFieldValue('question', e.target.value)}
                      error={!!(formManager.touched.question && formManager.errors.question)}
                      helperText={formManager.touched.question && formManager.errors.question}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>

        {sections.length > 0 && (
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
            >
              {submitButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
