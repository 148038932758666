import React from 'react';
import { useHistory } from 'react-router';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Edit } from 'react-feather';

import {
  useBulkDeleteComplianceReportQuestionsMutation,
  useFetchComplianceReportQuestions,
  useFetchComplianceReportSectionsQuery
} from 'src/apis';
import { ActionsCell, EnhancedTable } from 'src/components';
import { usePageContext } from './context';

export const Table = () => {
  const history = useHistory();
  const {
    data: questions = { all: [], restaurant: [], truck: [] },
    isLoading: loading_questions
  } = useFetchComplianceReportQuestions();
  const { data: sections = [], isLoading: loading_sections } = useFetchComplianceReportSectionsQuery();
  const { currentTab } = usePageContext();
  const [
    bulkDeleteQuestions,
    { isLoading: loading_bulkDeleteQuesitons }
  ] = useBulkDeleteComplianceReportQuestionsMutation();

  const sectionOptions = sections.map(({ id: value, name: label }) => ({ value, label }));

  const columns = [
    {
      id: 'questionCol',
      label: 'Question',
      style: {
        paddingTop: 16,
        paddingBottom: 16
      }
    },
    {
      id: 'locationTypeCol',
      label: 'Location Type',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: [
            {
              value: 'truck',
              label: 'Truck'
            },
            {
              value: 'restaurant',
              label: 'Restaurant'
            }
          ]
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.type);
        }
      }
    },
    {
      id: 'sectionCol',
      label: 'Section',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: sectionOptions
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.sectionId);
        }
      }
    },
    {
      id: 'weightCol',
      label: 'Weight/Value',
      filter: {
        type: 'checkbox',
        componentProps: {
          options: [
            {
              value: '1',
              label: '1'
            },
            {
              value: '2',
              label: '2'
            },
            {
              value: '3',
              label: '3'
            },
            {
              value: '4',
              label: '4'
            },
            {
              value: '5',
              label: '5'
            },
            {
              value: 'dnq',
              label: 'dnq'
            }
          ]
        },
        func: value => row => {
          return value.length === 0 || value.includes(row.weight);
        }
      }
    },
    {
      id: 'actionsCol',
      label: 'Actions',
      align: 'center',
      render: ({ actionsCol }) => <ActionsCell actions={actionsCol} />,
      sorter: false,
      filter: false
    }
  ];

  const rows = (currentTab ? questions[currentTab] : []).map(item => {
    const {
      id: questionId,
      question: questionCol = '',
      typeName: locationTypeCol,
      sectionName: sectionCol,
      weight: weightCol
    } = item;

    const actionsCol = [
      {
        icon: <Edit />,
        onClick: event => {
          event.stopPropagation();
          history.push(`/app/compliance-v2/questions/${questionId}/edit`);
        }
      }
    ];

    const row = {
      ...item,
      questionCol,
      locationTypeCol,
      sectionCol,
      weightCol,
      actionsCol
    };

    return row;
  });

  const tableActionsComponentProps = [
    {
      render: ({ rows }) => (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={loading_bulkDeleteQuesitons ? <CircularProgress size={16} color="inherit" /> : <Delete />}
          disabled={loading_bulkDeleteQuesitons}
          onClick={() => {
            const ids = rows.map(row => row.id);

            bulkDeleteQuestions({ ids });
          }}
        >
          Delete
        </Button>
      )
    }
  ];

  return (
    <EnhancedTable
      title="Select rows to delete"
      columns={columns}
      rows={rows}
      tableActionsComponentProps={tableActionsComponentProps}
      loading={{
        isLoading: loading_questions || loading_sections,
        text:
          currentTab === 'all'
            ? 'Loading report questions...'
            : currentTab === 'restaurant'
            ? 'Loading restaurant report questions...'
            : currentTab === 'truck'
            ? 'Loading truck report questions...'
            : ''
      }}
      empty={{
        description:
          currentTab === 'all'
            ? 'No report questions yet.'
            : currentTab === 'restaurant'
            ? 'No restaurant report questions yet.'
            : currentTab === 'truck'
            ? 'No truck report questions yet.'
            : '',
        buttonLabel: 'Create new question',
        onClick: () => history.push(`/app/compliance-v2/questions/new`)
      }}
      isCheckable
    />
  );
};
