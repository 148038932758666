import React, { forwardRef } from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';

import { FranchiseeLocationSelector } from 'src/components';
import { useFetchFranchiseeLocations } from 'src/apis';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { FRANCHISEE_OWNER } from 'src/constants';

export const Form = forwardRef(({}, ref) => {
  const history = useHistory();
  const { territoryIds, role } = useCurrentUser();
  const { data: franchiseeLocations } = useFetchFranchiseeLocations();
  const isFranchiseeOwner = role === FRANCHISEE_OWNER;
  let filteredFranchiseeLocations = franchiseeLocations;

  if (isFranchiseeOwner) {
    filteredFranchiseeLocations = franchiseeLocations.filter(l => territoryIds.includes(l.territoryId));
  }

  const formManager = useFormik({
    initialValues: {
      franchiseeLocationId: ''
    },
    validationSchema: Yup.object({
      franchiseeLocationId: Yup.string()
        .nullable()
        .required('Please select a franchisee location')
    }),
    onSubmit: values => {
      const { franchiseeLocationId } = values;

      const franchiseeLocation = franchiseeLocations.find(item => item.id === franchiseeLocationId);

      if (franchiseeLocation) {
        const { type } = franchiseeLocation;

        if (isFranchiseeOwner) {
          history.push(`/app/compliance-v2/reports/${franchiseeLocationId}/view/${type}?sample=true`);
        } else {
          history.push(`/app/compliance-v2/reports/new/${type}/${franchiseeLocationId}`);
        }
      }
    }
  });

  React.useImperativeHandle(ref, () => ({ onSubmit: () => formManager.handleSubmit() }), []);

  return (
    <Card>
      <CardHeader title="Which location will you be reporting?" />

      <Divider />

      <CardContent>
        {filteredFranchiseeLocations.length > 0 && (
          <form onSubmit={formManager.handleSubmit}>
            <FranchiseeLocationSelector
              value={formManager.values.franchiseeLocationId}
              onChange={value => formManager.setFieldValue('franchiseeLocationId', value)}
              error={formManager.touched.franchiseeLocationId && formManager.errors.franchiseeLocationId}
              label={'Select franchisee to start a report?'}
            />
          </form>
        )}

        {filteredFranchiseeLocations.length === 0 && !isFranchiseeOwner && (
          <Box textAlign={'center'} padding={3}>
            <Typography color="textSecondary">There is no franchisee location. Create one to continue.</Typography>

            <Box marginTop={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(`/app/tools/new-franchise-location`);
                }}
              >
                Create Franchisee Location
              </Button>
            </Box>
          </Box>
        )}
        {filteredFranchiseeLocations.length === 0 && isFranchiseeOwner && (
          <Box textAlign={'center'} padding={3}>
            <Typography color="textSecondary">There is no franchisee location. Please contact corporate</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
});
