import React, { useEffect } from 'react';
import { Button, Divider, Grid, SvgIcon } from '@material-ui/core';
import { Document, pdf, PDFViewer } from '@react-pdf/renderer';
import { Download } from 'react-feather';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { useFetchBlankComplianceReport, useFetchComplianceReport, useUpdateComplianceReportMutation } from 'src/apis';
import { DashboardPageContainer, HeaderWithButtons, Loading } from 'src/components';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { useParamValues, useQueryValues } from 'src/hooks/route.hook';
import { ComplianceReportPdf, CoverPdf } from '../pdfs';
import { groupBySection } from '../utils';
import { OverviewCard } from './overview-card';
import { NoteCard } from './note-card';
import { QuestionsGroupCard } from './questions-group-card';
import { FRANCHISEE_OWNER } from 'src/constants';

export const ComplianceReportViewPage = () => {
  const { id } = useParamValues(['id']);
  const { sample: isSample } = useQueryValues(['sample']);
  const { id: userId, role } = useCurrentUser();
  const isFranchiseeOwner = role === FRANCHISEE_OWNER;
  const fetchReportMethod = isFranchiseeOwner && isSample ? useFetchBlankComplianceReport : useFetchComplianceReport;
  const { data: complianceReport, isLoading: loading_complianceReport } = fetchReportMethod({ id });
  const [updateComplianceReport] = useUpdateComplianceReportMutation();

  const {
    franchiseeLocationTypeName: locationType = '',
    franchiseeLocationName: location = '',
    scoreName: score = '',
    createdAtTimestamp = 0,
    highlightNotes = '',
    lowlightNotes = '',
    questions = [],
    readRecipients = [],
    serialNo
  } = complianceReport;

  const dateCreated = createdAtTimestamp ? moment.unix(createdAtTimestamp).format('MMMM Do, YYYY') : '-';
  const overview = [
    {
      label: 'Location Type',
      value: locationType
    },
    {
      label: 'Location',
      value: location
    },
    {
      label: 'Score',
      value: score
    },
    {
      label: 'Date Created',
      value: dateCreated
    }
  ];

  const questionGroups = groupBySection(questions, 'sectionId');

  useEffect(() => {
    // Mark as read comliance report
    if (!loading_complianceReport && complianceReport.id && !readRecipients.includes(userId)) {
      updateComplianceReport({ ...complianceReport, readRecipients: [...readRecipients, userId] });
    }
  }, [loading_complianceReport]);

  const generateComplianceReportPdf = async () => {
    const blob = await pdf(
      <Document>
        <ComplianceReportPdf
          overview={overview}
          highlightNotes={highlightNotes}
          lowlightNotes={lowlightNotes}
          questionGroups={questionGroups}
          isSample={isFranchiseeOwner && isSample}
        />
      </Document>
    ).toBlob();

    const fileName = `CR${serialNo ? serialNo : 'sample'}-${location}-${moment.unix(createdAtTimestamp).format('MM-DD-YYYY')}.pdf`;
    saveAs(blob, fileName);
  };

  return (
    <DashboardPageContainer title="Compliance Report Details">
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <HeaderWithButtons
              breadcrumbs={[
                {
                  label: 'Dashboard',
                  link: '/app'
                },
                {
                  label: 'Compliance Reports',
                  link: '/app/compliance-v2/reports?status=passed'
                },
                {
                  label: 'Details'
                }
              ]}
              title={`${isSample ? 'Sample ' : ''}Compliance Report Details`}
              cancelButton={{ link: '/app/compliance-v2/reports?status=passed' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon fontSize="small">
                  <Download />
                </SvgIcon>
              }
              onClick={() => generateComplianceReportPdf()}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>

        {loading_complianceReport ? (
          <Grid item xs={12}>
            <Loading text="Loading compliance report..." height={500} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <OverviewCard overview={overview} />
            </Grid>

            <Grid xs={12} item>
              <NoteCard title="Positives & Highlights" note={highlightNotes || 'No notes added'} />
            </Grid>

            <Grid xs={12} item>
              <NoteCard title="Opportunities for Improvement" note={lowlightNotes || 'No notes added'} />
            </Grid>

            {Object.keys(questionGroups).map((sectionId, index) => (
              <Grid key={`questions-group-${index}`} xs={12} item>
                <QuestionsGroupCard questions={questionGroups[sectionId]} />
              </Grid>
            ))}

            {/* <Grid item xs={12}>
              <PDFViewer style={{ width: '100%', height: 600 }}>
                <Document>
                  <CoverPdf />
                  <ComplianceReportPdf
                    overview={overview}
                    highlightNotes={highlightNotes}
                    lowlightNotes={lowlightNotes}
                    questionGroups={questionGroups}
                  />
                </Document>
              </PDFViewer>
            </Grid> */}
          </>
        )}
      </Grid>
    </DashboardPageContainer>
  );
};
