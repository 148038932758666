import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { useCreateTrainingVideoMutation, useUpdateTrainingVideoMutation, useFetchTrainingVideo } from 'src/apis';
import { useParamValues } from 'src/hooks/route.hook';

export const useVideoForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParamValues(['id']);
  const {
    data: { title: _title = '', description: _description = '', categories = [], url: _url = '' } = {},
    isLoading: isInitializing
  } = useFetchTrainingVideo({ id });
  const [
    create,
    { isLoading: loading_create, isError: error_create, error: error_details_create, isSuccess: success_create }
  ] = useCreateTrainingVideoMutation();
  const [
    update,
    { isLoading: loading_update, isError: error_update, error: error_details_update, isSuccess: success_update }
  ] = useUpdateTrainingVideoMutation();

  const title = id ? _title : '';
  const description = id ? _description : '';
  const categoryIds = id ? categories.map(({ id }) => id) : [];
  const url = id ? _url : '';
  const mutate = id ? update : create;
  const successMessage = id ? 'Training video updated.' : 'Training video created.';
  const errorMessage = id ? 'Training video update failed.' : 'Training video creation failed.';
  const isSubmitting = id ? loading_update : loading_create;
  const isError = id ? error_update : error_create;
  const error = id ? error_details_update : error_details_create;
  const isSuccess = id ? success_update : success_create;

  const submitButtonLabel = id ? 'Update' : 'Submit';

  const formManager = useFormik({
    enableReinitialize: true,
    initialValues: { title, description, categoryIds, url },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Video title is required'),
      description: Yup.string().required('Video description is required'),
      categoryIds: Yup.array()
        .nullable()
        .required('Select at least one category'),
      url: Yup.string().required('Input vimeo link')
    }),
    onSubmit: values => {
      const mutateProps = id ? { id, ...values } : values;

      mutate(mutateProps);
    }
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.message || errorMessage, { variant: 'error' });
    } else if (!isSubmitting && isSuccess) {
      enqueueSnackbar(successMessage, { variant: 'success' });
      history.push('/app/training-v2/videos');
    }
  }, [isSubmitting, isError, isSuccess]);

  return [{ formManager, submitButtonLabel, isInitializing, isSubmitting }];
};
