import { filterRows, paginateRows, selectRow } from 'src/utils/table';
import { useFetchTerritories } from '../territories';
import { useFetchLlcQuery, useFetchLlcsQuery } from './llcs.api';

export const useFetchLlcs = (props = { where: null }) => {
  const { where } = props;

  const { data, isLoading } = useFetchLlcsQuery({ where });
  const { data: allTerritories = [], isLoading: loading_territories } = useFetchTerritories();

  let llcs = data || [];

  llcs = llcs
    .map(item => {
      const { id = '', name = '', territoryIds = [] } = item;

      const territories = allTerritories.filter(item => territoryIds.includes(item.id));

      return {
        id,
        name,
        territoryIds,
        territories
      };
    })
    .filter(item => item.territories.length > 0);

  llcs.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return { data: llcs, isLoading: isLoading || loading_territories };
};

export const useFetchLlcsTableRows = ({ id, query, filters, page, limit }) => {
  const { data, isLoading } = useFetchLlcs();

  const selected = selectRow(data, id);
  const filtered = filterRows(selected, query, filters);
  const paginated = paginateRows(filtered, page, limit);

  return { data: [selected, filtered, paginated, data], isLoading };
};

export const useFetchLlc = ({ id }) => {
  const { data: llc = {}, isLoading } = useFetchLlcQuery({ id });

  const { name = '', territoryIds = [] } = llc;

  return {
    data: {
      id,
      name,
      territoryIds
    },
    isLoading
  };
};
