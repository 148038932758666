import React from 'react';
import { Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 24
  },
  main: {
    top: '15%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center'
  },

  brand: {
    height: 'auto',
    width: 100,
    marginBottom: 10
  },
  h1: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 24
  }
});

export const CoverPdf = () => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.main}>
        <Image style={styles.brand} src="/static/logo.png" />
        <Text style={styles.h1}>Compliance Report</Text>
      </View>
    </Page>
  );
};
