import React from 'react';

import { FRANCHISEE_OWNER, FULFILLMENT, SUPER_ADMIN } from 'src/constants';
import { useCurrentUser } from 'src/contexts/firebase-context';

import { adminSections } from './admin-sections';
import { franchiseeSections } from './franchisee-sections';
import { generalSections } from './general-sections';

export const useSections = () => {
  const { role } = useCurrentUser();

  if (role === SUPER_ADMIN || role === FULFILLMENT) {
    return adminSections;
  }

  if (role === FRANCHISEE_OWNER) {
    return franchiseeSections;
  }

  return generalSections;
};
