import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const HeaderBreadcrumbsText = ({ text = '' }) => {
  return (
    <Typography variant="body1" color="textPrimary">
      {text}
    </Typography>
  );
};

HeaderBreadcrumbsText.propTypes = {
  text: PropTypes.string
};
