import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { Loading } from 'src/components/Loading';
import { PhoneNumberMask } from 'src/components/PhoneNumberMask';

import { useNotificationsSettingsFormManager } from './notifications-settings-form.hook';

export const NotificationsSettingsForm = () => {
  const { formManager, isInitializing, isSubmitting } = useNotificationsSettingsFormManager();

  return isInitializing ? (
    <Loading text="Loading notification settings..." height={360} />
  ) : (
    <form onSubmit={formManager.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Orders Team" />

            <Divider />

            <CardContent>
              <Typography color="textSecondary">
                All Notifications regarding pending new orders will be sent to this Email/Phone.
              </Typography>

              <Box marginTop={4}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Orders Team Email"
                      name="ordersTeamEmail"
                      value={formManager.values.ordersTeamEmail}
                      onChange={formManager.handleChange}
                      error={Boolean(formManager.touched.ordersTeamEmail && formManager.errors.ordersTeamEmail)}
                      helperText={formManager.touched.ordersTeamEmail && formManager.errors.ordersTeamEmail}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Orders Team Mobile Number"
                      name="ordersTeamPhone"
                      value={formManager.values.ordersTeamPhone}
                      onChange={formManager.handleChange}
                      error={Boolean(formManager.touched.ordersTeamPhone && formManager.errors.ordersTeamPhone)}
                      helperText={formManager.touched.ordersTeamPhone && formManager.errors.ordersTeamPhone}
                      placeholder="(000) 000-0000"
                      InputProps={{
                        inputComponent: PhoneNumberMask
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Fulfillment Team" />

            <Divider />

            <CardContent>
              <Typography color="textSecondary">
                All Notifications regarding fulfilled teams orders will be sent to this Email/Phone.
              </Typography>

              <Box marginTop={4}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Fulfillment Team Email"
                      name="fulfillmentTeamEmail"
                      value={formManager.values.fulfillmentTeamEmail}
                      onChange={formManager.handleChange}
                      error={Boolean(
                        formManager.touched.fulfillmentTeamEmail && formManager.errors.fulfillmentTeamEmail
                      )}
                      helperText={formManager.touched.fulfillmentTeamEmail && formManager.errors.fulfillmentTeamEmail}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Fulfillment Team Mobile Number"
                      name="fulfillmentTeamPhone"
                      value={formManager.values.fulfillmentTeamPhone}
                      onChange={formManager.handleChange}
                      error={Boolean(
                        formManager.touched.fulfillmentTeamPhone && formManager.errors.fulfillmentTeamPhone
                      )}
                      helperText={formManager.touched.fulfillmentTeamPhone && formManager.errors.fulfillmentTeamPhone}
                      placeholder="(000) 000-0000"
                      InputProps={{
                        inputComponent: PhoneNumberMask
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Compliance Team" />

            <Divider />

            <CardContent>
              <Typography color="textSecondary">
                When an approval is created or revised by a Franchise, a notification will be sent to the Compliance
                Team Email/Phone.
              </Typography>

              <Box marginTop={4}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Comliance Team Email"
                      name="complianceTeamEmail"
                      value={formManager.values.complianceTeamEmail}
                      onChange={formManager.handleChange}
                      error={Boolean(formManager.touched.complianceTeamEmail && formManager.errors.complianceTeamEmail)}
                      helperText={formManager.touched.complianceTeamEmail && formManager.errors.complianceTeamEmail}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Comliance Team Mobile Number"
                      name="complianceTeamPhone"
                      value={formManager.values.complianceTeamPhone}
                      onChange={formManager.handleChange}
                      error={Boolean(formManager.touched.complianceTeamPhone && formManager.errors.complianceTeamPhone)}
                      helperText={formManager.touched.complianceTeamPhone && formManager.errors.complianceTeamPhone}
                      placeholder="(000) 000-0000"
                      InputProps={{
                        inputComponent: PhoneNumberMask
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
