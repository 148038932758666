export const selectRow = (rows, id) => {
  if (id === 'all') {
    return rows || [];
  } else {
    return (rows || []).filter(row => row.id === id);
  }
};

export const filterRows = (rows, query, filters) => {
  return (rows || []).filter(row => {
    if (!query) {
      return true;
    }

    for (const key of Object.keys(filters)) {
      const filterPossible = filters[key];
      if (!filterPossible) continue;

      const value = row[key];
      if (!value) continue;

      if (value.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
    }

    return false;
  });
};

export const paginateRows = (rows, page, limit) => {
  if (limit === 'all') return rows;

  return (rows || []).slice(page * limit, page * limit + limit);
};

export const filterByTimeRange = (rows, start, end) => {
  return rows.filter(row => {
    return start.valueOf() <= row.createdAt.seconds * 1000 && end.valueOf() >= row.createdAt.seconds * 1000;
  });
};

export const sortByTime = (rows, sort, key = 'createdAt') => {
  switch (sort) {
    case 'asc':
      return rows.sort((a, b) => {
        if (a && b) {
          return a[key].seconds - b[key].seconds;
        } else {
          return -1;
        }
      });

    case 'desc':
      return rows.sort((a, b) => {
        if (a && b) {
          return b[key].seconds - a[key].seconds;
        } else {
          return 1;
        }
      });

    default:
      return rows;
  }
};
