import moment from 'moment';

import { useFetchTerritories } from '../territories';
import { useFetchFranchiseeLocations } from '../franchisee-locations';
import { useFetchMysteryShopperReportQuery, useFetchMysteryShopperReportsQuery } from './mystery-shopper-reports.api';
import { useFetchUsers } from '../users';

const getMysteryShopperReportDetails = ({
  mysteryShopperReport = {},
  territories = [],
  franchiseeLocations = [],
  users = []
}) => {
  const { userId, date, franchiseeLocationId, territoryId } = mysteryShopperReport;

  const dateTimestamp = (date?.seconds || 0) + (date?.nanosecond || 0) / 1000;
  const dateTimestampString = dateTimestamp ? moment.unix(dateTimestamp).format('DD MMM YYYY | hh:mm') : '-';
  const { name: territoryName = '' } = territories.find(t => t.id === territoryId) || {};
  const { name: franchiseeLocationName = '' } = franchiseeLocations.find(f => f.id === franchiseeLocationId) || {};
  const { name: userName = '' } = users.find(u => u.id === userId) || {};

  return {
    ...mysteryShopperReport,
    dateTimestamp,
    dateTimestampString,
    territoryName,
    franchiseeLocationName,
    userName
  };
};

export const useFetchMysteryShopperReports = ({ where } = {}) => {
  const { data = [], isLoading: loading_mysteryShopperReports, ...rest } = useFetchMysteryShopperReportsQuery({
    where
  });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: franchiseeLocations, isLoading: loading_frachiseeLocations } = useFetchFranchiseeLocations();
  const { data: users, isLoading: loading_users } = useFetchUsers();

  const mysteryShopperReports = data.map(mysteryShopperReport =>
    getMysteryShopperReportDetails({ mysteryShopperReport, territories, franchiseeLocations, users })
  );

  return {
    data: mysteryShopperReports,
    isLoading: loading_mysteryShopperReports || loading_territories || loading_frachiseeLocations || loading_users,
    ...rest
  };
};

export const useFetchMysteryShopperReport = ({ id }) => {
  const {
    data: mysteryShopperReport = {},
    isLoading: loading_mysteryShopperReport,
    ...rest
  } = useFetchMysteryShopperReportQuery({ id });
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();
  const { data: franchiseeLocations, isLoading: loading_frachiseeLocations } = useFetchFranchiseeLocations();
  const { data: users, isLoading: loading_users } = useFetchUsers();

  return {
    data: getMysteryShopperReportDetails({ mysteryShopperReport, territories, franchiseeLocations, users }),
    isLoading: loading_mysteryShopperReport || loading_territories || loading_frachiseeLocations || loading_users,
    ...rest
  };
};
