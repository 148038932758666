import { COMPLIANCE_REPORT_STATUS_MAP } from 'src/constants';
import { db, storage } from 'src/lib/firebase';
import {
  baseApi,
  bulkDeleteDocs,
  bulkUpdateDocs,
  createDoc,
  deleteDoc,
  getCollection,
  getDoc,
  updateDoc
} from '../base';
import { rollbarClient } from 'src/utils/rollbarClient';
import { collection, doc, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const reports_collectionName = 'compliance_reports';
const drafts_collectionName = 'compliance_reports_drafts';

export const complianceReportsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchComplianceReports: builder.query({
      queryFn: async ({ where, isDraft = false } = {}) => {
        let options = {};

        if (where) {
          options.where = where;
        }

        const collectionName = reports_collectionName;

        return await getCollection(collectionName, options);
      },
      providesTags: ['compliance-reports']
    }),

    fetchComplianceReport: builder.query({
      queryFn: async ({ id, isDraft = false }) => {
        const collectionName = reports_collectionName;

        return await getDoc(collectionName, { id });
      },
      providesTags: (result, error, { id }) => [{ type: 'compliance-report', id }]
    }),

    createComplianceReport: builder.mutation({
      queryFn: async ({
        userId,
        franchiseeLocationId,
        questions: _questions,
        highlightNotes,
        lowlightNotes,
        readRecipients,
        isDraft = false
      }) => {
        try {
          const collectionName = reports_collectionName;
          let serialNo = 1;
          const cRef = collection(db, collectionName);
          const reportRef = doc(cRef);

          try {
            const cRef = collection(db, collectionName);
            const cQuery = query(
              cRef,
              orderBy("createdAt", "desc"),
              limit(1)
            );
            const lastReportRes = await getDocs(cQuery);

            const lastReportDetails = lastReportRes?.docs?.[0]?.data();
            serialNo = (lastReportDetails?.serialNo || 0) + 1;
          } catch (e) {
            console.log('error getting lastReportDetails e', e);
          }

          const questions = await Promise.all(
            _questions.map(async ({ images, ...rest }) => {
              if (images?.length) {
                const processedImages = await Promise.all(
                  images.map(async image => {
                    const { file = null } = image || { file: null };

                    // TODO - refactor storage, ensure this works
                    const name = `${collectionName}/${reportRef.id}/${file.name}`;
                    const storageRef = ref(storage, name);
                    const uploadTask = await uploadBytes(storageRef, file)
                    const url = await getDownloadURL(uploadTask.ref);
                    const fileName = file.name;

                    return { fileName, url };
                  })
                );

                return { ...rest, images: processedImages };
              } else {
                return { ...rest, images: null };
              }
            })
          );

          const report = {
            docId: reportRef.id,
            userId,
            franchiseeLocationId,
            questions,
            highlightNotes,
            lowlightNotes,
            serialNo,
            readRecipients,
            status: isDraft ? COMPLIANCE_REPORT_STATUS_MAP.DRAFT : COMPLIANCE_REPORT_STATUS_MAP.PUBLISHED
          }

          if (!isDraft) {
            report.serialNo = serialNo;
            report.readRecipients = readRecipients;
          }

          return await createDoc(collectionName, report);
        } catch (error) {
          console.log('createComplianceReport error', error);
          rollbarClient.error('reports.api createComplianceReport', error);

          return { error };
        }
      },
      invalidatesTags: ['compliance-reports']
    }),

    updateComplianceReport: builder.mutation({
      queryFn: async ({
        id: docId,
        userId,
        franchiseeLocationId,
        questions: _questions,
        highlightNotes,
        lowlightNotes,
        readRecipients,
        isDraft = false
      }) => {
        try {
          const collectionName = reports_collectionName;
          const questions = await Promise.all(
            _questions.map(async ({
              id = '',
              pass = true,
              isNotApplicable = false,
              comment = '',
              images,
              weight,
              question,
              type,
              order,
              sectionId,
              section,
              sectionName,
              typeName
            }) => {
              if (images?.length) {
                const processedImages = await Promise.all(
                  images.map(async image => {
                    const { file = null } = image || { file: null };

                    if (file) {
                      // TODO - refactor storage, ensure this works
                      const name = `${reports_collectionName}/${docId}/${file.name}`;
                      const storageRef = ref(storage, name);
                      const uploadTask = await uploadBytes(storageRef, file)
                      const url = await getDownloadURL(uploadTask.ref);
                      const fileName = file.name;

                      return { fileName, url };
                    }

                    return image;
                  })
                );

                return {
                  id,
                  pass,
                  isNotApplicable,
                  comment,
                  images: processedImages,
                  weight,
                  question,
                  type,
                  order,
                  sectionId,
                  section,
                  sectionName,
                  typeName
                };
              } else {
                return {
                  id,
                  pass,
                  isNotApplicable,
                  comment,
                  images: images || null,
                  weight,
                  question,
                  type,
                  order,
                  sectionId,
                  section,
                  sectionName,
                  typeName
                };
              }
            })
          );

          return await updateDoc(collectionName, {
            id: docId,
            userId,
            franchiseeLocationId,
            questions,
            highlightNotes,
            lowlightNotes,
            readRecipients,
            status: isDraft ? COMPLIANCE_REPORT_STATUS_MAP.DRAFT : COMPLIANCE_REPORT_STATUS_MAP.PUBLISHED
          });
        } catch (error) {
          rollbarClient.error('reports.api updateComplianceReport', error);

          return { error };
        }
      },
      invalidatesTags: (result, error, { id }) => ['compliance-reports', { type: 'compliance-report', id }]
    }),

    bulkUpdateComplianceReports: builder.mutation({
      queryFn: async ({ reports: _reports, isDraft = false }) => {
        const collectionName = reports_collectionName;

        const reports = _reports.map(report => {
          const {
            id,
            userId,
            franchiseeLocationId,
            questions: _questions,
            highlightNotes,
            lowlightNotes,
            readRecipients
          } = report;

          const questions = _questions.map(({
            id,
            pass,
            isNotApplicable,
            comment,
            images,
            weight,
            question,
            type,
            order,
            sectionId,
            section,
            sectionName,
            typeName
          }) => ({
            id,
            pass,
            isNotApplicable,
            comment,
            images,
            weight,
            question,
            type,
            order,
            sectionId,
            section,
            sectionName,
            typeName
          }));

          return { id, userId, franchiseeLocationId, questions, highlightNotes, lowlightNotes, readRecipients };
        });

        return await bulkUpdateDocs(collectionName, reports);
      },
      invalidatesTags: ['compliance-reports']
    }),

    deleteComplianceReport: builder.mutation({
      queryFn: async ({ id, isDraft = false }) => {
        const collectionName = reports_collectionName;

        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['compliance-reports']
    }),

    bulkDeleteComplianceReports: builder.mutation({
      queryFn: async ({ ids = [], isDraft = false }) => {
        const collectionName = reports_collectionName;

        return await bulkDeleteDocs(collectionName, { ids });
      },
      invalidatesTags: ['compliance-reports']
    })
  })
});

export const {
  useFetchComplianceReportsQuery,
  useFetchComplianceReportQuery,
  useCreateComplianceReportMutation,
  useUpdateComplianceReportMutation,
  useBulkUpdateComplianceReportsMutation,
  useDeleteComplianceReportMutation,
  useBulkDeleteComplianceReportsMutation
} = complianceReportsApi;
