import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

import { PhoneNumberMask } from 'src/components/PhoneNumberMask';

import { useGeneralSettingsFormManager } from './general-settings-form.hook';
import { TIMEZONES_MAP } from 'src/constants';

export const GeneralSettingsForm = () => {
  const { formManager } = useGeneralSettingsFormManager();

  return (
    <form onSubmit={formManager.handleSubmit}>
      <Card>
        <CardHeader title="Profile" />

        <Divider />

        <CardContent>
          <Box paddingY={2}>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="First name"
                  name="firstName"
                  value={formManager.values.firstName}
                  onChange={formManager.handleChange}
                  error={Boolean(formManager.touched.firstName && formManager.errors.firstName)}
                  helperText={formManager.touched.firstName && formManager.errors.firstName}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Last name"
                  name="lastName"
                  value={formManager.values.lastName}
                  onChange={formManager.handleChange}
                  error={Boolean(formManager.touched.lastName && formManager.errors.lastName)}
                  helperText={formManager.touched.lastName && formManager.errors.lastName}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Email Address"
                  name="email"
                  value={formManager.values.email}
                  onChange={formManager.handleChange}
                  error={Boolean(formManager.touched.email && formManager.errors.email)}
                  helperText={formManager.touched.email && formManager.errors.email}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Mobile"
                  name="mobile"
                  value={formManager.values.mobile}
                  onChange={formManager.handleChange}
                  error={Boolean(formManager.touched.mobile && formManager.errors.mobile)}
                  helperText={formManager.touched.mobile && formManager.errors.mobile}
                  placeholder="(000) 000-0000"
                  InputProps={{
                    inputComponent: PhoneNumberMask
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(formManager.touched.timezone && formManager.errors.timezone)}
                  fullWidth
                  helperText={formManager.touched.timezone && formManager.errors.timezone}
                  label="Select a Timezone for SMS notifications"
                  name="timezone"
                  SelectProps={{ native: true }}
                  onBlur={formManager.handleBlur}
                  onChange={event => {
                    formManager.handleChange(event);
                  }}
                  select
                  value={formManager.values.timezone}
                  variant="outlined"
                >
                  {[TIMEZONES_MAP.PST, TIMEZONES_MAP.MST, TIMEZONES_MAP.CST, TIMEZONES_MAP.EST].map(timezone => {
                    return (
                      <option key={timezone.zone} value={timezone.zone}>
                        {timezone.zone}
                      </option>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </CardContent>

        <Divider />

        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={formManager.isSubmitting}
            endIcon={formManager.isSubmitting && <CircularProgress size={16} color="inherit" />}
          >
            Save Changes
          </Button>
        </Box>
      </Card>
    </form>
  );
};
