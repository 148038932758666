// Main Dashboard
export const DASHBOARD = 'Dashboard';
export const VIEW_DASHBOARD = 'View Dashboard';

// Orders
export const ORDERS = 'Orders';
export const VIEW_ORDERS = 'View Orders';
export const CREATE_ORDERS = 'Create Orders';
export const EDIT_ORDERS = 'Edit Orders';
export const DELETE_ORDERS = 'Delete Orders';
export const SET_ORDER_STATUS = 'Set Order Status';

// Draft Orders
export const DRAFT_ORDERS = 'Draft Orders';
export const VIEW_DRAFT_ORDERS = 'View Draft Orders';

// Item Sales Reports
export const ITEM_SALES_REPORTS = 'Item Sales Reports';
export const VIEW_ITEM_SALES_REPORTS = 'View Item Sales Reports';

// Inventory Reports
export const INVENTORY_REPORTS = 'Inventory Reports';
export const VIEW_INVENTORY_REPORTS = 'View Inventory Reports';
export const CREATE_INVENTORY_REPORTS = 'Create Inventory Reports';
export const EDIT_INVENTORY_REPORTS = 'Edit Inventory Reports';
export const DELETE_INVENTORY_REPORTS = 'Delete Inventory Reports';

// Franchise Sales Reports
export const FRANCHISE_SALES_REPORTS = 'Franchise Sales Reports';
export const VIEW_FRANCHISE_SALES_REPORTS = 'View Franchise Sales Reports';
export const CREATE_FRANCHISE_SALES_REPORTS = 'Create Franchise Sales Reports';
export const EDIT_FRANCHISE_SALES_REPORTS = 'Edit Franchise Sales Reports';
export const DELETE_FRANCHISE_SALES_REPORTS = 'Delete Franchise Sales Reports';

// COGS Reports
export const COGS_REPORTS = 'COGS Reports';
export const VIEW_COGS_REPORTS = 'View COGS Reports';

// Fulfillments
export const FULFILLMENTS = 'Fulfillments';
export const VIEW_FULFILLMENTS = 'View Fulfillments';
export const SET_FULFILLMENT_STATUS = 'Set Fulfillment Status';

// Fulfillment Notes
export const FULFILLMENT_NOTES = 'Fulfillment Notes';
export const VIEW_FULFILLMENT_NOTES = 'View Fulfillment Notes';
export const EDIT_FULFILLMENT_NOTES = 'Edit Fulfillment Notes';
export const DELETE_FULFILLMENT_NOTES = 'Delete Fulfillment Notes';

// Approvals
export const APPROVALS = 'Approvals';
export const VIEW_APPROVALS = 'View Approvals';
export const CREATE_REQUESTS = 'Create Requests';

// Compliance Reports
export const COMPLIANCE_REPORTS = 'Compliance Reports';
export const VIEW_COMPLIANCE_REPORTS = 'View Compliance Reports';
export const VIEW_SAMPLE_COMPLIANCE_REPORTS = 'View Sample Compliance Reports';
export const CREATE_COMPLIANCE_REPORTS = 'Create Compliance Reports';
export const EDIT_COMPLIANCE_REPORTS = 'Edit Compliance Reports';
export const DELETE_COMPLIANCE_REPORTS = 'Delete Compliance Reports';
export const SET_COMPLIANCE_REPORT_DNQ_STATUS = 'Set Compliance Report DNQ Status';

// Draft Compliance Reports
export const DRAFT_COMPLIANCE_REPORTS = 'Draft Compliance Reports';
export const VIEW_DRAFT_COMPLIANCE_REPORTS = 'View Draft Compliance Reports';

// Compliance Questions
export const COMPLIANCE_QUESTIONS = 'Compliance Questions';
export const VIEW_COMPLIANCE_QUESTIONS = 'View Compliance Questions';
export const CREATE_COMPLIANCE_QUESTIONS = 'Create Compliance Questions';
export const EDIT_COMPLIANCE_QUESTIONS = 'Edit Compliance Questions';
export const DELETE_COMPLIANCE_QUESTIONS = 'Delete Compliance Questions';
export const CREATE_COMPLIANCE_QUESTION_SECTIONS = 'Create Compliance Question Sections';

// Mystery Shopper Reports
export const MYSTERY_SHOPPER_REPORTS = 'Mystery Shopper Reports';
export const VIEW_MYSTERY_SHOPPER_REPORTS = 'View Mystery Shopper Reports';

// Training videos
export const TRAINING_VIDEOS = 'Training Videos';
export const VIEW_TRAINING_VIDEOS = 'View Training Videos';
export const CREATE_TRAINING_VIDEOS = 'Create Training Videos';
export const DELETE_TRAINING_VIDEOS = 'Delete Training Videos';
export const EDIT_TRAINING_VIDEOS = 'Edit Training Videos';
export const CREATE_TRAINING_VIDEO_CATEGORIES = 'Create Training Video Categories';

// Territories
export const TERRITORIES = 'Territories';
export const VIEW_TERRITORIES = 'View Territories';
export const CREATE_TERRITORIES = 'Create Territories';
export const EDIT_TERRITORIES = 'Edit Territories';
export const DELETE_TERRITORIES = 'Delete Territories';

// Franchise Locations
export const FRANCHISE_LOCATIONS = 'Franchise Locations';
export const VIEW_FRANCHISE_LOCATIONS = 'View Franchise Locations';
export const CREATE_FRANCHISE_LOCATIONS = 'Create Franchise Locations';
export const EDIT_FRANCHISE_LOCATIONS = 'Edit Franchise Locations';
export const DELETE_FRANCHISE_LOCATIONS = 'Delete Franchise Locations';

// Delivery Locations
export const DELIVERY_LOCATIONS = 'Delivery Locations';
export const VIEW_DELIVERY_LOCATIONS = 'View Delivery Locations';
export const CREATE_DELIVERY_LOCATIONS = 'Create Delivery Locations';
export const EDIT_DELIVERY_LOCATIONS = 'Edit Delivery Locations';
export const DELETE_DELIVERY_LOCATIONS = 'Delete Delivery Locations';

// LLCs
export const LLCS = 'LLCs';
export const VIEW_LLCS = 'View LLCs';
export const CREATE_LLCS = 'Create LLCs';
export const EDIT_LLCS = 'Edit LLCs';
export const DELETE_LLCS = 'Delete LLCs';

// Items
export const ITEMS = 'Items';
export const VIEW_ITEMS = 'View Items';
export const CREATE_ITEMS = 'Create Items';
export const EDIT_ITEMS = 'Edit Items';
export const DELETE_ITEMS = 'Delete Items';

// Users
export const USERS = 'Users';
export const VIEW_USERS = 'View Users';
export const CREATE_USERS = 'Create Users';
export const EDIT_USERS = 'Edit Users';
export const DELETE_USERS = 'Delete Users';
export const EDIT_USER_PERMISSIONS = 'Edit User Permissions';

// Signature Generator
export const SIGNATURE_GENERATOR = 'Signature Generator';
export const VIEW_SIGNATURE_GENERATOR = 'View Signature Generator';

// Announcements
export const ANNOUNCEMENTS = 'Announcements';
export const VIEW_ANNOUNCEMENTS = 'View Announcements';
export const CREATE_ANNOUNCEMENTS = 'Create Announcements';
export const EDIT_ANNOUNCEMENTS = 'Edit Announcements';
export const DELETE_ANNOUNCEMENTS = 'Delete Announcements';
