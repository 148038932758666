import React from 'react';
import { Tab, Tabs } from '@material-ui/core';

export const TabFilter = ({ value, tabs, onChange = () => {}, ...rest }) => {
  return (
    <Tabs
      scrollButtons="auto"
      textColor="secondary"
      variant="scrollable"
      value={value}
      onChange={(event, value) => {
        event.persist();
        onChange(value);
      }}
      {...rest}
    >
      {tabs.map((tab, index) => (
        <Tab key={`filter-tab-${index}`} value={tab.value} label={tab.label} />
      ))}
    </Tabs>
  );
};
