import React from 'react';
import { Badge, Box, Divider, Grid, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';

import { useEnhancedTableContext } from './enhanced-table.context';
import { FilterList } from '@material-ui/icons';

import { checkIsFiltered, initFilters } from './utils';

export const EnhancedTableToolbar = () => {
  const {
    title,
    selected,
    filters,
    tableActionsComponent: TableActions,
    tableActionsComponentProps: tableActionsProps,
    isDraggable,
    updateTableStatus
  } = useEnhancedTableContext();

  const numSelected = selected.length;

  const isFiltered = checkIsFiltered({ filters });

  return (
    <Box>
      <Toolbar>
        {numSelected > 0 && !isDraggable ? (
          <Typography variant="subtitle1" color="textSecondary">
            {numSelected} selected
          </Typography>
        ) : (
          <Box>
            <Typography variant="subtitle1" color="textSecondary">
              {title}
            </Typography>
          </Box>
        )}

        {!isDraggable && (
          <Box marginLeft={'auto'}>
            <Grid container spacing={2} alignItems="center">
              {numSelected > 0 && TableActions && tableActionsProps.length > 0 && (
                <Grid item>
                  <TableActions actions={tableActionsProps} />
                </Grid>
              )}

              <Grid item>
                <Tooltip title={isFiltered ? 'Clear all filters' : 'No filtered'} arrow>
                  <IconButton
                    onClick={() => {
                      if (isFiltered) {
                        updateTableStatus({ filters: initFilters({ filters }) });
                      }
                    }}
                  >
                    <Badge
                      color="secondary"
                      variant="dot"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      invisible={!isFiltered}
                    >
                      <FilterList />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        )}
      </Toolbar>

      <Divider />
    </Box>
  );
};
