import React from 'react';
import { useHistory } from 'react-router';
import { Box, Divider } from '@material-ui/core';

import { TabFilter } from 'src/components';
import { useCurrentUser } from 'src/contexts/firebase-context';
import { FULFILLMENT, SUPER_ADMIN } from 'src/constants';

import { useAccountPageContext } from './account-page.context';

export const AccountTab = () => {
  const history = useHistory();
  const { role } = useCurrentUser();
  const { currentTab } = useAccountPageContext();

  let tabs = [
    {
      value: 'general',
      label: 'General'
    },
    {
      value: 'password',
      label: 'Update Password'
    }
  ];

  // hiding notifcations tab for now
  // if (role === SUPER_ADMIN || role === FULFILLMENT) {
  //   tabs = [
  //     ...tabs,
  //     {
  //       value: 'notifications',
  //       label: 'Notifications'
  //     }
  //   ];
  // }

  return tabs.map(item => item.value).includes(currentTab) ? (
    <Box>
      <TabFilter
        value={currentTab}
        tabs={tabs}
        onChange={value => {
          history.push(value);
        }}
      />

      <Divider />
    </Box>
  ) : null;
};
