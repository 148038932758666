import moment from 'moment';
import { useBulkUpdateComplianceReportsMutation, useFetchComplianceReports } from 'src/apis';
import { useCurrentUser } from 'src/contexts/firebase-context';

export const useNotifications = () => {
  const { id: userId } = useCurrentUser();
  const { data: { all: _reports = [] } = {} } = useFetchComplianceReports();
  const [bulkUpdate] = useBulkUpdateComplianceReportsMutation();

  const list = _reports
    .filter(({ readRecipients = [] }) => !readRecipients.includes(userId))
    .sort(({ updatedAtTimestamp: a }, { updatedAtTimestamp: b }) => (a < b ? 1 : a > b ? -1 : 0))
    .map(({ id, createdAtTimestamp, franchiseeLocationName }) => {
      const title = `A new compliance report has been submitted for ${franchiseeLocationName}`;
      const subtitle = moment.unix(createdAtTimestamp).fromNow();
      const link = `/app/compliance-v2/reports/${id}/view`;

      return { title, subtitle, link };
    });

  const onMarkAllAsRead = () => {
    const reports = _reports
      .filter(({ readRecipients = [] }) => !readRecipients.includes(userId))
      .map(report => {
        const { readRecipients = [] } = report;

        return { ...report, readRecipients: [...readRecipients, userId] };
      });

    bulkUpdate({ reports });
  };

  return { data: { list, onMarkAllAsRead } };
};
