export const APP_VERSION = '1.3.2';
export const APP_VERSION_CHECK_REFRESH_COUNT_LS_KEY = 'cml-app-version-check-refresh-count';
export const APP_VERSION_CHECK_REFRESH_MAX_COUNT = 3;

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

// constants for order notification

export const orderNotificationStatus = {
  IS_PENDING: 'isPending',
  IS_APPROVED: 'isApproved',
  IS_FULFILLED: 'isFulfilled'
};

// Roles for roleGuard and user saving
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const FRANCHISEE_OWNER = 'FRANCHISEE_OWNER';
export const MANAGER = 'MANAGER';
export const EMPLOYEE = 'EMPLOYEE';
export const FULFILLMENT = 'FULFILLMENT';

// Item status options for Item and Orders
export const itemStatusOptions = [
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'out_of_stock',
    label: 'Out of Stock'
  },
  {
    value: 'discontinued',
    label: 'Discontinued'
  }
];

// Total Max volume of pallet used

export const PALLET_MAX_VOLUME = 137224;
export const PALLET_MAX_WEIGHT = 1728;

// This goes into the intial state of all Views with lists
// It starts dictates the starting and ending dates to filter to documents in the list
// Global place to update all
export const INITIAL_DATE_RANGE = 'week';

export const DATE_RANGE_OPTIONS = [
  {
    value: 'today',
    label: 'Today'
  },
  {
    value: 'yesterday',
    label: 'Yesterday'
  },
  {
    value: 'week',
    label: 'Week'
  },
  {
    value: 'last7',
    label: 'Last 7 Days'
  },
  {
    value: 'last30',
    label: 'Last 30 Days'
  },
  {
    value: 'last90',
    label: 'Last 90 Days'
  },
  {
    value: 'weekToDate',
    label: 'Week To Date'
  },
  {
    value: 'monthToDate',
    label: 'Month To Date'
  },
  {
    value: 'yearToDate',
    label: 'Year To Date'
  },
  {
    value: 'lastMonth',
    label: 'Last Month'
  },
  {
    value: 'lastYear',
    label: 'Last Year'
  }
];

export const COMPLIANCE_REPORT_STATUS_MAP = {
  PUBLISHED: 'published',
  DRAFT: 'draft'
}

export const TIMEZONES_MAP = {
  PST: {
    zone: 'PST',
    name: 'Los_Angeles'
  },
  MST: {
    zone: 'MST',
    name: 'America/Denver'
  },
  CST: {
    zone: 'CST',
    name: 'America/Chicago'
  },
  EST: {
    zone: 'EST',
    name: 'America/New_York'
  },
};
