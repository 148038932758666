import { useFetchFranchiseeLocations } from '../franchisee-locations';
import { useFetchComplianceReportQuestions } from './questions.hook';
import { useFetchComplianceReportQuery, useFetchComplianceReportsQuery } from './reports.api';
import { checkIsDnq, getOverAllScore } from './utils';

const getComplianceReportDetails = ({ report, questions: allQuestions, franchiseeLocations }) => {
  const { questions: reportQuestions = [], franchiseeLocationId = '' } = report || {};

  const questions = reportQuestions
    .map(rq => {
      const q = allQuestions.find(aq => aq.id === rq.id);

      if (!q) {
        return undefined;
      }

      return { ...q, ...rq };
    })
    .filter(rq => !!rq);

  const score = getOverAllScore(questions);
  const scoreName = `${score.toFixed(2)}%`;
  const result = score >= 60 ? 'pass' : 'fail';
  const resultName = `${result.charAt(0).toUpperCase() + result.slice(1)}`;

  const franchiseeLocation = franchiseeLocations.find(item => item.id === franchiseeLocationId);

  if (!franchiseeLocation) {
    return undefined;
  }

  const {
    territoryId,
    territoryName = '',
    name: franchiseeLocationName = '',
    type: franchiseeLocationType = '',
    typeName: franchiseeLocationTypeName = ''
  } = franchiseeLocation;

  if (!territoryId || !territoryName || !franchiseeLocationName || !franchiseeLocationTypeName) {
    return undefined;
  }

  return {
    ...report,
    questions,
    score,
    scoreName,
    result,
    resultName,
    territoryId,
    territoryName,
    franchiseeLocationName,
    franchiseeLocationType,
    franchiseeLocationTypeName
  };
};

const getBlankComplianceReportDetails = ({ franchiseeLocationId, questions: allQuestions, franchiseeLocations }) => {
  const franchiseeLocation = franchiseeLocations.find(item => item.id === franchiseeLocationId);

  if (!franchiseeLocation) {
    return undefined;
  }

  const {
    territoryId,
    territoryName = '',
    name: franchiseeLocationName = '',
    type: franchiseeLocationType = '',
    typeName: franchiseeLocationTypeName = ''
  } = franchiseeLocation;

  const questions = allQuestions
    .map(q => ({...q, pass: null}))
    .filter(q => q.type === franchiseeLocationType);

  if (!territoryId || !territoryName || !franchiseeLocationName || !franchiseeLocationTypeName) {
    return undefined;
  }

  return {
    questions,
    score: null,
    scoreName: null,
    result: null,
    resultName: null,
    territoryId,
    territoryName,
    franchiseeLocationName,
    franchiseeLocationType,
    franchiseeLocationTypeName
  };
};

const getComplianceReportDraftDetails = ({ report, questions: allQuestions, franchiseeLocations }) => {
  const { questions: reportQuestions = [], franchiseeLocationId = '' } = report || {};

  const questions = reportQuestions
    .map(rq => {
      const q = allQuestions.find(aq => aq.id === rq.id);

      if (!q) {
        return undefined;
      }

      return { ...q, ...rq };
    })
    .filter(rq => !!rq);

  const franchiseeLocation = franchiseeLocations.find(item => item.id === franchiseeLocationId);

  if (!franchiseeLocation) {
    return undefined;
  }

  const {
    territoryId,
    territoryName = '',
    name: franchiseeLocationName = '',
    type: franchiseeLocationType = '',
    typeName: franchiseeLocationTypeName = ''
  } = franchiseeLocation;

  if (!territoryId || !territoryName || !franchiseeLocationName || !franchiseeLocationTypeName) {
    return undefined;
  }

  return {
    ...report,
    questions,
    territoryId,
    territoryName,
    franchiseeLocationName,
    franchiseeLocationType,
    franchiseeLocationTypeName
  };
};

export const useFetchComplianceReports = ({ where, isDraft = false } = {}) => {
  const { data: reports = [], isLoading: loading_reports, ...rest } = useFetchComplianceReportsQuery({
    where,
    isDraft
  });
  const {
    data: { all: questions = [] },
    isLoading: loading_questions
  } = useFetchComplianceReportQuestions();
  const { data: franchiseeLocations = [], isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();

  const getDetails = isDraft ? getComplianceReportDraftDetails : getComplianceReportDetails;

  const all = reports.map(report => getDetails({ report, questions, franchiseeLocations })).filter(report => !!report);

  const dnq = all
    .filter(item => checkIsDnq(item.questions))
    .map(item => ({ ...item, result: 'dnq', resultName: 'DNQ' }));

  const no_dnq = all.filter(item => !checkIsDnq(item.questions));

  const passed = no_dnq.filter(item => item.result === 'pass');
  const failed = no_dnq.filter(item => item.result === 'fail');

  const truck = all.filter(item => item.franchiseeLocationType === 'truck');
  const restaurant = all.filter(item => item.franchiseeLocationType === 'restaurant');

  return {
    data: { all, dnq, passed, failed, truck, restaurant },
    isLoading: loading_reports || loading_questions || loading_franchiseeLocations,
    ...rest
  };
};

export const useFetchComplianceReport = ({ id, isDraft = false } = {}) => {
  const { data: report = {}, isLoading: loading_report, ...rest } = useFetchComplianceReportQuery({ id, isDraft });
  const {
    data: { all: questions = [] },
    isLoading: loading_questions
  } = useFetchComplianceReportQuestions();
  const { data: franchiseeLocations = [], isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();

  return {
    data: getComplianceReportDetails({ report, questions, franchiseeLocations }) || {},
    isLoading: loading_report || loading_questions || loading_franchiseeLocations,
    ...rest
  };
};

export const useFetchBlankComplianceReport = ({ id }) => {
  const {
    data: { all: questions = [] },
    isLoading: loading_questions
  } = useFetchComplianceReportQuestions();
  const { data: franchiseeLocations = [], isLoading: loading_franchiseeLocations } = useFetchFranchiseeLocations();

  return {
    data: getBlankComplianceReportDetails({ franchiseeLocationId: id, questions, franchiseeLocations }) || {},
    isLoading: loading_questions || loading_franchiseeLocations
  };
};
