import { useFetchComplianceReportQuestionQuery, useFetchComplianceReportQuestionsQuery } from './questions.api';
import { useFetchComplianceReportSectionsQuery } from './sections.api';

const getQuestionDetails = ({ question = {}, sections = [] }) => {
  const { sectionId = '', type = '' } = question;
  const section = sections.find(item => item.id === sectionId);

  if (!section) {
    return undefined;
  }

  const sectionName = section && section.name ? section.name.charAt(0).toUpperCase() + section.name.slice(1) : '';
  const typeName = `${type.charAt(0).toUpperCase() + type.slice(1)}`;

  return { ...question, section, sectionName, typeName };
};

export const useFetchComplianceReportQuestions = ({ where } = {}) => {
  const { data: questions = [], isLoading: loading_questions, ...rest } = useFetchComplianceReportQuestionsQuery({
    where
  });
  const { data: sections = [], isLoading: loading_sections } = useFetchComplianceReportSectionsQuery();

  const all = questions.map(question => getQuestionDetails({ question, sections })).filter(question => !!question);
  const restaurant = all.filter(item => item.type === 'restaurant');
  const truck = all.filter(item => item.type === 'truck');

  return { data: { all, restaurant, truck }, isLoading: loading_questions || loading_sections, ...rest };
};

export const useFetchComplianceReportQuestion = ({ id }) => {
  const { data: question = {}, isLoading: loading_question, ...rest } = useFetchComplianceReportQuestionQuery({ id });
  const { data: sections = [], isLoading: loading_sections } = useFetchComplianceReportSectionsQuery();

  return {
    data: getQuestionDetails({ question, sections }) || {},
    isLoading: loading_question || loading_sections,
    ...rest
  };
};
