import moment from 'moment';
import { useBulkUpdateOrdersMutation, useFetchOrders } from 'src/apis';
import { useCurrentUser } from 'src/contexts/firebase-context';

export const useNotifications = () => {
  const { id: userId } = useCurrentUser();
  const { data: _orders = [] } = useFetchOrders();
  const [bulkUpdate] = useBulkUpdateOrdersMutation();

  const list = _orders
    .filter(({ readRecipients = [] }) => !readRecipients.includes(userId))
    .sort(({ updatedAtTimestamp: a }, { updatedAtTimestamp: b }) => (a < b ? 1 : a > b ? -1 : 0))
    .map(
      ({
        id,
        overnightShipping,
        status,
        orderNumber,
        territory: { name: territoryName = '' } = {},
        updatedAtTimestamp
      }) => {
        let statusText = 'updated';

        if (status === 'pending') {
          statusText = 'created';
        }

        if (status === 'revision') {
          statusText = 'revised';
        }

        if (status === 'approved') {
          statusText = 'approved';
        }

        if (status === 'fulfilled') {
          statusText = 'fulfilled';
        }

        if (status === 'expired') {
          statusText = 'expired';
        }

        const title = `${
          overnightShipping ? 'Overnight order' : 'Order'
        } #${orderNumber} for ${territoryName} has been ${statusText}`;
        const subtitle = moment.unix(updatedAtTimestamp).fromNow();
        const link = `/app/orders/${id}`;

        return { title, subtitle, link };
      }
    );

  const onMarkAllAsRead = () => {
    const orders = _orders
      .filter(({ readRecipients = [] }) => !readRecipients.includes(userId))
      .map(report => {
        const { readRecipients = [] } = report;

        return { ...report, readRecipients: [...readRecipients, userId] };
      });

    bulkUpdate({ orders });
  };

  return { data: { list, onMarkAllAsRead } };
};
