import React from 'react';
import { Box, Breadcrumbs } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import PropTypes, { string } from 'prop-types';

import { HeaderTitle } from './header-title';
import { HeaderBreadcrumbsText } from './header-breadcrumbs-text';
import { HeaderBreadcrumbsLink } from './header-breadcrumbs-link';

export const HeaderBreadcrumbs = ({ breadcrumbs = [], title = '' }) => {
  if (breadcrumbs.length === 0) {
    return <HeaderTitle title={title} />;
  }

  if (breadcrumbs.length === 1) {
    return <HeaderBreadcrumbsText text={breadcrumbs[0].label} />;
  }

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs
          .filter((item, index) => index < breadcrumbs.length - 1)
          .map((item, index) => (
            <HeaderBreadcrumbsLink key={`header-breadcrumbs-${index}`} label={item.label} link={item.link} />
          ))}

        <HeaderBreadcrumbsText text={breadcrumbs[breadcrumbs.length - 1].label} />
      </Breadcrumbs>

      <HeaderTitle title={title} />
    </Box>
  );
};

HeaderBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string
    })
  ),
  title: string
};
