import { db, storage } from 'src/lib/firebase';
import { baseApi, bulkDeleteDocs, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';
import { collection, doc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const collectionName = 'mystery_shopper_reports';

export const mysteryShopperReportsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchMysteryShopperReports: builder.query({
      queryFn: async ({ where } = {}) => {
        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['mystery-shopper-reports']
    }),

    fetchMysteryShopperReport: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createMysteryShopperReport: builder.mutation({
      queryFn: async ({ userId, territoryId, franchiseeLocationId, date, text, files: uploadFiles = [] }) => {
        const cRef = collection(db, collectionName);
        const docRef = doc(cRef);

        const files = await Promise.all(
          uploadFiles.map(async file => {
            // TODO - refactor storage, ensure this works
            const fileName = `${collectionName}/${docRef.id}/${file.name}`;
            const storageRef = ref(storage, fileName);
            const uploadTask = await uploadBytes(storageRef, file)
            const url = await getDownloadURL(uploadTask.ref);
            const { name } = file;

            return { name, url };
          })
        );

        return await createDoc(collectionName, {
          docId: ref.id,
          userId,
          territoryId,
          franchiseeLocationId,
          date: new Date(date),
          text,
          files
        });
      },
      invalidatesTags: ['mystery-shopper-reports']
    }),

    updateMysteryShopperReport: builder.mutation({
      queryFn: async ({ id, userId, territoryId, franchiseeLocationId, date, text, files: uploadFiles = [] }) => {
        const docRef = doc(db, collectionName, id);

        const files = await Promise.all(
          uploadFiles.map(async file => {
            // TODO - refactor storage, ensure this works
            const fileName = `${collectionName}/${docRef.id}/${file.name}`;
            const storageRef = ref(storage, fileName);
            const uploadTask = await uploadBytes(storageRef, file)
            const url = await getDownloadURL(uploadTask.ref);
            const { name } = file;

            return { name, url };
          })
        );

        return await updateDoc(collectionName, {
          id,
          userId,
          territoryId,
          franchiseeLocationId,
          date: new Date(date),
          text,
          files
        });
      },
      invalidatesTags: ['mystery-shopper-reports']
    }),

    deleteMysteryShopperReport: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['mystery-shopper-reports']
    }),

    bulkDeleteMysteryShopperReports: builder.mutation({
      queryFn: async ({ ids = [] }) => {
        return await bulkDeleteDocs(collectionName, { ids });
      },
      invalidatesTags: ['mystery-shopper-reports']
    })
  })
});

export const {
  useFetchMysteryShopperReportsQuery,
  useFetchMysteryShopperReportQuery,
  useCreateMysteryShopperReportMutation,
  useUpdateMysteryShopperReportMutation,
  useDeleteMysteryShopperReportMutation,
  useBulkDeleteMysteryShopperReportsMutation
} = mysteryShopperReportsApi;
