import { filterRows, paginateRows, selectRow } from 'src/utils/table';
import { useFetchTerritories } from '../territories';
import { useFetchFranchiseeLocationQuery, useFetchFranchiseeLocationsQuery } from './franchisee-locations.api';

export const useFetchFranchiseeLocations = () => {
  const { data, isLoading } = useFetchFranchiseeLocationsQuery();
  const { data: territories, isLoading: loading_territories } = useFetchTerritories();

  let franchiseeLocations = data || [];

  franchiseeLocations = franchiseeLocations
    .map(franchiseeLocation => {
      const { id = null, name = '', territoryId = '', type = 'truck', enabled = true } = franchiseeLocation;

      const typeName = type === 'truck' ? 'Truck' : 'Restaurant';
      const territory = territories.find(item => item.id === territoryId) || {};
      const { name: territoryName = '' } = territory;

      return {
        id,
        name,
        territoryId,
        territoryName,
        type,
        typeName,
        enabled
      };
    })
    .filter(item => !!item.territoryName);

  franchiseeLocations.sort((a, b) => {
    if (a.territoryName < b.territoryName) {
      return -1;
    }
    if (a.territoryName > b.territoryName) {
      return 1;
    }
    return 0;
  });

  return { data: franchiseeLocations, isLoading: isLoading || loading_territories };
};

export const useFetchFranchiseeLocationsTableRows = ({ id, query, filters, page, limit }) => {
  const { data, isLoading } = useFetchFranchiseeLocations();

  const selected = selectRow(data, id);
  const filtered = filterRows(selected, query, filters);
  const paginated = paginateRows(filtered, page, limit);

  return { data: [selected, filtered, paginated, data], isLoading };
};

export const useFetchFranchiseeLocation = ({ id }) => {
  const { data: franchiseeLocation = {}, isLoading } = useFetchFranchiseeLocationQuery({ id });

  const { name = '', territoryId = '', type = 'truck', enabled = true } = franchiseeLocation;

  return {
    data: {
      id,
      name,
      territoryId,
      type,
      enabled
    },
    isLoading
  };
};
