import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const HeaderTitle = ({ title = '' }) => {
  return (
    <Typography variant="h3" color="textPrimary">
      {title}
    </Typography>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.string
};
