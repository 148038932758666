import { baseApi, createDoc, deleteDoc, getCollection, getDoc, updateDoc } from '../base';
import {
  arrayRemove,
  doc,
  updateDoc as fUpdateDoc,
  deleteDoc as fDeleteDoc,
  arrayUnion,
  writeBatch,
  collection,
  addDoc,
  getDoc as fGetDoc,
  getDocs,
  query
} from 'firebase/firestore';
import { db } from 'src/lib/firebase';

/**
 * recommended to use
 */
const collectionName = 'delivery_locations';

export const deliveryLocationsApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    fetchDeliveryLocations: builder.query({
      queryFn: async (props = { where: null }) => {
        const { where } = props;

        let options = {};

        if (where) {
          options.where = where;
        }

        return await getCollection(collectionName, options);
      },
      providesTags: ['delivery-locations']
    }),

    fetchDeliveryLocation: builder.query({
      queryFn: async ({ id }) => {
        return await getDoc(collectionName, { id });
      }
    }),

    createDeliveryLocation: builder.mutation({
      queryFn: async ({ name, territoryId, address1, address2, city, state, zip, mobile, email, enabled }) => {
        return await createDoc(collectionName, {
          name,
          territoryId,
          address1,
          address2,
          city,
          state,
          zip,
          mobile,
          email,
          enabled
        });
      },
      invalidatesTags: ['delivery-locations']
    }),

    updateDeliveryLocation: builder.mutation({
      queryFn: async ({ id, name, territoryId, address1, address2, city, state, zip, mobile, email, enabled }) => {
        return await updateDoc(collectionName, {
          id,
          name,
          territoryId,
          address1,
          address2,
          city,
          state,
          zip,
          mobile,
          email,
          enabled
        });
      },
      invalidatesTags: ['delivery-locations']
    }),

    deleteDeliveryLocation: builder.mutation({
      queryFn: async ({ id }) => {
        return await deleteDoc(collectionName, { id });
      },
      invalidatesTags: ['delivery-locations']
    })
  })
});

export const {
  useFetchDeliveryLocationsQuery,
  useFetchDeliveryLocationQuery,
  useCreateDeliveryLocationMutation,
  useUpdateDeliveryLocationMutation,
  useDeleteDeliveryLocationMutation
} = deliveryLocationsApi;

/**
 * not recommended to use
 */
export const useDeliveryLocationsApi = () => {
  const deleteDeliveryLocationFromTerritory = (territoryId, deliveryLocationToBeRemoved) => {
    const docRef = doc(db, 'deliveryLocations', territoryId);

    return fUpdateDoc(docRef, {
      deliveryLocations: arrayRemove(deliveryLocationToBeRemoved)
    });
  };

  const deleteUniqueDeliveryLocation = id => {
    const docRef = doc(db, 'delivery_locations', id);

    return fDeleteDoc(docRef);
  };

  const addLocationIdToTerritory = (locationId, territoryId) => {
    const docRef = doc(db, 'deliveryLocations', territoryId);

    return fUpdateDoc(docRef, {
      locationIds: arrayUnion(locationId)
    });
  };

  const removeDeliveryLocationAndAddToNewTerritory = (
    oldTerritoryId,
    newTerritoryId,
    oldDeliveryAddress,
    newDeliveryAddress
  ) => {
    const batch = writeBatch(db);

    // Remove
    const oldTerritoryRef = doc(db, 'deliveryLocations', oldTerritoryId);

    // Add
    const newTerritoryRef = doc(db, 'deliveryLocations', newTerritoryId);

    batch.update(oldTerritoryRef, 'deliveryLocations', arrayRemove(oldDeliveryAddress));
    batch.update(newTerritoryRef, 'deliveryLocations', arrayUnion(newDeliveryAddress));

    return batch.commit();
  };

  const addDeliveryLocationToTerritory = (territoryId, deliveryLocation) => {
    const { id, recipientName, address1, address2, city, state, zip, mobile, email, enabled } = deliveryLocation;
    const docRef = doc(db, 'deliveryLocations', territoryId);

    return fUpdateDoc(docRef, {
      deliveryLocations: arrayUnion({
        id,
        recipientName,
        address1,
        address2,
        city,
        state,
        zip,
        mobile,
        email,
        enabled
      })
    });
  };

  const createDeliveryLocation = location => {
    const {
      territoryId,
      // territoryLocationIds,
      name,
      recipientName,
      address1,
      address2,
      city,
      state,
      zip,
      mobile,
      email,
      enabled
    } = location;
    const cRef = collection(db, 'delivery_locations');

    return addDoc(cRef, {
      territoryId,
      name,
      recipientName,
      address1,
      address2,
      city,
      state,
      zip,
      mobile,
      email,
      enabled
    });
  };

  const getAllLocations = () => {
    const cRef = collection(db, 'delivery_locations');
    const cQuery = query(cRef);

    return getDocs(cQuery);
  };

  const updateUniqueLocation = (id, location) => {
    const { territoryId, name, recipientName, address1, address2, city, state, zip, mobile, email } = location;
    const docRef = doc(db, 'delivery_locations', id);

    return fUpdateDoc(docRef, {
      territoryId,
      name,
      recipientName,
      address1,
      address2,
      city,
      state,
      zip,
      mobile,
      email
    });
  };

  const getUniqueLocation = id => {
    const docRef = doc(db, 'delivery_locations', id);

    return fGetDoc(docRef);
  };

  return {
    deleteDeliveryLocationFromTerritory,
    deleteUniqueDeliveryLocation,
    addLocationIdToTerritory,
    removeDeliveryLocationAndAddToNewTerritory,
    addDeliveryLocationToTerritory,
    createDeliveryLocation,
    getAllLocations,
    updateUniqueLocation,
    getUniqueLocation
  };
};
