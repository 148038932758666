import React from 'react';
import { Grid } from '@material-ui/core';

import { DashboardPageContainer, HeaderWithButtons } from 'src/components';
import { SectionForm } from '../section-form';

export const ComplianceReportQuestionSectionEditPage = () => {
  return (
    <DashboardPageContainer title="Create Compliance Report Section">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithButtons
            breadcrumbs={[
              {
                label: 'Dashboard',
                link: '/app'
              },
              {
                label: 'Compliance Questions',
                link: '/app/compliance-v2/questions'
              },
              {
                label: 'Question Sections',
                link: '/app/compliance-v2/question-sections'
              },
              {
                label: 'Edit'
              }
            ]}
            title={'Edit Compliance Report Section'}
            cancelButton={{ link: '/app/compliance-v2/question-sections' }}
          />
        </Grid>

        <Grid item xs={12}>
          <SectionForm />
        </Grid>
      </Grid>
    </DashboardPageContainer>
  );
};
