import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { useTeamContacts, useUpdateTeamContactsMutation } from 'src/apis/team-contacts';

export const useNotificationsSettingsFormManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: {
      ordersTeamEmail,
      ordersTeamPhone,
      fulfillmentTeamEmail,
      fulfillmentTeamPhone,
      complianceTeamEmail,
      complianceTeamPhone
    },
    isLoading: loading_teamContacts
  } = useTeamContacts();
  const [mutate, { isLoading, isSuccess, isError }] = useUpdateTeamContactsMutation();

  const formManager = useFormik({
    enableReinitialize: true,

    initialValues: {
      ordersTeamEmail,
      ordersTeamPhone,
      fulfillmentTeamEmail,
      fulfillmentTeamPhone,
      complianceTeamEmail,
      complianceTeamPhone
    },

    validationSchema: Yup.object().shape({
      ordersTeamEmail: Yup.string()
        .email('Must be a valid email')
        .max(255),
      ordersTeamPhone: Yup.string()
        .min(10, 'Invalid phone number')
        .max(10, 'Invalid phone number'),
      fulfillmentTeamEmail: Yup.string()
        .email('Must be a valid email')
        .max(255),
      fulfillmentTeamPhone: Yup.string()
        .min(10, 'Invalid phone number')
        .max(10, 'Invalid phone number'),
      complianceTeamEmail: Yup.string()
        .email('Must be a valid email')
        .max(255),
      complianceTeamPhone: Yup.string()
        .min(10, 'Invalid phone number')
        .max(10, 'Invalid phone number')
    }),

    onSubmit: async values => {
      mutate(values);
    }
  });

  useEffect(() => {
    if (!isLoading && isSuccess && !isError) {
      enqueueSnackbar('Notifications settings updated', {
        variant: 'success'
      });
    }

    if (isError) {
      enqueueSnackbar('Updating notifications settings has been failed', {
        variant: 'error'
      });
    }
  }, [isLoading, isSuccess, isError]);

  return { formManager, isInitializing: loading_teamContacts, isSubmitting: isLoading };
};
